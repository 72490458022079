import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";

import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import DataTable from "../../../DataDisplay/Table";

const columns: GridColDef[] = [
  {
    headerName: "OS",
    flex: 1,
    field: "h_type",
    sortable: true,
    disableColumnMenu: true,
  },
  {
    headerName: "Attempts",
    flex: 1,
    field: "h_cnt",
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      cellParams1.value - cellParams2.value,
    renderCell: (params) => params.value?.toLocaleString() || params.value,
    disableColumnMenu: true,
  },
  {
    headerName: "Devices Attempts",
    flex: 1,
    field: "d_cnt",
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      cellParams1.value - cellParams2.value,
    renderCell: (params) => params.value?.toLocaleString() || params.value,
    disableColumnMenu: true,
  },
  {
    headerName: "Daily Attempts",
    flex: 1,
    field: "h_avg",
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      cellParams1.value - cellParams2.value,
    renderCell: (params) => params.value?.toLocaleString() || params.value,
    disableColumnMenu: true,
  },
  {
    headerName: "Daily Devices Attempts",
    flex: 1,
    field: "d_avg",
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      cellParams1.value - cellParams2.value,
    renderCell: (params) => params.value?.toLocaleString() || params.value,
    disableColumnMenu: true,
  },
];

interface TopOperatingSystemsCompromisedProps {
  totalData: any;
}
const TopOperatingSystemsCompromised = ({
  totalData,
}: TopOperatingSystemsCompromisedProps) => {
  const { t } = useTranslation();
  const createRows = (data: any) => {
    const rows = data.map((item: any, index: number) => {
      return { ...item, id: index + 1 };
    });

    return rows;
  };

  return (
    <div className="w-full min-h-24 bg-primary mb-4 py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <p className="text-base text-primary normal-case font-normal mb-6">
        {t("top_operating_systems_compromised")}
      </p>
      <div className="mx-6 flex-grow">
          <DataTable
            rows={createRows(totalData || [])}
            columns={columns}
            hideFooter={true}
          />
      </div>
      <div className="flex justify-end mt-5">
        <Tooltip title={t("show_hacking_dections_details")}>
          <div>
            <span className="mr-2">
              {t("hacking_detection_operating_systems")}
            </span>
            <span className="hover:underline text-linkblue">
              <Link className="text-linkblue" to="/threatdetection">
                {t("details")}
                <EastIcon className="text-xs ml-1" fontSize="small" />
              </Link>
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default TopOperatingSystemsCompromised;
