import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BilllingDetailsReducerInterface {
    cardDetails?: any,
    planDetails?: any,
    billingDetails?: any
};

const initialState: BilllingDetailsReducerInterface = {
    cardDetails: null,
    planDetails: null,
    billingDetails: null,
};

const BilllingDetailsReducerSlice = createSlice({
    name: 'billingDetailsReducer',
    initialState,
    reducers: {
        setCardDetails: (state, action: PayloadAction<any>) => {
            state.cardDetails = action.payload
        },
        setPlanDetails: (state, action: PayloadAction<any>) => {
            state.planDetails = action.payload
        },
        setBillingDetails: (state, action: PayloadAction<any>) => {
            state.billingDetails = action.payload
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = initialState;
        }
    }
});

export const { setCardDetails, setPlanDetails, setBillingDetails, userLogout } = BilllingDetailsReducerSlice.actions;

export default BilllingDetailsReducerSlice.reducer;