import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { acceptInvitation, signupValidate } from '../../../services/login';
import LoadMask from '../../utils/loadmask';
import SignUp from '../signup';
import { useTranslation } from 'react-i18next';
import AuthLayout from '..';
import { loginData } from '../AuthData';
import AuthForm from '../AuthForm';
import { showToast } from '../../utils/notifications';
import { Typography } from '@mui/material';

const Invite = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isNewMember, setIsNewMember] = useState(true);
    const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [params, setParams] = useState<any>({
        invite_by: undefined,
        token: undefined,
        user_id: undefined,
    });

    useEffect(() => {
        if (location && location.pathname === '/invites/accept') {
            setIsNewMember(false);
            setParams({
                invite_by: query.get('invite_by') || '',
                token: query.get('invite_token') || '',
                user_id: query.get('user_id') || '',
            });
        }  else if (location && location.pathname === '/invites/signup') {
            setIsNewMember(true);
        } else navigate('/');
    }, [location, query, navigate]);

    return <React.Fragment>{isNewMember ? <SignUp /> : <ExistingMemberInvite {...params} />}</React.Fragment>;
};

const ExistingMemberInvite = (props: InviteInterface) => {
    const { i18n, t } = useTranslation();
    const [isSuccess, setIsSuccess] = useState(false);
    const [loadMask, showLoadMask] = useState<any>(false);
    const [formData, setFormData] = useState<any>(null);

    useEffect(() => {
        loginData[0].disabled = true;
        loginData[0].value = props.user_id;
        setFormData(loginData);
    }, [props.user_id]);

    const onAcceptClick = async (values: any) => {
        const params = {
            ...values,
            token: props.token,
            user_id: props.user_id,
            invite_by: props.invite_by,
            domain: i18n.language === 'kr_KR' ? 'KR' : 'EN',
        };

        showLoadMask({ msg: t('checking_account_information') });
        const response = await acceptInvitation(params);
        showLoadMask(false);
        if (response?.result?.code === '0000') setIsSuccess(true);
        else if (t(`RCM-${response?.result?.code}`) !== '') showToast('error', t(`RCM-${response?.result?.code}`));
        else showToast('error', t('RCM-5700'));
    };

    const FormHeader = (
        <div>
            <div className={`text-center mb-10`}>
                <Typography variant="h1" className="font-bold">
                    {t(isSuccess ? 'congratulation' : 'accept_invitation')}
                </Typography>
            </div>
            <div className="mt-4 mb-[60px]">
                {isSuccess ? (
                    <>
                        <p>{t('accept_invite_success')}</p>
                        <p className="mt-2">{t('accept_invite_success_2')}</p>
                    </>
                ) : (
                    <>
                        {t('accept_invite_1')}
                        <b>{props.invite_by}</b>
                        {t('accept_invite_2')}
                    </>
                )}
            </div>
        </div>
    );
    return (
        <React.Fragment>
            {loadMask && <LoadMask msg={loadMask.msg} style={{ zIndex: '10007', background: '#88295C', color: 'white' }} />}
            <AuthLayout BackButton={false}>
                <AuthForm title={'accept_invitation'} formHeader={FormHeader} data={formData} btnText="accept" onSubmit={onAcceptClick} btnMargin="mt-10" />
            </AuthLayout>
        </React.Fragment>
    );
};

export default Invite;

interface InviteInterface {
    user_id: string;
    token: string;
    invite_by: string;
}
