import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HistoryRow from './HistoryRow';

interface ExpandableTableProps {
    data: any;
    onMenuClick: (key: string, row: any) => void;
    expanded?: boolean;
}

const ExpandableTable: React.FC<ExpandableTableProps> = ({ data, onMenuClick, expanded }) => {
    const { t } = useTranslation();
    const [sortConfig, setSortConfig] = useState<{
        field: string;
        direction: 'asc' | 'desc';
    } | null>(null);
    const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(12);

    const sortData = (data: any[]) => {
        if (!sortConfig) return data;

        return [...data].sort((a, b) => {
            let aValue = a[sortConfig.field];
            let bValue = b[sortConfig.field];

            if (typeof aValue === 'string' && aValue.includes('$')) {
                aValue = parseFloat(aValue.replace(/[^0-9.-]+/g, ''));
            }
            if (typeof bValue === 'string' && bValue.includes('$')) {
                bValue = parseFloat(bValue.replace(/[^0-9.-]+/g, ''));
            }

            const bothAreNumbers = typeof aValue === 'number' && typeof bValue === 'number';

            if (bothAreNumbers) {
                return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                // Fallback to string comparison
                aValue = String(aValue);
                bValue = String(bValue);
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        });
    };
    const handleSort = (field: string) => {
        const isAsc = sortConfig && sortConfig.field === field && sortConfig.direction === 'asc';
        setSortConfig({ field, direction: isAsc ? 'desc' : 'asc' });
    };

    const tableHeaders = [
        {
            title: 'date',
            key: 'sealed_date',
        },
        {
            title: 'type',
            key: 'service_type',
        },
        {
            title: 'package_name',
            key: 'package_name',
        },
        {
            title: 'user_id',
            key: 'user_id',
        },
        {
            title: 'app_category',
            key: 'option_appType',
        },
        {
            title: 'version',
            key: 'core_version',
        },
        {
            title: 'status',
            key: 'status_code',
        },
    ];
    
    return (
        <TableContainer component={Paper} elevation={0} sx={{ border: 'none' }}>
            {data.length > 0 ? (
                <>
                    <Table aria-label="historyical info table" sx={{ border: 'none' }} size="small">
                        <TableHead>
                            <TableRow sx={{ border: 'none' }}>
                                {tableHeaders.map((header) => (
                                    <TableCell
                                        key={header.key}
                                        className="font-bold cursor-pointer"
                                        sortDirection={sortConfig?.field === header.key ? sortConfig.direction : false}
                                        onClick={() => handleSort(header.key)}
                                        onMouseEnter={() => setHoveredColumn(header.key)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                        sx={{ padding: '6px 14px' }}
                                    >
                                        <span>{t(header.title)}</span>
                                        <span className="inline-block min-w-[4] ml-2">
                                            {sortConfig && sortConfig.field === header.key ? (
                                                sortConfig.direction === 'asc' ? (
                                                    <ArrowUpwardIcon fontSize="small" />
                                                ) : (
                                                    <ArrowDownwardIcon fontSize="small" />
                                                )
                                            ) : hoveredColumn === header.key ? (
                                                <ArrowDownwardIcon fontSize="small" />
                                            ) : null}
                                        </span>
                                    </TableCell>
                                ))}
                                {!expanded && (
                                    <TableCell sx={{ padding: '6px 14px' }} className="font-bold">
                                        {t('action')}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(data)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <React.Fragment key={row.id}>
                                        <HistoryRow key={row.id} row={row} index={row.id} onMenuClick={onMenuClick} expanded={expanded} />
                                    </React.Fragment>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        sx={{
                            paddingRight: '0',
                            '& .MuiToolbar-root': {
                                paddingRight: '0 !important',
                                paddingTop: '10px !important',
                            },
                        }}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                        rowsPerPageOptions={[5, 10, 12, 20, 30]}
                    />
                </>
            ) : (
                <div className="mx-auto text-xl text-center whitespace-pre py-52">
                    <Typography variant="body1" sx={{ padding: '16px', textAlign: 'center' }}>
                        {t('no_data_to_show')}
                    </Typography>
                </div>
            )}
        </TableContainer>
    );
};

export default ExpandableTable;
