export const isPresent = (value) => value !== null && value !== undefined;
export const isBlank = (value) => value === null || value === undefined;
export const isArray = (value) => Array.isArray(value);
export const isFunction = (value) => typeof value === 'function';
export const isString = (value) => typeof value === 'string';
export const isTruthy = (value) => !!value;
export const isNullOrEmptyString = (value) => isBlank(value) || value.trim().length === 0;
export const isNotNullOrEmptyString = (value) => !isNullOrEmptyString(value);
export const isNumeric = (value) => !isNaN(value - parseFloat(value));
export const isDate = (value) => value && value.getTime;
