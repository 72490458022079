import { Post } from "..";
import { getRegionalURL, RegionalURL } from "../../utility/AppURL";

type token = 'string';

export const getAndroidPackages = async (params: { token: token }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.getandroidpackages, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getIOSPackages = async (params: { token: token }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.getiospackages, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};
