import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, IconButton, Tooltip, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '../../../Foundation/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface SdkCardProps {
    item: {
        project_name: string;
        bundle_id_prefix: string;
    };
    building: boolean;
    onCredentialClick: () => void;
    onDownloadSDKClick: () => void;
    onSaveClick: (newTitle: string, project_name: string) => void;
    onSaveBundleIdPrefix: (newBundleIdPrefix: string, oldBundleIdPrefix: string) => void;
}

const SdkCard = ({ item, building, onDownloadSDKClick, onCredentialClick, onSaveClick, onSaveBundleIdPrefix }: SdkCardProps) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [editBundleId, setEditBundleId] = useState(false);
    const [title, setTitle] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [bundleIdPrefix, setBundleIdPrefix] = useState('');
    const [editBundleIdPrefix, setEditBundleIdPrefix] = useState('');
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isBetaAccount = authContext?.is_beta_account;
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;

    useEffect(() => {
        if (title !== item.project_name) {
            setTitle(item.project_name);
        }
        if (bundleIdPrefix !== item.bundle_id_prefix) {
            setBundleIdPrefix(item.bundle_id_prefix);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.project_name, item.bundle_id_prefix]);

    const handleEdit = () => {
        setEdit(true);
        setEditTitle(item.project_name);
    };

    const handleNameChange = (value: string) => {
        setEditTitle(value);
    };

    const handleSave = () => {
        onSaveClick(editTitle, title);
        setTitle(editTitle);
        setEdit(false);
    };

    const handleCancel = () => {
        setEditTitle(title);
        setEdit(false);
    };

    const handleEditBundleId = () => {
        setEditBundleId(true);
        setEditBundleIdPrefix(item.bundle_id_prefix);
    };

    const handleBundleIdChange = (value: string) => {
        setEditBundleIdPrefix(value);
    };

    const handleSaveBundleId = () => {
        onSaveBundleIdPrefix(editBundleIdPrefix, bundleIdPrefix);
        setBundleIdPrefix(editBundleIdPrefix);
        setEditBundleId(false);
    };

    const handleCancelBundleId = () => {
        setEditBundleIdPrefix(bundleIdPrefix);
        setEditBundleId(false);
    };

    return (
        <div className="w-full lg:w-[316px]">
            <Card variant="outlined" className="w-full lg:w-[316px]">
                <React.Fragment>
                    <CardContent>
                        <div className="mb-3">
                            <p className="text-[#A0A0A1] text-xs">{t('project')}</p>
                            <div className="flex items-center justify-between flex-none">
                                {edit ? (
                                    <TextField size="small" variant="outlined" value={editTitle} onChange={(e) => handleNameChange(e.target.value)} fullWidth />
                                ) : (
                                    <Tooltip title={item.project_name}>
                                        <p className="text-base truncate">{item.project_name}</p>
                                    </Tooltip>
                                )}

                                {edit ? (
                                    title === editTitle ? (
                                        <IconButton onClick={handleCancel}>
                                            <CancelIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={handleSave}>
                                            <CheckIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    )
                                ) : (
                                    (!isBetaAccount || (isBetaAccount && (isOwner || isManager))) && (
                                        <IconButton onClick={handleEdit}>
                                            <EditIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="mb-6">
                            <p className="text-[#A0A0A1] text-xs">{t('bundle_id_prefix')}</p>
                            <div className="flex items-center justify-between flex-none">
                                {editBundleId ? (
                                    <TextField size="small" variant="outlined" value={editBundleIdPrefix} onChange={(e) => handleBundleIdChange(e.target.value)} fullWidth />
                                ) : (
                                    <Tooltip title={item.bundle_id_prefix}>
                                        <p className="text-base truncate">{item.bundle_id_prefix}</p>
                                    </Tooltip>
                                )}

                                {editBundleId ? (
                                    bundleIdPrefix === editBundleIdPrefix ? (
                                        <IconButton onClick={handleCancelBundleId}>
                                            <CancelIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={handleSaveBundleId}>
                                            <CheckIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    )
                                ) : (
                                    ((isBetaAccount && (isOwner || isManager))) && (
                                        <IconButton onClick={handleEditBundleId}>
                                            <EditIcon sx={{ padding: 0 }} fontSize="small" />
                                        </IconButton>
                                    )
                                )}
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button key="1" variant="text" className="w-1/2 p-0 text-sm capitalize" onClick={onCredentialClick}>
                            {t('check_credential')}
                        </Button>
                        <span className="bg-[#D6D6D6] w-[1px] h-[24px]"></span>
                        <LoadingButton key="2" className="w-1/2 p-0 text-sm capitalize" loading={building} onClick={onDownloadSDKClick}>
                            {building ? t('build_in_progress') : t('download_sdk')}
                        </LoadingButton>
                    </CardActions>
                </React.Fragment>
            </Card>
        </div>
    );
};

export default SdkCard;
