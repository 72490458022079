import { useState } from "react";
import {
  Box,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { BarChartColors } from "../../../utils/common";
import ViewMore from "./ViewMore";

interface HackingTypesChartProps {
  streamType: string;
  streamLegend: any;
  streamData: any;
  setStreamType: (type: string) => void;
}

const HackingTypesChart = ({
  streamType,
  streamLegend,
  streamData,
  setStreamType
}: HackingTypesChartProps) => {

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (streamType: string) => {
    setStreamType(streamType);
    handleClose();
  };

  return (
    <div className="col-span-12 lg:col-span-8 xl:col-span-4 min-h-24 bg-primary py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0] pb-20">
      <div
        aria-controls={open ? 'stream-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="flex items-center cursor-pointer mb-3.5"
      >
        <p className="text-base text-primary normal-case font-normal">
          {streamType === "byDevice" && t("hacking_types_straming")}
          {streamType === "byType" && t("protected_unique_devices_data")}
        </p>
        <KeyboardArrowDownIcon />
      </div>
      <Menu
        id="stream-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuClick('byDevice')}>{t('hacking_types_straming')}</MenuItem>
        <MenuItem onClick={() => handleMenuClick('byType')}>{t('protected_unique_devices_data')}</MenuItem>
      </Menu>
    
      <div className="text-4xl mt-2">
        <Tooltip
          title={`${
            streamType === "byType"
              ? t("total_devices_protected_in_last_10_mins")
              : t("total_hacking_attempts_in_last_10_mins")
          } : ${streamLegend[streamType].total}`}
        >
          <>
            <span className="text-disabled font-light text-sm block">
              {t("last_10_mins")}
            </span>
            <span>{streamLegend[streamType].total.toLocaleString()}</span>
          </>
        </Tooltip>
      </div>
      <Box sx={{ height: "33.33%", mt: 4 }}>
        <ResponsiveBar
          data={streamData[streamType][0].data}
          indexBy="x"
          keys={["y"]}
          axisBottom={null}
          enableGridX={false}
          enableGridY={false}
          axisLeft={null}
          labelTextColor={"#36373A"}
          colors={BarChartColors}
          padding={0.4}
          margin={{ top: 10, right: 10, bottom: 10, left: 0 }}
          tooltip={(e: any) => (
            <Box sx={{ bgcolor: "white", border: "1px solid", p: 4 }}>
              <Typography>
                {t("no_of_hacking_types")} :{" "}
                <span className="font-semibold">{`${e.data.y}`}</span>
              </Typography>
              <Typography>
                {t("time_of_detection")} :{" "}
                <span className="font-semibold">{`${e.data.x}`}</span>
              </Typography>
            </Box>
          )}
        />
      </Box>
      <div className="flex justify-center gap-7">
        <div className="w-1/2">
          <p className="font-semibold">{t("top_hacking_types")}</p>
          <ul className="m-0 p-0 pl-4">
            {streamLegend[streamType].types
              .slice(0, 5)
              .map((e: any, index: any) => (

                <li key={e.key + index} className="text-sm mt-1">
                  {t(e.key.toLowerCase().replaceAll(" ", "_"), e.key as string)}{" "}
                  -  <span className="font-semibold">{e.cnt}</span>
                </li>
              ))}
          </ul>
        </div>
        <div className="w-1/2">
          <p className="font-semibold">{t("top_device_models")}</p>
          <ul className="m-0 p-0 pl-4">
            {streamLegend[streamType].models
              .slice(0, 5)
              .map((e: any, index: any) => (
                <li key={e.key + index} className="text-sm mt-1">
                  {e.key} - <span className="font-semibold">{e.cnt}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ViewMore type="absolute" tooltip={t('show_real_time_threat_analytics_details')} path={"/analytics"} label={t("realTimeThreatAnalytics")}/>
    </div>
  );
};

export default HackingTypesChart;
