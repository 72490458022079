import React, { useEffect, useState } from "react";
import { iosModalJson } from "./iosData";
import { useTranslation } from "react-i18next";
import { ModalConfig } from "../../../../../type/ios";
import Drawer from "@mui/material/Drawer";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from '@mui/icons-material/Close';
interface IOSModalProps {
  type: keyof typeof iosModalJson;
  visible: boolean;
  onCancel: (e?: any) => void;
  iosData?: any;
  buildData?: any;
  setBuildData?: any;
  isPlanValid?: any;
  isHybridPlanValid?: any;
}

const CredentialInformation = ({
  type,
  visible,
  onCancel,
  iosData,
}: IOSModalProps) => {
  const { t } = useTranslation();
  const data: ModalConfig = iosModalJson[type];
  const [isOpen, setIsOpen] = useState(visible);
  const [copyToolTip, setCopyToolTip] = useState(t("click_to_copy"));

  useEffect(() => {
    if (visible !== isOpen) setIsOpen(visible);
  }, [isOpen, visible]);

  const copyValueToClipboard = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value || "");
      setCopyToolTip(t("copied"));
      setTimeout(() => setCopyToolTip(t("click_to_copy")), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onCancel}>
      <div className="p-10 max-w-[800px]">
        <div className="mb-[50px] flex justify-between">
          <div>
            <h2 className="text-2xl font-medium m-0">{t(data.title)}</h2>
            <p className="text-[#A0A0A1] text-xs">{t(data.subtitle)}</p>
          </div>
          <IconButton className="w-10 h-10" onClick={onCancel}><CloseIcon/></IconButton>
        </div>
        <div className="mb-10">
          <p className="text-warning text-xs">{t("credential_alert")}</p>
          <p className="text-subtitle text-xs">{t("credential_guide")}</p>
        </div>
        <div>
          <ul className="list-none p-0 m-0">
            {data.items.map((item, index) => (
              <li key={index} className="flex md:flex-nowrap flex-wrap mb-8">
                <p className="text-sm font-medium min-w-[200px]">
                  {t(item.label as string)}
                </p>
                {item.function === "copy" ? (
                  <div className="flex items-center cursor-pointer">
                    <Tooltip title={copyToolTip}>
                      <IconButton
                        onClick={() =>
                          copyValueToClipboard(iosData[`${item.key}`])
                        }
                      >
                        <ContentCopyIcon
                          className="text-inka500"
                          fontSize={"small"}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={iosData[`${item.key}`] || ""}>
                      <p className="truncate max-w-[400px]">
                        {iosData[`${item.key}`]}
                      </p>
                    </Tooltip>
                  </div>
                ) : (
                  <p className="truncate max-w-[400px]">
                    {iosData[`${item.key}`]}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Drawer>
  );
};

export default CredentialInformation;
