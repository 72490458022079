import React, { useEffect, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Tooltip } from '@mui/material';
interface DragnDropProps {
    accept: Accept;
    title: string;
    subtitle: string;
    maxFiles: number;
    onChange?: (e: any) => void;
    color?: string;
    appFile?: [File];
}
const DragnDrop = ({ title, subtitle, accept, maxFiles, onChange, color, appFile }: DragnDropProps) => {
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>(appFile || []);

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxFiles,
        onDropAccepted(files, event) {
            setAcceptedFiles(files);
            onChange && onChange(files);
        },
    });

    useEffect(() => {
        if (appFile) {
            setAcceptedFiles(appFile);
        }
    }, [appFile]);
    
    const files = acceptedFiles.map((file: any) => (
        <li key={file.path} className='flex items-center'>
            <AttachFileIcon fontSize="small" className="text-sm mr-1 font-semibold" />
            <Tooltip title={file.path}><span className='block truncate text-xs'>{file.path}</span></Tooltip>
        </li>
    ));
    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })} className="bg-white transition border-dashed border-2 hover:border-[#8E44AD] hover:bg-[#e6cef0]  hover:bg-opacity-10 border-opacity-50 text-center pt-6 pb-10 px-5 cursor-pointer rounded-sm">
                <div className="flex justify-center mb-2">
                    <span className="bg-[#E3EEF9] rounded-full w-10 h-10 flex justify-center items-center">
                        <UploadFileIcon className={color ? 'text-accent' : 'text-[#1976D2]'} />
                    </span>
                </div>
                <p className={`underline text-sm ${color ? 'text-accent' : 'text-[#1976D2]'}`}>{title}</p>
                <p className="text-darkgray text-xs">{subtitle}</p>
                <input {...getInputProps()} />
            </div>
            <aside>
                <ul className="list-none p-0 m-0 my-2">{files}</ul>
            </aside>
        </section>
    );
};

export default DragnDrop;
