import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiIcon from '../../../../assets/images/api_icon.png';
import CliIcon from '../../../../assets/images/cli_icon.png';
import { downloadCLITool, openHelpCenter } from '../../../../services/main';
import { RootState } from '../../../../store';
import { AppURL } from '../../../../utility/AppURL';
import { Button } from '../../../Foundation/Button';
import ContentLayout from '../../../Layout/Content';
import { isProduction } from '../../../utils/common/CheckEnv';
import { showToast } from '../../../utils/notifications';

const ThirdParty = () => {
    const {i18n, t } = useTranslation();
    const navigate = useNavigate();
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    
    const isAndroidExpired = () => {
        let isExpired = false;
        if (planDetails?.plan?.android?.type === 'T' && planDetails?.plan?.android?.isExpired && planDetails?.plan?.hybrid?.type === 'T' && planDetails?.plan?.hybrid?.isExpired) isExpired = true;
        return isExpired;
    };

    const onDownloadCLIToolClick = () => downloadCLITool();
    const onUpgradeClick = () => navigate(`/billingdetails/plan`);
    const onHelpCenterClick = () => {
        if (isProduction()) {
            openHelpCenter(authContext.token, AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'));
        } else {
            showToast('info', t('only_available_in_production'));
        }
    };
    return (
        <ContentLayout>
            <React.Fragment>
                <div className="flex flex-none align-middle gap-4">
                    <div className="ant-descriptions-title text-2xl flex-none font-medium">
                        <span>{t('3rd_party_integration')}</span>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="flex flex-none items-center max-w-[840px]">
                        <span className="font-bold block text-nowrap mr-5">{t('cli_tool_title')}</span>
                        <span className="h-[1px] w-full bg-gray-200"></span>
                    </div>
                    <div className="flex flex-wrap flex-none gap-8 mt-4 w-full">
                        <p className="w-[60px] h-[60px] min-w-[60px] min-h-[60px]">
                            <img src={CliIcon} alt="icon" />
                        </p>
                        <div>
                            {planDetails?.android?.cli_auth_key && !isAndroidExpired() && (
                                <>
                                    <p>
                                        <Button className="mt-2 ml-auto" color="primary" onClick={onDownloadCLIToolClick} label={t('download')} />
                                    </p>
                                    <p className="pt-2.5 pb-4 break-all">
                                        <strong>{t('cli_key')}: </strong>
                                        {planDetails?.android?.cli_auth_key}
                                    </p>
                                </>
                            )}
                            {!planDetails?.android?.cli_auth_key && !isAndroidExpired() && (
                                <>
                                    <span className="mr-1 mb-2">{t('cli_key_missing_contact_help_desk')}</span>
                                    <a href={AppURL.helpCenter} className="bold underline text-linkblue">
                                        {t('help_desk_team')}
                                    </a>
                                </>
                            )}
                            {isAndroidExpired() && (
                                <>
                                    <p>
                                        <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>
                                            {t('upgrade_plan')}
                                        </Button>
                                    </p>
                                    <p className="pt-2.5 pb-4 break-all">
                                        {t('looks_like_you_need_bigger_plan_to_suit_your_requirement')}
                                    </p>
                                </>
                            )}
                            <ul className="list-none italic text-darkgray p-0 text-xs leading-6">
                                <li className="break-all">{t('cli_tool_desc1')}</li>
                                <li>{t('cli_tool_desc2')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="flex flex-none items-center max-w-[840px]">
                        <span className="font-bold block text-nowrap mr-5">{t('api_data_title')}</span>
                        <span className="h-[1px] w-full bg-gray-200"></span>
                    </div>
                    <div className="flex flex-wrap flex-none gap-8 mt-4  w-full">
                        <p className="w-[60px] h-[60px] min-w-[60px] min-h-[60px]">
                            <img src={ApiIcon} alt="icon" />
                        </p>
                        <div>
                            <p className="pb-4">
                                {t('api_data_desc')}{' '}
                                <span className={`text-linkblue underline font-semibold cursor-pointer`} onClick={onHelpCenterClick}>
                                    {t('help_desk_team')}
                                </span>
                                {t('api_data_desc4')}
                            </p>
                            <ul className="list-none italic text-darkgray p-0 text-xs leading-6">
                                <li>{t('api_data_desc1')}</li>
                                <li>{t('api_data_desc3')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </ContentLayout>
    );
};

export default ThirdParty;
