import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { contactUs } from "../../../../../services/manage/billingdetails";
import { RootState } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import { showToast } from "../../../../utils/notifications";
interface InquiryModalInterface {
  visible: boolean;
  onCancel: () => void;
}

const InquiryModal = (props: InquiryModalInterface) => {
  const { t } = useTranslation();
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      content: "",
    },
  });
  useEffect(() => {
    if (props.visible) reset();
  }, [props.visible, reset]);

  const onSendClick = async (values: any) => {
    const params = {
      ...values,
      token: authContext.token,
    };

    const response = await contactUs(params);
    if (response?.result?.code === "0000") {
      showToast("success", t("inquiry_details_has_sent_to_team"));
      props.onCancel();
    } else showToast("error", t("error_while_submitting_inquire"));
  };

  const onFinishFailed = () =>
    showToast("error", t("please_validate_mandatory_fields"));
  const telephonePattern = /^[0-9-+]+.{8,}$/;

  return (
    <Dialog
      open={props.visible}
      onClose={props.onCancel}
      aria-labelledby='form-dialog-title'
    >
      <DialogContent>
        <div className='h-full w-full grid items-baseline'>
          <div className="mb-10">
            <Typography variant='h1'>
              {t("inquiry_about_enterprise_plan")}
            </Typography>
            <div className='mb-4'>
              {t(
                "please_fill_in_the_details_below_and_we_will_get_in_touch_with_you_shortly"
              )}
            </div>
          </div>

          <form
            id='inquiryForm'
            onSubmit={handleSubmit(onSendClick, onFinishFailed)}
            style={{ display: "grid", gap: "20px" }}
          >
            <Controller
              name='name'
              control={control}
              rules={{ required: t("please_enter_full_name") }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("full_name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  fullWidth
                  variant='outlined'
                  placeholder={t("enter_full_name")}
                />
              )}
            />
            <Controller
              name='email'
              control={control}
              rules={{
                required: t("please_enter_valid_email_address"),
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: t("please_enter_valid_email_address"),
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type='email'
                  label={t("email_address")}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  fullWidth
                  variant='outlined'
                  placeholder={t("enter_email_address")}
                />
              )}
            />
            <Controller
              name='mobile'
              control={control}
              rules={{
                required: { value: true, message: t("please_enter_telephone") },
                pattern: {
                  value: telephonePattern,
                  message: t("please_enter_valid_telephone_number"),
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type='tel'
                  label={t("telephone")}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  fullWidth
                  variant='outlined'
                  placeholder={t("enter_telephone")}
                  InputProps={{ inputMode: "numeric" }}
                />
              )}
            />
            <Controller
              name='content'
              control={control}
              rules={{ required: t("please_enter_details_about_your_inquiry") }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label={t("inquiry_details")}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ""}
                  fullWidth
                  variant='outlined'
                  placeholder={t("enter_inquire_details")}
                />
              )}
            />
          </form>
        </div>
      </DialogContent>
      <DialogActions className="mt-10 p-0">
        <Button color={'secondary'} variant="outlined">
          <CachedIcon fontSize={'small'}/>
        </Button>
        <Button color="secondary" variant="outlined" onClick={props.onCancel}>{t("cancel")}</Button>
        <Button variant="contained" form='inquiryForm' type='submit' color='primary'>
          {t("send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InquiryModal;
