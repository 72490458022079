import React from 'react';
import { useTranslation } from 'react-i18next';

interface PasswordRequirementProps {
  newPassword: string;
}

const PasswordRequirement: React.FC<PasswordRequirementProps> = ({ newPassword }) => {
  const { t } = useTranslation();

  const passwordRequirements = [
    {
      check: newPassword.length >= 8 && newPassword.length <= 16,
      label: t("password_rule_1"),
    },
    {
      check: /[a-z]/.test(newPassword),
      label: t("password_rule_2"),
    },
    {
      check: /[A-Z]/.test(newPassword),
      label: t("password_rule_3"),
    },
    {
      check: /[0-9]/.test(newPassword),
      label: t("password_rule_4"),
    },
    {
      check: /[?=@#$!+\-_]/.test(newPassword),
      label: t("password_rule_5"),
    },
  ];

  const passwordStrength = passwordRequirements.filter(
    (requirement) => requirement.check
  ).length;

  return (
    <div className="mt-6 max-w-[400px]">
      <div className="flex mb-2">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={`flex-1 h-2 mr-1 ${
              newPassword.length > 0 && index < passwordStrength
                ? "bg-blue-500"
                : "bg-red-300"
            }`}
          ></div>
        ))}
      </div>
      <p className="text-sm font-bold">{t("password_requirement")}</p>
      <p className="text-xs text-gray-500 mb-2">{t("password_requirement_desc")}</p>
      <ul>
        {passwordRequirements.map((req, index) => (
          <li
            key={index}
            className={`text-xs ${
              newPassword.length === 0 || req.check ? "text-gray-500" : "text-red-500"
            }`}
          >
            {req.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordRequirement;
