// create Lottie Animation component
import React from 'react';
import Lottie from 'lottie-react';
import animationData from './loading.json';

const LottieAnimation = () => {
    return (
        <div className="lottie-container max-w-[200px] mx-auto">
          <Lottie animationData={animationData} />
        </div>
    );
};

export default LottieAnimation;
