import React, { useEffect, useState } from 'react';
import { AppBar, Button, Chip, Menu, MenuItem, Toolbar, Tooltip, IconButton } from '@mui/material';

import AppSealing from '../../assets/images/appsealingtext.png';
import AppSealingIcon from '../../assets/images/appsealinglogo.svg';
import { setRegion, setUserPreferences } from '../../store/main';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { openHelpCenter } from '../../services/main';
import { setPreferences } from '../../services/manage/accounts';
import { AppURL } from '../../utility/AppURL';
import { setNotifications, setBadgeCount } from '../../store/common';
import { NotificationsListType } from '../../type/index';
import { useTranslation } from 'react-i18next';
import NotificationMenu from './Header/notificationMenu';
import UserMenu from './Header/userMenu';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { Link } from 'react-router-dom';
const lang: any = {
    KR: 'kr_KR',
    EN: 'en_US',
};

interface MainHeaderProps {
    handleClick: () => void;
}

const MainHeader = (props: MainHeaderProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorLangEl, setAnchorLangEl] = useState<null | HTMLElement>(null);
    const [locale, setLocale] = useState<any>(null);

    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const notifications = useSelector((state: RootState) => state.commonReducer.notifications);
    const userPreferences = useSelector((state: RootState) => state.mainReducer.userPreferences);
    const badgeCount = useSelector((state: RootState) => state.commonReducer.badgeCount);
    const { i18n, t } = useTranslation();
    useEffect(() => {
        if (locale === null && userPreferences) {
            if (lang[userPreferences?.lang_set] !== i18n.language) {
                setLocale(i18n.language);
                localStorage.setItem('user-lang', i18n.language);
            } else if (localStorage.getItem('user-lang') === i18n.language && i18n.language !== locale) setLocale(i18n.language);
            else setLocale(lang[userPreferences?.lang_set]);
        }
    }, [userPreferences, locale, i18n.language]);

    useEffect(() => {
        if (userDetails) {
            dispatch(setRegion(userDetails?.default_service_region));
        }
    }, [userDetails, dispatch]);

    useEffect(() => {
        const getIsRead = localStorage.getItem('isRead');
        let modifiedJson: NotificationsListType[] = [];
        let totalUnread = 0;
        if (getIsRead) {
            notifications?.list.map((n) => {
                if (JSON.parse(getIsRead).includes(n.seq)) {
                    modifiedJson.push({ ...n, read: true });
                } else {
                    modifiedJson.push(n);
                    totalUnread++;
                }
                return false;
            });
            dispatch(setNotifications({ ...notifications, list: modifiedJson }));
            dispatch(setBadgeCount(totalUnread));
        } else {
            dispatch(setNotifications(notifications));
            dispatch(setBadgeCount(notifications?.list.length));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badgeCount, dispatch]);

    const onChangeLanguage = (lang: string) => {
        if (lang === locale) return;
        setAnchorLangEl(null);
        const updatePreferences = async () => {
            let params = {
                lang_set: lang === 'kr_KR' ? 'KR' : 'EN',
                token: authContext.token,
            };
            const response = await setPreferences(params);
            if (response?.result?.code === '0000') {
                dispatch(
                    setUserPreferences({
                        ...response.preference,
                    }),
                );
            }
        };

        updatePreferences();
        setLocale(lang);
        localStorage.setItem('user-lang', lang);
        i18n.changeLanguage(lang);
    };

    const onHelpCenterClick = () => openHelpCenter(authContext.token, AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'));

    const onIconClick = () => navigate('/');

    const getTag = () => {
        let planType = planDetails?.android.billing_type || 'N';
        const isExpired = planDetails?.android?.trial_expired === 'Y';
        const colour: string = isExpired ? 'red' : 'orange';
        if (planType === 'T')
            return (
                <Tooltip key={'trial-tag'} title={`Trial ${isExpired ? '- Expired' : ''}`}>
                    <Chip sx={{ backgroundColor: colour }} className="hidden lg:inline-block" onClick={() => navigate('/billingdetails/plan')} label={'Trial'} />
                </Tooltip>
            );
        return null;
    };

    return (
        <React.Fragment>
            <AppBar position="static" className="p-2 pl-4">
                <Toolbar className="flex justify-between">
                    <div className="cursor-pointer flex items-center gap-4">
                        <div onClick={onIconClick} className="flex items-center gap-4">
                            <img src={AppSealingIcon} alt="App Sealing" className="h-7 cursor-pointer" />
                            <img src={AppSealing} alt="App Sealing" className="h-7 cursor-pointer" />
                        </div>
                        <Link to="/billingdetails/plan">{getTag()}</Link>
                    </div>
                    <div className="flex items-center gap-5">
                        <div>
                            <Button
                                aria-label="language"
                                aria-controls="menu-language"
                                aria-haspopup="true"
                                onClick={(e) => setAnchorLangEl(e.currentTarget)}
                                color="primary"
                                variant="outlined"
                                className="capitalize border-[#D9D9D9] text-disabled rounded-full text-xs hover:text-accent px-5"
                            >
                                {t('language')}
                            </Button>
                            <Menu id="menu-language" anchorEl={anchorLangEl} keepMounted open={Boolean(anchorLangEl)} onClose={() => setAnchorLangEl(null)}>
                                <MenuItem onClick={() => onChangeLanguage(locale !== 'en_US' ? 'en_US' : 'kr_KR')} className="min-w-[150px] text-sm" value={locale !== 'en_US' ? 'en_US' : 'kr_KR'}>
                                    {locale !== 'en_US' ? 'English' : '한국어'}
                                </MenuItem>
                            </Menu>
                        </div>
                        <div>
                            <NotificationMenu notifications={notifications} />
                        </div>
                        <div>
                            <Tooltip title={t('help_center')}>
                                <IconButton aria-label="help center" onClick={onHelpCenterClick} className="p-1">
                                    <HelpOutlinedIcon sx={{ color: '#797A7C', fontSize: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <UserMenu userDetails={userDetails} />
                    </div>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default MainHeader;
