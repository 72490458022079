import React from "react";

type tMessages = {
    [key: string]: string
};

type tLocalizationData = {
    [lang: string]: tMessages;
};

const messages: tLocalizationData = {};
export const loadMessages = (data: tMessages, locale: string) => {
    messages[locale] = { ...messages[locale], ...data };
};

interface LocalizationInterface {
    lang: string;
    children?: any;
    toLanguageString: (key: string, defaultValue: string) => string;
};

const defaultValue: LocalizationInterface = {
    lang: 'en_US',
    toLanguageString: () => ''
};

interface LocalizationProviderInterface {
    lang: string;
    children?: any;
};

const LocalizationContext = React.createContext<LocalizationInterface>(defaultValue);
export const LocalizationProvider = ({ lang, children }: LocalizationProviderInterface) => {
    const toLanguageString = (key: string, defaultValue: string) => {
        let value = typeof (messages?.[lang]?.[key]) === 'string' ? messages?.[lang]?.[key] : defaultValue;
        return value;
    };

    return (
        <LocalizationContext.Provider value={{ lang, children, toLanguageString }} >
            {children}
        </LocalizationContext.Provider>
    );
};

export const useLocalizationService = () => React.useContext(LocalizationContext);