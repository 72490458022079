import React, { useEffect, useState } from 'react';
import { Drawer, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { showToast } from '../../../utils/notifications';
import { addAndroidProject } from '../../../../services/sealing/android';

interface RegisterNewAppProps {
    visible: boolean;
    onCancel: (e?: any) => void;
    setAppList?: React.Dispatch<React.SetStateAction<any[] | null>>;
}

const RegisterNewApp = ({ visible, onCancel, setAppList }: RegisterNewAppProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(visible);
    const { control, handleSubmit, reset } = useForm();
    const accountContext = useSelector((state: RootState) => state.mainReducer.accountDetails);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    useEffect(() => {
        if (visible !== isOpen) setIsOpen(visible);
    }, [isOpen, visible]);

    const onRegister = async (values: any) => {
        const params = {
            ...values,
            accountId: accountContext.account_id,
        };

        const response = await addAndroidProject(params, authContext.token);
        if (response?.result?.code === '0000') {
            showToast('success', t('successfully_registered_app'));
            onCancel();
            if (setAppList) setAppList(null);
            reset();
        } else {
            showToast('error', t(`RCM-${response?.result?.code}`, 'error_while_registering_app'));
        }
    };

    const onSubmit = (values: any) => {
        onRegister(values);
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onCancel} transitionDuration={1000}>
            <div className="p-10 min-w-4/5 md:min-w-[800px] w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-[50px] flex justify-between items-start gap-10">
                        <div>
                            <h2 className="m-0 text-2xl font-medium whitespace-nowrap">{t('register_new_app')}</h2>
                            <p className="text-xs text-darkgray">{t('register_new_app_subtitle')}</p>
                        </div>
                        <div>
                            <Button type="submit" variant="contained" color="primary" className="font-medium text-[13px]">
                                {t('register_new_app')}
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 max-w-[520px]">
                        <Controller
                            name="serviceType"
                            control={control}
                            defaultValue="NATIVE_AOS"
                            render={({ field }) => (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" className="text-sm font-medium">
                                        {t('app_framework')}
                                    </FormLabel>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value="NATIVE_AOS" control={<Radio />} label={t('native')} />
                                        <FormControlLabel value="HYBRID_AOS" control={<Radio />} label={t('hybrid')} />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="appName"
                            control={control}
                            rules={{ required: t('app_name_is_required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label={t('app_name')}
                                    placeholder={t('enter_app_name')}
                                    variant="outlined"
                                    fullWidth
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            name="packageName"
                            control={control}
                            rules={{ required: t('package_name_is_required') }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label={t('package_name')}
                                    placeholder={t('enter_package_name')}
                                    variant="outlined"
                                    fullWidth
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </div>
                </form>
            </div>
        </Drawer>
    );
};

export default RegisterNewApp;
