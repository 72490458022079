// validations.ts
import { ValidateResult } from 'react-hook-form';

export const validateUserName = (rule: any, value: string, t: any): ValidateResult => {
  if (value?.includes("<") || value?.includes(">")) {
    return t("should_not_contain_lessthan_greater_than_in_name");
  }
  return true;
};

export const validatePassword = (rule: any, value: string, t: any): ValidateResult => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#$\-_=+]).{8,16}$/;

  if (value.length < 8) {
    return t("password_should_contain_min_8_chars");
  }
  if (value.length > 16) {
    return t("password_should_contain_max_16_chars");
  }
  if (!regex.test(value)) {
    return t("password_criteria");
  }
  return true;
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string,
  t: any
): ValidateResult => {
  if (password !== confirmPassword) {
    return t("password_does_not_match_with_new_password");
  }
  return true;
};
