import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface commonReducerInterface {
    countries: Array<any>,
    timezones: Array<any>,
    languages?: Array<any>,
    appCategories: Array<any>,
    notifications: { tags: Array<any>, list: Array<any> },
    badgeCount?: number;
};

const initialState: commonReducerInterface = {
    countries: [],
    timezones: [],
    appCategories: [],
    notifications: { tags: [], list: [] },
    languages: [
        { label: 'English', value: 'EN' },
        { label: '한국어', value: 'KR' }
    ],
    badgeCount:0
};

const MainReducerSlice = createSlice({
    name: 'commonReducer',
    initialState,
    reducers: {
        setCommonData: (state, action: PayloadAction<commonReducerInterface>) => {
            state.countries = action.payload.countries;
            state.timezones = action.payload.timezones;
            state.appCategories = action.payload.appCategories;
            state.notifications = action.payload.notifications;
        },
        setCountries: (state, action: PayloadAction<any>) => {
            state.countries = action.payload;
        },
        setAppCategories: (state, action: PayloadAction<any>) => {
            state.appCategories = action.payload;
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = initialState;
        },
        setNotifications:(state, action: PayloadAction<any>) => {
            state.notifications = action.payload;
        },
        setBadgeCount: (state, action: PayloadAction<number>) => {
            state.badgeCount = action.payload;
        }
    }
});

export const { setCommonData, setCountries, setAppCategories, userLogout, setNotifications, setBadgeCount } = MainReducerSlice.actions;

export default MainReducerSlice.reducer;