import { ResponsiveLine } from "@nivo/line";
interface LineChartProps {
  data : any;
  chartTooltip: any;
  chartLengend?: any;
}
const LineChart = ({data, chartTooltip, chartLengend} : LineChartProps) => {
  const customColors = ['#F52D2D', '#4ECCF3'];
    return (
      <ResponsiveLine
      data={data}
      curve="linear"
      lineWidth={1}
      margin={{ top: 20, right: 5, bottom: 50, left: 55 }}
      useMesh={true}
      pointBorderWidth={1}
      pointLabelYOffset={-12}
      colors={customColors}
      pointColor="white"
      pointSize={8}
      pointBorderColor={{ from: "serieColor" }}
      theme={{grid: {line: { strokeWidth: 0.5}}}}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false
      }}
      axisLeft={{
   
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      tooltip={(e) => chartTooltip(e.point.data)}
      legends={chartLengend ? chartLengend() : []}
    />
    )
}



export default LineChart