import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import x64_ko_1 from '../../../../assets/images/x64_ko_001.png';
import x64_ko_2 from '../../../../assets/images/x64_ko_002.png';
import x64_ko_3 from '../../../../assets/images/x64_ko_003.png';
import x64_ko_4 from '../../../../assets/images/x64_ko_004.png';
import x64_ko_5 from '../../../../assets/images/x64_ko_005.png';
import x64_en_1 from '../../../../assets/images/x64_en_001.png';
import x64_en_2 from '../../../../assets/images/x64_en_002.png';
import x64_en_3 from '../../../../assets/images/x64_en_003.png';
import x64_en_4 from '../../../../assets/images/x64_en_004.png';
import x64_en_5 from '../../../../assets/images/x64_en_005.png';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface x64PopupProps {
    btnText: string;
    onClick: (value: string) => void;
    disabled?: boolean;
}

const X64Popup = ({ btnText, onClick, disabled }: x64PopupProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const onClose = () => {
        setVisible(false);
    };
    const handleDownload = () => {
        onClick('released');
    };
    return (
        <>
            <Button
                disabled={disabled}
                variant="contained"
                onClick={() => setVisible(true)}
                sx={{
                    width: '100%',
                    maxWidth: 200,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                }}
            >
                {btnText}
            </Button>
            <Dialog open={visible} onClose={onClose} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { maxWidth: '720px' } }}>
                <DialogTitle className="flex items-center justify-between gap-5 mb-10">
                    <Typography variant="h2">{t('aism_x64_guide_title')}</Typography>
                    <CloseIcon onClick={() => setVisible(false)} className="opacity-50" />
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: '65vh' }}>
                        <p>{t('aism_x64_instruction_1')}</p>
                        <p style={{ justifyContent: 'center', display: 'flex' }}>
                            <img src={i18n.language === 'kr_KR' ? x64_ko_1 : x64_en_1} alt="x64Image" />
                        </p>
                        <p>{t('aism_x64_instruction_2')}</p>
                        <p style={{ justifyContent: 'center', display: 'flex' }}>
                            <img src={i18n.language === 'kr_KR' ? x64_ko_2 : x64_en_2} alt="x64Image" />
                        </p>
                        <p>{t('aism_x64_instruction_3')}</p>
                        <p style={{ justifyContent: 'center', display: 'flex' }}>
                            <img src={i18n.language === 'kr_KR' ? x64_ko_3 : x64_en_3} alt="x64Image" />
                        </p>
                        <p>{t('aism_x64_instruction_4')}</p>
                        <p style={{ justifyContent: 'center', display: 'flex' }}>
                            <img src={i18n.language === 'kr_KR' ? x64_ko_4 : x64_en_4} alt="x64Image" />
                        </p>
                        <p>{t('aism_x64_instruction_5')}</p>
                        <p style={{ justifyContent: 'center', display: 'flex' }}>
                            <img src={i18n.language === 'kr_KR' ? x64_ko_5 : x64_en_5} alt="x64Image" />
                        </p>
                    </Box>
                </DialogContent>
                <DialogActions className="p-0 mt-10">
                    <Button onClick={handleDownload} color="primary" variant="contained" sx={{ textTransform: 'uppercase', fontWeight: 'semibold' }}>
                        {btnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default X64Popup;
