import { Post } from '..';
import { PackageInterface, TransferAccountInterface, UpdateAdvanceServiceInterface, UpdateCustomBlacklistInterface, UpdateDexInterface, UpdateServiceInterface } from '../../type/api/securityservices';
import { getRegionalURL, RegionalURL } from '../../utility/AppURL';

export const updateService = async (params: UpdateServiceInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, status, api_ver, platform, package_name } = params;
    const headers = {
        'As-Token': token,
    };
    let urlParams = new URLSearchParams();

    urlParams.append('api_ver', api_ver);
    urlParams.append('package_name', package_name);
    urlParams.append('platform', platform);
    urlParams.append('status', status);
    urlParams.append('token', token);

    const response = await Post(getRegionalURL(RegionalURL.toggleservice, region), urlParams, headers)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const updateAdvanceService = async (params: UpdateAdvanceServiceInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, request, service_id, package_name } = params;
    const headers = {
        'As-Token': token,
    };
    let urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('request', request);
    urlParams.append('service_id', service_id);
    urlParams.append('token', token);

    const response = await Post(getRegionalURL(RegionalURL.toggleadvservice, region), urlParams, headers)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const getWorkingItemList = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.getworkingitemlist, region), urlParams).then((res) => res.json());

    return response;
};

export const getPublishedList = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.getpublishedlist, region), urlParams).then((res) => res.json());

    return response;
};

export const publishedWorkingItems = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.publishworkingcopy, region), urlParams).then((res) => res.json());

    return response;
};

export const clearPublishedList = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.toggleoffpublished, region), urlParams).then((res) => res.json());

    return response;
};

export const disableOTAServiceUpdates = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.stopotaserviceupdate, region), urlParams).then((res) => res.json());

    return response;
};

export const addWorkingItem = async (params: UpdateCustomBlacklistInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name, act_type, blocked_package } = params,
        urlParams = new URLSearchParams();

    urlParams.append('act_type', act_type);
    urlParams.append('blocked_package', blocked_package);
    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.addworkingitem, region), urlParams).then((res) => res.json());

    return response;
};

export const removeWorkingItem = async (params: UpdateCustomBlacklistInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name, act_type, blocked_package } = params,
        urlParams = new URLSearchParams();

    urlParams.append('act_type', act_type);
    urlParams.append('blocked_package', blocked_package);
    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.removeworkingitem, region), urlParams).then((res) => res.json());

    return response;
};

export const getSelectiveDexList = async (params: PackageInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.getselectivedexlist, region), urlParams).then((res) => res.json());

    return response;
};

export const addSelectiveDex = async (params: UpdateDexInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name, enc_type, selective_dex_class_package } = params,
        urlParams = new URLSearchParams();

    urlParams.append('enc_type', enc_type);
    urlParams.append('package_name', package_name);
    urlParams.append('selective_dex_class_package', selective_dex_class_package);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.addselectivedex, region), urlParams).then((res) => res.json());

    return response;
};

export const removeSelectiveDex = async (params: UpdateDexInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name, enc_type, selective_dex_class_package } = params,
        urlParams = new URLSearchParams();

    urlParams.append('enc_type', enc_type);
    urlParams.append('package_name', package_name);
    urlParams.append('selective_dex_class_package', selective_dex_class_package);
    urlParams.append('token', token);

    let response = await Post(getRegionalURL(RegionalURL.removeselectivedex, region), urlParams).then((res) => res.json());

    return response;
};

export const requestAppTransfer = async (params: TransferAccountInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token, package_name, target_mail, transfer_reason, transfer_reason_select } = params,
        urlParams = new URLSearchParams();

    urlParams.append('package_name', package_name);
    urlParams.append('target_mail', target_mail);
    urlParams.append('token', token);
    urlParams.append('transfer_reason', transfer_reason);
    urlParams.append('transfer_reason_select', transfer_reason_select);

    let response = await Post(getRegionalURL(RegionalURL.requesttransfer, region), urlParams).then((res) => res.json());

    return response;
};
