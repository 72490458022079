import React from 'react';
import Chip from '@mui/material/Chip';
interface ChipComponentProps {
  label: string;
  color: string;
  className: string;
}


const ChipComponent = ({ label, color = "success", className = "" }:ChipComponentProps) => {
  const colors: { [key: string]: string } = {
    native: '#1FBF98',
    hybrid: '#B0A32F',
    ios: '#517FF7',
    android: '#96C217',
    error: '#F52D2D',
    success: '#8548A8',
    update: '#1D8734',
    warning: '#F5812D',
    info: '#0288D1',
    beta: '#FF5C00',
  };

  return (
    <Chip className={className} style={{ backgroundColor: colors[color] }} label={label} />
  );
};

export default ChipComponent;