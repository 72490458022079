import React from 'react';
import moment from 'moment';

import DataTable from '../../DataDisplay/Table';
import { getLocalisedDate } from '../../utils'; 
import { useTranslation } from 'react-i18next';
import { GridColumnGroupingModel } from '@mui/x-data-grid';

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "device_details",
    headerName: "Device Details",
		headerClassName: 'text-[12px] text-subtitle font-medium border-none max-h-[20px] align-bottom self-end',
    children: [
      { field: "device_model" },
      { field: "os_version" },
      { field: "app_version" },
    ],
  },
  {
    groupId: "Hacking Details",
    headerName: "Hacking Details",
		headerClassName: 'text-[12px] text-subtitle font-medium border-none max-h-[20px] align-bottom self-end',
    children: [
      { field: "country" },
      { field: "cnt" },
      { field: "emul_name" },
      { field: "rooting" },
      { field: "jailbreak" },
      { field: "desc_en" },
    ],
  },
];
interface HackingAttemptsTableProps {
  rows: any[];
  platform: string;
}

const DetailedTable = ({ rows, platform }: HackingAttemptsTableProps) => {
  const { t } = useTranslation();
  const ensureRowIntegrity = (row: any) => {
    const template = {
      register_datetime: '',
      android_raw_id: '',
      device_uuid: '',
      os_version: '',
      app_version_code: '',
      app_version_name: '',
      cntTotalHits: '',
      emul_name: '',
      rooting: '',
      jailbreak: '',
      desc_en: '',
      msg: '',
    };
    return { ...template, ...row };
  };

  const filteredRows = rows.map(ensureRowIntegrity);

  const allColumns = [
    {
      field: 'register_datetime',
      headerName: t("reported_on"),
      flex: 0.2,
      sortable: true,
      renderCell: (params:any) => {
        const val = params?.value;
        return val ? moment(getLocalisedDate(moment(val, "YYYYMMDDHHmmss").toDate())).format("YYYY/MM/DD HH:mm:ss") : "";
      },
    },
    {
      field: 'android_raw_id',
      headerName: t("android_id"),
      flex: 0.2,
      sortable: true,
      hide: platform !== 'android',
    },
    {
      field: 'device_uuid',
      headerName: t("uuid"),
      flex: 0.15,
      sortable: true,
      hide: platform !== 'ios',
    },
    {
      field: 'os_version',
      headerName: t("android_version"),
      flex: 0.15,
      sortable: true,
    },
    {
      field: 'app_version_code',
      headerName: t("app_version"),
      flex: 0.15,
      sortable: true,
      hide: platform !== 'android',
    },
    {
      field: 'app_version_name',
      headerName: t("app_version_name"),
      flex: 0.15,
      sortable: true,
      hide: platform !== 'android',
    },
    {
      field: 'cntTotalHits',
      headerName: t("attempts"),
      flex: 0.1,
      sortable: true,
    },
    {
      field: 'emul_name',
      headerName: t("emulator"),
      flex: 0.15,
      sortable: true,
      hide: platform !== 'android',
    },
    {
      field: 'rooting',
      headerName: t("rooting"),
      flex: 0.15,
      sortable: true,
      renderCell: (params:any) => (params.row.rooting === "y" ? t("Rooted") : t("Not Rooted")),
      hide: platform !== 'android',
    },
    {
      field: 'jailbreak',
      headerName: t("jailbreak"),
      flex: 0.15,
      sortable: true,
      hide: platform !== 'ios',
    },
    {
      field: 'desc_en',
      headerName: t("hacking_type"),
      flex: 0.15,
      sortable: true,
    },
    {
      field: 'msg',
      headerName: t("details"),
      flex: 0.2,
      sortable: true,
    }
  ];


  const filteredColumns = allColumns.filter(column => !column.hide);

  return (
    <div className='w-full min-h-[400px] bg-primary hacking-attempt-table'>
      <DataTable
        rows={filteredRows}
        columns={filteredColumns}
        pageSize={9}
        pageSizeOptions={[5, 10, 15, 20, 30, 40]}
        columnGroupingModel={columnGroupingModel}
        disableColumnSelector={false}
        filter={true}
      />
    </div>
  );
};

export default DetailedTable;
