import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import LoadMask from '../../utils/loadmask';
import { getAccountsInformation } from '../../../services/manage/accounts';
import { setAccountDetails, setOwnerList } from '../../../store/manage/accounts';
import { setUserDetails, setUserPreferences } from '../../../store/main';
import Account from './account';
import Members from './members';
import AccountWithdrawal from './accountwithdrawal';
import ServiceRegion from './serviceregion';
import ThirdParty from './thirdparty';
import { showToast } from '../../utils/notifications';
import SecuritySettings from './security';
import GroupManagement from './group';
import GroupManagementSub from './group/manage/index';
import GroupMembers from './group/members';
import UserLog from './userlog';

const Accounts = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showMask, setShowMask] = useState<any>(null);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isSubOwner = authContext?.role === 'SubOwner';
    const blockUrls = ['/organization/withdrawal', '/organization/security', '/organization/accounts'];
    const BetaBlockUrls = ['/organization/group/members'];

    useEffect(() => {
        if (authContext?.role !== 'Owner') {
            if (authContext?.is_beta_account) {
                if ((!authContext?.is_group_manager && !isSubOwner && BetaBlockUrls.includes(location.pathname)) || blockUrls.includes(location.pathname)) {
                    navigate('/organization/group/manage');
                } else if (authContext?.is_group_manager && blockUrls.includes(location.pathname)) {
                    navigate('/organization/group/manage');
                }
            } else if (blockUrls.includes(location.pathname)) {
                navigate('/organization/serviceregion');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext, location, navigate]);

    useEffect(() => {
        if (authContext?.token && authContext?.role === 'Owner') {
            const fetchAccountInfo = async (token: string) => {
                const params = { token };
                setShowMask({ msg: t('fetching_account_details') });
                const response = await getAccountsInformation(params);
                setShowMask(null);
                if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
                    dispatch(
                        setUserDetails({
                            ...response.user,
                            fullName: response.user.first_name || response.user.last_name ? `${response.user.first_name || ''} ${response.user.last_name || ''}` : response.user.user_id.split('@')[0],
                        }),
                    );
                    dispatch(
                        setUserPreferences({
                            ...response.preference,
                        }),
                    );
                    dispatch(
                        setAccountDetails({
                            ...response.account,
                        }),
                    );
                    dispatch(
                        setOwnerList({
                            ownerList: Array.isArray(response.ownerList) ? response.ownerList : [],
                        }),
                    );
                } else if (response?.result?.code) {
                    showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_load_account_details')));
                } else {
                    showToast('error', t('failed_to_load_account_details'));
                }
            };
            fetchAccountInfo(authContext.token);
        }
    }, [authContext, dispatch, t]);

    const renderComponent = () => {
        switch (location.pathname) {
            case '/organization':
                return <Account />;
            case '/organization/accounts':
                return <Account />;
            case '/organization/members':
                return <Members />;
            case '/organization/group/members':
                return <GroupMembers />;
            case '/organization/withdrawal':
                return <AccountWithdrawal />;
            case '/organization/serviceregion':
                return <ServiceRegion />;
            case '/organization/3rdpartyintegration':
                return <ThirdParty />;
            case '/organization/security':
                return <SecuritySettings />;
            case '/organization/group/manage':
                return <GroupManagement />;
            case '/organization/group/manage/sub':
                return <GroupManagementSub />;
            case '/organization/user/log':
                return <UserLog />;
            default:
                navigate('/organization/serviceregion');
                return null;
        }
    };

    return (
        <>
            {showMask && <LoadMask msg={showMask.msg} />}
            {renderComponent()}
        </>
    );
};

export default Accounts;
