
import Mail_Icon from "../../../../assets/images/Icon/mail.svg";
import Helpdesk_Icon from "../../../../assets/images/Icon/helpdesk.svg";
import Support_Icon from "../../../../assets/images/Icon/support.svg";

export const planData: any = {
  android: [
    {
      title: "Security Features",
      tooltip: "Features that protect keep your Application safe",
      items: {
        trial: {
          enabled: true,
          tooltip:
            "Protection against emulators, rooting, emulators, code tampering, threat activities, network stiffing, etc.",
        },
        professional: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources, Whitebox AES 256 & FIPS 140-2 encryption",
        },
        enterprise: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources, Whitebox AES 256 & FIPS 140-2 encryption",
        },
      },
    },
    {
      title: "Data Visualization",
      tooltip: "Representing all the threat detections and analytics",
      items: {
        trial: {
          enabled: true,
          tooltip:
            "Hacking activity monitoring and investigation, Multi-security versions",
        },
        professional: {
          enabled: true,
          tooltip: "Real-time hacking data stream",
        },
        enterprise: {
          enabled: true,
          tooltip: "Custom blacklist updates in runtime (OTA update)",
        },
      },
    },
    {
      title: "Latest Version",
      tooltip: "New feautes on priority, customised release for specific needs",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip: "Get all new features on priority",
        },
        enterprise: {
          enabled: true,
          tooltip: "Get all new features and custom features requested",
        },
      },
    },
    {
      title: "App Release",
      tooltip: "Application readiness for Play Store release",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip: "Application ready for releasing in Play Store",
        },
        enterprise: {
          enabled: true,
          tooltip: "Application ready for releasing in Play Store",
        },
      },
    },
    {
      title: "Unlimited Features",
      tooltip:
        "Addition features over sealing like Data Sealing, Over the Air Updates, Data Api's & many more",
      items: {
        trial: { enabled: false },
        professional: { enabled: false },
        enterprise: {
          enabled: true,
          tooltip: "Data Sealing, Over the Air Updates, Data Api's & many more",
        },
      },
    },
    {
      title: "Tech Support",
      tooltip: "Help Center, Email & On Call Support",
      items: {
        trial: {
          el: (
            <div className="flex justify-center">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5"
              />
            </div>
          ),
          tooltip: "Help Center Support",
        },
        professional: {
          el: (
            <div className="flex">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5 ml-auto mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5 mr-auto ml-1"
              />
            </div>
          ),
          tooltip: "Help Center & Email Support",
        },
        enterprise: {
          el: (
            <div className="flex justify-center">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5 mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5 mr-1"
              />
              <img
                src={Support_Icon}
                alt="English"
                className="w-3 h-3 lg:w-5 lg:h-5 mr-1"
              />
            </div>
          ),
          tooltip: "Help Center, Email & On Call Support",
        },
      },
    },
    {
      title: "Contract",
      items: {
        trial: { enabled: false },
        professional: { tooltip: "Pay as per usage", text: "Monthly" },
        enterprise: {
          tooltip: "Pay as per usage or Pay per app",
          text: "Yearly",
        },
      },
    },
  ],
  ios: [
    {
      title: "Security Features",
      tooltip: "Features that protect keep your Application safe",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources",
        },
        enterprise: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources",
        },
      },
    },
    {
      title: "Data Visualization",
      tooltip: "Representing all the threat detections and analytics",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip:
            "Hacking activity monitoring and investigation, Multi-security versions, Real-time hacking data stream",
        },
        enterprise: {
          enabled: true,
          tooltip:
            "Hacking activity monitoring and investigation, Multi-security versions, Real-time hacking data stream",
        },
      },
    },
    {
      title: "Latest Version",
      tooltip: "New feautes on priority, customised release for specific needs",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip: "Get all new features on priority",
        },
        enterprise: {
          enabled: true,
          tooltip: "Get all new features and custom features requested",
        },
      },
    },
    {
      title: "Unlimited Features",
      tooltip:
        "Addition features over sealing like Data Sealing, Data Api's & many more",
      items: {
        trial: { enabled: false },
        professional: { enabled: false },
        enterprise: {
          enabled: true,
          tooltip: "Data Sealing, Data Api's & many more",
        },
      },
    },
    {
      title: "Tech Support",
      tooltip: "Help Center, Email & On Call Support",
      items: {
        trial: { enabled: false },
        professional: {
          el: (
            <div className="flex">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-5 h-5 ml-auto mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-5 h-5 mr-auto ml-1"
              />
            </div>
          ),
          tooltip: "Help Center & Email Support",
        },
        enterprise: {
          el: (
            <div className="flex">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-5 h-5 ml-auto mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-5 h-5 mr-1 ml-1"
              />
              <img
                src={Support_Icon}
                alt="English"
                className="w-5 h-5 mr-auto ml-1"
              />
            </div>
          ),
          tooltip: "Help Center, Email & On Call Support",
        },
      },
    },
    {
      title: "Contract",
      items: {
        trial: { enabled: false },
        professional: { tooltip: "Pay as per usage", text: "Monthly" },
        enterprise: {
          tooltip: "Pay as per usage or Pay per app",
          text: "Yearly",
        },
      },
    },
  ],
  hybrid: [
    {
      title: "Security Features",
      tooltip: "Features that protect keep your Application safe",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources",
        },
        enterprise: {
          enabled: true,
          tooltip:
            "Data Encryption Features such as Auth Token, API Key, Sensitive Data Protection, Secure Game resources",
        },
      },
    },
    {
      title: "Data Visualization",
      tooltip: "Representing all the threat detections and analytics",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip:
            "Hacking activity monitoring and investigation, Multi-security versions, Real-time hacking data stream",
        },
        enterprise: {
          enabled: true,
          tooltip:
            "Hacking activity monitoring and investigation, Multi-security versions, Real-time hacking data stream",
        },
      },
    },
    {
      title: "Latest Version",
      tooltip: "New feautes on priority, customised release for specific needs",
      items: {
        trial: { enabled: false },
        professional: {
          enabled: true,
          tooltip: "Get all new features on priority",
        },
        enterprise: {
          enabled: true,
          tooltip: "Get all new features and custom features requested",
        },
      },
    },
    {
      title: "Unlimited Features",
      tooltip:
        "Addition features over sealing like Data Sealing, Data Api's & many more",
      items: {
        trial: { enabled: false },
        professional: { enabled: false },
        enterprise: {
          enabled: true,
          tooltip: "Data Sealing, Data Api's & many more",
        },
      },
    },
    {
      title: "Tech Support",
      tooltip: "Help Center, Email & On Call Support",
      items: {
        trial: { enabled: false },
        professional: {
          el: (
            <div className="flex">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-5 h-5 ml-auto mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-5 h-5 mr-auto ml-1"
              />
            </div>
          ),
          tooltip: "Help Center & Email Support",
        },
        enterprise: {
          el: (
            <div className="flex">
              <img
                src={Helpdesk_Icon}
                alt="English"
                className="w-5 h-5 ml-auto mr-1"
              />
              <img
                src={Mail_Icon}
                alt="English"
                className="w-5 h-5 mr-1 ml-1"
              />
              <img
                src={Support_Icon}
                alt="English"
                className="w-5 h-5 mr-auto ml-1"
              />
            </div>
          ),
          tooltip: "Help Center, Email & On Call Support",
        },
      },
    },
    {
      title: "Contract",
      items: {
        trial: { enabled: false },
        professional: { tooltip: "Pay as per usage", text: "Monthly" },
        enterprise: {
          tooltip: "Pay as per usage or Pay per app",
          text: "Yearly",
        },
      },
    },
  ],
};
