import { Post } from "../..";
import { DailyActiveDeviceInterface } from "../../../type/api/data";
import { getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const getDailyActiveDeviceData = async (params: DailyActiveDeviceInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, package_name, sealing_mode, type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    if (type === 'android') {
        urlParams.append('package_name', package_name);
        urlParams.append('sealing_mode', sealing_mode);
    }
    else {
        urlParams.append('bundle_id', package_name);
    }
    urlParams.append('token', token);


    const response = await Post(
        getRegionalURL(type === 'android' ? RegionalURL.getdaud : RegionalURL.getiosdaud, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getMonthlyActiveDeviceData = async (params: DailyActiveDeviceInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, package_name, sealing_mode, type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    if (type === 'android') {
        urlParams.append('package_name', package_name);
        urlParams.append('sealing_mode', sealing_mode);
    }
    else {
        urlParams.append('bundle_id', package_name);
    }
    urlParams.append('token', token);


    const response = await Post(
        getRegionalURL(type === 'android' ? RegionalURL.getmaud : RegionalURL.getiosmaud, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};