import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getDeviceHackingDetails,
  getHackingLogStream,
} from "../../../../services/data/hackingdetection";
import { RootState } from "../../../../store";
import { getRange } from "../../analytics";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import { BarChartColor3, BarChartColors2 } from "../../../utils/common";
import HackingAttemptsDetailTable from "../../analytics/HackingAttemptsDetailTable";
import DataLayout from "../../DataLayout";
interface HackingDetailsInterface {
  data: any;
  sealingMode: "R" | "T";
  onBackClick: () => void;
}

const processStreamData = (data: any) => {
  let legendInfo = { max: 10 };
  let from = moment().subtract(60, "minutes").toDate(),
    to = moment().toDate();

  let range: Array<any> = [],
    date = from;
  while (date <= to) {
    range.push({ x: moment(date).format("HH:mm"), y: 0 });
    date = moment(date).add(1, "minute").toDate();
  }

  const streamData = range.map((e: any) => {
    const y =
      (data.hacking_log_stream || []).filter(
        (item: any) =>
          moment(new Date(item.register_datetime)).format("HH:mm") === e.x
      )?.length || 0;
    if (y > legendInfo.max) legendInfo.max = y;

    return {
      ...e,
      y,
    };
  });

  return {
    streamData: [
      {
        id: "Inka Entworks",
        data: streamData,
      },
    ],
    legendInfo,
  };
};

let initialData = [
  {
    id: "Inka Entworks",
    data: getRange(
      moment().subtract(30, "minutes").format("YYYYMMDDHHmmss"),
      moment().format("YYYYMMDDHHmmss")
    ),
  },
];

const HackingDetails = (props: HackingDetailsInterface) => {
  const { t } = useTranslation();
  const [loop, setLoop] = useState<any>(null);
  const [deviceData, setDeviceData] = useState<any>({});
  const [streamTableData, setStreamTableData] = useState([]);
  const [streamData, setStreamData] = useState<any>(initialData);
  const [streamLegend, setStreamLegand] = useState<any>({ max: 10 });
  const [deviceHackingDetails, setDeviceHackingDetails] = useState([]);

  const region = useSelector((state: RootState) => state.mainReducer.region);
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );

  const fetchHackingDataStream = useCallback(
    async (android_raw_id: string, package_name: string = "") => {
      let params = {
        android_raw_id,
        package_name,
        token: authContext.token,
        reportEndDT: moment().utc().format("YYYYMMDDHHmmss"),
        reportStDT: moment().subtract(1, "hour").utc().format("YYYYMMDDHHmmss"),
      };

      const response = await getHackingLogStream(params, region);
      if (Array.isArray(response?.hacking_log_stream)) {
        const data = processStreamData(response);
        setStreamData(data.streamData);
        setStreamLegand(data.legendInfo);
        setStreamTableData(response.hacking_log_stream);
      } else {
        setStreamTableData([]);
        setStreamData(initialData);
        setStreamLegand({ max: 10 });
      }
    },
    [region, authContext.token]
  );

  useEffect(() => {
    if (loop === null) {
      setLoop(
        setInterval(() => {
          fetchHackingDataStream(
            props.data.android_raw_id,
            props.data.package_name
          );
        }, 60000)
      );
    }
    if (loop) {
      return () => clearInterval(loop);
    }
  }, [loop, props.data, fetchHackingDataStream]);

  useEffect(() => {
    if (props.data) {
      const fetchDeviceHackingDetails = async () => {
        const params = {
          token: authContext.token,
          sealing_mode: props.sealingMode,
          package_name: props.data.package_name,
          android_raw_id: props.data.android_raw_id,
          calc_date_from: moment().startOf("month").format("YYYYMMDD"),
          calc_date_to: moment().endOf("month").format("YYYYMMDD"),
        };
        const response = await getDeviceHackingDetails(params, region);
        let data = [];
        if (
          response?.result?.code === "0000" &&
          Array.isArray(response.hackingAttemptsList)
        ) {
          data = response.hackingAttemptsList.map((e: any) => ({
            ...e,
            x: moment(e.calc_date, "YYYYMMDD").format("MM-DD"),
            y: e.hacking_cnt || 0,
            key: e.calc_date + e.android_raw_id + e.os_version + e.device_model,
          }));
          data = data.sort((a: any, b: any) => a.calc_date - b.calc_date);
        }
        setDeviceHackingDetails(data);
      };
      setDeviceData(props.data);
      fetchDeviceHackingDetails();
      fetchHackingDataStream(
        props.data.android_raw_id,
        props.data.package_name
      );
    } else setDeviceData({});
  }, [
    region,
    props.data,
    props.sealingMode,
    authContext.token,
    fetchHackingDataStream,
  ]);

  return (
    <DataLayout>
      <div className='w-full mb-2.5'>
        <div>
          <IconButton onClick={props.onBackClick}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className='bg-primary py-5 px-[30px] relative rounded-[5px] border border-[#E0E0E0]'>
          <p className='text-base text-[#36373A] normal-case font-medium'>
            {t("device_information")}
          </p>
          <div className='flex lg:flex-nowrap flex-wrap justify-start lg:justify-between gap-4 lg:gap-0 mt-[18px]'>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("android_id")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.android_raw_id}
              </span>
            </p>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("device_model")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.device_model}
              </span>
            </p>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("operating_system")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.os_version}
              </span>
            </p>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("rooting_enabled")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.desc_cn === "Rooted device" ? "Yes" : "No"}
              </span>
            </p>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("country")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.country}
              </span>
            </p>
            <p>
              <span className='block text-xs text-[#A0A0A1]'>
                {t("package_name")}
              </span>
              <span className='text-base text-[#36373A]'>
                {deviceData.package_name}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className='w-full bg-primary min-h-[500px] py-5 px-[30px] relative rounded-[5px] border border-[#E0E0E0] mb-2.5'>
        <div className='mb-9'>
          <p className='text-base text-inka800'>
            {t("real_time_protection_stream")}
          </p>
        
        </div>
        <div className='min-h-[300px] p-2 h-[300px]'>
          <ResponsiveLine
            theme={{grid: {line: { strokeWidth: 0.5}}}}
            data={streamData}
            animate={true}
            useMesh={true}
            pointBorderWidth={2}
            pointLabelYOffset={-12}
            colors={BarChartColor3.reverse()}
            pointColor='white'
            pointBorderColor={{ from: "serieColor" }}
            margin={{ top: 10, right: 25, bottom: 50, left: 25 }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 45,
              tickValues: 10,
              legend: t("time_period"),
              legendOffset: 40,
              legendPosition: "middle",
              format: (value: any) =>
                value && parseInt(moment(value, "HH:mm").format("mm")) % 2 === 0
                  ? value
                  : "",
            }}
            yScale={{
              type: "linear",
              max: streamLegend.max + 0.1 * streamLegend.max,
            }}
            tooltip={(e: any) =>
              e.point.data ? (
                <div className='bg-primary p-2 border border-solid'>
                  <p className='font-semibold truncate max-w-sm'>
                    {e.point.data.package_name || e.point.data.bundle_id}
                  </p>
                  <p>
                    {t("no_of_hacking_types")} :{" "}
                    <span className='font-semibold'>{`${e.point.data.y}`}</span>
                  </p>
                  <p>
                    {t("time_of_detection")} :{" "}
                    <span className='font-semibold'>{`${e.point.data.x}`}</span>
                  </p>
                </div>
              ) : (
                <p />
              )
            }
          />
        </div>
        <div className=''>
          <div className='overflow-auto'>
            <HackingAttemptsDetailTable
              rows={streamTableData
                .map((v: any, i) => ({ ...v, id: i }))
                .sort(
                  (a: any, b: any) => b.register_datetime - a.register_datetime
                )}
            />
          </div>
        </div>
      </div>
      <div className='w-full bg-primary min-h-[500px] py-5 px-[30px] relative rounded-[5px] border border-[#E0E0E0] mb-2.5'>
      <p className='text-base text-inka800'>
            {t("protected_hacking_attempts")}
          </p>
          <div className='min-h-[300px] p-2 h-[300px]'>
        
        <ResponsiveBar
          data={deviceHackingDetails}
          indexBy='x'
          keys={["y"]}
          margin={{ top: 30, right: 10, bottom: 55, left: 50 }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            tickValues: 10,
          }}
          enableGridX={false}
          enableGridY={false}
          colors={BarChartColors2}
          padding={0.4}
          tooltip={(e: any) => (
            <div className='bg-white border border-solid p-4'>
              <p>
                <strong>{t("stream_information")}</strong>
              </p>
              <p>
                {t("detection_date")} :{" "}
                <span className='font-semibold'>{`${e.data.x}`}</span>
              </p>
              <p>
                {e.data.detail || t("detected_hacking_behaviours")} :{" "}
                <span className='font-semibold'>{`${e.data.y}`}</span>
              </p>
            </div>
          )}
        />
      </div>
      <div className='w-full mb-4 bg-primary'>
        <div className='bg-primary flex flex-col p-4 '>
          <div className=' overflow-auto'>
            <HackingAttemptsDetailTable
              rows={deviceHackingDetails
                .map((v: any, i) => ({ ...v, id: i })).sort(
                (a: any, b: any) => b.calc_date - a.calc_date
              )}
              history={true}
            />
          </div>
        </div>
      </div>
      </div>
     
    </DataLayout>
  );
};

export default HackingDetails;
