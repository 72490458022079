import React, { useEffect, useRef, useState } from 'react';
import PaymentCard from './card';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import BillingInfo, { ChildComponentMethods } from './billinginfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getPublicKey, getSetupIntent } from '../../../../services/manage/billingdetails';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import AvailableCredits from './AvailableCredits';
import { Button } from '../../../Foundation/Button';
let stripePromise: any = null;

const BillingInformation = () => {
    const { t } = useTranslation();
    const [stripeDetails, setStripeDetails] = useState<any>(null);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const billingDetails = useSelector((state: RootState) => state.billingDetailsReducer.billingDetails);
    const billingFormRef = useRef<ChildComponentMethods>(null);

    useEffect(() => {
        if (billingDetails && stripeDetails === null) {
            const getStripInfo = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getPublicKey(params);
                const intentResponse = await getSetupIntent(params);
                if (response?.result?.code === '0000' && intentResponse?.result?.code === '0000') {
                    stripePromise = loadStripe(response.result.publickey);
                    setStripeDetails({
                        publicKey: { ...response },
                        paymentInfo: JSON.parse(intentResponse?.result?.PLglobalPaymentInfo),
                    });
                }
            };
            getStripInfo();
        }
    }, [billingDetails, stripeDetails, authContext.token]);

    const handleSubmit = () => {
        billingFormRef.current?.submitForm();
    };

    const handleReset = () => {
        billingFormRef.current?.resetForm();
    };

    return (
        <ContentLayout>
            <div className="flex flex-none justify-between items-center mb-[42px]">
                <div className="text-2xl font-medium">{t('billing_information')}</div>
                {authContext.role === 'Owner' && (
                    <div className="flex gap-2">
                        <Button variant={'outlined'} color="secondary" onClick={handleReset} label={t('reset')} />
                        <Button label={t('save')} onClick={handleSubmit} />
                    </div>
                )}
            </div>
            <div className="flex flex-none flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-2/3">
                    <BillingInfo ref={billingFormRef} />
                </div>
                <div className="w-full mt-6 lg:mt-0 lg:w-1/3 relative">
                    <Elements stripe={stripePromise}>
                        <PaymentCard data={stripeDetails} />
                    </Elements>
                    <div className="mt-8 lg:mt-0 lg:absolute bottom-1 w-full">
                        <AvailableCredits />
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
};

export default BillingInformation;
