import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { resetNewPwd } from "../../../services/login";
import LoadMask from '../../utils/loadmask';
import { showToast } from "../../utils/notifications";
import { newPasswordData } from "../AuthData";
import AuthForm from "../AuthForm";
import AuthLayout from "../index";

const NewPassword = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const [loadMask, showLoadMask] = useState(false);
	
	let query = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );


	const onOk =  async (values: any) => {
		showLoadMask(true);
		let response = await resetNewPwd({
			token: query.get("token") || '',
			password: values.user_pw
		});
		showLoadMask(false);
		if (
			response?.result.code === "0000" &&
			response?.result.message === "SUCCESS"
		)
			navigate('/login')
		else {
			const msg =
				t(`RCM-${response.result.code}`) !== ""
					? t(`RCM-${response.result.code}`)
					: response?.result?.message ||
						t("user_id_does_not_exists_in_system");
			showToast("error", msg);
		}
	}

	return (
		<>
		 {loadMask && <LoadMask msg={t('validating_user_information')} style={{ zIndex: '10007' }} />}
		 <AuthLayout BackButton={false} hideInfo={true}>
			 <AuthForm
				title={"new_password"}
				data={newPasswordData}
				btnText="ok"
				onSubmit={onOk}
				passwordValidator={true}
				btnMargin={'mt-6'}
			/>
		</AuthLayout>
		</>
	
	);
}

export default NewPassword;