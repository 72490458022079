export const appFrameworks = [
    { name: 'Native | Java | Kotlin', type: 'native', service_type: 'NATIVE_AOS', detail: 'NATIVE' },
    { name: 'Unity', type: 'native', service_type: 'NATIVE_AOS', detail: 'UNITY' },
    { name: 'Unreal', type: 'native', service_type: 'NATIVE_AOS', detail: 'UNREAL' },
    { name: 'Flutter', type: 'native', service_type: 'NATIVE_AOS', detail: 'FLUTTER' },
    { name: 'Xamarin', type: 'native', service_type: 'NATIVE_AOS', detail: 'XAMARIN', minVersion: '2.26.0.0' },
    { name: 'React Native', type: 'hybrid', service_type: 'HYBRID_AOS', detail: 'REACT_NATIVE' },
    { name: 'Ionic', type: 'hybrid', service_type: 'HYBRID_AOS', detail: 'IONIC', minVersion: '1.3.0.0' },
    { name: 'Cordova', type: 'hybrid', service_type: 'HYBRID_AOS', detail: 'CORDOVA', minVersion: '1.3.0.0' },
    { name: 'Others', type: 'native', service_type: 'NATIVE_AOS', detail: 'OTHERS' },
];

export const AlwaysOnSecurityData = [
    {
        text: 'code_encryption',
        name: 'code_encryption',
        type: 'code_encryption',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'so_encryption',
                name: 'so_encryption',
                tooltip: 'so_encryption_tooltip',
            },
            {
                text: 'dll_encryption_for_unity_&_xamarin_app',
                value: 'dll_encryption_for_unity_xamarin_app',
                tooltip: 'dll_encryption_tooltip',
            },
            {
                text: 'js_encryption_for_reactnative_app',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'js_encryption_tooltip',
            },
        ],
    },
    {
        text: 'anti_debugging_runtime_attack',
        name: 'anti_debugging_runtime_attack',
        type: 'anti_debugging_runtime_attack',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'monitoring_process_status',
                name: 'so_encryption',
                tooltip: 'monitoring_process_status_tooltip',
            },
            {
                text: 'block_debugger_attach_to_process',
                value: 'dll_encryption_for_unity_xamarin_app',
                tooltip: 'block_debugger_attach_to_process_tooltip',
            },
            {
                text: 'frida_framework',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'frida_framework_tooltip',
            },
            {
                text: 'xposed_framework',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'xposed_framework_tooltip',
            },
            {
                text: 'trampoline_code_hooking',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'trampoline_code_hooking_tooltip',
            },
            {
                text: 'memory_dump',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'memory_dump_tooltip',
            },
            {
                text: 'ssl_pinning_bypass',
                value: 'js_encryption_for_reactnative_app',
                tooltip: 'ssl_pinning_bypass_tooltip',
            },
        ],
    },
    {
        text: 'anti_tampering',
        name: 'anti_tampering',
        type: 'anti_tampering',
        options: [
            {
                text: 'code_integrity_check',
                name: 'code_integrity_check',
                tooltip: 'code_integrity_check_tooltip',
            },
            {
                text: 'resource_integrity_check',
                name: 'resource_integrity_check',
                tooltip: 'resource_integrity_check_tooltip',
            },
            {
                text: 'package_name_manipulation',
                value: 'package_name_manipulation',
                tooltip: 'package_name_manipulation_tooltip',
            },
            {
                text: 'android_manifest_manipulation',
                value: 'android_manifest_manipulation',
                tooltip: 'androidmanifest_manipulation_tooltip',
            },
            {
                text: 'block_memory_manipulation',
                value: 'block_memory_manipulation',
                tooltip: 'block_memory_manipulation_tooltip',
            },
        ],
    },
    {
        text: 'virtual_environments',
        name: 'virtual_environments',
        type: 'virtual_environments',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'virtual_os',
                name: 'virtual_os',
                tooltip: 'virtual_os_tooltip',
            },
            {
                text: 'virtual_space_tools',
                value: 'virtual_space_tools',
                tooltip: 'virtual_space_tools_tooltip',
            },
            {
                text: 'xiaomi_dual_app',
                value: 'xiaomi_dual_app',
                tooltip: 'xiaomi_dual_app_tooltip',
            },
            {
                text: 'xiaomi_second_space',
                value: 'xiaomi_second_app',
                tooltip: 'xiaomi_second_app_tooltip',
            },
        ],
    },
    {
        text: 'cheating_tools',
        name: 'cheating_tools',
        type: 'cheating_tools',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'game_guardian',
                name: 'game_guardian',
                tooltip: 'game_guardian_tooltip',
            },
            {
                text: 'lucky_patcher',
                value: 'lucky_patcher',
                tooltip: 'lucky_patcher_tooltip',
            },
            {
                text: 'speedhack_tools',
                value: 'speedhack_tools',
                tooltip: 'speedhack_tools_tooltip',
            },
            {
                text: 'apk_editors',
                value: 'apk_editors',
                tooltip: 'apk_editors_tooltip',
            },
        ],
    },
    {
        text: 'hacking_detection_&_analytics',
        name: 'hacking_detection_&_analytics',
        type: 'hacking_detection_analytics',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'realtime_hacking_data_streaming',
                name: 'realtime_hacking_data_streaming',
                tooltip: 'real_time_hacking_data_streaming_tools_tooltip',
            },
            {
                text: 'hacking_detection_message_localization',
                value: 'hacking_detection_message_localization',
                tooltip: 'hacking_detection_message_localization_tooltip',
            },
        ],
    },
];

export const GeneralFeaturesData = [
    {
        text: 'dex_encryption',
        name: 'dex_encrypt',
        type: 'encryptions',
        options: [
            {
                text: 'yes',
                value: 'yes',
                tooltip: 'dex_yes_tooltip',
            },
            {
                text: 'selective',
                value: 'selective',
                tooltip: 'dex_selective_tooltip',
            },
            {
                text: 'no',
                value: 'no',
                tooltip: 'dex_no_tooltip',
            },
        ],
    },
    {
        text: 'dex_string_encrypt',
        name: 'dex_string_encrypt',
        type: 'encryptions',
        options: [
            {
                text: 'yes',
                value: 'yes',
            },
            {
                text: 'no',
                value: 'no',
            },
        ],
    },
    {
        text: 'block_dex_dump',
        name: 'block_dex_dump',
        type: 'encryptions',
        options: [
            {
                text: 'yes',
                value: 'yes',
            },
            {
                text: 'no',
                value: 'no',
            },
        ],
    },
    {
        text: 'rooting',
        name: 'rooting',
        type: 'untrusted_environments',
        options: [
            {
                text: 'block',
                value: 'forbid',
                tooltip: 'root_block_tooltip',
            },
            {
                text: 'allow',
                value: 'allow',
                tooltip: 'root_allow_tooltip',
            },
        ],
    },
    {
        text: 'developer_options',
        name: 'block_developer_options',
        type: 'untrusted_environments',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'developer_options_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'developer_options_allow_tooltip',
            },
        ],
    },
    {
        text: 'usb_debugging',
        name: 'block_usb_debugging',
        type: 'untrusted_environments',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'usb_debugging_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'usb_debugging_allow_tooltip',
            },
        ],
    },
    {
        text: 'wi-fi_identification',
        name: 'wifi_security_protocol',
        type: 'untrusted_environments',
        options: [
            {
                text: 'collect_data',
                value: 'collect',
                tooltip: 'wifi_security_protocol_collect_tooltip',
            },
            {
                text: 'disable',
                value: 'disable',
                tooltip: 'wifi_security_protocol_disable_tooltip',
            },
        ],
    },
    {
        text: 'Emulators',
        name: 'vmenv',
        list: 'sealingAllowEmulList',
        listname: 'allow_emulator',
        type: 'untrusted_environments',
        options: [
            {
                text: 'block',
                value: 'forbid',
                tooltip: 'emulators_block_tooltip',
            },
            {
                text: 'optional',
                value: 'optional',
                tooltip: 'emulators_optional_tooltip',
            },
            {
                text: 'allow',
                value: 'allow',
                tooltip: 'emulators_allow_tooltip',
            },
        ],
    },
    {
        text: 'work_profile_&_samsung_security_folder',
        list: 'sealingAllowWorkProfileList',
        listname: 'allow_work_profiles_owner_app_name',
        name: 'block_work_profile',
        type: 'untrusted_environments',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'workprofile_block_tooltip',
            },
            {
                text: 'optional',
                value: 'optional',
                tooltip: 'workprofile_optional_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'workprofile_allow_tooltip',
            },
        ],
    },
    {
        text: 'use_query_all_packages',
        name: 'use_query_all_packages',
        type: 'app_permission',
        fieldtype: 'radio',
        linebreak: true,
        hint: true,
        helpdesk: '12554252246937',
        options: [
            {
                text: 'enable',
                value: 'yes',
                tooltip: 'use_query_all_packages_yes_tooltip',
            },
            {
                text: 'disable',
                value: 'no',
                tooltip: 'use_query_all_packages_no_tooltip',
            },
        ],
    },
    {
        text: 'macro_tools',
        name: 'macro',
        type: 'cheat_tools',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'macro_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'macro_allow_tooltip',
            },
        ],
    },
    {
        text: 'panel_attacks',
        name: 'sniff',
        type: 'cheat_tools',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'packet_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'packet_allow_tooltip',
            },
        ],
    },
    {
        text: 'keylogger',
        name: 'block_keylogger',
        type: 'cheat_tools',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'keylogger_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'keylogger_allow_tooltip',
            },
        ],
    },
    {
        text: 'screen_mirroring_&_capture',
        name: 'block_screen_capture',
        type: 'malicious_behaviour',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'screen_capture_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'screen_capture_allow_tooltip',
            },
        ],
    },
    {
        text: 'mirroring_app',
        name: 'block_mirroring',
        type: 'malicious_behaviour',
        max_version: '2.28.1.0',
        options: [
            {
                text: 'Block',
                value: 'yes',
                tooltip: 'mirroring_block_tooltip',
            },
            {
                text: 'Allow',
                value: 'no',
                tooltip: 'mirroring_allow_tooltip',
            },
        ],
    },
    {
        text: 'remote_access_app',
        name: 'block_remote_access',
        type: 'malicious_behaviour',
        max_version: '2.28.1.0',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'remote_access_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'remote_access_allow_tooltip',
            },
        ],
    },
    {
        text: 'launch_by_external_app',
        name: 'block_external_launch',
        type: 'cheat_tools',
        max_version: '2.26.0.0',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'external_launch_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'external_launch_allow_tooltip',
            },
        ],
    },
    {
        text: 'overlay_windows',
        name: 'hide_overlay_windows',
        type: 'cheat_tools',
        options: [
            {
                text: 'block',
                value: 'yes',
                tooltip: 'hide_overlay_windows_block_tooltip',
            },
            {
                text: 'allow',
                value: 'no',
                tooltip: 'hide_overlay_windows_allow_tooltip',
            },
        ],
    },
];

export const AdvancedFeaturesData = [
    {
        text: 'data_sealing',
        name: 'data_sealing',
        type: 'others',
        options: [
            {
                text: 'yes',
                value: 'native_api',
                tooltip: 'data_sealing_enable_tooltip',
            },
            {
                text: 'no',
                value: 'no',
                tooltip: 'root_allow_tooltip',
            },
        ],
    },
    {
        text: 'over_the_air_update',
        name: 'use_ota_service',
        type: 'others',
        options: [
            {
                text: 'yes',
                value: 'yes',
                tooltip: 'over_the_air_update_tooltip',
            },
            {
                text: 'no',
                value: 'no',
                tooltip: 'disable_custom_blacklist_for_over_the_air_updates',
            },
        ],
    },
];

interface ApkSecurityMeasures {
    [key: string]: {
        [value: string]: {
            symbol?: string;
            num?: number;
            text?: string;
            color?: string;
            service_version?: string;
            aos_service_version?: string;
        };
    };
}

export const securityMeasuresInfo: ApkSecurityMeasures = {
    dex_encrypt: {
        yes: {
            num: 5,
            symbol: '+',
            text: 'dex_encryption_blocked',
            color: 'infoblue',
        },
        selective: {
            num: 2,
            symbol: '+',
            text: 'selective_dex_encryption_allowed',
            color: 'inkaorange',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'dex_encryption_allowed',
            color: 'warning',
        },
    },
    rooting: {
        forbid: {
            num: 10,
            symbol: '+',
            text: 'rooting_blocked',
            color: 'infoblue',
        },
        allow: {
            num: 0,
            symbol: '-',
            text: 'rooting_allowed',
            color: 'warning',
        },
    },
    vmenv: {
        forbid: {
            num: 10,
            symbol: '+',
            text: 'emulators_blocked',
            color: 'infoblue',
        },
        optional: {
            num: 5,
            symbol: '+',
            text: 'optional_emulators_allowed',
            color: 'inkaorange',
        },
        allow: {
            num: 0,
            symbol: '-',
            text: 'emulators_allowed',
            color: 'warning',
        },
    },
    block_work_profile: {
        yes: {
            num: 5,
            symbol: '+',
            text: 'workprofile_blocked',
            color: 'infoblue',
        },
        optional: {
            num: 2,
            symbol: '+',
            text: 'optional_workprofile_allowed',
            color: 'inkaorange',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'workprofile_allowed',
            color: 'warning',
        },
    },
    block_usb_debugging: {
        compare: {
            service_version: '2.29.0.0',
        },
        yes: {
            num: 0,
            symbol: '+',
            text: 'usb_debugging_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'usb_debugging_allowed',
            color: 'inkaorange',
        },
    },
    block_developer_options: {
        compare: {
            service_version: '2.32.0.0',
        },
        yes: {
            num: 0,
            symbol: '+',
            text: 'developer_option_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'developer_option_allowed',
            color: 'inkaorange',
        },
    },
    macro: {
        yes: {
            num: 5,
            symbol: '+',
            text: 'macrotools_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'macrotools_allowed',
            color: 'warning',
        },
    },
    sniff: {
        yes: {
            num: 5,
            symbol: '+',
            text: 'sniffingtools_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'sniffingtools_allowed',
            color: 'warning',
        },
    },
    block_keylogger: {
        yes: {
            num: 5,
            symbol: '+',
            text: 'keyloggers_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'keyloggers_allowed',
            color: 'warning',
        },
    },
    block_screen_capture: {
        compare: {
            aos_service_version: '2.29.0.0',
        },
        yes: {
            num: 0,
            symbol: '+',
            text: 'screen_capture_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'screen_capture_allowed',
            color: 'inkaorange',
        },
    },
    block_mirroring: {
        compare: {
            service_version: '2.27.0.0',
        },
        yes: {
            num: 0,
            symbol: '+',
            text: 'mirroring_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'mirroring_allowed',
            color: 'inkaorange',
        },
    },
    block_remote_access: {
        compare: {
            service_version: '2.29.0.0',
        },
        yes: {
            num: 0,
            symbol: '+',
            text: 'remote_access_blocked',
            color: 'infoblue',
        },
        no: {
            num: 0,
            symbol: '-',
            text: 'remote_access_allowed',
            color: 'inkaorange',
        },
    },
};

export const isHybridFramework = (framework: string) =>
    appFrameworks
        .filter((e) => e.type === 'hybrid')
        .map((e) => e.detail)
        .includes(framework);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
