import React, { useEffect, useState } from 'react';

import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import LoadMask from '../../../utils/loadmask';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { filterBy } from '../../../../utility/filtering';
import { showDialog, showToast } from '../../../utils/notifications';
import { updateAdvanceService, updateService } from '../../../../services/securityservices';
import { getAppList } from '../../../../services/manage/settings';
import AndroidServiceCard from '../../../form/field/cards/android';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RegisterNewApp from './RegisterNewApp';

const AppConfig = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>([]);
    const [filterText, setFilterText] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [pageSize, setPageSize] = useState<number>(20);
    const [appList, setAppList] = useState<any[] | null>(null);
    const [visible, setVisible] = useState(false);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isBetaAccount = authContext?.is_beta_account;
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;
    
    const handleSelectApp = (app: any) => {
        navigate(`/settings/service/configure/${app.id}${app.groupId ? `?groupId=${app.groupId}&` : '?'}platform=android`);
    };
    useEffect(() => {
        if (appList === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };
                setShowMask({ msg: t('fetching_application') });
                const response = await getAppList(params, region);
                setShowMask(null);
                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus.filter((item: any) => item.platform === 'android');
                }
                setAppList(data);
            };
            fetchApplications();
        }
    }, [region, appList, authContext, t]);

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'app_name', value: value, operator: 'contains' },
                    { field: 'package_name', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };

    const updateServices = async (type: string, params: any) => {
        let data: any = null;
        if (type === 'service') {
            const response = await updateService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('app_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { service_onoff: params.status };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', `${t('app_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'datasealing') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('data_sealing_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { use_data_sealing: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('data_sealing_service_failed_to_update_for_package')));
            else showToast('error', `${t('data_sealing_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'otaservice') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('ota_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { use_ota_service: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('ota_failed_to_update_for_package')));
            else showToast('error', `${t('ota_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        }

        if (data && Array.isArray(appList)) {
            setAppList((oldData: any) => {
                const index = oldData.findIndex((e: any) => e.platform === 'android' && e.package_name === params.package_name);
                let item = oldData[index];
                oldData[index] = { ...item, ...data };
                return [...oldData];
            });
        }
    };

    const onSwitchToggle = async (type: string, params: any) => {
        let msg = '';
        switch (type) {
            case 'service':
                msg = params.status === 'Y' ? t('app_can_be_sealed_and_will_incur_a_monthly_fee') : t('app_cannot_be_sealed_and_data_will_not_collected_while_the_sealing_option_is_off');
                break;
            case 'datasealing':
                msg =
                    params.request === 'Y'
                        ? isBetaAccount ? t('beta_datasealing_option_will_be_available_while_applying_security_to_your_app') : t('datasealing_option_will_be_available_while_applying_security_to_your_app')
                        : t('datasealing_is_not_supported_when_applying_security_to_your_app_any_longer');
                break;
            case 'otaservice':
                msg =
                    params.request === 'Y'
                        ? t('once_activated_you_can_manage_the_custom_blacklist_by_yourself_and_it_is_updated_over')
                        : t('custom_blacklist_option_is_not_supported_when_applying_security_to_your_app_any_longer_and_mad_is_not_counted_any_longer');
                break;
            default:
                break;
        }
        if (msg) {
            showDialog({
                title: t('confirm_update'),
                content: msg,
                onOk: () => updateServices(type, params),
                okText: t('yes'),
                cancelText: t('no'),
            });
        } else updateServices(type, params);
    };

    const onCardButtonClick = (type: string, appData: any) => {
        navigate(`/settings/service/configure/${appData.id}${appData.groupId ? `?groupId=${appData.groupId}&` : '?'}platform=android`);
    };

    const onShowLessClick = () => setPageSize((oldPageSize: number) => oldPageSize - 20);
    const onShowMoreClick = () => setPageSize((oldPageSize: number) => oldPageSize + 20);

    const handleClearClick = () => {
        setFilterText('');
        setFilters([]);
    };

    const handleOnCancel = () => {
        setVisible(false);
    };

    return (
        <React.Fragment>
            {showMask && <LoadMask msg={showMask.msg} />}
            <RegisterNewApp visible={visible} onCancel={handleOnCancel} setAppList={setAppList} />
            <div className="h-full pr-2">
                <div className="flex flex-wrap gap-6 mb-8 lg:flex-nowrap">
                    <div className="w-full lg:max-w-[316px] text-2xl font-medium">
                        <span className="text-2xl font-medium whitespace-nowrap">{t('app_configurations')}</span>
                    </div>

                    <div className="lg:mt-0 w-full max-w-[656px]">
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            label={t('search')}
                            value={filterText}
                            onChange={onSearchClick}
                            placeholder={t('search_app_name_or_bundle_id')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {filterText && (
                                            <IconButton aria-label="clear text" onClick={handleClearClick} size="small" edge="end">
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    {isBetaAccount && (
                        <div className="w-full max-w-full lg:max-w-[316px]">
                            <Button variant="contained" className="h-[37px]" disabled={!(isOwner || isManager)} fullWidth onClick={() => setVisible(true)}>
                                {t('register_new_app')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="overflow-auto">
                    <div className="flex flex-wrap gap-6">
                        {Array.isArray(appList) &&
                            appList.length > 0 &&
                            (filterBy(appList, filters) || []).slice(0, pageSize).map((item: any) => {
                                return (
                                    <AndroidServiceCard
                                        key={item.app_name + item.package_name}
                                        {...item}
                                        onButtonClick={onCardButtonClick}
                                        onToggleSwitch={onSwitchToggle}
                                        handleSelectApp={handleSelectApp}
                                    />
                                );
                            })}
                    </div>
                    <div className="w-full mt-6 text-center">
                        {pageSize > 20 ? (
                            <Button variant="text" onClick={onShowLessClick}>
                                {t('show_less')}
                            </Button>
                        ) : null}
                        {appList?.length
                            ? (filterBy(appList, filters) || []).length > pageSize && (
                                  <Button variant="text" onClick={onShowMoreClick}>
                                      {t('show_more')}
                                  </Button>
                              )
                            : null}
                    </div>
                    {appList?.length === 0 ? (
                        <div className="mx-auto text-xl text-center whitespace-pre py-52">{t('please_apply_appsealing_first')}</div>
                    ) : (
                        (filterBy(appList, filters) || []).length === 0 && <div className="mx-auto text-xl text-center whitespace-pre py-52">{t('no_results_found')}</div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AppConfig;
