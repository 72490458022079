import Base64 from 'crypto-js/enc-base64';
import { AES, enc, pad, mode } from "crypto-js";
import { Base64Key } from "../store/main"
import { useLocation } from "react-router-dom";

export const encryptMsg = (str: string) => {
    const key = Base64.parse(Base64Key);

    return AES.encrypt(str, key, {
        mode: mode.ECB,
        padding: pad.Pkcs7
    }).toString();
};

export const decryptMsg = (str: string) => {
    const key = Base64.parse(Base64Key);

    return AES.decrypt(str, key, {
        mode: mode.ECB,
        padding: pad.Pkcs7
    }).toString(enc.Utf8);
};

export const setCookies = (key: string, value: string, expires = '') => {
    key = (window.location.host.match(/^(.+?)-/)?.[0] || '') + key;
    if (window.location.hostname === 'localhost')
        document.cookie = `local-${key}=${value} ${expires ? `;  Expires=${expires}` : ""}`;
    else
        document.cookie = `${key}=${value}; domain=appsealing.com ${expires ? `; Expires =${expires}` : ""}`;
};

export const getCookies = (key: string) => {
    key = (window.location.host.match(/^(.+?)-/)?.[0] || '') + key;
    if (window.location.hostname === 'localhost')
        key = `local-${key}`;
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
};

export const htmlDecode = (input: string) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
};


export const deleteCookie= (key: string) =>{
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}