import { Box, Chip, FormControl, FormControlLabel, FormHelperText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { showDialog } from '../../../utils/notifications';
import { isHybridFramework, MenuProps } from './data';
import i18next from 'i18next';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'; // Add this line
import React from 'react';
import { Controller } from 'react-hook-form';

interface ISealingFunction {
    fields: any;
    isOTADisabled: any;
    formData: any;
    setValue: any;
    getValues: any;
    appFrameworks: any;
    serviceables: any;
    setSealingData: any;
    nativePlanType: any;
    hybridPlanType: any;
    setShowSealingModal?: any;
    setSelectedServiceDetails?: any;
    setFileType: any;
    setSealingTabs?: any;
    TabsInfo?: any;
    setSelectedTab?: any;
    setFormData: any;
    clearErrors?: any;
}

export const onFieldsChanges = ({
    fields,
    isOTADisabled,
    formData,
    setValue,
    getValues,
    appFrameworks,
    serviceables,
    setSealingData,
    nativePlanType,
    hybridPlanType,
    setShowSealingModal,
    setSelectedServiceDetails,
    setFileType,
    setSealingTabs,
    TabsInfo,
    setSelectedTab,
    setFormData,
    clearErrors,
}: ISealingFunction) => {
    if (fields.length !== 1) return;
    if (isOTADisabled(formData.app_framework)) {
        setValue('use_ota_service', 'no');
    }
    let data: any = getValues();
    data[fields?.[0]?.name?.[0]] = fields?.[0]?.value;
    setValue(fields?.[0]?.name?.[0], fields?.[0]?.value);

    const isHybrid = isHybridFramework(data.app_framework);
    const minVersion = appFrameworks.find((e: any) => e.detail === data.app_framework)?.minVersion;
    let sealingVersions = (serviceables?.serviceVersions || []).filter((e: any) => e.type === (isHybrid ? 'hybrid' : 'native'));

    if (fields?.[0]?.name?.[0] === 'app_framework' && formData.always_latest) {
        setValue('always_latest', false);
        data['always_latest'] = false;
    }

    if (fields?.[0]?.name?.[0] === 'app_framework' && formData.app_framework !== data.app_framework) {
        data['app_framework'] = fields?.[0]?.value;

        if (minVersion) sealingVersions = sealingVersions.filter((e: any) => e.service_version >= minVersion);

        setSealingData(sealingVersions);
        setValue('service_version', null);
        data['service_version'] = null;
        if (isHybridFramework(formData.app_framework) !== isHybrid && nativePlanType !== hybridPlanType && formData.use_ota_service === 'yes') {
            setValue('use_ota_service', 'no');
        }
    }

    if (fields?.[0]?.name?.[0] === 'always_latest' && fields?.[0]?.value) {
        let sealingVersions = (serviceables?.serviceVersions || []).filter((e: any) => e.type === (isHybrid ? 'hybrid' : 'native'));
        if (minVersion) sealingVersions = sealingVersions.filter((e: any) => e.service_version >= minVersion);
        const compareVersions = (v1: string, v2: string): number => {
            const v1Parts: (string | number)[] = v1.split('.').map((part) => (isNaN(Number(part)) ? part : parseInt(part, 10)));
            const v2Parts: (string | number)[] = v2.split('.').map((part) => (isNaN(Number(part)) ? part : parseInt(part, 10)));

            for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
                if (v1Parts[i] === undefined) return -1;
                if (v2Parts[i] === undefined) return 1;
                if (v1Parts[i] < v2Parts[i]) return -1;
                if (v1Parts[i] > v2Parts[i]) return 1;
            }
            return 0;
        };

        const highestVersion = sealingVersions.reduce((max: any, obj: any) => {
            return compareVersions(max.service_version, obj.service_version) > 0 ? max : obj;
        });

        setValue('service_version', highestVersion.service_version);
        data['service_version'] = highestVersion.service_version;
    }
    if (fields?.[0]?.name?.[0] === 'apkfile' && fields?.[0].value?.[0]?.type === 'application/zip' && formData.app_framework !== 'REACT_NATIVE') {
        setValue('app_framework', 'REACT_NATIVE');
        data = {
            ...formData,
            app_framework: 'REACT_NATIVE',
            service_version: null,
            apkfile: fields?.[0].value,
        };

        const minVersion = appFrameworks.find((e: any) => e.detail === data.app_framework)?.minVersion;
        let sealingVersions = (serviceables?.serviceVersions || []).filter((e: any) => e.type === 'hybrid');
        if (minVersion) sealingVersions = sealingVersions.filter((e: any) => e.service_version >= minVersion);

        setSealingData(sealingVersions);
    }

    if (fields?.[0]?.name?.[0] === 'app_type' && (data.vmenv === 'allow' || data.dex_encrypt === 'no')) {
        if (data.vmenv === 'allow') setValue('vmenv', 'forbid');
        if (data.dex_encrypt === 'no') setValue('dex_encrypt', 'yes');
    } else if (fields?.[0]?.name?.[0] === 'block_work_profile') setValue('allow_work_profiles_owner_app_name', []);
    else if (fields?.[0]?.name?.[0] === 'vmenv') {
        setValue('allow_emulator', []);
    } else if (fields?.[0]?.name?.[0] === 'block_keylogger') {
        if (formData.block_keylogger === 'yes' && fields?.[0].value === 'no') {
            showDialog({
                title: i18next.t('do_you_want_to_allow_an_environment_in_which_keyloggers_are_installed'),
                content: i18next.t('allow_keylogger_content_msg'),
                cancelButtonProps: { hidden: true },
                okText: i18next.t('ok'),
            });
        } else if (formData.block_keylogger === 'no' && fields?.[0].value === 'yes') {
            showDialog({
                title: i18next.t('do_you_want_to_block_keylogger'),
                content: i18next.t('block_keylogger_content_msg'),
                cancelButtonProps: { hidden: true },
                okText: i18next.t('ok'),
            });
        }
    } else if (fields?.[0]?.name?.[0] === 'block_external_launch') {
        if (formData.block_external_launch === 'yes' && fields?.[0].value === 'no') {
            if (setShowSealingModal) setShowSealingModal(false);
            showDialog({
                title: i18next.t('do_you_want_to_allow_external_app_launch'),
                content: i18next.t('allow_launch_by_external_app_content_msg'),
                cancelButtonProps: { hidden: true },
                okText: i18next.t('ok'),
            });
        } else if (formData.block_external_launch === 'no' && fields?.[0].value === 'yes') {
            if (setShowSealingModal) setShowSealingModal(false);
            showDialog({
                title: i18next.t('do_you_want_to_block_external_app_launch'),
                content: i18next.t('block_launch_by_external_app_content_msg'),
                cancelButtonProps: { hidden: true },
                okText: i18next.t('ok'),
            });
        }
    } else if (fields?.[0]?.name?.[0] === 'service_version') {
        const isHybrid = isHybridFramework(data.app_framework);
        const details = (serviceables?.serviceVersions || []).filter((e: any) => e.type === (isHybrid ? 'hybrid' : 'native')).find((e: any) => e.service_version === data.service_version);
        if (data.vmenv === 'optional' && details?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.24.0.0') setValue('vmenv', 'forbid');
        if (data.dex_encrypt === 'selective' && details?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.22.0.0') setValue('dex_encrypt', 'yes');
        if (data.block_work_profile === 'optional' && details?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.25.0.0') setValue('block_work_profile', 'yes');
        if (setSelectedServiceDetails) setSelectedServiceDetails(details);
    } else if (fields?.[0]?.name?.[0] === 'apkfile') {
        if (fields?.[0]?.value?.[0]?.name && fields?.[0]?.value?.[0]?.name?.toLowerCase().endsWith('.zip')) {
            setFileType('zip');
            if (TabsInfo) TabsInfo.filter((tab: any) => tab.key !== '2' && tab.key !== '3' && tab.key !== '4');
        } else {
            setFileType('apk');
            if (setSealingTabs) setSealingTabs(TabsInfo);
        }
        setSelectedTab(1);
    } else if (fields?.[0]?.name?.[0] === 'block_usb_debugging' && formData.block_usb_debugging === 'no' && fields?.[0].value === 'no') {
        showDialog({
            title: i18next.t('do_you_want_to_allow_an_environment_in_which_usb_debugging_is_installed'),
            content: i18next.t('allow_usb_debugging_content_msg'),
            cancelButtonProps: { hidden: true },
            okText: i18next.t('ok'),
        });
    } else if (fields?.[0]?.name?.[0] === 'use_query_all_packages' && formData.use_query_all_packages === 'yes' && fields?.[0].value === 'yes') {
        showDialog({
            title: i18next.t('warning'),
            content: i18next.t('use_query_all_packages_content_msg'),
            cancelButtonProps: { hidden: true },
            okText: i18next.t('ok'),
        });
    }

    setFormData(data);
    if (clearErrors) clearErrors();
};

export const getElCommonChild = (el: any, item: any, formData: any, planDetails: any) => {
    const isHybrid = isHybridFramework(formData.app_framework);
    if (formData.app_type === 'NON_GAME' && ((item.name === 'vmenv' && el.value === 'allow') || (item.name === 'dex_encrypt' && el.value === 'no'))) return null;
    else {
        let disabled = true,
            tooltip = i18next.t(el.tooltip, i18next.t(el.text) as string);

        if (item.name === 'vmenv' && el.value === 'optional' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.24.0.0') tooltip = i18next.t('optional_emulator_blocked');
        else if (item.name === 'block_work_profile' && el.value === 'optional' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.25.0.0')
            tooltip = i18next.t('optional_workprofile_blocked');
        else if (item.name === 'dex_encrypt' && el.value === 'selective' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.22.0.0') tooltip = i18next.t('selective_dex_blocked');
        else if ((item.name === 'block_mirroring' || item.name === 'block_remote_access') && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.27.0.0')
            tooltip = i18next.t('new_feature_blocked');
        else if (item.name === 'block_external_launch' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] !== '2.26.0.0') tooltip = i18next.t('2.26_Deprecated');
        else if (item.name === 'use_query_all_packages' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.28.1.0') tooltip = i18next.t('2.28.1_Deprecated');
        else if ((item.name === 'block_usb_debugging' || item.name === 'block_screen_capture') && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.29.0.0')
            tooltip = i18next.t('2.29.0_Deprecated');
        else if (item.name === 'hide_overlay_windows' && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.30.0.0') tooltip = i18next.t('2.30.0_Deprecated');
        else if ((item.name === 'block_developer_options' || item.name === 'wifi_security_protocol') && formData?.[isHybrid ? 'aos_service_version' : 'service_version'] < '2.32.0.0')
            tooltip = i18next.t('2.32.0_Deprecated');
        else if (item.name === 'use_ota_service' && (planDetails?.android?.billing_type !== 'E' || planDetails?.android?.advanced['OTA_SERVICE'] === 'N')) {
            tooltip = i18next.t('available_with_enterprise_plan_only');
        } else disabled = false;

        return (
            <Tooltip key={el.value} title={tooltip}>
                <>
                    {item.fieldtype === 'radio' ? (
                        <FormControlLabel className="w-1/2 text-[10px]" key={el.value} value={el.value} disabled={disabled} control={<Radio className="py-0" />} label={i18next.t(el.text)} />
                    ) : (
                        <ToggleButton size="small" fullWidth key={el.value} value={el.value} disabled={disabled} className="px-4 h-[32px] text-[10px] whitespace-nowrap">
                            {i18next.t(el.text)}
                        </ToggleButton>
                    )}
                </>
            </Tooltip>
        );
    }
};

export const getCommonEl = (data: any, formData: any, control: any, onFieldsChange: any, serviceables: any, planDetails: any, onHelpCenterClick: any) => {
    return data.map((item: any, index: any) => {
        const isHybrid = isHybridFramework(formData.app_framework);
        const serviceVersion = formData?.[isHybrid ? 'aos_service_version' : 'service_version'];

        if (item.max_version && item.max_version < serviceVersion) return null;
        let required = true;
        if (item.name === 'use_query_all_packages' && serviceVersion < '2.29.0.0') {
            required = false;
        }
        if (item.name === 'block_dex_dump' || item.name === 'dex_string_encrypt') {
            if (!serviceVersion || serviceVersion < '2.33.0.0') {
                return false;
            }
        }
        if (item.name === 'block_dex_dump' && formData.dex_encrypt !== 'yes') return false;
        if(item.name === 'dex_string_encrypt' && formData.dex_encrypt !== 'yes') return false;
        if (item.name === 'block_external_launch' && (serviceVersion !== '2.26.0.0' || isHybrid)) {
            return false;
        }

        if((item.name === 'block_mirroring' || item.name === 'block_remote_access')&& (serviceVersion < '2.27.0.0' || isHybrid)){
          return false;
        }

        return (
            <React.Fragment key={index + item.name}>
                <div className={`flex flex-wrap md:flex-nowrap gap-5 mt-1  mb-4 md:mb-0 ${formData[item.name] === 'optional' ? 'items-start' : 'items-center'}`}>
                    <div className={`flex items-center md:justify-between w-full md:w-1/2 md:max-w-[260px] h-full`}>
                        <span>{i18next.t(item.text)}</span>
                        <Tooltip title={i18next.t(item.text + '_tooltip', item.text as string)}>
                            <HelpOutlineOutlinedIcon className={`ml-2 align-middle text-subtitle text-sm ${item.hint ? 'invisible' : 'visible'}`} />
                        </Tooltip>
                    </div>
                    <div className="flex flex-col flex-wrap gap-2 w-full md:w-1/2 max-w-[400px]">
                        <Controller
                            name={item.name}
                            control={control}
                            rules={{
                                required: {
                                    value: required,
                                    message: i18next.t('please_select_atleast_one_value'),
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    {item.fieldtype === 'radio' ? (
                                        <RadioGroup
                                            {...field}
                                            className="flex-nowrap flex"
                                            value={formData[item.name]}
                                            row
                                            onChange={(e) => onFieldsChange([{ name: [item.name], value: e.target.value }])}
                                        >
                                            {item.options.map((el: any) => getElCommonChild(el, item, formData, planDetails))}
                                        </RadioGroup>
                                    ) : (
                                        <ToggleButtonGroup {...field} color="primary" onChange={(e: any) => onFieldsChange([{ name: [item.name], value: e.target.value }])}>
                                            {item.options.map((el: any) => getElCommonChild(el, item, formData, planDetails))}
                                        </ToggleButtonGroup>
                                    )}
                                </>
                            )}
                        />
                        {formData[item.name] === 'optional' && (
                            <Controller
                                name={item.listname}
                                control={control}
                                rules={{
                                    required: i18next.t('please_select_atleast_one_value'),
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl fullWidth size="small" error={formData[item.listname] && formData[item.listname].length === 0}>
                                        <Select
                                            {...field}
                                            size="small"
                                            defaultValue={formData[item.listnamelistname] || []}
                                            placeholder={i18next.t('please_select_atleast_one_value')}
                                            id="select-multiple-chip"
                                            multiple
                                            fullWidth
                                            onChange={(e) => onFieldsChange([{ name: [item.listname], value: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value }])}
                                            input={<OutlinedInput id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {selected.map((value: any) => (
                                                        <Chip key={value} label={value} className="bg-[#999]" />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {(serviceables?.[item.list || ''] || [])
                                                .filter((e: any) => e.type === (isHybridFramework(formData.app_framework) ? 'hybrid' : 'native'))
                                                .map((el: any) => (
                                                    <MenuItem key={el.en_name} value={el.emul_code || el.work_profile_code}>
                                                        {el.en_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {formData[item.listname] && formData[item.listname].length === 0 && <FormHelperText className="text-xs">{i18next.t('please_select_option')}</FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        )}
                    </div>
                </div>
                {item.hint === true && (
                    <p className="ml-2 italic font-light">
                        {i18next.t(`use_query_all_packages_hint_undefined`)}
                        {item.helpdesk && (
                            <span className="ml-2 underline cursor-pointer" style={{ color: '#8E44AD' }} onClick={() => onHelpCenterClick(item.helpdesk)}>
                                {i18next.t('read_more')}
                            </span>
                        )}
                    </p>
                )}
            </React.Fragment>
        );
    });
};

export const getSealingPrestOptions = (data: any) => {
    const preset = data;
    const presetOptions = {
        id: preset.id,
        app_framework: preset.framework,
        service_version: preset.service_version,
        mode: preset.mode,
        app_type: preset.app_type,
        dex_encrypt: preset.dex_encrypt,
        use_query_all_packages: preset.use_query_all_packages,
        dex_string_encrypt: preset.dex_string_encrypt,
        block_dex_dump: preset.block_dex_dump,
        rooting: preset.block_environment.includes('rooting') ? 'forbid' : 'allow',
        vmenv: preset.block_environment.includes('emulator') ? (preset.allow_emulator.length > 0 ? 'optional' : 'forbid') : 'allow',
        block_work_profile: preset.allow_work_profiles_owner_app_name.length > 0 ? 'optional' : preset.block_work_profile,
        block_usb_debugging: preset.block_usb_debugging,
        macro: preset.allow_external_tool.includes('macro') ? 'yes' : 'no',
        sniff: preset.allow_external_tool.includes('sniff') ? 'yes' : 'no',
        block_keylogger: preset.block_keylogger,
        block_external_launch: preset.block_external_launch ?? 'no',
        block_mirroring: preset.block_mirroring ?? 'no',
        block_remote_access: preset.block_remote_access ?? 'no',
        block_screen_capture: preset.block_screen_capture,
        allow_emulator: preset.allow_emulator,
        allow_work_profiles_owner_app_name: preset.allow_work_profiles_owner_app_name,
        hide_overlay_windows: preset.hide_overlay_windows,
        data_sealing: preset.data_sealing,
        use_ota_service: preset.use_ota_service,
        block_developer_options: preset.block_developer_options,
        wifi_security_protocol: preset.wifi_security_protocol,
    };

    return presetOptions;
};

export const compareJSON = (obj1: any, obj2: any): boolean => {
    let isDifferent = false;

    function compareObjects(obj1: any, obj2: any, path: string = '') {
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

        keys.forEach((key) => {
            if (key === 'id') return;

            const newPath = path ? `${path}.${key}` : key;
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (val1 && typeof val1 === 'object' && val2 && typeof val2 === 'object') {
                compareObjects(val1, val2, newPath);
            } else if (val1 !== val2) {
                isDifferent = true;
                // console.log(`Difference found at ${newPath}: obj1=${val1}, obj2=${val2}`);
            }
        });
    }

    compareObjects(obj1, obj2);
    return isDifferent;
};
