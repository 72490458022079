import { Dialog, Box, Typography, Tooltip, Button, TextField, Select, MenuItem, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, DialogActions } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWebhookStatus, requestWebhook, updateWebhook, updateWebhookSubscription } from '../../../../services/manage/settings';
import { RootState } from '../../../../store';
import { AppURL } from '../../../../utility/AppURL';
import { showDialog, showToast } from '../../../utils/notifications';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';

interface WebhookInterface {
    type: string;
    url: string;
    triggerList: string[];
}

let PlainOptions = [
    {
        label: 'Notify when AppSealing`s new announcement arrived',
        value: 'ADC_NEW_ANNOUNCE',
    },
    {
        label: 'Notify when AppSealing`s new Invoice is sent',
        value: 'INVOICE_SENT',
    },
    {
        label: 'Notify when AppSealing update new version for Android',
        value: 'AOS_NATIVE_UPDATED',
    },
    {
        label: 'Notify when AppSealing update new version for iOS',
        value: 'AOS_HYBRID_UPDATED',
    },
];

const ManageNotifications = (props: any) => {
    const { t } = useTranslation();
    const [feedback, setFeedback] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [webhookData, setWebhookData] = useState<WebhookInterface | undefined>();
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const [disabled, setDisabled] = useState(true);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);

    useEffect(() => {
        if (!webhookData) {
            const getWebhookDetails = async () => {
                const params = { token: authContext.token };
                const response = await getWebhookStatus(params);

                var data: WebhookInterface = {
                    type: 'SLACK',
                    url: '',
                    triggerList: PlainOptions.map((e: any) => e.value),
                };

                if (response?.result?.code === '0000' && response?.webhookUrl?.webhook_url) {
                    data.url = response.webhookUrl.webhook_url;
                    data.type = response.webhookUrl.webhook_type;
                    data.triggerList = [];
                    response.subscribedTriggerList.forEach((e: any) => {
                        if (e.enabled === 'Y') data.triggerList.push(e.trigger_code);
                    });
                }
                setWebhookData(data);
                setCheckedValues(data.triggerList);
                setDisabled(false);
            };
            getWebhookDetails();
        }
    }, [webhookData, authContext.token]);

    const onPlatformChange = (e: { target: { value: any } }) => {
        let value = e.target.value;
        if (value === 'other') setIsModalVisible(true);
    };

    const onURLChange = (e: any) => {
        setWebhookData((oldData) => {
            let data;
            if (oldData) data = { ...oldData, url: e.target.value };
            return data;
        });
    };

    const onCheckAllChange = (e: any) => {
        const triggerList = e.target.checked ? PlainOptions.map((e: any) => e.value) : [];
        setWebhookData((oldData) => {
            let data;
            if (oldData) data = { ...oldData, triggerList };
            return data;
        });
        setCheckedValues(triggerList);
    };

    const onSaveClick = async () => {
        const updateNotifications = async () => {
            const params = {
                token: authContext.token,
                webhook_type: webhookData?.type || 'SLACK',
                webhook_url: webhookData?.url || '',
            };

            const subscriptionParams = {
                token: authContext.token,
                options: PlainOptions.map((e) => e.value),
                triggers: webhookData?.triggerList || [],
            };

            const response = await updateWebhook(params);
            if (response?.result?.code === '0000') {
                const subscriptionResponse = await updateWebhookSubscription(subscriptionParams);
                if (subscriptionResponse.code === '0000') showToast('success', t('slack_integration_subscription_updated_successfully_contact_help_desk_for_any_assistance'));
                else showToast('success', t('slack_integration_updated_successfully_contact_help_desk_for_any_assistance'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_integrating_notifications_please_try_again_or_connect_with_help_desk')));
            else showToast('error', t('error_while_integrating_notifications_please_try_again_or_connect_with_help_desk'));
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_update_notifications_setting'),
            onOk: updateNotifications,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onSendClick = async () => {
        const payload = `${t('webhook_request_arrived')}\n
        >>> ${userDetails.user_id} \n
        ${t('at')} = ${window.location.href} \n
        ${t('feedback')}:\n ${feedback}`;

        const response = await requestWebhook({ payload });
        if (response?.result?.code === '0000') {
            setFeedback('');
            setIsModalVisible(false);
            showToast('success', t('your_request_for_webhook_sent_successfully_contact_help_desk_for_any_assistance'));
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_sending_feedback_please_try_again_or_connect_with_help_desk')));
        else showToast('error', t('error_while_sending_feedback_please_try_again_or_connect_with_help_desk'));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCheckedValues((prevValues) => (prevValues.includes(value) ? prevValues.filter((v) => v !== value) : [...prevValues, value]));
    };

    const checkboxes = PlainOptions.map((option) => (
        <FormControlLabel
            key={option.value}
            control={<Checkbox size={'small'} checked={checkedValues.includes(option.value)} onChange={handleCheckboxChange} value={option.value} />}
            label={<span className="text-darkgray text-sm">{t(option.label.toLowerCase().replaceAll(' ', '_'))}</span>}
        />
    ));

    return (
        <ContentLayout title="manage_notifications" onSubmit={onSaveClick} btnText={t('save')} disabled={disabled}>
            <Dialog open={isModalVisible} onClose={() => setIsModalVisible(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modalStyle">
                    <Typography variant="h1">
                        {t('request_other_platform_integration')}
                    </Typography>
                    <Box className="flex flex-wrap gap-4">
                        <Typography mt={2} mb={1} className='text-sm'>
                            {t('request_other_platform_integration_msg')}
                        </Typography>
                        <TextField
                            placeholder={t('please_specify_other_platform_details')}
                            multiline
                            minRows={2}
                            maxRows={3}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            style={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Box>
                    <DialogActions className='mt-10'>
                        <Button variant='contained' onClick={onSendClick}>{t('send')}</Button>
                        <Button variant="outlined" color="secondary" onClick={() => setIsModalVisible(false)}>{t('cancel')}</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <div className="text-base font-bold">{t('integrate_appsealing_notifications')}</div>
            <div className="mx-2.5">
                <div className="my-5">
                    <span className="text-darkgrey text-sm">{t('get_notified_about_appsealing_updates_directly_via_your_communication_platform_tools')}</span>
                    <Tooltip
                        placement="top"
                        title={
                            <p>
                                <span>{t('do_you_need_a_guide_to_create_slack_webhook_url')}</span>
                                <a className="ml-2 underline text-white" target="_blank" rel="noreferrer" href={AppURL.slackwebhook}>
                                    {t('click_here')}
                                </a>
                            </p>
                        }
                    >
                        <HelpOutlineOutlinedIcon className="ml-2 mt-auto mb-auto align-middle text-sm text-darkgray" />
                    </Tooltip>
                </div>
                <div className="flex flex-none gap-4">
                    <FormControl fullWidth className="sm:w-52">
                        <InputLabel>{t('select_platform')}</InputLabel>
                        <Select value={webhookData?.type || 'SLACK'} onChange={onPlatformChange} size={'small'} label={t('select_platform')}>
                            <MenuItem value="SLACK">{t('slack_webhook')}</MenuItem>
                            <MenuItem value="other">{t('other_platform')}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        type="url"
                        placeholder="https://...."
                        value={webhookData?.url || ''}
                        fullWidth
                        className="max-w-[300px]"
                        label={t('site_address')}
                        size={'small'}
                        onChange={onURLChange}
                        variant="outlined"
                    />
                </div>
            </div>
            <hr className="mt-7 mb-10" />
            <Box>
                <p className="text-base font-bold mb-5">{t('list_of_appsealing_notification_events')} :</p>
                <div className="pl-7">
                    <FormGroup>
                        <FormControlLabel
                            key={'select_all'}
                            control={<Checkbox size="small" checked={checkedValues.length > 0 && checkedValues.length > 3} onChange={onCheckAllChange} />}
                            label={<span className="font-bold text-darkgray text-sm">{t('select_all_notification_events')}</span>}
                        />
                        {checkboxes}
                    </FormGroup>
                </div>
            </Box>
        </ContentLayout>
    );
};

export default ManageNotifications;
