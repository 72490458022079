import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountsReducerInterface {
    accountDetails?: any,
    ownerList?: Array<any>
};

const initialState: AccountsReducerInterface = {
    accountDetails: null,
    ownerList: [],
};

interface OwnerListInterface {
    ownerList: Array<any>
};

const AccountsReducerSlice = createSlice({
    name: 'accountsReducer',
    initialState,
    reducers: {
        setAccountDetails: (state, action: PayloadAction<any>) => {
            state.accountDetails = action.payload
        },
        setOwnerList: (state, action: PayloadAction<OwnerListInterface>) => {
            state.ownerList = action.payload.ownerList
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = initialState;
        }
    }
});

export const { setAccountDetails, setOwnerList, userLogout } = AccountsReducerSlice.actions;

export default AccountsReducerSlice.reducer;