import { Button, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { requestAppTransfer } from '../../../../../services/securityservices';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, InputLabel, TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import { showToast } from '../../../../utils/notifications';
import CachedIcon from '@mui/icons-material/Cached';

interface AccountTransferInterface {
    appData: any;
}

const AccountTransfer = (props: AccountTransferInterface) => {
    const { t } = useTranslation();
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isOwner = authContext?.role === 'Owner';

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            target_mail: '',
            transfer_reason_select: '',
            transfer_reason: '',
        },
    });

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appData]);

    const onRequestTransferClick = async () => {
        const { target_mail, transfer_reason_select, transfer_reason = '' } = watch();
        if (props.appData) {
            const params = {
                token: authContext.token,
                package_name: props.appData.package_name,
                target_mail,
                transfer_reason,
                transfer_reason_select,
            };
            const response = await requestAppTransfer(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', t('application_trasferred_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', t('error_while_transferring_account'));
        }
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <p className="mb-5">{t('transfer_account')}</p>
            <Button variant="outlined" color="error" fullWidth onClick={handleDrawerOpen}  disabled={!isOwner}>
                {t('transfer')}
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
                <div className="p-10 w-9/12 lg:w-[800px]">
                    <div className="flex justify-between pb-9">
                        <div>
                            <h2 className="m-0 text-2xl font-medium">
                                {t('transfer_account')} - {props.appData.package_name}
                            </h2>
                            <p className="text-xs text-gray-500">{t('transfer_account')}</p>
                        </div>
                        <div className="flex justify-end gap-4 mb-4">
                            <Button variant="outlined" color="secondary" onClick={() => reset()}>
                                <CachedIcon />
                            </Button>
                            <Button variant="outlined" color="error" onClick={handleSubmit(onRequestTransferClick)}>
                                {t('request_transfer')}
                            </Button>
                        </div>
                    </div>
                    <div className="mb-10">
                        <p className="block text-sm font-normal">{t('you_will_not_able_to_access_this_app')}</p>
                    </div>
                    <form onSubmit={handleSubmit(onRequestTransferClick)} className="pt-2 max-w-[520px]">
                        <FormControl fullWidth error={!!errors.target_mail} size="small" className="mb-10">
                            <Controller
                                name="target_mail"
                                control={control}
                                rules={{ required: t('please_enter_valid_email_address') }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        className="h-[40px]"
                                        size="small"
                                        error={!!error}
                                        placeholder={t('enter_email_address')}
                                        label={t('email_address')}
                                        helperText={error ? error.message : null}
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors.transfer_reason} size="small" className="mb-10">
                            <Controller
                                name="transfer_reason_select"
                                control={control}
                                rules={{ required: t('please_select_transfer_reason') }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <InputLabel id="transfer_reason_label" className={`${!!error ? 'text-[#FF0000]' : ''}`}>{`${t('target_type')} *`}</InputLabel>
                                        <Select
                                            {...field}
                                            labelId="transfer_reason_label"
                                            id="transfer_reason_select"
                                            placeholder={t('please_select_transfer_reason')}
                                            label={`${t('transfer_reason')} *`}
                                            error={!!error}
                                            fullWidth
                                            required
                                        >
                                            <MenuItem value="1">{t('i_want_to_grant_team_member_to_use_appsealing_with_this_app')}</MenuItem>
                                            <MenuItem value="2">{t('i_want_to_use_company_mail_rather_than_public_mail_for_the_test')}</MenuItem>
                                            <MenuItem value="3">{t('app_publisher_company_domain_is_changed')}</MenuItem>
                                            <MenuItem value="4">{t('other')}</MenuItem>
                                        </Select>
                                        {error && <FormHelperText className="text-[#FF0000]">{error.message}</FormHelperText>}
                                    </>
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth className="mb-[60px]">
                            <Controller
                                name="transfer_reason"
                                control={control}
                                render={({ field }) => (
                                    <TextField {...field} label={t('detailed_reason')} placeholder={t('enter_detailed_reason_for_transfer')} multiline minRows={3} variant="outlined" fullWidth />
                                )}
                            />
                        </FormControl>
                    </form>
                </div>
            </Drawer>
        </>
    );
};

export default AccountTransfer;
