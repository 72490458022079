import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authoriseUser } from '../../../../../services/manage/settings';
import { RootState } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { showToast } from '../../../../utils/notifications';

interface ConfirmAccountUpdateInterface {
    visible: boolean;
    onCancel: () => void;
    onConfirmed: () => void;
}

const ConfirmAccountUpdate = (props: ConfirmAccountUpdateInterface) => {
    const { t } = useTranslation();
    const [userPassword, setUserPassword] = useState('');
    const [isAuthorised, setIsAuthorised] = useState(true);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);

    useEffect(() => {
        if (props.visible) {
            setUserPassword('');
        }
    }, [props.visible]);

    const onConfirmClick = async () => {
        if (isAuthorised) {
            props.onConfirmed();
        } else {
            const params = {
                user_id: userDetails.user_id,
                user_pw: userPassword,
                token: authContext.token,
            };
            const response = await authoriseUser(params);
            if (response?.result?.code === '0000') {
                setIsAuthorised(true);
            } else if (response?.result?.code) {
                setUserPassword('');
                setIsAuthorised(false);
                showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_validate_please_try_again_later')));
            } else {
                setUserPassword('');
                setIsAuthorised(false);
                showToast(
                    'error',
                    response?.result?.code || response?.result?.message ? (t(`RCM-${response?.result?.code}`, response?.result?.message) as string) : t('failed_to_validate_please_try_again_later'),
                );
            }
        }
    };

    return (
        <Dialog
            open={props.visible}
            aria-labelledby="confirm-account-update-title"
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: {
                    padding: 20,
                },
            }}
        >
            <DialogTitle id="confirm-account-update-title">
                <Typography variant="h1">{t('confirm_account_update')}</Typography>
            </DialogTitle>
            <DialogContent className="pb-5">
                {isAuthorised ? (
                    <p>{t('are_you_sure_to_update_your_account_details')}</p>
                ) : (
                    <div>
                        <p className="pb-4">{t('to_validate_authority_please_confirm_your_password')}</p>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label={t('password')}
                            placeholder={t('enter_password')}
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={userPassword}
                            onChange={(e) => setUserPassword(e.target.value)}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="secondary" variant="outlined">
                    {t('cancel')}
                </Button>
                <Button onClick={onConfirmClick} color="primary" variant="contained">
                    {isAuthorised ? t('agree_update') : t('confirm_identity')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmAccountUpdate;
