import { Post } from "../..";
import { DashboardInterface } from "../../../type/api/data";
import { getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const getDashboardData = async (params: DashboardInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, account_id = '', type, package_name, compare_date_from, compare_date_to, sealing_mode = 'R' } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    urlParams.append('compare_date_from', compare_date_from);
    urlParams.append('compare_date_to', compare_date_to);
    if (type === 'android') {
        urlParams.append('package_name', package_name);
        urlParams.append('sealing_mode', sealing_mode);
    }
    else {
        urlParams.append('bundle_id', package_name);
        urlParams.append('account_id', account_id);
    }
    urlParams.append('token', token);

    const url = getRegionalURL(type === 'android' ? RegionalURL.totdata : RegionalURL.iostotdata, region);
    const response = await Post(
        url, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};