import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import DataTable from '../../DataDisplay/Table'
import { useTranslation } from 'react-i18next';

interface CountryTableProps {
	countryData?: any[];
  countryDataFull?: any[];
  hideFooter?: boolean;
  pageSize?: number;
}


const CountryTable = ({countryData = [], countryDataFull, hideFooter=false, pageSize = 1}: CountryTableProps) => {
  const { t, i18n } = useTranslation();

  const transformedData = countryData
    .filter(e => e.country)
    .sort((a, b) => b.total - a.total)
    .slice(0, 5);

  const columns: GridColDef[] = [
    {
      flex:1,
      field: 'countryName',
      headerName: t('country'),
      width: 150,
			disableColumnMenu: true,
      renderCell: (params) => { 
      if(countryDataFull) return params.value
       return i18n.language !== "kr_KR" ? params.row.country_name : params.row.country_name_kr
      
      },
    },
    {
      flex:1,
      field: countryDataFull ? 'h_cnt' : 'typeCnt',
      headerName: t('hacking_attempts'),
      width: 150,
			disableColumnMenu: true,
      renderCell: (params) => {
        if(countryDataFull) return params.value.toLocaleString();
        return `${params.value.toLocaleString()} (${params.row.typeDistribution.toFixed(2)}%)`;
      }
    },
    {
      flex:1,
      field: countryDataFull ? 'd_cnt' : 'deviceCnt',
      headerName: t('unique_devices_hacking_attempts'),
      width: 200,
			disableColumnMenu: true,
      renderCell: (params) =>{
        if(countryDataFull) return params.value.toLocaleString();
        return `${params.value.toLocaleString()} (${params.row.typeDistribution.toFixed(2)}%)`;
      }
    },
  ];

  return (
    <div>
      <DataTable
        rows={countryDataFull ? countryDataFull.map((country, index)=> ({...country, key: index})) : transformedData}
        columns={columns}
        pageSize={pageSize}
				hideFooter={hideFooter}
      />
    </div>
  );
};

export default CountryTable;
