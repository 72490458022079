import { Button, ButtonGroup } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

interface ViewToggleBtnProps {
    viewType: string;
    setViewType: (value: string) => void;
}

const ViewToggleBtn = ({ viewType, setViewType }: ViewToggleBtnProps) => {



    return (
        <ButtonGroup aria-label="ui view" className="h-[32px]">
            <Button 
                variant={'outlined'} 
                aria-label="step"
                className="p-2"
                onClick={() => setViewType('step')}
                sx={{
                    borderColor: viewType === 'step' ? '#CEB6DC' : '#D2D3D3',
                    color: viewType === 'step' ? '#9D6DB9' : '#D2D3D3',
                    backgroundColor: viewType === 'step' ? '#F0E9F4' : 'transparent',
                }}
                
            >
                <ViewModuleIcon />
            </Button>
            <Button 
                variant={'outlined'} 
                aria-label="basic"
                onClick={() => setViewType('basic')}
                className="p-2"
                sx={{
                    borderColor: viewType === 'basic' ? '#CEB6DC' : '#D2D3D3',
                    color: viewType === 'basic' ? '#9D6DB9' : '#D2D3D3',
                    backgroundColor: viewType === 'basic' ? '#F0E9F4' : 'transparent',
                }}
            >
                <ViewHeadlineIcon />
            </Button>
        </ButtonGroup>
    );
};

export default ViewToggleBtn;
