import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MuiDialogProps {
  open: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  handleOk?: () => void;
  handelCancel?: () => void;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function MuiDialog({
  open,
  header,
  footer,
  maxWidth = false,
  handleOk,
  handelCancel,
  children,
}: MuiDialogProps) {
  const [visible, setVisible] = React.useState(false);

  const handleClose = () => {
    setVisible(false);
    if (handelCancel) {
      handelCancel();
    }
  };
  React.useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={visible}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
          >
            {header}
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{padding: 0}}
            >
              <CloseIcon sx={{color: '#505050'}}/>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {footer && <DialogActions>{footer}</DialogActions>}
      </Dialog>
    </React.Fragment>
  );
}
