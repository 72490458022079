import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProfileInfo, fetchCommonData } from '../services/main';
import { setAuthContext, setPlanDetails, setAccountDetails, setUserDetails, setUserPreferences, setLocalPreferences, setCompanyDetails } from '../store/main';
import { setCommonData } from '../store/common';
import { setCookies } from '../utility/Common';

export const useAuthorizationService = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authorizeUser = useCallback(
        async (token: string, showLoadMask?: any) => {
            if (showLoadMask) showLoadMask({ msg: t('validating_user_information') });
            const response = await getProfileInfo(token);
            if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
                const commonDataResponse = await fetchCommonData(token);
                dispatch(
                    setAuthContext({
                        ...response.auth,
                        isAuth: true,
                        isAppSealed: response?.packageOnOffStatus?.length > 0 ? true : false,
                    }),
                );
                dispatch(
                    setCommonData({
                        ...commonDataResponse,
                    }),
                );
                dispatch(
                    setPlanDetails({
                        ...response.plan,
                    }),
                );
                dispatch(
                    setAccountDetails({
                        ...response.account,
                    }),
                );
                dispatch(
                    setUserDetails({
                        ...response.user,
                        fullName: response.user.first_name || response.user.last_name ? `${response.user.first_name || ''} ${response.user.last_name || ''}` : response.user.user_id.split('@')[0],
                    }),
                );
                dispatch(
                    setUserPreferences({
                        ...response.preference,
                    }),
                );
                dispatch(
                    setCompanyDetails({
                        ...response.company,
                    }),
                );
                dispatch(
                    setLocalPreferences({
                        ...commonDataResponse.localPreference,
                    }),
                );
            } else {
                setCookies('appsealing', '');
                dispatch(
                    setAuthContext({
                        isAuth: false,
                    }),
                );
            }
            if (showLoadMask) showLoadMask(false);
        },
        [dispatch, t],
    );
    return { authorizeUser };
};
