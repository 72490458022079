import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAndroidPackages, getIOSPackages } from '../../../services/data';
import { getDailyActiveDeviceData, getMonthlyActiveDeviceData } from '../../../services/data/activedevices';
import { RootState } from '../../../store';
import { setAndroidPackages, setIOSPackages } from '../../../store/data';
import { setLocalPreferences } from '../../../store/main';
import { useTranslation } from 'react-i18next';
import DataHeader from '../DataHeader';
import { Button } from '../../Foundation/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Tooltip } from '@mui/material';
import DataLayout from '../DataLayout';

const currentDate = moment(new Date()).subtract(1, 'd');
const calculateChange = (newVal: any, oldVal: any) => Math.abs(((newVal - oldVal) / oldVal || 0) * 100).toFixed(3);

const getDays = (from: any, to: any, format?: string) => {
    format = format || 'YYYYMMDD';
    from = moment(from, 'YYYYMMDD').toDate();
    to = moment(to, 'YYYYMMDD').toDate();

    let dateArray: Array<any> = [],
        date = from;
    while (date <= to) {
        dateArray.push({ x: moment(date).format('YYYYMMDD'), y: 0 });
        date = moment(date).add(1, 'd').toDate();
    }
    return dateArray;
};

const getDateFrequency = (frequncy: string, toDate?: moment.Moment, fromDate?: moment.Moment) => {
    toDate = toDate || moment(currentDate);
    let range: any = {};
    if (frequncy === 'week') {
        range.calc_date_to = moment(toDate).format('YYYYMMDD');
        range.calc_date_from = moment(toDate).subtract(7, 'd').format('YYYYMMDD');
    } else if (frequncy === 'month') {
        range.calc_date_to = moment(toDate).format('YYYYMMDD');
        range.calc_date_from = moment(toDate).startOf('month').format('YYYYMMDD');
    } else if (frequncy === 'prevmonth') {
        range.calc_date_to = moment(toDate).subtract(1, 'M').endOf('month').format('YYYYMMDD');
        range.calc_date_from = moment(toDate).subtract(1, 'M').startOf('month').format('YYYYMMDD');
    } else if (frequncy === 'custom') {
        range.calc_date_to = moment(fromDate).format('YYYYMMDD');
        range.calc_date_from = moment(toDate).format('YYYYMMDD');
    }
    return range;
};

const getMonths = (from: any, to: any, format?: string) => {
    format = format || 'YYYYMM';
    from = moment(from, 'YYYYMM').toDate();
    to = moment(to, 'YYYYMM').toDate();

    let dateArray: Array<any> = [],
        date = from;
    while (date <= to) {
        dateArray.push({ x: moment(date).format('YYYYMM'), y: 0 });
        date = moment(date).add(1, 'M').toDate();
    }
    return dateArray;
};

const getMonthFrequency = (frequncy: string, toMonth?: moment.Moment, fromMonth?: moment.Moment) => {
    toMonth = toMonth || moment(currentDate);
    let range: any = {};
    if (frequncy === 'querter') {
        range.calc_date_to = moment(toMonth).format('YYYYMM');
        range.calc_date_from = moment(toMonth).subtract(2, 'M').format('YYYYMM');
    } else if (frequncy === 'semiyear') {
        range.calc_date_to = moment(toMonth).subtract(1, 'M').format('YYYYMM');
        range.calc_date_from = moment(toMonth).subtract(6, 'M').format('YYYYMM');
    } else if (frequncy === 'year') {
        range.calc_date_to = moment(toMonth).subtract(1, 'M').format('YYYYMM');
        range.calc_date_from = moment(toMonth).subtract(12, 'M').format('YYYYMM');
    } else if (frequncy === 'custom') {
        range.calc_date_to = moment(fromMonth).format('YYYYMM');
        range.calc_date_from = moment(toMonth).format('YYYYMM');
    }
    return range;
};

const disabledDate = (current: any) => {
    return current && (current > moment().endOf('day') || current < moment().subtract(1, 'year'));
};

let initialDADData = [
    {
        id: 'Inka Entworks',
        data: getDays(moment(currentDate).subtract(7, 'd').format('YYYYMMDD'), moment(currentDate).format('YYYYMMDD')),
    },
];

let initialMADData = [
    {
        id: 'Inka Entworks',
        data: getMonths(moment(currentDate).subtract(3, 'M').format('YYYYMM'), moment(currentDate).subtract(1, 'M').format('YYYYMM')),
    },
];

const ActiveDevices = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState<any>(null);
    const [dadData, setDadData] = useState<any>(initialDADData);
    const [dadLegend, setDadLegend] = useState<any>({ min: 0, max: 10, avg: 0 });
    const [dadHistoryLegend, setDadHistoryLegend] = useState<any>({
        min: 0,
        max: 10,
        avg: 0,
    });
    const [madData, setMadData] = useState<any>(initialMADData);
    const [madLegend, setMadLegend] = useState<any>({ min: 0, max: 10, avg: 0 });
    const [madHistoryLegend, setMadHistoryLegend] = useState<any>({
        min: 0,
        max: 10,
        avg: 0,
    });
    const [platform, setPlatform] = useState<'android' | 'ios'>('android');
    const [selectedApp, setSelectedApp] = useState<string | null | undefined>(null);
    const [dadFrequency, setDADFrequency] = useState('week');
    const [madFrequency, setMADFrequency] = useState('querter');
    const [sealingMode, setSealingMode] = useState<'R' | 'T'>('R');
    const [dadCustomRange, setDADCustomRange] = useState<any>([moment(currentDate).subtract(7, 'd'), moment(currentDate)]);
    const [madCustomRange, setMADCustomRange] = useState<any>([moment(currentDate).subtract(1, 'M'), moment(currentDate)]);
    const [key, setKey] = useState(0);
    const [key2, setKey2] = useState(100);

    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const iosPackages = useSelector((state: RootState) => state.dataReducer.iosPackages);
    const androidPackages = useSelector((state: RootState) => state.dataReducer.androidPackages);
    const localPreference = useSelector((state: RootState) => state.mainReducer.localPreference);

    useEffect(() => {
        if (localPreference && Array.isArray(iosPackages) && Array.isArray(androidPackages) && data === null) {
            if (localPreference.platform) setPlatform(localPreference.platform);
            if (localPreference.sealingMode) setSealingMode(localPreference.sealingMode);
            if (localPreference.platform === 'android' && androidPackages.find((e) => e.package_name === localPreference.android_pkg)) setSelectedApp(localPreference.android_pkg);
            else if (localPreference.platform === 'ios' && iosPackages.find((e) => e.bundle_id === localPreference.ios_pkg)) setSelectedApp(localPreference.ios_pkg);
            setData(localPreference);
        }
    }, [localPreference, data, iosPackages, androidPackages]);

    useEffect(() => {
        if (platform && selectedApp) {
            const newPref = {
                ...data,
                platform,
                sealingMode,
                [platform === 'android' ? 'android_pkg' : 'ios_pkg']: selectedApp,
            };
            dispatch(setLocalPreferences(newPref));
            localStorage.setItem('user_preferences', JSON.stringify(newPref));
        }
    }, [platform, selectedApp, sealingMode, data, dispatch]);

    useEffect(() => {
        if (iosPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getIOSPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataBundleIdList)) {
                    dispatch(setIOSPackages([...response.dataBundleIdList]));
                }
            };
            fetchPackages();
        }
    }, [region, iosPackages, authContext.token, dispatch]);

    useEffect(() => {
        if (androidPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getAndroidPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataPackageList)) {
                    dispatch(setAndroidPackages([...response.dataPackageList]));
                }
            };
            fetchPackages();
        }
    }, [region, androidPackages, authContext.token, dispatch]);

    const fetchDailyActiveDevicesData = useCallback(async () => {
        let toDate = dadFrequency === 'custom' ? dadCustomRange[1] : undefined,
            fromDate = dadFrequency === 'custom' ? dadCustomRange[0] : undefined;
        const range = getDateFrequency(dadFrequency, fromDate, toDate);
        const params = {
            ...range,
            token: authContext.token,
            package_name: selectedApp,
            sealing_mode: sealingMode,
            type: platform as string,
        };
        const response = await getDailyActiveDeviceData(params, region);
        if (dadFrequency === 'custom') {
            const duration = moment.duration(moment(toDate).diff(moment(fromDate))).days();
            fromDate = moment(range.calc_date_from, 'YYYYMMDD').subtract(1, 'd');
            toDate = moment(fromDate).subtract(duration, 'd');
        } else {
            toDate = moment(range.calc_date_from, 'YYYYMMDD').subtract(1, 'd');
        }
        const historyrange = getDateFrequency(dadFrequency, toDate, fromDate);
        const historyParams = {
            ...params,
            ...historyrange,
        };
        const historyResponse = await getDailyActiveDeviceData(historyParams, region);
        let legendInfo = { min: 0, max: 10, sum: 0, avg: 0 },
            historyLegendInfo = { min: 0, max: 10, sum: 0, avg: 0 },
            data = getDays(range.calc_date_from, range.calc_date_to),
            historyData = getDays(historyrange.calc_date_from, historyrange.calc_date_to);
        if (response?.result?.code === '0000' && Array.isArray(response?.dataList)) {
            data = data.map((element: any) => {
                const item = response?.dataList.find((e: any) => e.calc_date === element.x);
                const y = parseInt(item?.cnt) || element.y;
                if (y < legendInfo.min) legendInfo.min = y;
                else if (y > legendInfo.max) legendInfo.max = y;
                legendInfo.sum = y + legendInfo.sum;
                return {
                    ...element,
                    ...item,
                    y,
                };
            });

            historyData.forEach((element: any) => {
                const item = historyResponse?.dataList.find((e: any) => e.calc_date === element.x);
                if (item) historyLegendInfo.sum = (parseInt(item?.cnt) || element.y) + historyLegendInfo.sum;
            });
        }

        legendInfo.avg = parseInt(legendInfo.sum / data.length + '');
        historyLegendInfo.avg = parseInt(historyLegendInfo.sum / historyResponse?.dataList.length + '');
        setDadLegend(legendInfo);
        setDadHistoryLegend(historyLegendInfo);
        setDadData([{ id: 'Inka Entworks', data: data }]);
    }, [region, platform, sealingMode, selectedApp, dadFrequency, authContext.token, dadCustomRange]);

    const fetchMonthlyActiveDevicesData = useCallback(async () => {
        let toMonth = madFrequency === 'custom' ? madCustomRange[1] : undefined,
            fromMonth = madFrequency === 'custom' ? madCustomRange[0] : undefined;
        const range = getMonthFrequency(madFrequency, fromMonth, toMonth);
        const params = {
            ...range,
            token: authContext.token,
            package_name: selectedApp,
            sealing_mode: sealingMode,
            type: platform as string,
        };
        const response = await getMonthlyActiveDeviceData(params, region);
        if (madFrequency === 'custom') {
            const duration = moment.duration(moment(toMonth).diff(moment(fromMonth)));
            fromMonth = moment(range.calc_date_from, 'YYYYMM').subtract(1, 'M');
            toMonth = moment(fromMonth).subtract(duration, 'M');
        } else {
            toMonth = moment(range.calc_date_from, 'YYYYMM');
        }
        const historyrange = getMonthFrequency(madFrequency, toMonth, fromMonth);
        const historyParams = {
            ...params,
            ...historyrange,
        };
        const historyResponse = await getMonthlyActiveDeviceData(historyParams, region);

        let legendInfo = { min: 0, max: 10, sum: 0, avg: 0 },
            historyLegendInfo = { min: 0, max: 10, sum: 0, avg: 0 },
            data = getMonths(range.calc_date_from, range.calc_date_to),
            historyData = getMonths(historyrange.calc_date_from, historyrange.calc_date_to);
        if (response?.result?.code === '0000' && Array.isArray(response?.dataList)) {
            data = data.map((element: any) => {
                const item = response?.dataList.find((e: any) => e.calc_date === element.x);
                const y = parseInt(item?.cnt) || element.y;
                if (y < legendInfo.min) legendInfo.min = y;
                else if (y > legendInfo.max) legendInfo.max = y;
                legendInfo.sum = y + legendInfo.sum;
                return {
                    ...element,
                    ...item,
                    y,
                };
            });

            historyData.forEach((element: any) => {
                const item = historyResponse?.dataList.find((e: any) => e.calc_date === element.x);
                if (item) historyLegendInfo.sum = (parseInt(item?.cnt) || element.y) + historyLegendInfo.sum;
            });
        }

        legendInfo.avg = parseInt(legendInfo.sum / data.length + '');
        historyLegendInfo.avg = parseInt(historyLegendInfo.sum / historyResponse?.dataList?.length + '');
        setMadLegend(legendInfo);
        setMadHistoryLegend(historyLegendInfo);
        setMadData([{ id: 'Inka Entworks', data }]);
    }, [region, platform, sealingMode, selectedApp, madFrequency, authContext.token, madCustomRange]);

    useEffect(() => {
        if (platform && selectedApp && dadFrequency) fetchDailyActiveDevicesData();
    }, [platform, selectedApp, dadFrequency, authContext.token, fetchDailyActiveDevicesData]);

    useEffect(() => {
        if (platform && selectedApp && madFrequency) fetchMonthlyActiveDevicesData();
    }, [platform, selectedApp, madFrequency, authContext.token, fetchMonthlyActiveDevicesData]);

    return (
        <DataLayout>
            <DataHeader
                title={t('active_devices')}
                subtitle={t('unique_active_devices_information')}
                sealingMode={sealingMode}
                setSealingMode={setSealingMode}
                platform={platform}
                setPlatform={setPlatform}
                selectedApp={selectedApp ? (platform === 'android' ? androidPackages?.find((e) => e.package_name === selectedApp) : iosPackages?.find((e) => e.bundle_id === selectedApp)) : null}
                setSelectedApp={setSelectedApp}
                packages={platform === 'android' ? androidPackages : iosPackages}
            />
            <div className="w-full min-h-full md:min-h-2/3 3xl:min-h-1/2 3xl:h-1/2 grid grid-cols-8 md:gap-x-4 gap-y-4 mb-4">
                <div className="col-span-8 lg:col-span-3 1xl:col-span-2 grid gap-4">
                    <div className="p-4 bg-primary flex flex-col rounded-[4px] border border-[#E0E0E0]">
                        <p className="text-base">{t('daily_active_devices')}</p>
                        <p className="text-subtitle text-[12px]">{dadFrequency === 'month' ? t('this_month') : t(dadFrequency)}</p>
                        <p
                            className={`mt-4 text-5xl font-semibold 
                         ${dadLegend.avg < dadHistoryLegend.avg ? 'text-infored' : 'text-infoblue'}`}
                        >
                            {dadLegend.avg?.toLocaleString() || 0}
                        </p>
                        <div
                            className={`mt-4 text-xl flex gap-x-4 justify-start 
                        ${dadLegend.avg < dadHistoryLegend.avg ? 'text-infored' : 'text-infoblue'}`}
                        >
                            <p>
                                {dadLegend.avg < dadHistoryLegend.avg ? <ArrowDownwardIcon className="mr-2" /> : <ArrowUpwardIcon className="mr-2" />}
                                {`${calculateChange(dadLegend.avg, dadHistoryLegend.avg)} %`}
                            </p>
                            <p>
                                {' '}
                                {dadLegend.avg < dadHistoryLegend.avg ? <ArrowDownwardIcon className="mr-2" /> : <ArrowUpwardIcon className="mr-2" />}
                                {Math.abs(dadLegend.avg - dadHistoryLegend.avg).toLocaleString()}
                            </p>
                        </div>
                        <div className="flex flex-col gap-5 mt-9">
                            <div className="flex gap-5 flex-wrap lg:flex-nowrap">
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    onClick={() => setDADFrequency('week')}
                                    fullWidth
                                    variant={dadFrequency === 'week' ? 'contained' : 'outlined'}
                                    color={dadFrequency === 'week' ? 'primary' : 'secondary'}
                                >
                                    {t('last_7_days')}
                                </Button>
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setDADFrequency('month')}
                                    variant={dadFrequency === 'month' ? 'contained' : 'outlined'}
                                    color={dadFrequency === 'month' ? 'primary' : 'secondary'}
                                >
                                    {t('this_month')}
                                </Button>
                            </div>
                            <div className="flex gap-5 flex-wrap lg:flex-nowrap">
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setDADFrequency('prevmonth')}
                                    variant={dadFrequency === 'prevmonth' ? 'contained' : 'outlined'}
                                    color={dadFrequency === 'prevmonth' ? 'primary' : 'secondary'}
                                >
                                    {t('last_month')}
                                </Button>
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setDADFrequency('custom')}
                                    variant={dadFrequency === 'custom' ? 'contained' : 'outlined'}
                                    color={dadFrequency === 'custom' ? 'primary' : 'secondary'}
                                >
                                    {t('custom')}
                                </Button>
                            </div>
                        </div>

                        {dadFrequency === 'custom' && (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="flex gap-5 my-5 flex-wrap md2:flex-nowrap">
                                    <DatePicker
                                        label={t('from_date')}
                                        value={dadCustomRange[0]}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, inputProps: { disabled: true } } }}
                                        onChange={(date) => {
                                            setDADCustomRange([date, dadCustomRange[1]]);
                                            setKey(key + 1);
                                        }}
                                        disableFuture
                                        shouldDisableDate={disabledDate}
                                    />
                                    <DatePicker
                                        key={key}
                                        label={t('to_date')}
                                        value={dadCustomRange[1]}
                                        minDate={dadCustomRange[0]}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, inputProps: { disabled: true } } }}
                                        onChange={(date) => setDADCustomRange([dadCustomRange[0], date])}
                                        disableFuture
                                        shouldDisableDate={disabledDate}
                                    />
                                </div>
                            </LocalizationProvider>
                        )}

                        <p className="mt-auto text-sm text-subtitle text-center">{t('average_daily_active_devices_for_selected_period')}</p>
                    </div>
                </div>
                <div className="col-span-8 lg:col-span-5 1xl:col-span-6 p-4 bg-primary flex flex-col rounded-[4px] border border-[#E0E0E0]">
                    <div className="mb-2 flex flex-col md:flex-row align-middle justify-between">
                        <p className="text-xl mb-4 md:mb-0 cursor-pointer">
                            <Tooltip title={t('daily_active_device_data_will_be_start_processing_after_next_day')}>
                                <span>{t('daily_active_devices')}</span>
                            </Tooltip>
                        </p>
                    </div>
                    <div className="flex-grow p-4 mt-2 min-h-[520px]">
                        <ResponsiveLine
                            data={dadData}
                            animate={true}
                            useMesh={true}
                            curve={'linear'}
                            pointBorderWidth={1}
                            pointLabelYOffset={-12}
                            colors={'#F34E4E'}
                            pointSize={8}
                            pointColor="white"
                            pointBorderColor={{ from: 'serieColor' }}
                            margin={{ top: 10, right: 45, bottom: 45, left: 70 }}
                            enableArea={true}
                            areaBaselineValue={0}
                            areaOpacity={0.2}
                            areaBlendMode="multiply"
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: '%b %d',
                                tickValues: dadFrequency === 'month' || dadFrequency === 'prevmonth' ? 'every 3 days' : 'every 1 days',
                                legend: t('period'),
                                legendOffset: 40,
                                legendPosition: 'middle',
                            }}
                            yScale={{
                                type: 'linear',
                                max: dadLegend.max + 0.1 * dadLegend.max,
                            }}
                            xScale={{
                                type: 'time',
                                format: '%Y%m%d',
                                useUTC: false,
                                precision: 'day',
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: t('no_of_devices'),
                                legendOffset: -65,
                                legendPosition: 'middle',
                            }}
                            tooltip={(e: any) =>
                                e.point.data ? (
                                    <div className="bg-primary p-2 border border-solid">
                                        <p className="font-semibold truncate max-w-sm">{e.point.data.package_name || e.point.data.bundle_id}</p>
                                        <p>
                                            {t('active_devices')} : <span className="font-semibold text-[#F34E4E]">{`${e.point.data.y?.toLocaleString() || 0}`}</span>
                                        </p>
                                        <p>
                                            {t('detection_date')} : <span className="font-semibold text-[#F34E4E]">{moment(e.point.data.x).format('YYYY/MM/DD')}</span>
                                        </p>
                                    </div>
                                ) : (
                                    <p />
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="w-full min-h-full md:min-h-2/3 3xl:min-h-1/2 3xl:h-1/2 grid grid-cols-8 md:gap-x-4 gap-y-4 mb-4">
                <div className="col-span-8 lg:col-span-3 1xl:col-span-2 grid gap-4">
                    <div className="p-4 bg-primary flex flex-col rounded-[4px] border border-[#E0E0E0]">
                        <p className="text-base">{t('monthly_active_devices')}</p>
                        <p className="text-subtitle text-[12px]">{t(madFrequency)}</p>
                        <p
                            className={`mt-4 text-5xl font-semibold 
                         ${madLegend.avg < madHistoryLegend.avg ? 'text-infored' : 'text-infoblue'}`}
                        >
                            {madLegend.avg?.toLocaleString() || 0}
                        </p>
                        <div
                            className={`mt-4 text-xl flex gap-x-4 justify-start 
                        ${madLegend.avg < madHistoryLegend.avg ? 'text-infored' : 'text-infoblue'}`}
                        >
                            <p>
                                {madLegend.avg < madHistoryLegend.avg ? <ArrowDownwardIcon className="mr-2" /> : <ArrowUpwardIcon className="mr-2" />}
                                {`${calculateChange(madLegend.avg, madHistoryLegend.avg)} %`}
                            </p>
                            <p>
                                {' '}
                                {madLegend.avg < madHistoryLegend.avg ? <ArrowDownwardIcon className="mr-2" /> : <ArrowUpwardIcon className="mr-2" />}
                                {Math.abs(madLegend.avg - madHistoryLegend.avg).toLocaleString()}
                            </p>
                        </div>
                        <div className="flex flex-col gap-5 mt-9">
                            <div className="flex gap-5 flex-wrap lg:flex-nowrap">
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    onClick={() => setMADFrequency('querter')}
                                    fullWidth
                                    variant={madFrequency === 'querter' ? 'contained' : 'outlined'}
                                    color={madFrequency === 'querter' ? 'primary' : 'secondary'}
                                >
                                    {t('last_3_months')}
                                </Button>
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setMADFrequency('semiyear')}
                                    variant={madFrequency === 'semiyear' ? 'contained' : 'outlined'}
                                    color={madFrequency === 'semiyear' ? 'primary' : 'secondary'}
                                >
                                    {t('last_6_months')}
                                </Button>
                            </div>
                            <div className="flex gap-5 flex-wrap lg:flex-nowrap">
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setMADFrequency('year')}
                                    variant={madFrequency === 'year' ? 'contained' : 'outlined'}
                                    color={madFrequency === 'year' ? 'primary' : 'secondary'}
                                >
                                    {t('last_12_months')}
                                </Button>
                                <Button
                                    className="h-[40px] text-xs leading-none"
                                    fullWidth
                                    onClick={() => setMADFrequency('custom')}
                                    variant={madFrequency === 'custom' ? 'contained' : 'outlined'}
                                    color={madFrequency === 'custom' ? 'primary' : 'secondary'}
                                >
                                    {t('custom')}
                                </Button>
                            </div>
                        </div>
                        {madFrequency === 'custom' && (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="flex gap-5 my-5 flex-wrap md2:flex-nowrap">
                                    <DatePicker
                                        label={t('from_date')}
                                        value={madCustomRange[0]}
                                        openTo="month"
                                        views={['year', 'month']}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, inputProps: { disabled: true } } }}
                                        onChange={(date) => {
                                            setMADCustomRange([date, madCustomRange[1]]);
                                            setKey2(key + 2);
                                        }}
                                        disableFuture
                                        format="YYYY-MM"
                                        shouldDisableDate={disabledDate}
                                    />
                                    <DatePicker
                                        key={key2}
                                        openTo="month"
                                        views={['year', 'month']}
                                        label={t('to_date')}
                                        value={madCustomRange[1]}
                                        minDate={madCustomRange[0]}
                                        slotProps={{ textField: { size: 'small', fullWidth: true, inputProps: { disabled: true } } }}
                                        onChange={(date) => setMADCustomRange([madCustomRange[0], date])}
                                        disableFuture
                                        format="YYYY-MM"
                                        shouldDisableDate={disabledDate}
                                    />
                                </div>
                            </LocalizationProvider>
                        )}

                        <p className="mt-auto text-sm text-subtitle text-center">{t('average_monthly_active_devices_for_selected_period')}</p>
                    </div>
                </div>
                <div className="col-span-8 lg:col-span-5 1xl:col-span-6 p-4 bg-primary flex flex-col rounded-[4px] border border-[#E0E0E0]">
                    <div className="mb-2 flex align-middle justify-between">
                        <p className="text-xl">{t('monthly_active_devices')}</p>
                    </div>
                    <div className="flex-grow mt-2 h-[520px]">
                        <ResponsiveLine
                            data={madData}
                            animate={true}
                            useMesh={true}
                            curve={'linear'}
                            pointBorderWidth={1}
                            pointLabelYOffset={-12}
                            colors={'#127BF5'}
                            pointSize={8}
                            pointColor="white"
                            pointBorderColor={{ from: 'serieColor' }}
                            margin={{ top: 10, right: 45, bottom: 50, left: 85 }}
                            enableArea={true}
                            areaBaselineValue={0}
                            areaOpacity={0.2}
                            areaBlendMode="multiply"
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: t('month', 'Month'),
                                legendOffset: 35,
                                format: (value: any) => moment(value, 'YYYYMM').format('MMM YYYY'),
                                legendPosition: 'middle',
                            }}
                            yScale={{
                                type: 'linear',
                                max: madLegend.max + 0.1 * madLegend.max,
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: t('no_of_devices'),
                                legendOffset: -70,
                                legendPosition: 'middle',
                            }}
                            tooltip={(e: any) =>
                                e.point.data ? (
                                    <div className="bg-primary p-2 border border-solid">
                                        <p className="font-semibold truncate max-w-sm">{e.point.data.package_name || e.point.data.bundle_id}</p>
                                        <p>
                                            {t('monthly_active_devices')} : <span className="font-semibold text-[#127BF5]">{`${e.point.data.y?.toLocaleString() || 0}`}</span>
                                        </p>
                                        <p>
                                            {t('period')} : <span className="font-semibold text-[#127BF5]">{moment(e.point.data.x, 'YYYYMM').format('YYYY/MM')}</span>
                                        </p>
                                    </div>
                                ) : (
                                    <p />
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </DataLayout>
    );
};

export default ActiveDevices;
