// src/components/GroupManagement/GroupManagementSub.tsx

import React from 'react';
import { IconButton, Typography, Alert, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentLayout from '../../../../Layout/Content';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GroupTabs from './GroupTabs';
import { useGroupManagement } from '../../../../../hooks/useGroupManagement';

const GroupManagementSub: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { group, showMask, tabIndex, handleTabChange } = useGroupManagement();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <div>
                <div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <IconButton onClick={handleBackClick}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <div className="ml-2">
                                <Typography variant="h6" className="text-[20px]">
                                    {group?.name}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end ">
                        <Tooltip title={t('only_owner_and_manager_allowed_to_manage_group')} placement="top">
                            <Alert severity="info" className="max-w-[50px] md:max-w-[294px] lg:max-w-[600px]">
                                <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{t('only_owner_and_manager_allowed_to_manage_group')}</p>
                            </Alert>
                        </Tooltip>
                    </div>
                </div>
                <GroupTabs tabIndex={tabIndex} onTabChange={handleTabChange} />
            </div>
        </ContentLayout>
    );
};

export default GroupManagementSub;
