import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import "./mfa.less";
import { editMfaInfo, getMfaInfo, validateMfa } from "../../../services/login";
import { useLocation } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { showToast } from "../../utils/notifications";

interface OptPopupProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  token: string;
  setMfaStatus: React.Dispatch<React.SetStateAction<boolean>>;
  inactivateMfa: () => void;
}

const MfaCreate = ({
  isVisible,
  setIsVisible,
  token = "",
  setMfaStatus,
  inactivateMfa,
}: OptPopupProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
  const [optCode, setOptCode] = useState<string>("");
  const location = useLocation();
  useEffect(() => {
    if (isVisible && location.pathname !== "/profile") {
      setIsVisible(false);
      inactivateMfa();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isVisible) {
      createMFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const createMFA = async () => {
    try {
      const response = await getMfaInfo(token.toLocaleUpperCase());
      const { result, data } = response;
      if (result.code === "0000" || result.msg === "SUCCESS") {
        setQrCodeUrl(data);
        setShowModal(true);
      } else if (
        result.code === "11002" ||
        result.msg === "MULTI_FACTOR_IS_ALREADY_EXIST"
      ) {
        reactivateMfa();
      } else {
        showToast("error", t(`RCM-${result.code}`));
      }
    } catch (error) {
      showToast("error", t("failed_to_validate_please_try_again_later"));
    }
  };

  const reactivateMfa = async () => {
    try {
      const response = await editMfaInfo("active", token.toLocaleUpperCase());
      const { result, data } = response;
      if (result.code === "0000" || result.msg === "SUCCESS") {
        setQrCodeUrl(data);
        setShowModal(true);
      } else {
        showToast("error", t(`RCM-${result.code}`));
      }
    } catch (error) {
      showToast("error", t("failed_to_validate_please_try_again_later"));
    }
  };

  const handleValidateMfa = async () => {
    try {
      const response = await validateMfa(token.toLocaleUpperCase(), optCode);
      if (response) {
        setIsVisible(false);
        showToast("success", t("activate_mfa"));
        setMfaStatus(true);
        localStorage.removeItem("appsealing_enable2FA");
      } else {
        showToast("error", t("failed_to_validate_please_try_again_later"));
      }
    } catch (error) {
      showToast("error", t("failed_to_validate_please_try_again_later"));
    }
  };
  const handleOk = () => {
    handleValidateMfa();
  };
  const handleClose = () => {
    inactivateMfa();
  };
  const handleOnKeyPress = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleValidateMfa();
    }
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      className='opt-google-popup'
      aria-labelledby='dialog-title'
    >
      <DialogTitle id='dialog-title'>
        <div className="mb-10">
          <div className="flex justify-between">
            <Typography variant="h1" className='mb-0'>{t("google_opt")}</Typography>
            <CloseIcon onClick={handleClose} className="text-[#757575]"/>
          </div>
          <p className='lg:text-sm text-[#666666] font-normal'>{t("google_opt_subtitle")}</p>
        </div>
      </DialogTitle>
      <DialogContent>
        <ul className='lg:text-sm sm:text-lg m-0 p-0 pl-4 pt-2 whitespace-pre-line list-decimal '>
          <li>{t("google_opt_1")}</li>
          <li>{t("google_opt_2")}</li>
          <li>{t("google_opt_3")}</li>
        </ul>
        <div className='py-6 flex flex-none justify-center'>
          <div className='w-3/6 flex flex-none justify-center'>
            {qrCodeUrl !== "" && <QRCode value={qrCodeUrl} width={200} />}
          </div>
        </div>
        <p className='lg:text-sm'>4. {t("google_opt_4")}</p>
        <div className='flex flex-none my-8 justify-center'>
            <TextField
              label={t('google_opt_code')}
              className='max-w-[200px] h-10'
              variant='outlined'
              placeholder={t("google_opt_code")}
              inputProps={{ maxLength: 16 }}
              size={"small"}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setOptCode(e.target.value)
              }
              onKeyDown={handleOnKeyPress}
              fullWidth
            />

        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOk} color='primary' className="px-10 h-10">
          {t("enable")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default MfaCreate;
