import React, { useEffect, useState } from "react";
import { login, reactivateUser } from "../../../services/login";
import LoadMask from "../../utils/loadmask";
import { showDialog, showToast } from "../../utils/notifications";
import { useLocation } from "react-router";
import { openHelpCenter } from "../../../services/main";
import { useTranslation } from "react-i18next";
import AuthLayout from "..";
import AuthForm from "../AuthForm";
import { loginData } from "../AuthData";

const HelpDesk = () => {
  const { i18n, t } = useTranslation();
  const { search } = useLocation();
  const [loadMask, showLoadMask] = useState<any>(false);

  let query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (query.get("locale_id") && query.get("return_to")) {
      if (
        !(
          (i18n.language === "kr_KR" && query.get("locale_id") === "69") ||
          (i18n.language !== "kr_KR" && query.get("locale_id") === "1")
        )
      ) {
        localStorage.setItem(
          "user-lang",
          query.get("locale_id") === "1" ? "en_US" : "kr_KR"
        );
      }
    } else showToast("error", t("RCM-1000"));
  }, [i18n.language, query, t]);

  const reactivateAccount = async (values: any) => {
    let response = await reactivateUser({
      user_id: values.userId,
      lang_set: i18n.language === "kr_KR" ? "KR" : "EN",
    });
    if (
      response?.result.code === "0000" &&
      response?.result.message === "SUCCESS"
    )
      showToast(
        "success",
        t(
          "please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email"
        )
      );
    else showToast("error", response?.result?.message);
  };

  const onSignInClick = async (values: any) => {
    showLoadMask({ msg: t("checking_account_information") });
    let response = await login({
      user_id: values.userId,
      user_pw: values.password,
      domain: i18n.language === "kr_KR" ? "KR" : "EN",
    });
    showLoadMask(false);
    if (
      response?.result.code === "0000" &&
      response?.result.message === "SUCCESS"
    ) {
      showLoadMask({ msg: t("checking_account_information") });
      openHelpCenter(
        response.auth.token,
        query.get("return_to") || "",
        "_self"
      );
      showLoadMask(false);
    } else if (response?.result?.code === "2700") {
      showDialog({
        title: t("confirm_reactivation"),
        content: (
          <div>
            <p>
              {t(
                "please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email"
              )}
            </p>
            <br />
            <p>{t("didnt_receive_the_email")}</p>
          </div>
        ),
        onOk: () => reactivateAccount(values),
        okText: t("resend"),
        cancelText: t("close"),
      });
    } else if (response?.result?.code) {
      const msg =
        response?.result?.message ||
        t("failed_to_login_contact_help_desk_for_more_information");
      const rcm =
        t(`RCM-${response?.result?.code}`) !== ""
          ? t(`RCM-${response?.result?.code}`)
          : msg;
      showToast("error", rcm);
    } else {
      const msg =
        response?.result?.message ||
        t("failed_to_login_contact_help_desk_for_more_information");
      showToast("error", msg);
    }
  };

  return (
    <React.Fragment>
      {loadMask && (
        <LoadMask
          msg={loadMask.msg}
          style={{ zIndex: "10007", background: "#88295C", color: "white" }}
        />
      )}

      <AuthLayout BackButton={false}>
        <AuthForm
          title={"login"}
          data={loginData}
          btnText="sign_in"
          onSubmit={onSignInClick}
        />
      </AuthLayout>
    </React.Fragment>
  );
};

export default HelpDesk;
