import { combineReducers } from '@reduxjs/toolkit';

import mainReducer from './../main';
import commonReducer from './../common';
import dataReducer from '../data';
import androidSealingReducer from './../sealing/android';
import iOSSealingReducer from './../sealing/ios';
import accountsReducer from './../manage/accounts';
import billingDetailsReducer from './../manage/billingdetails';
import groupReducer from './../manage/group';

const rootReducer = combineReducers({
    mainReducer,
    commonReducer,
    accountsReducer,
    billingDetailsReducer,
    iOSSealingReducer,
    androidSealingReducer,
    dataReducer,
    groupReducer,
});

export default rootReducer;
