import { customFetch, fetchWithMethod } from './fetchUtilities';

export const Get = fetchWithMethod('GET');
export const Post = fetchWithMethod('POST');
export const Put = fetchWithMethod('PUT');
export const Patch = fetchWithMethod('PATCH');
export const Delete = fetchWithMethod('DELETE');

export const FilePost = async (url: string, params: FormData, header?: any): Promise<Response> => {
    let options: RequestInit = {
        method: 'POST',
        body: params,
    };
    if (header) {
        options['headers'] = header;
    }
    return customFetch(url, options);
};
