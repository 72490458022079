export const DataGridLocaleText = [
    'noRowsLabel',
    'toolbarColumns',
    'columnsManagementSearchTitle',
    'columnsManagementShowHideAllText',
    'columnsManagementReset',
    'toolbarFilters',
    'toolbarDensity',
    'toolbarFiltersTooltip',
    'toolbarExport',
    'toolbarExportCSV',
    'toolbarExportPrint',
    'footerPaginationRowsPerPage',
    'filterPanelColumns',
    'filterPanelInputLabel',
    'filterPanelInputPlaceholder',
    'filterPanelOperator'
];
