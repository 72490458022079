import { Delete, Get, Post, Put } from '../..';
import {
    AddGroupInterface,
    AddGroupMemberInterface,
    DeleteGroupInterface,
    GetGroupListInterface,
    UpdateMemberPositionInterface,
    DeleteAppInterface,
    DeleteMemberFromGroupInterface,
    UpdateGroupNameInterface,
    AddAppToGroupInterface,
    GrantPermissionInterface,
    UpdatePermissionInterface,
    RemovePermissionInterface,
} from '../../../type/api/manage';
import { AppURL } from '../../../utility/AppURL';

export const getGroupList = async (params: GetGroupListInterface) => {
    const { token } = params;
    const header = {
        'as-token': token,
    };
    try {
        const response = await Get(AppURL.groups, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        return false;
    }
};

export const updateGroupName = async (params: UpdateGroupNameInterface) => {
    const { groupId, token } = params;
    let { groupName } = params;
    if(groupName.includes('+')) {
        groupName = groupName.replace(/\+/g, '%2B');
    }
    const url = AppURL.groups + `/${groupId}?name=${groupName}`;
    const header = {
        'Content-Type': 'application/json',
        'as-token': token,
    };

    try {
        const response = await Put(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        return false;
    }
};

export const deleteGroup = async (params: DeleteGroupInterface) => {
    const { token, groupId } = params;
    const url = AppURL.groups + `/${groupId}`;
    const header = {
        'as-token': token,
    };
    try {
        const response = await Delete(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        return false;
    }
};

export const addGroup = async (params: AddGroupInterface) => {
    const { token, name } = params;
    const url = AppURL.groups + `?name=${name}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Post(url, null, header);

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const addGroupMember = async (params: AddGroupMemberInterface) => {
    const { token, groupId, memberEmail, role } = params;
    const url = AppURL.groups + `/${groupId}/users/${memberEmail}${role ? `?role=${role}` : ''}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Post(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const getSubGroupList = async (params: GetGroupListInterface) => {
    const { token, groupId } = params;
    const url = AppURL.groups + `/${groupId}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Get(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateMemberPosition = async (params: UpdateMemberPositionInterface) => {
    const { token, memberId, type, groupId, lang } = params;
    const url = AppURL.groups + `/${groupId}/group-members/${memberId}/role/${type.toLowerCase()}`;
    const header = {
        'as-token': token,
        lang,
    };

    try {
        const response = await Put(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const addAppToGroup = async (params: AddAppToGroupInterface) => {
    const { token, appId, groupId, platform } = params;
    const url = AppURL.groups + `/${groupId}/${platform}/${appId}`;
    const header = {
        'as-token': token,
    };
    try {
        const response = await Post(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteApp = async (params: DeleteAppInterface) => {
    const { token, groupAppId, groupId } = params;
    const url = AppURL.groups + `/${groupId}/group-apps/${groupAppId}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Delete(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteMemberFromGroup = async (params: DeleteMemberFromGroupInterface) => {
    const { token, memberId, groupId } = params;
    const url = AppURL.groups + `/${groupId}/group-members/${memberId}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Delete(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const grantPermission = async (params: GrantPermissionInterface, platform: string) => {
    const { token, groupId, groupAppId, memberId } = params;
    const url = AppURL.groups + `/${groupId}/grant-permission`;
    const body = {
        memberId,
        groupAppId,
        sealingActive: platform === 'android' ? false : true,
        presetActive: true,
        analyticsActive: true,
    };
    const header = {
        'as-token': token,
        'Content-Type': 'application/json',
    };

    try {
        const response = await Post(url, JSON.stringify(body), header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const updatePermission = async (params: UpdatePermissionInterface) => {
    const { token, groupId, permissionId, permissionType } = params;
    const url = `${AppURL.groups}/${groupId}/permissions/${permissionId}/${permissionType}`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Put(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const removePermission = async (params: RemovePermissionInterface) => {
    const { token, groupId, permissionId } = params;
    const url = `${AppURL.groups}/${groupId}/permissions/${permissionId}/revoke`;
    const header = {
        'as-token': token,
    };

    try {
        const response = await Delete(url, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
