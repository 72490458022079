import React from "react";
import { useTranslation } from "react-i18next";
import "./footer.less";
import { setCookies, deleteCookie } from "../../utility/Common";
import Modal from "../../components/Feedback/Modal";
import { Button } from "../Foundation/Button";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Rating,
  TextField,
  Typography
} from "@mui/material";
import { showToast } from "../utils/notifications";

interface FormValues {
  [key: string]: any;
  feedback: string;
}
const ratingsOptions = [
  "what_do_you_think_of_the_appsealing_experience",
  "what_do_you_feel_of_the_current_appsealing_security_feature",
  "how_was_the_technical_assistance_you_got_with_appsealing",
  "how_satisfied_are_you_overall_with_our_appsealing_product",
  "how_would_you_rate_the_value_for_the_money_of_the_product",
];

const FeedbackModal = ({
  isVisible,
  onClose,
  onSubmit,
}: {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<void>;
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const { t } = useTranslation();
  const setDontAskAgainCookie = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked.toString();
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const expires = new Date(Date.now() + oneMonth).toUTCString();
    if (isChecked === "true") {
      setCookies("HideFeedbackForMonth", isChecked, expires);
    } else {
      deleteCookie("HideFeedbackForMonth");
    }
  };

  const handleFinish = async (values: any) => {
    await onSubmit(values);
    reset();
    onClose();
  };

  const onSubmitError = () =>
    showToast("error", t("please_enter_mandatory_field_values"));

  return (
    <Modal
      open={isVisible}
      maxWidth="sm"
      header={
        <div className="leading-none">
          <Typography variant="h1">
            {t("send_feedback")}
          </Typography>
          <span className="text-xs text-[#A0A0A1] leading-none">
            {t("feedback_on_appsealing")}
          </span>
        </div>
      }
      footer={[
        <Button
          key={"submit"}
          variant="outlined"
          color="primary"
          onClick={handleSubmit(handleFinish)}
        >
          {t("send")}
        </Button>,
      ]}
      handelCancel={onClose}
    >
      <form
        onSubmit={handleSubmit(handleFinish, onSubmitError)}
        className="my-[20px]"
      >
        {ratingsOptions.map((item, index) => (
          <Box key={index} mb={2}>
            <p className="font-medium font-sm">{t(item)}</p>
            <Controller
              name={item}
              control={control}
              rules={{ required: t("please_enter_the_feedback_message") }}
              render={({ field }) => <Rating {...field} />}
            />
          </Box>
        ))}
        <p className="font-medium font-sm mb-2.5">{t('tell_us_more_about_any_problems_you_found')}</p>
        <Controller
          name="feedback"
          control={control}
          rules={{ required: t("please_enter_the_feedback_message") }}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              placeholder={t("enter_text")}
              minRows={3}
              fullWidth
              error={!!errors.feedback}
              helperText={errors.feedback ? errors.feedback.message : ""}
            />
          )}
        />
        <FormControlLabel
          control={
            <Controller
              name="disableForOneMonth"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  onChange={(e) => setDontAskAgainCookie(e)}
                />
              )}
            />
          }
          label={t("disable_for_one_month")}
        />
      </form>
    </Modal>
  );
};

export default FeedbackModal;
