import AuthLayout from '..';
import LanguageSelector from '../languageselector';
import { useTranslation } from 'react-i18next';

const InviteFail = () => {
    const { t } = useTranslation();
    return (
        <AuthLayout BackButton={false}>
            <div className={`pt-[120px] px-5 sm:px-10 md:px-[80px] pb-8`}>
                <div className={`text-xl font-medium text-center`}>{t('invite_failed_title')}</div>
                <div className="mb-20 text-center ">{t('invite_failed_subtitle')}</div>
                <div className="px-5 pb-10">
                    <p>{t('invite_failed_content')}</p>
                </div>

                <div className={`flex justify-end w-full mt-2`}>
                    <LanguageSelector />
                </div>
            </div>
        </AuthLayout>
    );
};

export default InviteFail;
