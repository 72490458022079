import React from "react";
import { Typography } from "@mui/material";
import { useWatch, Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "../../../Foundation/Input";
import { FormState } from "../../../../type/user/index";
import PasswordRequirement from "../../../Feedback/PasswordRequirement";

interface ChangePasswordProps {
  control: Control<FormState>;
}

const ChangePassword = ({ control }: ChangePasswordProps) => {
  const { t } = useTranslation();
  const oldPassword = useWatch({
    control,
    name: "password.user_pw",
    defaultValue: "",
  });

  const newPassword = useWatch({
    control,
    name: "password.new_user_pw",
    defaultValue: "",
  });

  const confirmPassword = useWatch({
    control,
    name: "password.new_user_pw_confirm",
  });

  const passwordMismatchError = newPassword && confirmPassword && newPassword !== confirmPassword;
  const sameAsOldPasswordError = oldPassword && newPassword && oldPassword === newPassword;

  return (
    <div className='flex flex-col pb-10 mb-10 border-b border-gray-300'>
      <Typography variant='h6' className='font-bold text-base mb-5'>
        {t("change_password")}
      </Typography>
      <div className='flex flex-col md:flex-row gap-6'>
        <div className='flex flex-col md:w-1/3'>
          <Controller
            name='password.user_pw'
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PasswordInput
                label={t("old_password")}
                className='lg:max-w-[325px]'
                fullWidth={true}
                ref={ref}
                {...field}
              />
            )}
          />
          <Controller
            name='password.new_user_pw'
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PasswordInput
                label={t("new_password")}
                className='lg:max-w-[325px]'
                error={!!sameAsOldPasswordError}
                helperText={sameAsOldPasswordError ? t('password_should_not_be_similar_to_existing_password') : ""}
                fullWidth={true}
                ref={ref}
                {...field}
              />
            )}
          />
          <Controller
            name='password.new_user_pw_confirm'
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PasswordInput
                error={!!passwordMismatchError}
                helperText={passwordMismatchError ? t("password_does_not_match_with_new_password") : ""}
                label={t("confirm_new_password")}
                className='lg:max-w-[325px]'
                fullWidth={true}
                ref={ref}
                {...field}
              />
            )}
          />
        </div>
        <div className='flex flex-col md:w-2/3'>
          <PasswordRequirement newPassword={newPassword} />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
