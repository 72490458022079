import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { downloadCLITool } from '../../../../services/main';

const CliFooter = () => {
    const { t } = useTranslation();
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const onDownloadCLIToolClick = () => downloadCLITool();
    return (
        <div className="flex gap-14">
            <div>
                <h4 className="font-bold mb-4">{t('3rd_party_integration')}</h4>
                <div className="flex items-center gap-8">
                    <div className="h-[60px] w-[60px] bg-gray-200 rounded-lg flex items-center justify-center">
                        <span className="text-gray-600 font-bold text-[26px]">CLI</span>
                    </div>
                    <Button variant="contained" className="text-xs w-[109px]" onClick={onDownloadCLIToolClick}>
                        {t('download')}
                    </Button>
                </div>
            </div>
            <div>
                <p className="pt-2.5 pb-4 break-all">
                    <strong>{t('cli_key')}: </strong>
                    {planDetails?.android?.cli_auth_key}
                </p>
                <ul className="list-none italic text-darkgray p-0 text-xs leading-6">
                    <li className="break-all">{t('cli_tool_desc1')}</li>
                    <li>{t('cli_tool_desc2')}</li>
                </ul>
            </div>
        </div>
    );
};

export default CliFooter;
