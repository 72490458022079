import React from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { FormItem } from "../../../../../type/ios";
import i18n from "../../../../../i18n/config";

interface RenderInputProps {
  item: FormItem;
  control: Control<any>;
  index: number;
}
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, 
      overflow: 'auto', 
    },
  },
  anchorOrigin: {
    vertical: "bottom" as const, 
    horizontal: "left" as const, 
  },
  transformOrigin: {
    vertical: "top" as const, 
    horizontal: "left" as const,  
  }
};
const RenderInput: React.FC<RenderInputProps> = ({ item, control, index }) => {
   
   
    switch(item.type) {
      case "input":
        return (
          <Controller
            name={item.label ?? ""}
            control={control}
            defaultValue={item.value ?? ''}
            rules={{
              required: item.error ? i18n.t(item.error as string) : false,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size="small"
                label={i18n.t(item.label as string)}
                placeholder={i18n.t(item.placeholder as string)}
                fullWidth
                error={!!error}
                helperText={error && error.message}
                variant="outlined"
                key={index}
                InputProps={{
                  endAdornment: (
                    <>
                      {item.suffix && (
                        <InputAdornment position="end">
                          {item.suffix}
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            )}
            key={index}
          />
        );
      case "select":
        return (
          <Controller
            name={item.label ?? ""}
            control={control}
            defaultValue={item.value ?? ''}
            render={({ field }) => (
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>{i18n.t(item.label as string)}</InputLabel>
                <Select
                  {...field}
                  label={i18n.t(item.label as string)}
                  key={index}
                  MenuProps={menuProps}
                  disabled={item.disabled}
                >
                  {item.options?.map((option, optionIndex) => (
                    <MenuItem key={optionIndex} value={option.value}>
                      {option.label}
                      {option.expired && (
                        <Chip
                          style={{ marginLeft: 8 }}
                          color="error"
                          label={i18n.t("expired")}
                        />
                      )}
                      {option.isHybrid && (
                        <Chip
                          style={{ marginLeft: 8 }}
                          color="warning"
                          label={i18n.t("hybrid")}
                        />
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        );
      case "text":
        return (
          <Controller
            name={item.label ?? ""}
            control={control}
            rules={{
              required: item.error ? i18n.t(item.error as string) : false,
            }}
            defaultValue={item.value ?? ''}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size="small"
                fullWidth
                error={!!error}
                helperText={error && error.message}
                variant="standard"
                key={index}
                inputProps={{
                  readOnly: true,
                }}
              />
            )}
            key={index}
          />
        );
      default:
        return null;
    }
};

export default RenderInput;
