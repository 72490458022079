import * as React from 'react';
import './index.less';
import LottieAnimation from './LottieAnimation';

interface LoadMaskInterface extends React.HTMLAttributes<HTMLDivElement> {
    msg?: string
};

const LoadMask = (props: LoadMaskInterface) => {
    const { msg = "Loading", ...others } = props;
    const mask = (
        <div className='loadmask-wrap'>
            <div className="fixed h-full w-full" {...others}>
                <div className="loadmask" />
                <div className="loadmask-item bg-primary py-12 px-20 rounded-md">
                    <LottieAnimation />
                    <h2 className="text-accent text-2xl mt-2">{msg}</h2>
                </div>
            </div>
        </div>
    );
    return mask;
};

export default LoadMask;