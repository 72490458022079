import { getURL } from '../services/fetchUtilities';
export const AppURL = {
    helpCenter: 'https://helpcenter.appsealing.com/hc/',
    privacyterms: 'https://www.appsealing.com/privacy-and-terms',
    privacyterms_kr: 'https://www.appsealing.com/kr/privacy-and-terms-2',
    slackwebhook: 'https://slack.com/apps/A0F7XDUAZ-incoming-webhooks',
    verifyrecaptch: 'https://www.google.com/recaptcha/api/siteverify',
    clitool: '/assetsvc/cli_tool/AppSealing_CI_Integration_Tool_latest.zip',
    emailValidate: 'https://mkt.appsealing.com/l/1057633/2024-06-14/3sy2cl',
    salesforce: 'https://mkt.appsealing.com/l/1057633/2024-02-13/3snhvd',

    login: '/usersvc/login',
    logout: '/usersvc/logout',
    signup: '/usersvc/signup',
    resetPwd: '/userssvc/change-password/link',
    resetNewPwd: '/userssvc/change-password',
    signupaws: '/usersvc/signupaws',
    signupmember: '/usersvc/signupMember',
    signupmemberValidate: '/usersvc/signupMember-validate',
    ssoLogin: '/usersvc/zendesk/ssoLogin',
    reactivation: '/usersvc/reactivation',
    agreeinvitemember: '/usersvc/agreeInviteMember',

    profile: '/usersvc/profile',
    userssvc: '/userssvc',
    activateaccountmember: '/userssvc/active',
    deactivateaccountmember: '/usersvc/ownerDeactivateUser',
    inviteaccountmember: '/usersvc/inviteAccountMember',
    getaccountmemberlist: '/usersvc/getAccountMemberList',
    editaccountmembernote: '/usersvc/editAccountMemberNote',
    getmemberinvitehistory: '/usersvc/MemberInviteHistory',
    removeaccountmember: '/userssvc',
    preference: '/usersvc/preference',
    changePwd: '/usersvc/changepwd',
    mfa: '/mfasvc',
    mfaValidate: '/mfasvc/validate',
    companySecurity: '/companiessvc/',

    city: '/commonsvc/city',
    state: '/commonsvc/state',
    country: '/commonsvc/country',
    timezone: '/commonsvc/timezone',
    appcategory: '/commonsvc/appCategory',
    notifycheck: '/accountsvc/notifyCheck',
    changeserviceregion: '/accountsvc/changeServiceRegion',

    information: '/accountsvc/information',
    contactus: '/accountsvc/contactus',

    plan: '/accountsvc/plan',
    payment: '/accountsvc/payment',
    changeplan: '/authsvc/changePlan',
    privacypolicy: '/assetsvc/AppSealing+Privacy+Policy.txt',
    termsofservice: '/assetsvc/AppSealing+Terms+of+Service.txt',

    txhistory: '/accountsvc/txhistory',
    getcreditinfo: '/promotionsvc/loadCreditInformation',
    redeempromocode: '/promotionsvc/redeemPromotionCode',

    getpublickey: '/invoicesvc/Stripe/getPublicKey',
    getsetupintent: '/invoicesvc/Stripe/getSetupIntent',
    savepaymentinfo: '/invoicesvc/Stripe/savePaymentInfo',
    removepginfo: '/accountsvc/removePgInfo',

    invoicelist: '/invoicesvc/information',
    refundrequest: '/invoicesvc/Stripe/globalRefundRequest',
    downloadinvoice: '/invoicesvc/ver2/downloadInvoicePdf',

    sendusernotification: '/webhooksvc/salesMetric',
    sendfeedback: '/webhooksvc/giveFeedback',
    webhookstatus: '/webhooksvc/webhookstatus',
    updatewebhook: '/webhooksvc/insertwebhookurl',
    subscribetrigger: '/webhooksvc/subscribeTrigger',
    unsubscribetrigger: '/webhooksvc/unsubscribeTrigger',

    authoriseuser: '/authsvc/AuthValidOwner',
    requestwithdrawal: '/accountsvc/requestWithdrawal',

    getAccountHash: '/asiasvc/getAccountHash',
    getReleasedVersionInfo: '/asiasvc/getReleasedVersions',
    getReleaseNote: '/asiasvc/getAiSMReleaseNote',
    getLatestVersionInfo: '/asiasvc/getLatestVersionInfo',
    getDownloadSdkUrlLauncher: '/asiasvc/getLauncherDownloadUrl',
    getDownloadSdkUrl: '/asiasvc/getDownloadSdkUrl',
    getUserGuideUrl: '/asiasvc/getDownloadManualUrl',
    getArm64Guide: {
        en_US: 'https://appsealing-adc-site.s3.ap-northeast-1.amazonaws.com/dev/iOS/AiSM_arm64_Installation_Troubleshooting.pdf',
        kr_KR: 'https://appsealing-adc-site.s3.ap-northeast-1.amazonaws.com/dev/iOS/AiSM_arm64_설치문제해결.pdf',
    },

    preset: '/presetsvc/',
    sealingPreset: '/sealingPresetsvc/',

    userlogs: '/userlogssvc',

    groups: '/groupssvc',

    application: '/appssvc',
};

export const RegionalURL: any = {
    totdata: '/datasvc/totdata',
    iostotdata: '/datasvc/ios/totdata',

    multidimension: '/datasvc/hacking//multidimension',
    multidimensiondaily: '/datasvc/hacking/multidimensionDaily',
    hackingattemptssubcategories: '/datasvc/hackingAttemptsSubCategories',
    iosmultidimension: '/datasvc/ios/hacking/multidimension',
    iosmultidimensiondaily: '/datasvc/ios/hacking/multidimensionDaily',
    hackinglogstream: '/datasvc/hackingLogStream',

    hackingauditstream: '/datasvc/android/hackingAuditStream',
    hackingauditlogstream: '/datasvc/hackingLogStreamWithoutAid',
    ioshackingauditstream: '/datasvc/ios/hackingAuditStream',
    ioshackingauditlogstream: '/datasvc/ios/hackingLogStreamWithoutDeviceUuid',

    getandroidpackages: '/datasvc/auPkg',
    getiospackages: '/datasvc/ios/getBundleIdList',
    getdaud: '/datasvc/daud',
    getiosdaud: '/datasvc/ios/daud',
    getmaud: '/datasvc/maud',
    getiosmaud: '/datasvc/ios/maud',

    viewlog: '/sealingsvc/viewlog',
    sealinginfo: '/sealingsvc/information',
    viewlogpath: '/sealingsvc/viewlogpath',
    serviceables: '/sealingsvc/serviceables',
    sealingavailable: '/sealingsvc/sealingAvailable',
    uploadkeystore: '/sealingsvc/uploadKeystore',
    deleteKeystore: '/sealingsvc/deleteKeystore',
    hybridserviceables: '/sealingsvc/hybrid/serviceables',
    checksealingstatus: '/sealingsvc/checkSealingStatus',
    selectkeystoreinfo: '/sealingsvc/selectKeystoreInfo',

    requestsealing: '/sealinghybridsvc/request_sealing',
    sealingHistory: '/sealinghybridsvc/html5/sealingHistory',
    requesthtmlsealing: '/sealinghybridsvc/html5/requestSealing',
    checkhybridsealingstatus: '/sealinghybridsvc/html5/sealingStatus',
    downloadsealedapk: '/sealinghybridsvc/apkfile/downloadSealedAPK',
    downloadsealedsignedapp: '/sealinghybridsvc/downloadSealedSignedApp',
    downloadSealedFile: '/sealinghybridsvc/html5/downloadSealedFile',

    application: '/appsvc',
    applist: '/appsvc/appList',
    addproject: '/appsvc/ios/addProject',
    getCredential: '/appsvc/ios/getCredential',
    projectlist: '/appsvc/ios/projectList',
    nativesdkinfo: '/appsvc/ios/nativeSdkInfo',
    hybridsdkinfo: '/appsvc/ios/hybridSdkInfo',
    getselectivelist: '/appsvc/getSelectiveList',
    getselectivedexlist: '/appsvc/getSelectiveDexClassPackageList',
    updateprojectname: '/appsvc/ios/updateProjectName',
    updatebundleidprefix: '/appssvc/ios/change-bundle-id',
    addselectivedex: '/appsvc/addSelectiveDexClassPackage',
    removeselectivedex: '/appsvc/removeSelectiveDexClassPackage',

    buildiosnative: '/sdksvc/ios/sdkBuild',
    buildioshybrid: '/sdksvc/ios/hybridSdkBuild',
    checkbuildioshybrid: '/sdksvc/ios/checkBuildHybridSdkStatus',

    toggleservice: '/authsvc/serviceOnOff',
    toggleadvservice: '/authsvc/toggleSingleAppAdvSvcOnOff',
    requesttransfer: '/pkgmangersvc/reqTransfer',
    getworkingitemlist: '/blacklistsvc/getWorkingList',
    addworkingitem: '/blacklistsvc/addWorkingItem',
    removeworkingitem: '/blacklistsvc/delWorkingItem',
    getpublishedlist: '/blacklistsvc/getLatestPublishedList',
    publishworkingcopy: '/blacklistsvc/publishWorkingCopy',
    toggleoffpublished: '/blacklistsvc/toggleOffPublished',
    stopotaserviceupdate: '/blacklistsvc/stopOtaServiceUpdate',

    requestDownloadsealedsignedapp: '/sealinghybridsvc/requestDownloadSealedSignedApp',
    checkDownloadsealedsignedapp: '/sealinghybridsvc/checkDownloadSealedSignedApp',
    downloadPreparedsealedsignedapp: '/sealinghybridsvc/downloadPreparedsealedsignedapp',
};

export const getRegionalURL = (regionalURL: string, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let url = '',
        routeKey = region === 'AWS_MUMBAI' ? '-in' : '';

    let list = regionalURL.split('/');
    list[1] = list[1] + routeKey;
    url = list.join('/');
    return getURL(url);
};
