import React, { useEffect, useState } from "react";
import { login, reactivateUser } from "../../../services/login";
import { setCookies } from "../../../utility/Common";
import LoadMask from "../../utils/loadmask";
import { useAuthorizationService } from "../../../hooks/useAuthorizationService";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import AuthLayout from "..";
import AuthForm from "../AuthForm";
import { confirmationData } from "../AuthData";
import { Link } from "react-router-dom";
import { showDialog, showToast } from "../../utils/notifications";
import { Typography } from "@mui/material";

const Confirmation = () => {
  const { search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [loadMask, showLoadMask] = useState<any>(false);
  const [title, setTitle] = useState<string>(t("activation_failed"));
  const authorizationService = useAuthorizationService();
  const [isSuccess, setIsSuccess] = useState(false);
  let query = React.useMemo(() => new URLSearchParams(search), [search]);


  const footerElements = (
    <div className="flex justify-end mt-2.5">
      <Link
        to="/forgotpassword"
        className="text-primary underline hover:text-accent"
      >
        {t("forgot_your_id_password")}
      </Link>
    </div>
  );
  useEffect(() => {
    if (location.pathname) {
      const isSuccess =
        location.pathname.split("/")[2] === "success" ? true : false;
      showLoadMask(false);
      if (isSuccess) {
        setTitle("activation_successful");
        confirmationData[0]["value"] = query.get("user_id") ?? "";
        setIsSuccess(true);
      } else {
        setTitle("activation_failed");
        setIsSuccess(false);
      }
    }
  }, [location, t, query]);
  const onLoginClick = async (values: any) => {
    showLoadMask({ msg: t("checking_account_information") });
    let response = await login({
      user_id: query.get("user_id") || "",
      user_pw: values.user_pw,
      domain: i18n.language === "kr_KR" ? "KR" : "EN",
    });
    if (
      response?.result.code === "0000" &&
      response?.result.message === "SUCCESS"
    ) {
      setCookies("appsealing", response.auth.token);
      navigate(`/start-securing-your-app/${response.plan.account_id}`);
      authorizationService.authorizeUser(response.auth.token, showLoadMask);
    } else {
      showLoadMask(false);
      if (response?.result?.code) {
        const msg =
          response?.result?.message ||
          t("failed_to_login_contact_help_desk_for_more_information");
        const rcm =
          t(`RCM-${response?.result?.code}`) !== ""
            ? t(`RCM-${response?.result?.code}`)
            : msg;
        showToast("error", rcm);
      } else {
        const msg =
          response?.result?.message ||
          t("failed_to_login_contact_help_desk_for_more_information");
        showToast("error", msg);
      }
    }
  };
  const reactivateAccount = async () => {
    let response = await reactivateUser({
      user_id: query.get("user_id") || "",
      lang_set: i18n.language === "kr_KR" ? "KR" : "EN",
    });
    if (
      response?.result.code === "0000" &&
      response?.result.message === "SUCCESS"
    )
      showToast(
        "success",
        t(
          "please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email"
        )
      );
    else showToast("error", response?.result?.message);
  };

  const FormHeader = (
    <div>
      <div className={`text-center mb-10`}> <Typography variant="h1" className="font-bold">{t(title)}</Typography></div>
      <div className='mt-4 mb-[60px]'>{isSuccess ? t("activation_successful_msg") : t("activation_failed_msg")}</div>
    </div>
  );
  const onFormSubmit = () => {
    showDialog({
      title: t("confirm_reactivation"),
      content: (
        <div>
          <p>
            {t(
              "please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email"
            )}
          </p>
          <p>{t("didnt_receive_the_email")}</p>
        </div>
      ),
      onOk: () => reactivateAccount(),
      okText: t("resend"),
      cancelText: t("close"),
    });
  };
  return (
    <React.Fragment>
      {loadMask && (
        <LoadMask
          msg={loadMask.msg}
          style={{ zIndex: "10007" }}
        />
      )}
      <AuthLayout BackButton={false}>
        
          <AuthForm
            title={title}
            formHeader={FormHeader}
            data={isSuccess ? confirmationData : null}
            btnText={isSuccess ? 'sign_in' : 'resend'}
            onSubmit={isSuccess ? onLoginClick : onFormSubmit}
            footerElements={footerElements}
            btnMargin='mt-[60px]'
          />
       
      </AuthLayout>
    </React.Fragment>
  );
};

export default Confirmation;
