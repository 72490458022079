import { useRef, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@mui/material';

interface MfaVerifyModalProps {
  handleMfaLogin: (code: string) => void;
  handleMfaCancel: () => void;
}

const MfaVerify = ({ handleMfaLogin, handleMfaCancel }: MfaVerifyModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [inputStatus, setInputStatus] = useState("");

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onConfirm = () => {
    const cleanCode = code.replace(/\s+/g, "");
    if (cleanCode === "") {
      setInputStatus("error");
    } else {
      handleMfaLogin(cleanCode);
    }
  };

  const handleCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setCode(input);
    if (input !== "") {
      setInputStatus("");
    }
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onConfirm();
    }
  };

  return (
    <Box
      className="max-h-screen h-screen bg-no-repeat"
      sx={{
        backgroundImage: 'linear-gradient(to bottom right, #D72544, #362674)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        className="py-16 px-10 z-10 bg-white rounded-md"
        sx={{
          maxWidth: 500,
          width: '80%',
          boxShadow: 3
        }}
      >
        <Typography variant="h1" className="text-center">{t("confirm_mfa_title")}</Typography>
        <p className="py-7">{t("confirm_mfa_instruction")}</p>
        <Box className="my-8 flex items-center">
          <strong className="mr-2">{t("google_opt_code")}:</strong>
          <TextField
            placeholder={t("google_opt_code")}
            inputProps={{ maxLength: 16 }}
            variant="outlined"
            size="small"
            error={inputStatus === "error"}
            helperText={inputStatus === "error" ? t("mfa_required") : ""}
            onChange={handleCodeInput}
            onKeyDown={handleOnKeyPress}
            InputProps={{
              type: 'number',
              startAdornment: <InputAdornment position="start"></InputAdornment>,
              inputRef: inputRef,
              sx: {
                '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                }
              }
            }}
            
          />
        </Box>
        <Box className="flex justify-between gap-4 w-full">
          <Button
            className="w-1/2 h-10"
            variant="outlined"
            color="secondary"
            onClick={handleMfaCancel}
          >
            {t("confirm_mfa_cancel")}
          </Button>
          <Button
            className="w-1/2 h-10"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {t("confirm_mfa")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MfaVerify;
