import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { InputAdornment, TextField, Button, IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../../../DataDisplay/Table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DropdownMenu from '../../../utils/dropDownMenu';
import { useNavigate } from 'react-router-dom';
import GroupDrawer from './GroupDrawer';
import { showDialog, showToast } from '../../../utils/notifications';
import { addGroup, addGroupMember, deleteGroup, getGroupList, updateGroupName } from '../../../../services/manage/groupmanagement';
import { saveGroupList } from '../../../../store/manage/group';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const GroupManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [groupList, setGroupList] = useState<any>([]);
    const [groupOptionType, setGroupOptionType] = useState<string>('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const [editingGroupId, setEditingGroupId] = useState<number | null>(null);
    const [newGroupName, setNewGroupName] = useState<string>('');
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;

    const editMenu = [
        {
            label: 'add_group_member',
            value: 'add_group_member',
        },
        {
            label: 'change_group_name',
            value: 'change_group_name',
            disabled: !(isOwner),
        },
        {
            label: 'delete_group',
            value: 'delete_group',
            className: 'text-red-500',
            disabled: !(isOwner),
        },
    ];

    const handleDeleteGroup = async () => {
        const params = { token: authContext.token, groupId: selectedGroup.groupId };
        setShowMask({ msg: t('deleting_group') });
        const response = await deleteGroup(params);
        setShowMask(null);
        if (response?.result?.code === '0000') {
            showToast('success', t('deleted_group_successfully'));
            const updatedGroupList = groupList.filter((group: any) => group.groupId !== selectedGroup.groupId);
            setGroupList(updatedGroupList);
        } else {
            showToast('success', t('error_while_deleting_group'));
        }
    };

    const handleMenuChange = (type: any, params: any) => {
        if (type.includes('add')) {
            setGroupOptionType(type);
            setDrawerOpen(true);
        } else if (type.includes('change')) {
            setEditingGroupId(params.groupId);
            setNewGroupName(params.groupName);
        } else {
            showDialog({
                title: t(`${type}_title`),
                content: t(`${type}_desc`),
                okColor: 'error',
                onOk: () => {
                    handleDeleteGroup();
                },
                okText: t('delete'),
                cancelText: t('cancel'),
            });
        }
    };

    const handleSaveGroupName = async (groupId: number) => {
        setShowMask({ msg: t('updating_group_name') });
        try {
            const response = await updateGroupName({ token: authContext.token, groupId, groupName: newGroupName });
            setShowMask(null);
            if (response?.result?.code === '0000') {
                setGroupList((prevGroupList: any[]) => prevGroupList.map((group) => (group.groupId === groupId ? { ...group, name: newGroupName } : group)));
                showToast('success', t('group_name_updated_successfully'));
            } else {
                showToast('error', t('error_while_updating_group_name'));
            }
        } catch (error) {
            showToast('error', t('error_while_updating_group_name'));
        } finally {
            setEditingGroupId(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setShowMask({ msg: t('fetching_group_list') });

            const fetchGroupList = async () => {
                const params = { token: authContext.token };
                const response = await getGroupList(params);
                if (response?.result?.code === '0000' && Array.isArray(response?.data.groups)) {
                    setGroupList(response.data.groups);
                    dispatch(saveGroupList(response.data.groups));
                }
            };

            try {
                await fetchGroupList();
            } catch (error) {
                console.error('Error fetching data:', error);
                showToast('error', t('error_fetching_data'));
            } finally {
                setShowMask(null);
            }
        };

        fetchData();
    }, [authContext.token, dispatch, t, setShowMask, setGroupList]);

    const handleGroupSub = (group: any) => {
        navigate(`/organization/group/manage/sub?groupId=${group.groupId}`);
    };

    const columns: GridColDef[] = [
        {
            headerName: t('group_name'),
            field: 'name',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                if (editingGroupId === params.row.groupId) {
                    return (
                        <div className="flex items-center h-full">
                            <TextField
                                defaultValue={params.row.name}
                                onChange={(e) => setNewGroupName(e.target.value)}
                                onBlur={() => setEditingGroupId(null)}
                                onKeyDown={(e) => {
                                    e.stopPropagation();
                                    if (e.key === 'Enter') {
                                        handleSaveGroupName(params.row.groupId);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => handleSaveGroupName(params.row.groupId)}>
                                                <KeyboardReturnIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                variant="outlined"
                                fullWidth
                                autoFocus
                            />
                        </div>
                    );
                }
                return <span>{params.row.name}</span>;
            },
        },
        {
            headerName: t('group_manager'),
            field: 'group_manager',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const getManager = params.row.members.filter((member: { type: string }) => member.type === 'Manager');

                if (getManager.length > 0) {
                    const managerList = getManager.map((manager: { userId: any }) => manager.userId).join(', ');
                    return (
                        <Tooltip title={managerList} arrow>
                            <span>{managerList}</span>
                        </Tooltip>
                    );
                }

                return '';
            },
        },
        {
            headerName: t('users'),
            field: 'members',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        {params.value ? (
                            <span onClick={() => handleGroupSub(params.row)} className="cursor-pointer text-linkblue">
                                {params.value.length} Users
                            </span>
                        ) : (
                            <span className="text-linkblue">0 Users</span>
                        )}
                    </>
                );
            },
        },
        {
            headerName: t('edit'),
            field: 'edit',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div key={params.id} onClick={() => setSelectedGroup(params.row)}>
                        <DropdownMenu disabled={!(isOwner || isManager)} menuOptions={editMenu} handleMenuChange={(type: string) => handleMenuChange(type, params.row)} />
                    </div>
                );
            },
        },
    ];

    const filterData = (data: any) => {
        const filteredList = data
            ? data.filter((group: any) => {
                  const groupNameMatch = group.name.toLowerCase().includes(searchValue);
                  return groupNameMatch;
              })
            : [];

        return filteredList;
    };

    const onSearch = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    const handleSaveDrawer = async (data: any, type: string) => {
        if (type === 'create_group') {
            const isDuplicateName = groupList.some((group: { name: string }) => group.name.toLowerCase() === data.group_name.toLowerCase());
            if (isDuplicateName) {
                showToast('error', t('duplicate_group_name'));
                return;
            }
            
            if(data.group_name.includes('+')) {
                data.group_name = data.group_name.replace(/\+/g, '%2B');
            }

            setShowMask({ msg: t('creating_group') });
            setDrawerOpen(false);
            const params = { token: authContext.token, name: data.group_name };
            const response = await addGroup(params);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                showToast('success', t('group_created_successfully'));
                setGroupList([response.data, ...groupList]);
            } else {
                showToast('error', t(`RCM-${response?.result?.code}`));
            }
        } else if (type === 'add_group_member') {
            setShowMask({ msg: t('adding_group_member') });
            const params = { token: authContext.token, groupId: selectedGroup.groupId, ...data };
            const response = await addGroupMember(params);

            setShowMask(null);
            if (response?.result?.code === '0000') {
                const updateGroupList = groupList.map((group: any) => {
                    if (group.groupId === selectedGroup.groupId) {
                        const updatedGroup = response.data.groups.find((g: any) => g.groupId === group.groupId);
                        return updatedGroup ? updatedGroup : group;
                    }
                    return group;
                });
                setGroupList(updateGroupList);
                showToast('success', t('group_member_added_successfully'));
                setDrawerOpen(false);
            } else {
                showToast('error', t(`RCM-${response?.result?.code}`));
            }
        }
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setGroupOptionType('');
    };

    const handleCreateGroup = () => {
        setGroupOptionType('create_group');
        setDrawerOpen(true);
    };

    const getRowId = (row: any) => row.groupId;

    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <GroupDrawer open={drawerOpen} onSave={handleSaveDrawer} onClose={handleCloseDrawer} type={groupOptionType} />
            <div className="flex flex-wrap justify-between gap-6 mb-8 lg:flex-nowrap">
                <div className="text-2xl font-medium">
                    <span className="text-2xl font-medium whitespace-nowrap">{t('group_management')}</span>
                </div>
                <div className="lg:mt-0 w-full max-w-[656px]">
                    <TextField
                        id="search-input"
                        label="Search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder={t('group_search_placeholder')}
                        onChange={(e) => onSearch(e.target.value)}
                        className="w-full"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {isOwner && (
                    <div>
                        <Button variant="contained" className="text-xs font-medium" onClick={handleCreateGroup}>
                            {t('create_group')}
                        </Button>
                    </div>
                )}
            </div>

            <div className="overflow-auto">
                <div className="flex-grow pl-1 p-4 pt-1 min-w-[800px]">
                    <DataTable height={'100%'} columns={columns} rows={filterData(groupList) || []} pageSize={10} pageSizeOptions={[5, 10, 15, 20, 25, 30]} getRowId={getRowId} />
                </div>
            </div>
        </ContentLayout>
    );
};

export default GroupManagement;
