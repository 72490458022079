import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, Drawer } from '@mui/material';
import { HelpOutlineRounded } from '@mui/icons-material';
import { iosModalJson, appFrameworks } from './iosData';
import { addiOSProject } from '../../../../../services/sealing/ios';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useForm } from 'react-hook-form';
import renderInput from './renderInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { ModalConfig } from '../../../../../type/ios';
import { refactorCodeBlock } from './iosFunction';
import { showToast } from '../../../../utils/notifications';

interface IOSModalProps {
    type: keyof typeof iosModalJson;
    visible: boolean;
    onCancel: (e?: any) => void;
    iosData?: any;
    buildData?: any;
    setBuildData?: any;
    isPlanValid?: any;
    isHybridPlanValid?: any;
}

const isHybridFramework = (framework: string) =>
    appFrameworks
        .filter((e) => e.type === 'hybrid')
        .map((e) => e.detail)
        .includes(framework);
const isXamarinFramework = (framework: string) =>
    appFrameworks
        .filter((e) => e.detail === 'Xamarin')
        .map((e) => e.detail)
        .includes(framework);

const IOSModal = ({ type, visible, onCancel, iosData, isPlanValid, isHybridPlanValid }: IOSModalProps) => {
    const { t } = useTranslation();
    const [data, setData] = useState<ModalConfig>(iosModalJson[type]);
    const [isOpen, setIsOpen] = useState(visible);
    const { control, handleSubmit, reset, setValue, getValues } = useForm();
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const sdkInfo = useSelector((state: RootState) => state.iOSSealingReducer.sdkInfo);
    const initialData = useSelector((state: RootState) => state.iOSSealingReducer.sealingPreferences);

    useEffect(() => {
        if (visible !== isOpen) setIsOpen(visible);
    }, [isOpen, visible]);

    useEffect(() => {
        if (type === 'CreateIOSProject') setData(iosModalJson.CreateIOSProject);
        if (type === 'DownloadIOSProject') {
            Object.keys(iosData).forEach((key) => {
                setValue(key, iosData[key]);
            });

            const isHybrid = isHybridFramework(initialData.app_framework);
            const isXamarin = isXamarinFramework(initialData.app_framework);
            const serviceVersions = isHybrid ? sdkInfo?.hybridSdkInfo || [] : sdkInfo?.nativeSdkInfo || [];

            const GetSealingVersion = refactorCodeBlock(sdkInfo, serviceVersions, isHybrid, isXamarin, initialData);
            const sealData = GetSealingVersion?.sealData;
            const hasAppFramework = getValues('app_framework');

            // eslint-disable-next-line array-callback-return
            iosModalJson[type].items.map((item) => {
                if (item.label === 'app_framework') {
                    item.options = appFrameworks.map((framework) => {
                        return {
                            label: t(framework.name),
                            value: framework.detail,
                            expired: (framework.type === 'hybrid' && !isHybridPlanValid) || (framework.type !== 'hybrid' && !isPlanValid),
                            isHybrid: framework.type === 'hybrid',
                        };
                    });
                } else if (item.label === 'sealing_version') {
                    if (!hasAppFramework) {
                        item.disabled = true;
                    } else {
                        item.disabled = false;
                        item.options = sealData.map((framework: { service_version: any | string | string[] }) => {
                            return {
                                label: t(framework.service_version),
                                value: framework.service_version,
                            };
                        });
                    }
                }
            });

            const updatedData = iosModalJson.DownloadIOSProject;
            setData(updatedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultValues =
        data &&
        data.items.reduce((acc, item) => {
            if (item.label) acc[item.label] = '';
            return acc;
        }, {});

    const onReset = () => {
        reset(defaultValues);
    };

    const onCreate = async (values: any) => {
        const params = {
            project_name: values.project_name,
            bundle_id_prefix: values.bundle_id,
            token: authContext.token,
            service_type: 'NATIVE_IOS',
        };

        const response = await addiOSProject(params, region);
        if (response?.result?.code === '0000') {
            onCancel({ data: response.projectList });
            reset();
        } else if (response?.result?.code) {
            showToast('error', t(`RCM-${response?.result?.code}`, response?.result?.message ? response?.result?.message : t('error_while_creating_project')) as string);
        } else {
            showToast('error', t('error_while_creating_project'));
        }
    };

    const onSubmit = (values: any) => {
        if (type === 'CreateIOSProject') onCreate(values);
    };

    const ModalHeader = (
        <div className="mb-[50px] flex md:flex-nowrap flex-wrap justify-between items-start gap-10">
            <div>
                <h2 className="m-0 text-2xl font-medium whitespace-nowrap">{t(data.title)}</h2>
                <p className="text-[#A0A0A1] text-xs">{t(data.subtitle)}</p>
            </div>
            <div>
                <div className="flex justify-end gap-4">
                    {data.footer?.map((item) =>
                        item.type === 'icon' ? (
                            <Button key={'icon'} variant="outlined" color="secondary" onClick={onReset}>
                                <RefreshIcon />
                            </Button>
                        ) : (
                            <React.Fragment key={'action'}>
                                <Button key={'cancel'} variant="outlined" color="secondary" onClick={onCancel} className="font-medium text-[13px]">
                                    {t('cancel')}
                                </Button>
                                <Button key={'submit'} type="submit" variant="contained" color="primary" className="font-medium text-[13px]">
                                    {t(item.label ?? '')}{' '}
                                </Button>
                            </React.Fragment>
                        ),
                    )}
                </div>
            </div>
        </div>
    );
    return (
        <Drawer anchor="right" open={isOpen} onClose={onCancel} transitionDuration={1000}>
            <div className="p-10 max-w-[800px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>{ModalHeader}</div>
                    <div>
                        {data.items.map((item, index) => (
                            <div key={index}>
                                {item.children ? (
                                    <div className="flex flex-none items-center gap-2.5 mb-[24px]">
                                        <div className="flex items-center justify-between flex-none min-w-1/3">
                                            <p className="font-medium text-primary">{t(item.label ?? '')} </p>
                                        </div>
                                        <div className="p-2 w-full max-w-[264px]">
                                            {item.children.map((child, childIndex) => (
                                                <React.Fragment key={`childIndex-${childIndex}`}>{renderInput({ item: child, control, index: childIndex })}</React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-none items-center gap-2.5 mb-[24px]" key={index}>
                                        <div className="min-w-[150px]">
                                            <p className="font-medium text-primary">{t(item.label ?? '')} </p>
                                        </div>
                                        <div className="p-2 w-full max-w-[264px] flex gap-2.5 items-center">
                                            {item.tooltip ? (
                                                <Tooltip title={t(item.tooltip)}>
                                                    <IconButton>
                                                        <HelpOutlineRounded sx={{ color: '#A6A6A7' }} fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : undefined}
                                            {renderInput({ item: item, control, index: index })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {data.notes && (
                        <div className="text-[#797A7C] text-xs">
                            <p className="font-semibold ">{t('note')}:</p>
                            <p>{t(data.notes)}</p>
                        </div>
                    )}
                </form>
            </div>
        </Drawer>
    );
};

export default IOSModal;
