import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BarChartColors } from "../../../utils/common";
import { ResponsiveBar } from "@nivo/bar";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { DummyBarChart } from "../../charts/DummyChart";

interface HackingTypeBarChartProps {
  hackingTypes: string[];
  hackingData: any;
  filterHackingData: (type: string) => void;
  clickedItems: string[];
  
}

const formatter = new Intl.NumberFormat('en-GB', { 
  notation: "compact", 
  compactDisplay: "short" 
}) 

const HackingTypeBarChart = ({hackingTypes, hackingData, filterHackingData, clickedItems}: HackingTypeBarChartProps) => {
  const { t } = useTranslation();
  const reverseHackingTypes = [...hackingTypes].reverse();
  
  return (
    <div className="w-full min-h-24 bg-primary mb-4 py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <p className="text-base text-primary normal-case font-normal">
        <Tooltip
          title={t("daily_data_will_be_start_processing_after_next_day")}
        >
          <span>{t("hacking_types")}</span>
        </Tooltip>
      </p>
      <div className="flex justify-end">
        <div className="flex flex-wrap justify-end max-w-[600px] p-4 mr-4">
          {reverseHackingTypes.map((e, i) => (
            <div className="w-full md:w-1/3 text-right" key={e}>
              <div
                className="flex items-center text-xs cursor-pointer justify-end text-darkgray"
                onClick={() => filterHackingData(e)}
              >
                <p className="h-4 ">
                  {t(e.toLowerCase().replace(/ /g, "_"), e as string)}
                </p>
                <p
                  className="w-3 h-3 ml-1"
                  style={{
                    backgroundColor: clickedItems.includes(e)
                      ? "#9ca3af"
                      : BarChartColors[i],
                  }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow mb-2 h-[500px]">
        {hackingData.hackingTypes.length > 0 ? 
        <ResponsiveBar
          data={hackingData.hackingTypes}
          theme={{
            "text": {
              "fontSize": "8px",
            },
            "axis": {
              "ticks": {
                "text": {
                  "fontSize": "11px",
                },
              },
              }
           }}
          keys={reverseHackingTypes.map((e) => `${e}`)}
          indexBy="date"
          margin={{ top: 10, right: 10, bottom: 55, left: 50 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={BarChartColors}
          labelTextColor={"#36373A"}
          labelSkipWidth={12}
          labelSkipHeight={12}
          label={(e) => Number(e.value) > 1000 ? `${formatter.format(Number(e.value))}` : `${e.value}`}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            tickValues: 10,
          }}
          tooltip={(e: any) => {
            const data = reverseHackingTypes.map((el, i) => ({
              label: t(el.toLowerCase().replaceAll(" ", "_"), el as string),
              cnt: e.data[`${el}`] || 0,
              key: i,
            }));

            return (
              <div
                className="bg-white border border-solid p-4"
                key={e.data.key}
              >
                <p>
                  <strong>
                    {t("detection_date")} : {e.data.date}
                  </strong>
                </p>
                {data
                  .sort((a: any, b: any) => b.cnt - a.cnt)
                  .map((el, i) => (
                    <p key={i}>
                      <span>{el.label}: </span>
                      <strong>{el.cnt.toLocaleString()}</strong>
                    </p>
                  ))}
              </div>
            );
          }}
          role="application"
        /> : <DummyBarChart count={30}/>}
      </div>
      <div className="flex justify-end mt-5">
          <Tooltip title={t("show_hacking_dections_details")}>
            <div>
              <span className="mr-2">{t("hacking_types")}</span>
              <span className="hover:underline text-linkblue">
                <Link className="text-linkblue" to="/threatdetection">
                  {t("details")}
                  <EastIcon className="text-xs ml-1" fontSize="small" />
                </Link>
              </span>
            </div>
          </Tooltip>
          </div>
    </div>
  );
};

export default HackingTypeBarChart;
