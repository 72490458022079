import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserInterface {
    userId: string;
    memberId: number;
    type: string;
}

interface GroupListInterface {
    groupId: string;
    groupName: string;
    members: UserInterface[];
}

interface GroupReducerInterface {
    groupList: GroupListInterface[];
    selectedGroup: GroupListInterface | null;
}

const initialState: GroupReducerInterface = {
    groupList: [],
    selectedGroup: null,
};

const GroupReducerSlice = createSlice({
    name: 'groupReducer',
    initialState,
    reducers: {
        saveGroupList: (state, action: PayloadAction<GroupListInterface[]>) => {
            state.groupList = action.payload;
        },
        saveSelectedGroup: (state, action: PayloadAction<GroupListInterface>) => {
            state.selectedGroup = action.payload;
        },
    },
});

export const { saveGroupList, saveSelectedGroup } = GroupReducerSlice.actions;

export default GroupReducerSlice.reducer;
