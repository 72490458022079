import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MemberTable from './MemberTable';
import AppTable from './AppTable';

interface GroupTabsProps {
    tabIndex: number;
    onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const GroupTabs: React.FC<GroupTabsProps> = ({ tabIndex, onTabChange }) => {
    const { t } = useTranslation();

    return (
        <>
            <Tabs value={tabIndex} onChange={onTabChange} sx={{ borderBottom: 1, borderColor: '#E5E5E5' }}>
                <Tab label={t('member')} />
                <Tab label={t('access_project')} />
            </Tabs>
            <div className="py-8">
                {tabIndex === 0 && <MemberTable />}
                {tabIndex === 1 && <AppTable />}
            </div>
        </>
    );
};

export default GroupTabs;
