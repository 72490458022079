import i18n from "../../i18n/config";
import { AppURL } from "../../utility/AppURL";
const privacyTermUrl = i18n.language === "en_US" ?  AppURL.privacyterms : AppURL.privacyterms_kr;

export interface FieldConfig {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  required: boolean;
  error?: string;
  children?: {
    type: string;
    label: string;
    url: string;
    name: string;
    required: true,
  }[];
  fields?: FieldConfig[];
  validate?: string;
  disabled?: boolean;
  value?: string;
  options?: { label: string; value: string }[];
}

export const confirmationData: FieldConfig[] = [
  {
    type: "email",
    name: "user_id",
    label: "email_address",
    placeholder: "enter_email_address",
    required: true,
    disabled: true,
    error: "please_enter_valid_email_address",
  },
  {
    type: "password",
    name: "user_pw",
    label: "password",
    placeholder: "enter_password",
    required: true,
    error: "please_enter_password",
  },
];

export const loginData: FieldConfig[] = [
  {
    type: "email",
    name: "user_id",
    label: "email_address",
    placeholder: "enter_email_address",
    required: true,
    error: "please_enter_valid_email_address",
  },
  {
    type: "password",
    name: "user_pw",
    label: "password",
    placeholder: "enter_password",
    required: true,
    error: "please_enter_password",
  },
];

export const forgotPasswordData: FieldConfig[] = [
  {
    type: "email",
    name: "user_id",
    label: "email_address",
    placeholder: "enter_email_address",
    required: true,
    error: "please_enter_valid_email_address",
  },
];


export const signUpData: FieldConfig[] = [
  {
    type: "text",
    name: "full_name",
    fields: [
      {
        type: "text",
        name: "first_name",
        label: "first_name",
        placeholder: "Enter your first name",
        required: true,
        error: "Please enter your first name",
      },
      {
        type: "text",
        name: "last_name",
        label: "last_name",
        placeholder: "Enter your last name",
        required: true,
        error: "Please enter your last name",
      },
    ],
    label: "",
    placeholder: "",
    required: false,
  },
  {
    type: "email",
    name: "user_id",
    label: "email_address",
    placeholder: "enter_email_address",
    required: true,
    error: "enter_email_address",
  },
  {
    type: "password",
    name: "user_pw",
    label: "password",
    placeholder: "enter_password",
    required: true,
    error: "please_enter_password",
    validate: "validatePassword",
  },
  {
    type: "password",
    name: "user_pw_confirm",
    label: "confirm_password",
    placeholder: "confirm_password",
    required: true,
    error: "please_confirm_password",
    validate: "validateConfirmPassword",
  },
  {
    type: 'select',
    name: 'region',
    label: 'region',
    placeholder: 'select_region',
    required: true,
    error: 'please_select_region',
    options: [
      { label: 'global', value: 'Global' },
      { label: 'southkorea', value: 'South Korea' },
    ],
  },
  {
    type: "checkbox",
    name: "all_agree",
    label: "agree_to_terms_of_use",
    required: true,
    error: "please_accept_terms_of_service",
    children: [
      {
        type: "link",
        label: "privacy_policy",
        url: `${privacyTermUrl}/#tab-1580458933031-5-2`,
        name: "privacy_policy",
        required: true,
      },
      {
        type: "link",
        label: "terms_of_service",
        url: privacyTermUrl,
        name: "terms_of_service",
        required: true,
      },
    ],
    placeholder: ""
  }
];

export const InviteSignUpData: FieldConfig[] = [
  {
    type: "text",
    name: "full_name",
    fields: [
      {
        type: "text",
        name: "first_name",
        label: "first_name",
        placeholder: "Enter your first name",
        required: true,
        error: "Please enter your first name",
      },
      {
        type: "text",
        name: "last_name",
        label: "last_name",
        placeholder: "Enter your last name",
        required: true,
        error: "Please enter your last name",
      },
    ],
    label: "",
    placeholder: "",
    required: false,
  },
  {
    type: "email",
    name: "user_id",
    label: "email_address",
    placeholder: "enter_email_address",
    disabled: true,
    required: true,
    error: "enter_email_address",
  },
  {
    type: "password",
    name: "user_pw",
    label: "password",
    placeholder: "enter_password",
    required: true,
    error: "please_enter_password",
    validate: "validatePassword",
  },
  {
    type: "password",
    name: "user_pw_confirm",
    label: "confirm_password",
    placeholder: "confirm_password",
    required: true,
    error: "please_confirm_password",
    validate: "validateConfirmPassword",
  },
  {
    type: "checkbox",
    name: "all_agree",
    label: "agree_to_terms_of_use",
    required: true,
    error: "please_accept_terms_of_service",
    children: [
      {
        type: "link",
        label: "privacy_policy",
        url: `${privacyTermUrl}/#tab-1580458933031-5-2`,
        name: "privacy_policy",
        required: true,
      },
      {
        type: "link",
        label: "terms_of_service",
        url: privacyTermUrl,
        name: "terms_of_service",
        required: true,
      },
    ],
    placeholder: ""
  },
];

export const newPasswordData: FieldConfig[] = [
  {
    type: "password",
    name: "user_pw",
    label: "new_password",
    placeholder: "enter_password",
    required: true,
    error: "please_enter_password",
    validate: "validatePassword",
  },
  {
    type: "password",
    name: "user_pw_confirm",
    label: "confirm_password",
    placeholder: "confirm_password",
    required: true,
    error: "please_confirm_password",
    validate: "validateConfirmPassword",
  },
];
