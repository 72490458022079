import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState } from 'react';
import { TextField, FormControl, Autocomplete } from '@mui/material';
import { savePlanDetails } from "../../../../services/manage/billingdetails";
import { setBillingDetails } from "../../../../store/manage/billingdetails";
import { useForm, Controller } from 'react-hook-form';
import { BillingFields } from './BillingFields';
import { useDispatch, useSelector } from "react-redux";
import { getCities, getStates } from "../../../../services/main";
import { RootState } from "../../../../store";
import { useTranslation } from "react-i18next";
import { CityTypes, StateTypes } from '../../../../type/user';
import { showDialog, showToast } from "../../../utils/notifications";


export interface ChildComponentMethods {
  submitForm: () => void;
  resetForm: () => void;
}


const FormComponent = forwardRef<ChildComponentMethods, {}>((props, ref) => {
  const { handleSubmit, reset, watch, control, setValue } = useForm();
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
  const countryData = useSelector((state: RootState) => state.commonReducer.countries);
  const billingDetails = useSelector((state: RootState) => state.billingDetailsReducer.billingDetails);

  const [cityData, setCityData] = useState<CityTypes[]>([]);
  const [stateData, setStateData] = useState<StateTypes[]>([]);

  const selectedCountry = watch("country");
  const selectedState = watch("state");

  const isEdit = authContext.role === "Owner";

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    },
    resetForm: () => {
      reset(); 
    }
  }));

  useEffect(() => {
    if (selectedCountry) {
      getStates(selectedCountry, authContext.token).then(setStateData);
    }
  }, [selectedCountry, authContext.token]);

  useEffect(() => {
    if (selectedState) {
      getCities(selectedState, authContext.token).then(setCityData);
    }
  }, [selectedState, authContext.token]);

  useEffect(() => {
    if (billingDetails) {
      Object.keys(billingDetails).forEach(key => {
        const value = billingDetails[key];
        setValue(key, value ? value : "");
      });



      if (billingDetails.country) {
        getStates(billingDetails.country, authContext.token).then(setStateData);
      }
      if (billingDetails.state) {
        getCities(billingDetails.state, authContext.token).then(setCityData);
      }
    }
  }, [billingDetails, setValue, authContext.token]);

   const onSubmit = (values:any) => {
      const updateBillingInfo = async () => {
        const { country_name, state_name, city_name, ...others } = billingDetails;
        const params = {
            token: authContext.token,
            ...others, ...values
        };
      
        const combinedData = {
            ...billingDetails,
            ...values,
            country_name: countryData.find((item: any) => item.country_id === values.country)?.country_name,
            state_name: stateData.find((item: any) => item.state_id === values.state)?.state_name,
            city_name: cityData.find((item: any) => item.city_id === values.city)?.city_name,
        };
  
        const response = await savePlanDetails(params);
        if (response?.result?.code === "0000") {
            dispatch(setBillingDetails({
                ...combinedData
            }));
            showToast('success', t('billing_information_updated_successfully'));
        }
        else if (response?.result?.code)
            showToast('error', t(`RCM-${response?.result?.code}`,
                t('failed_to_update_billing_information')));
        else
            showToast('error', t('failed_to_update_billing_information'));
    };

    showDialog({
        title: t('confirm_update'),
        content: t('do_you_really_want_to_update_billing_information'),
        onOk: updateBillingInfo,
        okText: t('yes'),
        cancelText: t('no')
    });
  };

 
  const getOptions = (data:any) => {
    switch (data.name) {
      case 'country':
        return countryData.map(({ country_id, country_name, country_name_kr }) => ({
          id: country_id,
          name: i18n.language === "kr_KR" ? country_name_kr : country_name,
        }));
      case 'state':
        return stateData.map(({ state_id, state_name }) => ({
          id: state_id,
          name: state_name
        }));
      case 'city':
        return cityData.map(({ city_id, city_name }) => ({
          id: city_id,
          name: city_name
        }));
      default:
        return [];
    }
  };
  return (
    <>
      <div className='text-base font-semibold pb-4'>{t('billingdetails')}</div>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className='lg:pr-10 lg:border-r border-[#e0e0e0] lg:mr-10'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4">
          {BillingFields.map((data) => (
            <div key={data.name}>
            <FormControl fullWidth margin="normal" key={data.name}>
              {data.type === 'input' ? (
                <Controller
                  name={data.name}
                  control={control}
                  defaultValue={data.value}
                  rules={{ required: data.errorMessage ? t(data.errorMessage) : false }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      disabled={!isEdit}
                      label={t(data.label)}
                      placeholder={t(data.placeholder)}
                      helperText={error && error.message}
                      required={data.required}
                      fullWidth
                      error={!!error}
                    />
                  )}
                />
              ) : (
         
                <Controller
                  name={data.name}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      key={field.value}
                      value={getOptions(data).find(option => option.id === field.value) || null}
                      disabled={!isEdit}
                      options={getOptions(data)}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, value) => {
                        if(data.name === 'country'){
                          setValue('state', '');
                          setValue('city', '');
                          field.onChange(value?.id);
                        }else if(data.name === 'state'){
                          setValue('city', '');
                          field.onChange(value?.id);
                        }else{
                          field.onChange(value?.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t(data.label)}
                          placeholder={t(data.placeholder)}
                          error={!!error}
                          helperText={error && data.errorMessage ? t(data.errorMessage) : ""}
                          disabled={!isEdit}
                        />
                      )}
                    />
                )}
              />
               
              )}
            </FormControl>
            </div>
          ))}
          </div>
        </form>
      </>
  );
});

export default FormComponent;
