import { Post } from "../..";
import { DeviceHackingDetailsInterface, HackingDataInterface, HackingLogStreamInterface } from "../../../type/api/data";
import { getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const getHackingData = async (params: HackingDataInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, type, account_id = '', package_name, dimension, lang_set, sealing_mode = 'R' } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    urlParams.append('dimension', dimension);
    urlParams.append('lang_set', lang_set);
    if (type === 'android') {
        urlParams.append('package_name', package_name);
        urlParams.append('sealing_mode', sealing_mode);
    }
    else {
        urlParams.append('bundle_id', package_name);
        urlParams.append('account_id', account_id);
    }
    urlParams.append('token', token);

    const url = getRegionalURL(type === 'android' ? RegionalURL.multidimensiondaily : RegionalURL.iosmultidimensiondaily, region)
    const response = await Post(
        url, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getDeviceHackingDetails = async (params: DeviceHackingDetailsInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, android_raw_id, calc_date_from, calc_date_to, limit = '100', package_name, sealing_mode = 'R' } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    urlParams.append('limit', limit);
    urlParams.append('package_name', package_name);
    if (android_raw_id)
        urlParams.append('android_raw_id', android_raw_id);
    else
        urlParams.append('sealing_mode', sealing_mode);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.hackingattemptssubcategories, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getPieChartData = async (params: HackingDataInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, type, account_id = '', package_name, dimension, lang_set, sealing_mode = 'R' } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    urlParams.append('dimension', dimension);
    urlParams.append('lang_set', lang_set);
    if (type === 'android') {
        urlParams.append('package_name', package_name);
        urlParams.append('sealing_mode', sealing_mode);
    }
    else {
        urlParams.append('bundle_id', package_name);
        urlParams.append('account_id', account_id);
    }
    urlParams.append('token', token);

    const url = getRegionalURL(type === 'android' ? RegionalURL.multidimension : RegionalURL.iosmultidimension, region)
    const response = await Post(
        url, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getHackingLogStream = async (params: HackingLogStreamInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, android_raw_id, reportEndDT, reportStDT, reqDocSize = '1000', package_name } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('android_raw_id', android_raw_id);
    urlParams.append('package_name', package_name);
    urlParams.append('reportEndDT', reportEndDT);
    urlParams.append('reportStDT', reportStDT);
    urlParams.append('reqDocSize', reqDocSize);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.hackinglogstream, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};