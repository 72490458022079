import React, { useEffect, useState } from "react";


import BillingInformation from "./billinginformation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getPlanDetails } from "../../../services/manage/billingdetails";
import { setBillingDetails, setCardDetails, setPlanDetails } from "../../../store/manage/billingdetails";
import { useTranslation } from "react-i18next";
import LoadMask from "../../utils/loadmask";
import { showToast } from "../../utils/notifications";


const BillingDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showMask, setShowMask] = useState<any>({});
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        if (authContext?.token) {
            const fetchAccountInfo = async (token: string) => {
                const { isAuth, ...params } = authContext;
                setShowMask({ msg: t('fetching_billing_details') });
                const response = await getPlanDetails(params);
                setShowMask(null);
                if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
                    dispatch(setPlanDetails({
                        ...response.plan
                    }));
                    dispatch(setBillingDetails({
                        ...response.billing
                    }));
                    const cardDetails = response.card ? JSON.parse(response.card) : null;
                    dispatch(setCardDetails(cardDetails));
                }
                else if (response?.result.code)
                    showToast('error', t(`RCM-${response?.result?.code}`,
                        t('failed_to_load_billing_details')));
                else
                    showToast('error', t('failed_to_load_billing_details'));
            };
            fetchAccountInfo(authContext.token);
        }
    }, [authContext, dispatch, t]);


    return (<>
    {showMask && <LoadMask msg={showMask.msg}/>}
        <BillingInformation/>
    </>);
};

export default BillingDetails;