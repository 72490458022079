import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Button, Autocomplete, TextField, Alert, Chip } from '@mui/material';

interface AddAppProps {
    handleAddAppToGroup: (appId: number, platform: string, handleDrawerClose: () => void, setSelectedApp: (app: any) => void) => void;
    group: any;
    appListAll: any[] | null;
    disable?: boolean;
}

const AddApp = ({ handleAddAppToGroup, appListAll, group, disable = false }: AddAppProps) => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedApp, setSelectedApp] = useState<any>(null);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleSave = async () => {
        if (!selectedApp) return;
        handleAddAppToGroup(selectedApp.id, selectedApp.platform, handleDrawerClose, setSelectedApp);
        setSelectedApp(null);
    };

    const handleChange = (event: React.SyntheticEvent, value: string | any) => {
        if (typeof value === 'string') {
            const matchingApp = appListAll?.find((app) => `${app.app_name} (${app.package_name})` === value);
            setSelectedApp(matchingApp || null);
        } else {
            setSelectedApp(value);
        }
    };

    return (
        <div>
            <Button className="mt-2" variant="contained" onClick={handleDrawerOpen} disabled={disable}>
                {t('add_app')}
            </Button>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
                <div className="p-10 w-9/12 lg:w-[800px]">
                    <div className="mb-[50px] flex justify-between items-start gap-10">
                        <div>
                            <h2 className="m-0 text-2xl font-medium">{t('assign_app_to_group_title')}</h2>
                            <p className="text-xs text-gray-500">{t('assign_app_to_group_desc')}</p>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button className="h-10" color="secondary" variant="outlined" onClick={handleDrawerClose}>
                                {t('cancel')}
                            </Button>
                            <Button variant="contained" disabled={!appListAll || appListAll.length === 0} color="primary" className="h-10" onClick={handleSave}>
                                {t('save')}
                            </Button>
                        </div>
                    </div>

                    {group && group.name && <p>{t('assign_app_to_group_note', { group_name: group.name })}</p>}
                    <div className="my-10">
                        <Autocomplete
                            id="enter-app-name"
                            disabled={!appListAll || appListAll.length === 0}
                            options={appListAll && appListAll.length > 0 ? appListAll : [{ app_name: t('please_add_app_first'), package_name: '', id: 'dummy', platform: '', groupId: null }]}
                            getOptionLabel={(option) => (option.id === 'dummy' ? option.app_name : `${option.app_name} (${option.package_name})`)}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id + option.package_name} style={{ pointerEvents: option.id === 'dummy' ? 'none' : 'auto' }}>
                                    <div style={{ color: option.id === 'dummy' ? 'rgba(0, 0, 0, 0.38)' : 'inherit' }}>
                                        {option.app_name}
                                        {option.id !== 'dummy' && (
                                            <>
                                                ({option.package_name})
                                                <Chip
                                                    className="ml-2 uppercase"
                                                    style={{
                                                        backgroundColor: option.platform === 'android' ? '#96C217' : '#517FF7',
                                                        color: 'inherit',
                                                    }}
                                                    label={option.platform}
                                                />
                                                {option.groupId && <span className="ml-2 text-[10px]">{option.groupName}</span>}
                                            </>
                                        )}
                                    </div>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={!appListAll || appListAll.length === 0 ? t('please_add_app_first') : t('enter_app_name')}
                                    placeholder={!appListAll || appListAll.length === 0 ? t('please_add_app_first') : t('search_app_name_or_package_name_or_bundle_id')}
                                />
                            )}
                            onChange={handleChange}
                            value={selectedApp}
                            size="small"
                            freeSolo={true}
                        />
                    </div>
                    <Alert severity="info" className="mt-4">
                        {t('add_app_info_note')}
                    </Alert>
                </div>
            </Drawer>
        </div>
    );
};

export default AddApp;
