import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAccountInvite } from '../../../../../../services/manage/accounts';
import { RootState } from '../../../../../../store';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import DataTable from '../../../../../DataDisplay/Table';
import { Alert, Autocomplete, Button, Chip, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { showDialog, showToast } from '../../../../../utils/notifications';
import { getGroupList } from '../../../../../../services/manage/groupmanagement';
import { saveGroupList } from '../../../../../../store/manage/group';
import { GridRenderCellParams } from '@mui/x-data-grid';
import DropdownMenu from '../../../../../utils/dropDownMenu';
interface InviteModalInterface {
    visible: boolean;
    inviteHistory: any;
    onCancel: () => void;
    updateHistoryDetails: () => void;
}

const menuOptions = [
    {
        label: 'resend_invitation',
        value: 'resend_invitation',
    },
];
const InviteModal = (props: InviteModalInterface) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [role, setRole] = useState<string>('');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [isValidEmail, setEmailValidity] = useState<boolean>(false);
    const [groupList, setGroupList] = useState<any>([]);
    const [groupId, setGroupId] = useState<string>('');
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [groupErrorMessage, setGroupErrorMessage] = useState<string | null>(null);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner';
    useEffect(() => {
        const fetchGroupList = async () => {
            const params = { token: authContext.token };

            const response = await getGroupList(params);
            if (response?.result?.code === '0000' && Array.isArray(response?.data.groups)) {
                setGroupList(response.data.groups);
                dispatch(saveGroupList(response.data.groups));
            } else {
                showToast('error', t('failed_to_fetch_group_list'));
            }
        };

        fetchGroupList();
    }, [authContext, dispatch, t]);

    useEffect(() => {
        setEmailAddress('');
        setEmailValidity(false);
        setGroupId('');
        setEmailErrorMessage(null);
        setGroupErrorMessage(null);
    }, [props.visible]);

    const onEmailChange = (e: any) => {
        setEmailAddress(e.target.value);
        setEmailValidity(e.target.checkValidity());
        setEmailErrorMessage(null);
    };

    const onInviteClick = async () => {
        if (!isValidEmail || !emailAddress) {
            setEmailErrorMessage(t('please_enter_valid_email_address'));

            return;
        }

        let params: any = {
            email: emailAddress,
            token: authContext.token,
            lang_set: i18n.language,
        };

        if (groupId !== '') {
            params = { ...params, groupId };
        }

        if (role !== '') {
            params = { ...params, role };
        }

        if (role === 'SubOwner') {
            if (groupId) {
                delete params.groupId;
            }
        }

        if (authContext?.role === 'SubOwner') {
            params = { ...params, role: 'Member' };
        }

        const response = await sendAccountInvite(params);
        if (response?.result?.code === '0000') {
            setEmailAddress('');
            setEmailValidity(false);
            setGroupId('');
            setRole('');
            showToast('success', t('account_invitation_sent_successfully'));
            props.updateHistoryDetails();
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_send_account_invite')));
        else showToast('error', t('failed_to_send_account_invite'));
    };

    const resendInvitation = async (email: string) => {
        const params = {
            email: email,
            token: authContext.token,
            lang_set: i18n.language,
        };
        const response = await sendAccountInvite(params);

        if (response?.result?.code === '0000') {
            showToast('success', t('account_invitation_resent_successfully', { email: email }));
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_send_account_invite')));
        else showToast('error', t('failed_to_send_account_invite'));
    };

    const handleMenuChange = async (type: string, member: any) => {
        showDialog({
            title: t(`${type}_title`),
            content: t(`${type}_desc`, { email: member.email }),
            onOk: () => {
                resendInvitation(member.email);
            },
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const columns = [
        { field: 'email', headerName: 'ID', flex: 1, sortable: true },
        {
            field: 'invite_timestamp',
            headerName: 'Invitation Date',
            flex: 1,
            sortable: true,
            renderCell: (params: any) => {
                const formattedDate = moment(params.value, 'YYYYMMDDHHmmss').format('YYYY/MM/DD HH:mm');
                return formattedDate;
            },
        },
        {
            field: 'state',
            headerName: 'State',
            width: 200,
            sortable: false,
            renderCell: (params: any) => {
                return params.row.is_signed_user === 'Y' ? (
                    <Chip icon={<CheckIcon className="text-white" />} label={t('signed_in_user')} className="bg-accent" />
                ) : (
                    <Chip label={t('resend_invite')} className="bg-[#F5812D]" />
                );
            },
        },
        {
            headerName: t('action'),
            field: 'action',
            flex: 0.3,
            renderCell: (params: GridRenderCellParams) => {
                return <DropdownMenu menuOptions={menuOptions} handleMenuChange={(type: string) => handleMenuChange(type, params.row)} />;
            },
        },
    ];

    const rows = Array.isArray(props.inviteHistory)
        ? props.inviteHistory.map((item: any) => ({
              key: item.seq,
              id: item.seq,
              email: item.target_email,
              invite_timestamp: item.invite_timestamp,
              is_signed_user: item.is_signed_user,
          }))
        : null;

    return (
        <Drawer anchor="right" open={props.visible} onClose={props.onCancel}>
            <div className="max-w-[800px]">
                <div className="p-10 pr-16">
                    <div className="mb-4">
                        <div className="mb-[50px] flex justify-between items-start gap-10">
                            <div>
                                <h2 className="m-0 text-2xl font-medium">{t('invite_team_member')}</h2>
                            </div>
                            <div className="flex justify-end gap-2">
                                <Button className="h-10" color="secondary" variant="outlined" onClick={props.onCancel}>
                                    {t('cancel')}
                                </Button>
                                <Button onClick={onInviteClick} variant="contained" color="primary" className="h-10">
                                    {t('invite')}
                                </Button>
                            </div>
                        </div>
                        <p className="mt-10 mb-5">{t('invite_colleague_note')}</p>
                        <div className="w-3/4 mb-10">
                            <TextField
                                fullWidth
                                type="email"
                                label={t('team_member_email_address')}
                                placeholder={t('enter_email_address')}
                                value={emailAddress}
                                onChange={onEmailChange}
                                required
                                variant="outlined"
                                size="small"
                                error={!!emailErrorMessage}
                                helperText={emailErrorMessage}
                            />
                        </div>
                        {isOwner && (
                            <div className="w-3/4 mb-10">
                                <FormControl fullWidth size="small" variant="outlined">
                                    <InputLabel id="role-select-label">{t('position')}</InputLabel>
                                    <Select labelId="role-select-label" id="role-select" value={role} onChange={(event) => setRole(event.target.value)} label={t('position')}>
                                        <MenuItem value="Member">{t('member')}</MenuItem>
                                        <MenuItem value="SubOwner">{t('subowner')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div className="w-3/4 mb-10">
                            {role !== 'SubOwner' && groupList.length > 0 && (
                                <Autocomplete
                                    options={groupList}
                                    getOptionLabel={(option: any) => option.name}
                                    renderInput={(params: any) => <TextField {...params} error={!!groupErrorMessage} helperText={groupErrorMessage} label={t('user_group')} />}
                                    onChange={(event: any, value: any) => {
                                        if (value) {
                                            setGroupId(value.groupId);
                                            setGroupErrorMessage(null);
                                        } else {
                                            setGroupId('');
                                        }
                                    }}
                                    value={groupList.find((group: { groupId: string }) => group.groupId === groupId) || null} // Set the value of Autocomplete
                                    size="small"
                                />
                            )}
                        </div>

                        <div style={{ height: 400, width: '100%' }}>
                            <DataTable rows={rows} columns={columns} pageSize={10} pageSizeOptions={[5, 10, 15, 20, 25, 30]} disableColumnSelector={false} filter={false} />
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default InviteModal;
