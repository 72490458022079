import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n/config';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
declare global {
  var piAId: string;
  var piCId: string;
  var piHostname: string;
}

window.piAId = '1058633'; 
window.piCId = '242709'; 
window.piHostname = 'mkt.appsealing.com';

const loadPiScript = () => {
const asyncLoad = () => {
	const scriptElement = document.createElement('script');
	scriptElement.type = 'text/javascript';
	scriptElement.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + piHostname + '/pd.js';
	const firstScript = document.getElementsByTagName('script')[0];
	if (firstScript.parentNode) {
		firstScript.parentNode.insertBefore(scriptElement, firstScript);
	}
};

window.addEventListener('load', asyncLoad, false);
};

const Index = () => {
  useEffect(() => {
    // Google Tag Manager
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-XM5S7W5492';
    document.body.appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments); } 
    window.gtag('js', new Date());
    window.gtag('config', 'G-XM5S7W5492');

    loadPiScript(); 
  }, []);

  return <App />;
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(<Index />);

const clearbitScript = document.createElement('script');
clearbitScript.src = 'https://tag.clearbitscripts.com/v1/pk_727dd43bc9d58d45b64833b71857aa3d/tags.js';
clearbitScript.referrerPolicy = 'strict-origin-when-cross-origin';
document.head.appendChild(clearbitScript);

reportWebVitals();