import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import moment
 from "moment";
type Field = {
  name: string;
  label: string;
  type: "input" | "select" | "datepicker" | "number";
  options?: { [key: string]: { text: string; color?: string } };
  value: string | number | Date | moment.Moment;
  required: boolean;
  errorMessage?: string;
  placeholder?: string;
};

export const InvoiceStatusCode: any = {
  S: { text: "Sent", color: "blue" },
  P: { text: "Paid", color: "green" },
  O: { text: "Overdue", color: "red" },
  C: { text: "Cancelled", color: "grey" },
  CR: { text: "Cancel Requested", color: "lightsalmon" },
  PL: { text: "Payment link ready", color: "orange" },
  I: { text: "Not Available", color: undefined },
  "N/A": { text: "Not Available", color: undefined },
};

export const TransactionFields: Field[] = [
  {
    name: "TransactionID",
    label: "transaction_id",
    type: "input",
    value: "",
    required: false,
    placeholder: "enter_transaction_id",
  },
  {
    name: "fromDate",
    label: "from_period",
    type: "datepicker",
    value: "",
    required: false,
  },
  {
    name: "toDate",
    label: "to_period",
    type: "datepicker",
    value: "",
    required: false,
  },
];

export const TransactionTableColumns : GridColDef[] = [
  {
    headerName: "transaction_date",
    field: "transactionDate",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return params.value && !isNaN(params.value) ? moment(params.value).format("YYYY-MM-DD HH:mm:ss") : "N/A";
    }
  },
  {
    headerName: "transaction_id",
    field: "TransactionID",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    headerName: "description",
    field: "Description",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const desc = params.value.length > 20 ? params.value : '';
      return <Tooltip title={desc}><p className="text-ellipsis overflow-hidden max-w-[200px] lg:max-w-[400px]">{params.value}</p></Tooltip>
    }
  },
  {
    headerName: "status",
    field: "statusText",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    headerName: "credit_amt",
    field: "CreditPaid",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    headerName: "amount",
    field: "Amount",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return `$${params.value ? parseFloat(params.value)?.toLocaleString() : params.value}`;
    }

  },
];