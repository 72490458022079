import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { changePlanDetails, getPrivacyPolicy, getTermsOfService } from '../../../../../services/manage/billingdetails';
import { RootState } from '../../../../../store';
import { showDialog, showToast } from '../../../../utils/notifications';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

interface AgreementModalInterface {
    visible: boolean;
    platform: string;
    onCancel: () => void;
    fetchAccountInfo: () => void;
}

const AgreementModal = (props: AgreementModalInterface) => {
    const { t } = useTranslation();
    const [serviceTerms, setServiceTerms] = useState<string | null>(null);
    const [privacyPolicy, setPrivacyPolicy] = useState<string | null>(null);

    const { control, setValue, watch } = useForm({
        defaultValues: {
            isTermsAgreed: false,
            isPrivacyAgreed: false,
        },
    });
    const isTermsAgreed = watch('isTermsAgreed');
    const isPrivacyAgreed = watch('isPrivacyAgreed');

    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        if (!props.visible) return;
        setValue('isTermsAgreed', false);
        setValue('isPrivacyAgreed', false);

        if (serviceTerms === null) {
            const fetchTermsOfService = async () => {
                const response = await getTermsOfService();
                if (response) {
                    setServiceTerms(response);
                } else showToast('error', t('failed_to_load_terms_of_service'));
            };
            fetchTermsOfService();
            setServiceTerms('');
        }
        if (privacyPolicy === null) {
            const fetchPrivacyPolicy = async () => {
                const response = await getPrivacyPolicy();
                if (response) setPrivacyPolicy(response);
                else showToast('error', t('failed_to_load_privacy_policy'));
            };
            fetchPrivacyPolicy();
            setPrivacyPolicy('');
        }
    }, [privacyPolicy, props.visible, serviceTerms, setValue, t]);

    useEffect(() => {
        if (serviceTerms === null) {
            const fetchTermsOfService = async () => {
                const response = await getTermsOfService();
                if (response) {
                    setServiceTerms(response);
                } else showToast('error', t('failed_to_load_terms_of_service'));
            };
            fetchTermsOfService();
            setServiceTerms('');
        }
        if (privacyPolicy === null) {
            const fetchPrivacyPolicy = async () => {
                const response = await getPrivacyPolicy();
                if (response) setPrivacyPolicy(response);
                else showToast('error', t('failed_to_load_privacy_policy'));
            };
            fetchPrivacyPolicy();
            setPrivacyPolicy('');
        }
    }, [serviceTerms, privacyPolicy, t]);

    const onUpgradeClick = async () => {
        const params: any = {
            token: authContext.token,
            aos_plan_type: 'P',
            ios_plan_type: 'P',
            hybrid_plan_type: 'P',
        };

        const updateProfessional = async () => {
            const response = await changePlanDetails(params);
            if (response?.result?.code === '0000') {
                showToast('success', t('you_have_now_subscribed_to_profession_plan'));
                props.onCancel();
                props.fetchAccountInfo();
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_update_plan_information')));
            else showToast('error', t('failed_to_update_plan_information'));
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_want_to_upgrade_to_professional_plan'),
            onOk: updateProfessional,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    return (
        <Dialog open={props.visible} onClose={props.onCancel} maxWidth="sm" fullWidth>
            <form onSubmit={onUpgradeClick}>
                <DialogContent>
                    <Typography variant='h1'>{t('upgrade_appsealing_plan')}</Typography>
                    <div className="flex flex-col w-full h-full gap-4">
                        <div className="flex flex-col flex-grow gap-2">
                            <TextField multiline rows={6} value={serviceTerms || ''} variant="outlined" InputProps={{ readOnly: true }} fullWidth />
                            <Controller
                                name="isTermsAgreed"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />}
                                        label={
                                            <>
                                                <span>{t('i_agree_to_the')}</span>
                                                <span className="font-semibold">{t('terms_of_service')}</span>
                                                <span>{t('i_agree_to_the_reverse')}</span>
                                            </>
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="flex flex-col flex-grow gap-2">
                            <TextField multiline rows={6} value={privacyPolicy || ''} variant="outlined" InputProps={{ readOnly: true }} fullWidth />
                            <Controller
                                name="isPrivacyAgreed"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />}
                                        label={
                                            <>
                                                <span>{t('i_agree_to_the')}</span>
                                                <span className="font-semibold">{t('privacy_policy')}</span>
                                                <span>{t('i_agree_to_the_reverse')}</span>
                                            </>
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined'  onClick={props.onCancel} color="secondary">
                        {t('cancel')}
                    </Button>
                    <Button variant='contained' type="submit" color="primary" disabled={!isTermsAgreed || !isPrivacyAgreed}>
                        {t('upgrade_plan')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AgreementModal;
