import React from 'react';
import MUIButton from '@mui/material/Button';


interface ButtonProps {
  variant?: 'text' | 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

export const Button: React.FC<ButtonProps> = ({type = "button",fullWidth = false, children, style, disabled = false, className, variant = "contained", color="primary", size = "medium", label, onClick }) => {
  return (
    <MUIButton  type={type} fullWidth={fullWidth} disabled={disabled} style={style} className={className} variant={variant} color={disabled ? undefined : color} onClick={onClick} size={size}>
      {children ? children : <span>{label}</span>}
    </MUIButton>
  );
};