import { Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import EastIcon from '@mui/icons-material/East';
import { useTranslation } from "react-i18next";
interface ViewMoreProps {
  tooltip: string;
  path: string;
  label: string;
  type: string;
}

const ViewMore = ({tooltip, path, label, type} : ViewMoreProps) => {
  const { t } = useTranslation();
  const style = type === "absolute" ? "absolute right-[20px] bottom-[20px]" : "flex justify-between w-full";
  return (
    <div className={style}>
          <Tooltip title={tooltip}>
            <div>
              <span className="mr-2 text-sm">{label}</span>
              <span className="hover:underline text-linkblue">
                <Link className="text-linkblue" to={path}>
                  {t("details")}
                  <EastIcon className="text-xs ml-1" fontSize="small" />
                </Link>
              </span>
            </div>
          </Tooltip>
          </div>
  )
}

export default ViewMore;