import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';
import { ResponsiveLine } from '@nivo/line';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getAndroidPackages, getIOSPackages } from '../../../services/data';
import { setAndroidPackages, setIOSPackages } from '../../../store/data';
import { getHackingDataStream, getHackingLogStream } from '../../../services/data/analytics';
import moment from 'moment';
import { setLocalPreferences } from '../../../store/main';
import { getLocalisedDate } from '../../utils';
import { useTranslation } from 'react-i18next';
import DataHeader from '../DataHeader';
import { ChoroplethChartColors, LineChartColors } from '../../utils/common';
import CountryTable from './CountryTable';
import HackingAttemptsTable from './HackingAttemptsTable';
import LogDetailTable from './LogDataTable';
import DataLayout from '../DataLayout';
import { showToast } from '../../utils/notifications';

const currentDate = moment(new Date());

export const getRange = (from: any, to: any, format?: string) => {
    format = format || 'YYYYMMDDHHmmss';
    from = moment(from, 'YYYYMMDDHHmmss').toDate();
    to = moment(to, 'YYYYMMDDHHmmss').toDate();

    let dateArray: Array<any> = [],
        date = from;
    while (date <= to) {
        dateArray.push({ x: moment(date).format('HH:mm'), y: 0 });
        date = moment(date).add(5, 'minute').toDate();
    }
    return dateArray;
};

export const processStreamData = (data: any, countries: Array<any>, processInsights: boolean, lang: string) => {
    lang = lang === 'kr_KR' ? 'kr' : 'en';
    let legendInfo = { min: 0, max: 10, avg: 0, sum: 0 };
    const streamData = (data.hacking_count_stream || []).map((e: any) => {
        const y = e.cnt;
        const utcDate = moment(e.register_datetime, 'YYYYMMDDHHmmss').toDate();
        const date = moment(getLocalisedDate(utcDate));
        if (y < legendInfo.min) legendInfo.min = y;
        else if (y > legendInfo.max) legendInfo.max = y;
        legendInfo.sum = y + legendInfo.sum;

        return {
            ...e,
            y,
            x: date.format('HH:mm'),
        };
    });
    legendInfo.avg = parseInt(legendInfo.sum / (streamData.length || 1) + '');

    let countryData: any = [],
        gridData = data.hacking_data_stream,
        insightsData: any = {
            hackingType: {},
            deviceModel: {},
            emulator: {},
            rooted: 0,
            jailbreak: 0,
        };

    gridData = gridData.map((element: any) => {
        const { country, cnt } = element;
        const existingEl = countryData.find((e: any) => e.country === country);
        const countryEl = countries?.find((item) => element.country?.toLowerCase() === item.country_sortname?.toLowerCase());
        const countryName = countryEl?.[lang === 'kr' ? 'country_name_kr' : 'country_name'] || element.country;
        const country_name = countryEl?.['country_name'] || element.country;
        const country_name_kr = countryEl?.['country_name_kr'] || element.country;

        if (processInsights) {
            if (element.rooting === 'y') insightsData.rooted++;
            if (element.jailbreak === '1') insightsData.jailbreak++;
            if (!insightsData.hackingType[element.desc_en]) insightsData.hackingType[element.desc_en] = 0;
            insightsData.hackingType[element.desc_en]++;
            if (!insightsData.emulator[element.emul_name]) insightsData.emulator[element.emul_name] = 0;
            insightsData.emulator[element.emul_name]++;
            if (!insightsData.deviceModel[element.device_model]) insightsData.deviceModel[element.device_model] = 0;
            insightsData.deviceModel[element.device_model]++;
        }
        if (existingEl) existingEl.total = existingEl.total + cnt;
        else
            countryData.push({
                country,
                countryName,
                country_name,
                country_name_kr,
                total: cnt,
            });

        return {
            ...element,
            countryName,
            country_name,
            country_name_kr,
            key: element.register_datetime + element.device_model + element.app_version_code,
        };
    });

    if (processInsights && gridData.length > 0) {
        let max = 0;
        for (let [key, value] of Object.entries(insightsData.hackingType) as [string, any][]) {
            if (key && value > max) {
                max = value;
                insightsData.hackingType = key;
            }
        }
        if (typeof insightsData.hackingType !== 'string') insightsData.hackingType = ' ';
        max = 0;
        for (let [key, value] of Object.entries(insightsData.emulator) as [string, any][]) {
            if (key && value > max) {
                max = value;
                insightsData.emulator = key;
            }
        }
        if (typeof insightsData.emulator !== 'string') insightsData.emulator = ' ';
        max = 0;
        for (let [key, value] of Object.entries(insightsData.deviceModel) as [string, any][]) {
            if (key && value > max) {
                max = value;
                insightsData.deviceModel = key;
            }
        }
        if (typeof insightsData.deviceModel !== 'string') insightsData.deviceModel = ' ';
        insightsData.rooted = ((insightsData.rooted / gridData.length) * 100).toFixed(2);
        insightsData.jailbreak = ((insightsData.jailbreak / gridData.length) * 100).toFixed(2);
    } else
        insightsData = {
            hackingType: ' ',
            emulator: ' ',
            deviceModel: ' ',
            rooted: 0,
        };

    return {
        streamData: [
            {
                id: 'Inka Entworks',
                data: streamData.reverse(),
            },
        ],
        gridData,
        legendInfo,
        countryData,
        insightsData,
    };
};

export const processCountryData = (hackingTypeData: any, hackingDeviceData: any, insightsData: any) => {
    let minCnt = 0,
        maxCnt = 10,
        data: any = [],
        maxCountry = ' ',
        totalHackingTypes = 0,
        totalHackingDevice = 0;

    hackingTypeData.forEach((e: any) => {
        const hackingDeviceCountry = hackingDeviceData.find((item: any) => item.country === e.country);
        data.push({
            typeCnt: e.total,
            country: e.country,
            countryName: e.countryName,
            country_name: e.country_name,
            country_name_kr: e.country_name_kr,
            deviceCnt: hackingDeviceCountry?.total || 0,
        });
        totalHackingTypes += e.total;
    });

    hackingDeviceData.forEach((e: any) => {
        if (!data.find((item: any) => item.country === e.country)) {
            data.push({
                typeCnt: 0,
                deviceCnt: e.total,
                country: e.country,
                countryName: e.countryName,
            });
        }
        totalHackingDevice += e.total;
    });

    data = data.map((item: any) => {
        const total = item.typeCnt + item.deviceCnt;
        if (item.country && total > maxCnt) {
            maxCnt = total;
            maxCountry = item.countryName;
        }
        if (item.country && total < minCnt) minCnt = total;
        return {
            ...item,
            value: total,
            id: item.country,
            typeDistribution: (item.typeCnt / totalHackingTypes) * 100,
            deviceDistribution: (item.deviceCnt / totalHackingDevice) * 100,
        };
    });
    insightsData['location'] = maxCountry;
    insightsData['totalHackingTypes'] = totalHackingTypes;
    insightsData['totalHackingDevice'] = totalHackingDevice;
    return { data, minCnt, maxCnt, insightsData };
};

let initialInsights = {
    rooted: 0,
    jailbreak: 0,
    emulator: ' ',
    location: ' ',
    hackingType: ' ',
    deviceModel: ' ',
    totalHackingTypes: 0,
    totalHackingDevice: 0,
};

let initialData = [
    {
        id: 'Inka Entworks',
        data: getRange(moment(currentDate).subtract(30, 'minutes').format('YYYYMMDDHHmmss'), moment(currentDate).format('YYYYMMDDHHmmss')),
    },
];

const Analytics = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const hackingAttemptsRef = useRef<any>(null);
    const [loop, setLoop] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [gridData, setGridData] = useState([]);

    const [minDomain, setMinDomain] = useState(0);
    const [maxDomain, setMaxDomain] = useState(10);
    const [countryData, setCountryData] = useState([]);
    const [refreshRate, setRefreshRate] = useState('10');
    const [selectedApp, setSelectedApp] = useState<string | null | undefined>(null);
    const [logGridData, setLogGridData] = useState<any>([]);
    const [insights, setInsights] = useState<any>(initialInsights);
    const [gridSelection, setGridSelection] = useState<string[]>([]);
    const [platform, setPlatform] = useState<'android' | 'ios'>('android');
    const [hackingTypeStreamData, setHackingTypeStreamData] = useState<any>(initialData);
    const [hackingDeviceStreamData, setHackingDeviceStreamData] = useState<any>(initialData);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const [hackingTypeStreamLegend, setHackingTypeStreamLegand] = useState<any>({
        min: 0,
        max: 10,
        avg: 0,
    });
    const [hackingDeviceStreamLegend, setHackingDeviceStreamLegand] = useState<any>({ min: 0, max: 10, avg: 0 });

    const region = useSelector((state: RootState) => state.mainReducer.region);
    const countries = useSelector((state: RootState) => state.commonReducer.countries);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const mapFeatures = useSelector((state: RootState) => state.dataReducer.mapFeatures);

    const iosPackages = useSelector((state: RootState) => state.dataReducer.iosPackages);
    const androidPackages = useSelector((state: RootState) => state.dataReducer.androidPackages);
    const localPreference = useSelector((state: RootState) => state.mainReducer.localPreference);

    const fetchHackingDataStream = useCallback(
        async (package_name: string = '', type: string = 'android') => {
            let params = {
                type,
                package_name,
                group_by_device: false,
                token: authContext.token,
                calc_date_to: moment(new Date()).utc().format('YYYYMMDDHHmmss'),
                calc_date_from: moment(new Date()).subtract(1, 'hour').utc().format('YYYYMMDDHHmmss'),
            };

            let gridData = [],
                hackingTypeCountryData: any = [],
                hackingDevicesCountryData: any = [],
                insightsData: any = { ...initialInsights };

            const responseByType = await getHackingDataStream(params, region);
            if (responseByType?.result?.code === '0000') {
                const data = processStreamData(responseByType, countries, false, i18n.language);
                hackingTypeCountryData = data.countryData;
                setHackingTypeStreamData(data.streamData);
                setHackingTypeStreamLegand(data.legendInfo);
            } else {
                setHackingTypeStreamData(initialData);
                setHackingTypeStreamLegand({ min: 0, max: 10, avg: 0 });
            }

            params['group_by_device'] = true;
            const responseByDevices = await getHackingDataStream(params, region);
            if (responseByDevices?.result?.code === '0000') {
                const data = processStreamData(responseByDevices, countries, true, i18n.language);
                gridData = data.gridData;
                hackingDevicesCountryData = data.countryData;
                insightsData = { ...insightsData, ...data.insightsData };
                setHackingDeviceStreamData(data.streamData);
                setHackingDeviceStreamLegand(data.legendInfo);
            } else {
                setHackingDeviceStreamData(initialData);
                setHackingDeviceStreamLegand({ min: 0, max: 10, avg: 0 });
            }

            const data = processCountryData(hackingTypeCountryData, hackingDevicesCountryData, insightsData);
            setGridSelection([]);
            setGridData(gridData);
            setMinDomain(data.minCnt);
            setMaxDomain(data.maxCnt);
            setCountryData(data.data);
            setInsights(data.insightsData);
        },
        [region, authContext.token, countries, i18n.language],
    );
    
    useEffect(() => {
        if (selectedApp && platform) setData(null);
    } , [selectedApp, platform]);

    useEffect(() => {
        if (localPreference && Array.isArray(iosPackages) && Array.isArray(androidPackages) && data === null) {
            if (localPreference.platform) setPlatform(localPreference.platform);
            if (localPreference.platform === 'android' && androidPackages.find((e) => e.package_name === localPreference.android_pkg)) {
                setSelectedApp(localPreference.android_pkg);
                fetchHackingDataStream(localPreference.android_pkg, 'android');
            } else if (localPreference.platform === 'ios' && iosPackages.find((e) => e.bundle_id === localPreference.ios_pkg)) {
                setSelectedApp(localPreference.ios_pkg);
                fetchHackingDataStream(localPreference.ios_pkg, 'ios');
            }
            if (['1', '5', '10'].includes(localPreference.refreshRate || '10')) setRefreshRate(localPreference.refreshRate || '10');
            setData(localPreference);
        }
    }, [localPreference, data, androidPackages, iosPackages, fetchHackingDataStream]);

    useEffect(() => {
        if (selectedApp && refreshRate) {
            const newPref = {
                ...data,
                platform,
                refreshRate: refreshRate,
                [platform === 'android' ? 'android_pkg' : 'ios_pkg']: selectedApp,
            };
            dispatch(setLocalPreferences(newPref));
            localStorage.setItem('user_preferences', JSON.stringify(newPref));
        }
    }, [selectedApp, data, refreshRate, platform, dispatch]);

    useEffect(() => {
        if (iosPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getIOSPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataBundleIdList)) {
                    dispatch(setIOSPackages([...response.dataBundleIdList]));
                }
            };
            fetchPackages();
        }
    }, [region, iosPackages, authContext.token, dispatch]);

    useEffect(() => {
        if (androidPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getAndroidPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataPackageList)) {
                    dispatch(setAndroidPackages([...response.dataPackageList]));
                }
            };
            fetchPackages();
        }
    }, [region, androidPackages, authContext.token, dispatch]);

    useEffect(() => {
        if (loop === null && refreshRate && selectedApp && platform) {
            setLoop(
                setInterval(
                    () => {
                        fetchHackingDataStream(selectedApp, platform);
                    },
                    parseInt(refreshRate) * 60000,
                ),
            );
        }
        if (loop) {
            return () => clearInterval(loop);
        }
    }, [loop, refreshRate, selectedApp, platform, fetchHackingDataStream]);

    useEffect(() => {
        if (selectedApp && gridData.length && gridSelection?.length && platform) {
            const record = gridData.find((e: any) => e.key === gridSelection[0]);
            if (record) {
                const fetchLogData = async (record: any) => {
                    const { country, core_version, sdk_version, device_model, register_datetime } = record;
                    const params = {
                        sdk_version,
                        core_version,
                        country,
                        device_model,
                        type: platform,
                        package_name: selectedApp,
                        reportEndDT: register_datetime,
                        reportStDT: moment(register_datetime, 'YYYYMMDDHHmmss').subtract(3, 'd').format('YYYYMMDDHHmmss'),
                        token: authContext.token,
                    };
                    const response = await getHackingLogStream(params, region);
                    if (Array.isArray(response?.hacking_log_stream))
                        setLogGridData(
                            response?.hacking_log_stream.map((e: any) => ({
                                ...e,
                                id: e.register_datetime + e.android_raw_id + e.app_version_code,
                                key: e.register_datetime + e.android_raw_id + e.app_version_code,
                            })),
                        );
                };
                fetchLogData(record);
            }
        }
    }, [region, gridSelection, platform, gridData, authContext.token, selectedApp]);

    const onRefreshRateChange = (e: any) => {
        if (loop) clearInterval(loop);
        setRefreshRate(e.target.value);
        setLoop(null);
    };

    const onSelectedChange = (value: any) => {
        if (value) fetchHackingDataStream(value, platform);
        if (loop) clearInterval(loop);
        setLoop(null);
        setSelectedApp(value);
    };

    const onShowDetailsClick = (record: any) => {
        if (planDetails.plan?.android?.type === 'E' || planDetails.plan?.android?.type === 'P' || planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') {
            setGridSelection([record.key]);
            setTimeout(() => {
                hackingAttemptsRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 2000);
            
        } else showToast('error', t('device_information_feature_is_available_only_with_professional_or_enterprise_plan'));
    };
   
    return (
        <DataLayout>
            <DataHeader
                title={t('realTimeThreatAnalytics')}
                subtitle={t('real_time_hacking_detection_data_streaming')}
                selectedApp={selectedApp ? (platform === 'android' ? androidPackages?.find((e) => e.package_name === selectedApp) : iosPackages?.find((e) => e.bundle_id === selectedApp)) : null}
                setSelectedApp={onSelectedChange}
                packages={(platform === 'android' ? androidPackages?.map((item, i) => ({ ...item, key: i })) : iosPackages?.map((item, i) => ({ ...item, key: i }))) || null}
                platform={platform}
                setPlatform={setPlatform}
                refreshRate={refreshRate}
                onRefreshRateChange={onRefreshRateChange}
            />
            <div className={`col-span-12 flex flex-col py-5 px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] mb-2.5`}>
                <div>
                    <p className="text-base font-medium truncate w-full">{androidPackages?.find((e) => selectedApp === e.package_name)?.app_name || selectedApp || t('application_name')}</p>
                </div>
                <div className="flex flex-none gap-12 flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-4/12">
                        <p className="text-[12px]">{selectedApp}</p>
                        <div className="flex mt-[18px] gap-10">
                            <div>
                                <p className="text-subtitle text-[12px]">{t('hacking_attempts')}</p>
                                <p className="text-infored text-2.5xl font-medium">{insights.totalHackingTypes.toLocaleString()}</p>
                            </div>
                            <div>
                                <p className="text-subtitle text-[12px]">{t('unique_devices_hacking_attempts')}</p>
                                <p className="text-infored text-2.5xl font-medium">{insights.totalHackingDevice.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12">
                        <p className="text-[12px] font-medium">{t('popular_insights')}</p>
                        <div className="flex justify-between flex-none mt-[18px]">
                            <div>
                                <p className="text-subtitle text-[12px] mb-2">{t('hacking_type')}</p>
                                <p>{insights.hackingType.trim() ? insights.hackingType : '-'}</p>
                            </div>
                            <div>
                                <p className="text-subtitle text-[12px] mb-2">{t('location')}</p>
                                <p>{insights.location.trim() ? insights.location : '-'}</p>
                            </div>
                            <div>
                                <p className="text-subtitle text-[12px] mb-2">{t('device')}</p>
                                <p>{insights.deviceModel.trim() ? insights.deviceModel : '-'}</p>
                            </div>
                            <div>
                                <p className="text-subtitle text-[12px] mb-2">{t('emulator')}</p>
                                <p>{insights.emulator.trim() ? insights.emulator : '-'}</p>
                            </div>
                            <div>
                                <p className="text-subtitle text-[12px] mb-2">{t('rooting_enabled')}</p>
                                <p>{insights.rooted}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-wrap 2xl:flex-nowrap gap-y-4 gap-x-2.5 md:h-2/3 3xl:h-1/2 sm:h-full">
                    <div className="w-full 2xl:w-1/2 p-4 bg-primary flex flex-col rounded-[5px] border border-[#E0E0E0]">
                        <p className="text-base cursor-pointer mb-3.5">{t('hacking_types_straming')}</p>
                        <div className="flex-grow p-2 min-h-24">
                            <ResponsiveLine
                                data={hackingTypeStreamData}
                                animate={true}
                                curve="linear"
                                useMesh={true}
                                enableGridX={false}
                                pointBorderWidth={1}
                                pointLabelYOffset={-12}
                                colors={LineChartColors[0]}
                                pointColor="white"
                                pointBorderColor={{ from: 'serieColor' }}
                                margin={{ top: 10, right: 25, bottom: 50, left: 35 }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    tickValues: 10,
                                    legend: 'Time Period',
                                    legendOffset: 40,
                                    legendPosition: 'middle',
                                    format: (value: any) => (value && parseInt(moment(value, 'HH:mm').format('mm')) % 3 === 0 ? value : ''),
                                }}
                                yScale={{
                                    type: 'linear',
                                    max: hackingTypeStreamLegend.max + 0.1 * hackingTypeStreamLegend.max,
                                }}
                                tooltip={(e: any) =>
                                    e.point.data ? (
                                        <div className="bg-primary p-2 border border-solid">
                                            <p className="font-semibold truncate max-w-sm">{e.point.data.package_name || e.point.data.bundle_id}</p>
                                            <p>
                                                {t('no_of_hacking_types')} : <span className={`text-[${LineChartColors[0]}] font-semibold`}>{`${e.point.data.y}`}</span>
                                            </p>
                                            <p>
                                                {t('time_of_detection')} : <span className={`text-[${LineChartColors[0]}] font-semibold`}>{`${e.point.data.x}`}</span>
                                            </p>
                                        </div>
                                    ) : (
                                        <p />
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full 2xl:w-1/2 p-4 bg-primary flex flex-col rounded-[5px] border border-[#E0E0E0]">
                        <p className="text-base cursor-pointer mb-3.5">{t('protected_unique_devices_data')}</p>
                        <div className="flex-grow p-2 min-h-24">
                            <ResponsiveLine
                                data={hackingDeviceStreamData}
                                animate={true}
                                useMesh={true}
                                enableGridX={false}
                                pointBorderWidth={1}
                                pointLabelYOffset={-12}
                                colors={LineChartColors[1]}
                                pointColor="white"
                                pointBorderColor={{ from: 'serieColor' }}
                                margin={{ top: 10, right: 25, bottom: 50, left: 25 }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    tickValues: 10,
                                    legend: 'Time Period',
                                    legendOffset: 40,
                                    legendPosition: 'middle',
                                    format: (value: any) => (value && parseInt(moment(value, 'HH:mm').format('mm')) % 3 === 0 ? value : ''),
                                }}
                                yScale={{
                                    type: 'linear',
                                    max: hackingDeviceStreamLegend.max + 0.1 * hackingDeviceStreamLegend.max,
                                }}
                                tooltip={(e: any) =>
                                    e.point.data ? (
                                        <div className="bg-primary p-2 border border-solid">
                                            <p className="font-semibold truncate max-w-sm">{e.point.data.package_name || e.point.data.bundle_id}</p>
                                            <p>
                                                {t('no_of_hacking_types')} : <span className={`text-[${LineChartColors[1]}] font-semibold`}>{`${e.point.data.y}`}</span>
                                            </p>
                                            <p>
                                                {t('time_of_detection')} : <span className={`text-[${LineChartColors[1]}] font-semibold`}>{`${e.point.data.x}`}</span>
                                            </p>
                                        </div>
                                    ) : (
                                        <p />
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full md:h-2/3 3xl:h-1/2 sm:h-full grid 1xl:grid-cols-12 grid-cols-6 gap-2.5 mb-4 mt-2.5">
                <div className="bg-primary flex flex-col p-4 col-span-6 relative rounded-[5px] border border-[#E0E0E0]">
                    <p className="text-base cursor-pointer mb-3.5">{t('hacking_attempts_by_map_overlay')}</p>
                    <div className="flex-grow min-h-[350px] chart-map-wrap">
                        <ResponsiveChoropleth
                            data={countryData}
                            features={mapFeatures}
                            colors={ChoroplethChartColors}
                            domain={[minDomain, maxDomain]}
                            unknownColor="#f2f2f2"
                            label="properties.name"
                            fillColor="#000"
                            valueFormat=".1s"
                            projectionScale={70}
                            borderWidth={0.3}
                            borderColor="#152538"
                            projectionTranslation={[0.5, 0.6]}
                            tooltip={(e: any) =>
                                e.feature?.data ? (
                                    <div className="bg-primary p-2 border border-solid">
                                        <p className="font-semibold truncate max-w-sm">{i18n.language !== 'kr_KR' ? e.feature.data.country_name : e.feature.data.country_name_kr}</p>
                                        <p>
                                            {t('no_of_hacking_types')} : <span className="font-semibold">{`${e.feature.data.typeCnt} (${e.feature.data.typeDistribution?.toFixed(2)}%)`}</span>
                                        </p>
                                    </div>
                                ) : (
                                    <p />
                                )
                            }
                        />
                    </div>
                </div>
                <div className="bg-primary flex flex-col p-4 col-span-6 rounded-[5px] border border-[#E0E0E0]">
                    <p className="text-base cursor-pointer mb-3.5">{t('hacking_attempts_by_map_overlay')}</p>
                    <div className="flex-grow overflow-auto">
                        <CountryTable countryData={countryData} />
                    </div>
                </div>
            </div>
            <div className={`col-span-12 flex flex-col py-5 px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] mb-2.5`}>
                <p className="text-base cursor-pointer mb-3.5">{t('list_of_hacking_attempts')}</p>
                <div className='overflow-x-auto'><HackingAttemptsTable rows={gridData} platform={platform} onShowDetailsClick={onShowDetailsClick} /></div>
            </div>
            {logGridData.length > 0 && (
                <div className={`col-span-12 flex flex-col py-5 px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] mb-2.5 min-w-[800px]`}>
                    <p className="text-base cursor-pointer mb-3.5">{t('hacking_attempts_by_device_information')}</p>
                    <LogDetailTable rows={logGridData} platform={platform} />
                </div>
            )}
            <div ref={hackingAttemptsRef}/>
        </DataLayout>
    );
};

export default Analytics;
