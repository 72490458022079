import { useTranslation } from 'react-i18next';
import { openHelpCenter, submitFeedback } from '../../services/main';
import { AppURL } from '../../utility/AppURL';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FeedbackModalMui from './feedbackMui';
import FeedbackButton from './feedbackBtn';
import { getCookies } from '../../utility/Common';
import { showToast } from '../utils/notifications';

const MainFooter = ({ color }: { color: string }) => {
    const { i18n, t } = useTranslation();
    const [isFeedbackBtnVisible, setIsFeedbackBtnVisible] = useState(false);
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const privacyTermUrl = i18n.language === 'en_US' ? AppURL.privacyterms : AppURL.privacyterms_kr;

    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        const HideFeedbackForMonth = getCookies('HideFeedbackForMonth');
        const feedbackButtonClosed = sessionStorage.getItem('FeedbackButtonClosed');
        if (HideFeedbackForMonth || feedbackButtonClosed) {
            setIsFeedbackBtnVisible(false);
        } else {
            setIsFeedbackBtnVisible(true);
        }
    }, []);

    const handleOpenModal = () => setIsModalVisible(true);
    const handleCloseModal = () => setIsModalVisible(false);

    const onHelpDeskClick = () => {
        if (authContext.token) {
            openHelpCenter(authContext.token, AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'));
        } else {
            window.open(AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'), '_blank');
        }
    };

    const getRatings = (values: any) => {
        let ratings = '';
        for (let key in values) {
            if (key !== 'feedback') {
                ratings += `${key} = ${values[key]}/5 \n`;
            }
        }
        return ratings;
    };
    const onSubmitFeedback = async (values: any) => {
        let payload = `
        >>> ${userDetails.user_id}
        Rating = ${getRatings(values)}
        at = ${location.pathname}
        Feedback = ${values.feedback} 
      `;

        const response = await submitFeedback({ payload });
        if (response?.result?.code === '0000') {
            showToast('success', t('thank_you_for_your_valuable_feedback_we_are_looking_into_it'));
        } else {
            showToast('error', t('issue_while_submitting_feedback_contact_help_desk_for_more_information'));
        }

        setIsModalVisible(false);
        setIsFeedbackBtnVisible(false);
    };

    return (
        <>
            <FeedbackModalMui isVisible={isModalVisible} onClose={handleCloseModal} onSubmit={onSubmitFeedback} />
            <footer className={`text-center mt-10 mb-4 text-[10px] appsealing-footer text-${color}`}>
                <p>
                    ©2024 INKA | AppSealing |{' '}
                    <span className={`${color === 'white' ? 'text-white' : 'text-accent'} cursor-pointer hover:underline`} onClick={onHelpDeskClick}>
                        {t('contact_us')}
                    </span>{' '}
                    |{' '}
                    <a href={privacyTermUrl} target="_blank" rel="noreferrer" className="hover:underline" style={{ color: color === 'white' ? '#fff' : undefined }}>
                        {t('footer_service_terms')}
                    </a>{' '}
                    |{' '}
                    <a href={`${privacyTermUrl}/#tab-1580458933031-5-2`} target="_blank" rel="noreferrer" className="hover:underline" style={{ color: color === 'white' ? '#fff' : undefined }}>
                        {t('footer_privacy_policy')}
                    </a>{' '}
                    {authContext.token && (
                        <>
                            |{' '}
                            <span className="cursor-pointer" style={{ color: color === 'white' ? '#fff' : '#8E44AD' }} onClick={handleOpenModal}>
                                {t('send_feedback')}
                            </span>
                        </>
                    )}
                </p>
            </footer>
            {authContext.token && <FeedbackButton visible={isFeedbackBtnVisible} handleOpenModal={handleOpenModal} />}
        </>
    );
};

export default MainFooter;
