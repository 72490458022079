import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";


const LanguageSelector = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState<string>(localStorage.getItem('user-lang') || 'en_US');
  const { i18n, t } = useTranslation();


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedLocale: string) => {
    setLang(selectedLocale);
    localStorage.setItem('user-lang', selectedLocale);
    i18n.changeLanguage(selectedLocale);
    handleClose();
  };

  return (
    <>
      <Button
        className="flex items-center text-darkgray"
        size="small"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
       {t('language')}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => handleSelect(lang === "en_US" ? "kr_KR" : "en_US")}
        >
          {lang === "en_US" ? "한국어" : "English"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelector;
