import { Get } from '../..';
import { GetUserLogInterface } from '../../../type/api/manage';
import { AppURL } from '../../../utility/AppURL';

export const getUserLogs = async (params: GetUserLogInterface) => {
    const { token, startDate, endDate } = params;
    const url = `${AppURL.userlogs}?startDate=${startDate}&endDate=${endDate}`;
    const headers = {
        'as-token': token,
    };
    try {
        const response = await Get(url, null, headers);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
