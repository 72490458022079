import { Post } from "..";
import { AppURL } from "../../utility/AppURL";

export const getCountries = async (token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);

    const response = await Post(
        AppURL.country, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });

    let results = response?.result?.code === '0000' ? response.countryList : [];
    results = results.map((item: any) => ({ ...item, country_id: `${item.country_id}` }));
    return results;
};

export const getStates = async (country_id: string, token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('country_id', country_id);
    urlParams.append('token', token);

    const response = await Post(
        AppURL.state, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });

    let states = [];
    if (response?.result?.code === '0000' && Array.isArray(response?.stateList))
        states = response.stateList;
    return states.map((item: any) => ({ ...item, state_id: `${item.state_id}` }));
};

export const getCities = async (state_id: string, token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('state_id', state_id);
    urlParams.append('token', token);

    const response = await Post(
        AppURL.city, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    let cities = [];
    if (response?.result?.code === '0000' && Array.isArray(response?.cityList))
        cities = response.cityList;
    return cities.map((item: any) => ({ ...item, city_id: `${item.city_id}` }));
};

export const getAppCategories = async () => {
    const response = await Post(
        AppURL.appcategory
    ).then(res => res.json()
    ).catch(() => {

    });
    let results = response?.result?.code === '0000' ? response.appCategoryList : [];
    results = results.map((item: any) => ({ ...item, category_id: `${item.category_id}` }));
    return results;
};

export const getTimezones = async () => {
    const response = await Post(
        AppURL.timezone
    ).then(res => res.json()
    ).catch(() => {

    });
    let results = response?.result?.code === '0000' ? response.timezoneCode : [];
    results = results.map((item: any) => ({ ...item, timezone_code: `${item.timezone_code}` }));
    return results;
};

export const getNotifications = async (token: string) => {
    let urlParams = new URLSearchParams();
    urlParams.append('count', '5');
    urlParams.append('token', token);
    urlParams.append('count', '5');
    const response = await Post(
        AppURL.notifycheck, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });

    let results = response?.result?.code === '0000' && Array.isArray(response?.NotifyList) ? response.NotifyList : [];
    const tags = response?.result?.code === '0000' && Array.isArray(response?.NotifyTagList) ? response.NotifyTagList : [];

    results = results.map((item: any) => {
        const ntypescode = item.notify_type.split(',');
        return ({ ...item, ntypescode });
    })
    return { list: results, tags };
};

export const getLocalPreferences = () => {
    let preferences: any = localStorage.getItem('user_preferences');
    try {
        preferences = JSON.parse(preferences);
    } catch (error) {
        preferences = {};
    }
    return preferences;
};

export const fetchCommonData = async (token: string) => {
    const countries = await getCountries(token);
    const timezones = await getTimezones();
    const appCategories = await getAppCategories();
    const notifications = await getNotifications(token);
    const localPreference = getLocalPreferences();

    return { countries, appCategories, timezones, notifications, localPreference };
};

export const getProfileInfo = async (token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(
        AppURL.profile, urlParams
    ).then(res => res.json()
    ).catch(() => {

    });

    return response;
};

export const downloadCLITool = () => {
    window.open(AppURL.clitool, '_blank');
};

export const submitFeedback = async (params: { payload: string }) => {
    let { payload } = params,
        urlParams = new URLSearchParams();

    urlParams.append('payload', payload);

    let response = await Post(
        AppURL.sendfeedback, urlParams
    ).then(res => res.json());

    return response;
};

export const changeServiceRegion = async (default_service_region: string, token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('default_service_region', default_service_region);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.changeserviceregion, urlParams
    ).then(res => res.json());

    return response;
};

const loginToHelpCenter = async (token: string) => {
    let urlParams = new URLSearchParams();

    urlParams.append('sso_auth_type', 'token');
    urlParams.append('token', token);

    let response = await Post(
        AppURL.ssoLogin, urlParams
    ).then(res => res.json());

    return response;
};

export const openHelpCenter = async (token: string, redirectUrl?: string, target?: string) => {
    target = target || 'blank';
    const ssoURLResponse = await loginToHelpCenter(token);
    if (ssoURLResponse?.result?.code === '0000' && ssoURLResponse?.sso_redirect_url) {
        const url = redirectUrl ? `${ssoURLResponse?.sso_redirect_url}&return_to=${redirectUrl}` : ssoURLResponse?.sso_redirect_url;
        window.open(url, target);
    }
    else
        window.open(redirectUrl, target);

};