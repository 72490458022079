import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setCookies } from '../../../utility/Common';
import LanguageSelector from '../languageselector';
import { Link } from 'react-router-dom';

interface PasswordExpiryProps {
    isExpired: boolean;
    expirationDays: number;
    updateLater: () => void;
}

const PasswordExpiry = ({ isExpired, expirationDays, updateLater }: PasswordExpiryProps) => {
    const { t } = useTranslation();

    const ButtonElements = (
        <>
            {isExpired ? (
                <Link to="/updatepassword" className="w-full">
                    <Button variant="contained" fullWidth className="text-xs h-10">
                        {t('update_now')}
                    </Button>
                </Link>
            ) : (
                <div className="flex gap-4">
                    <Button className="text-xs h-10" variant="outlined" fullWidth color="secondary" onClick={updateLater}>
                        {t('update_later')}
                    </Button>
                    <Link to="/updatepassword" className='w-full'>
                        <Button variant="contained" className="text-xs h-10" fullWidth color="primary">
                            {t('update_now')}
                        </Button>
                    </Link>
                </div>
            )}
        </>
    );

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCookies('dontShowUntilExpired', event.target.checked as unknown as string);
    };

    return (
        <div className="pt-[120px] px-5 md:px-[80px] pb-8">
            <div className={`text-center`}>
                <Typography variant="h1">{t('login')}</Typography>
            </div>
            <div className="my-20">{isExpired ? <p>{t('password_expired_msg')}</p> : <p>{t('password_expires_soon_msg', { days: expirationDays })}</p>}</div>
            <div>{ButtonElements}</div>
            <div className={`flex ${isExpired ? 'justify-end' : 'justify-between'}`}>
                {!isExpired && <FormControlLabel control={<Checkbox size="small" onChange={handleCheckboxChange} />} label={t('disable_until_expires')} />}
                <LanguageSelector />
            </div>
            <div className={`uppercase w-full text-[8px] text-center absolute bottom-[20px] left-0`}>{t('copyright_inka_entworks_all_rights_reserved')}</div>
        </div>
    );
};

export default PasswordExpiry;
