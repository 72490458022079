import { ModalConfig } from '../../../../../type/ios';

export const iosModalJson: { [key: string]: ModalConfig } = {
    CreateIOSProject: {
        title: 'new_ios_appsealing_project',
        subtitle: 'new_ios_appsealing_project_subtitle',
        size: 'md',
        footer: [
            {
                type: 'icon',
                icon: 'refresh',
            },
            {
                type: 'button',
                label: 'create',
            },
        ],
        items: [
            {
                label: 'project_name',
                tooltip: 'project_name_for_ios_application',
                type: 'input',
                value: '',
                placeholder: 'enter_project_name',
                error: 'please_enter_project_name',
            },
            {
                label: 'bundle_id',
                tooltip: 'bundle_id_prefix_for_an_ios_application',
                suffix: '.*',
                value: '',
                type: 'input',
                placeholder: 'com.example',
                error: 'please_enter_project_name',
            },
        ],
        notes: 'create_ios_project_note',
    },
};

export const appFrameworks = [
    { name: 'Xcode & Flutter', type: 'native', service_type: 'NATIVE_IOS', detail: 'xcode' },
    { name: 'Unity', type: 'native', service_type: 'NATIVE_IOS', detail: 'unity' },
    { name: 'Unreal', type: 'native', service_type: 'NATIVE_IOS', detail: 'unreal' },
    { name: 'Xamarin', type: 'native', service_type: 'NATIVE_IOS', detail: 'Xamarin' },
    { name: 'React Native', type: 'hybrid', service_type: 'HYBRID_IOS', detail: 'ReactNative' },
    { name: 'Ionic', type: 'hybrid', service_type: 'HYBRID_IOS', detail: 'Ionic' },
    { name: 'Cordova', type: 'hybrid', service_type: 'HYBRID_IOS', detail: 'Cordova' },
];
