import * as React from 'react';
import { DataGrid, GridColDef, GridColumnGroupingModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { DataGridLocaleText } from './data';
import { Box } from '@mui/material';

interface DataTableProps {
    checkboxSelection?: boolean;
    rows: any[] | null;
    columns: GridColDef[];
    pageSize?: number;
    pageSizeOptions?: number[];
    height?: number | string;
    hideFooter?: boolean;
    columnGroupingModel?: GridColumnGroupingModel;
    filter?: boolean;
    disableColumnSelector?: boolean;
    getRowId?: any;
    sorting?:any;
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const DataTable = ({
    disableColumnSelector = true,
    filter = false,
    columnGroupingModel,
    hideFooter,
    checkboxSelection,
    height,
    rows,
    columns,
    pageSize = 10,
    pageSizeOptions = [10, 15, 20, 30],
    getRowId,
    sorting,
}: DataTableProps) => {
    const { t } = useTranslation();

    const DataTableLocaleText = DataGridLocaleText.reduce((acc, text) => {
        return {
            ...acc,
            [text]: t(text),
        };
    }, {});

    return (
        <div style={{ minHeight: '600px' }} id="column-selector">
            <DataGrid
                sx={{
                    border: 'none',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                        fontSize: '12px',
                        whiteSpace: 'pre-wrap',
                    },
                }}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: t('footerPaginationRowsPerPage'),
                    },
                    panel: {
                        className: 'left-auto top-[-60px] right-[150px] md:right-[400px] max-w-[510px]',
                        sx: {
                            '& .MuiDataGrid-columnsManagement': {
                                maxHeight: '200px',
                            },
                        },
                    },
                }}
                localeText={DataTableLocaleText}
                autoHeight
                rows={rows || []}
                columns={columns}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: pageSize },
                    },
                    sorting: sorting ? {
                        sortModel: [sorting]
                    } : undefined,
                }}
                slots={{ toolbar: filter ? CustomToolbar : null }}
                pageSizeOptions={pageSizeOptions}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                disableColumnSelector={disableColumnSelector}
                hideFooter={hideFooter}
                columnGroupingModel={columnGroupingModel ? columnGroupingModel : undefined}
                getRowId={getRowId ?? null}
            />
        </div>
    );
};

export default DataTable;
