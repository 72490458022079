import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Serie } from '@nivo/line';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getAndroidPackages, getIOSPackages } from '../../../services/data';
import { setAndroidPackages, setIOSPackages } from '../../../store/data';
import { getDashboardData } from '../../../services/data/dashboard';
import { getHackingData, getPieChartData } from '../../../services/data/hackingdetection';
import { getRange, processStreamData } from '../analytics';
import { getHackingDataStream } from '../../../services/data/analytics';
import { setLocalPreferences } from '../../../store/main';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/config'
import DataHeader from '../DataHeader';
import ThreatAnlysis from './sections/ThreatAnlysis';
import HackingTypesChart from './sections/HackingTypesChart';
import HackingDetectionByCountry from './sections/HackingDetectionsByCountry';
import HackingDetectionOverview from './sections/HackingDetectionOverview';
import MonthTrendBarChart from './sections/MonthTrendBarChart';
import HackingTypeBarChart from './sections/HackingTypesBarChart';
import MonthlyActiveChart from './sections/MonthlyActiveDevices';
import TopOperatingSystemsCompromised from './sections/TopOperatingSystemsCompromised';
import DataLayout from '../DataLayout';

export const hackingTypeKeys = [
    'Android emulator', 'Code modification', 'Detour security module', 'Repackaged application', 'Rooted device', 'ETC', 'Speed modification',
    'Debugging', 'Cheat tool',
    'Bypass security module', 'Dynamic memory cracking', 'Jailbreak', 'Repackaged app'];

interface AnalyticsInterface {
    activeDevice: number;
    deviceAttempts: number;
    hackingAttempts: number;
    activeDeviceChange: number;
    deviceAttemptsChange: number;
    hackingAttemptsChange: number;
};
interface DashboardDataInterface {
    analytics: AnalyticsInterface;
    countryLegend: any;
    osData: Array<any>;
    countryData: Array<any>;
    chartData: { [key: string]: Serie[] };
};


export const processDashboardResponse = (response?: any, countries?: any, skipCompare?: boolean, lang?: string) => {
    lang = (lang === 'kr_KR' ? 'kr' : 'en');
    let data: DashboardDataInterface = {
        osData: [],
        countryData: [],
        chartData: {
            activeDevices: [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: []
            }],
            hackingAttempts: [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: []
            }],
            deviceAttempts: [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: []
            }],
            monthlyDevices: [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: []
            }],
        },
        analytics: {
            activeDevice: 0,
            deviceAttempts: 0,
            hackingAttempts: 0,
            activeDeviceChange: 0,
            deviceAttemptsChange: 0,
            hackingAttemptsChange: 0
        },
        countryLegend: {
            minDomain: 0,
            maxDomain: 50000
        }
    };
    let minCnt = 0,
        maxCnt = 50000,
        totalDeviceCnt = 0,
        totalActiveCnt = 0,
        totalHackingCnt = 0,
        totalHackingTypes = 0,
        totalHackingDevice = 0;

    if (response?.result?.code === '0000') {
        if (Array.isArray(response.os_version))
            data.osData = response.os_version.sort((a: any, b: any) => b.h_cnt - a.h_cnt).slice(0, 6);
        if (Array.isArray(response.country)) {
            let countryData: any = [];
            response.country.forEach((element: any) => {
                const countryCode = (element.h_type || '').split('_')[0];
                const countryName = countries?.find((item: any) => countryCode.toLowerCase() === item.country_sortname.toLowerCase())?.[lang === 'kr' ? 'country_name_kr' : 'country_name'] || (element.h_type || '').split('_')[1] || countryCode;
                totalHackingTypes += element.h_cnt;
                totalHackingDevice += element.d_cnt;
                countryData.push({
                    ...element,
                    countryName,
                    value: element.h_cnt,
                    id: (countryCode || '').toLowerCase()
                });
            });
            data.countryData = countryData.map((item: any) => {
                const total = item.typeCnt + item.deviceCnt;
                if (item.country && total > maxCnt)
                    maxCnt = total;
                if (item.country && total < minCnt)
                    minCnt = total;
                return {
                    ...item,
                    typeDistribution: (item.h_cnt / totalHackingTypes * 100).toFixed(2),
                    deviceDistribution: (item.d_cnt / totalHackingDevice * 100).toFixed(2)
                };
            }).sort((a: any, b: any) => b.value - a.value);
            data.countryLegend.minDomain = minCnt;
            data.countryLegend.maxDomain = maxCnt;
        }
        if (Array.isArray(response.dataList)) {
            let activeDevices: any = [],
                deviceAttempts: any = [],
                monthlyDevices: any = [],
                hackingAttempts: any = [];
            response.dataList.forEach((item: any) => {
                totalActiveCnt += parseInt(item.dau_cnt) || 0;
                totalDeviceCnt += parseInt(item.dah_cnt) || 0;
                totalHackingCnt += parseInt(item.dth_cnt) || 0;
                activeDevices.push({
                    ...item,
                    y: parseInt(item.dau_cnt) || 0,
                    x: skipCompare ? moment(item.calc_date, 'YYYY-MM-DD').format('MM-DD') : moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                hackingAttempts.push({
                    ...item,
                    y: parseInt(item.dth_cnt) || 0,
                    x: skipCompare ? moment(item.calc_date, 'YYYY-MM-DD').format('MM-DD') : moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                deviceAttempts.push({
                    ...item,
                    y: parseInt(item.dah_cnt) || 0,
                    x: skipCompare ? moment(item.calc_date, 'YYYY-MM-DD').format('MM-DD') : moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                monthlyDevices.push({
                    ...item,
                    y: parseInt(item.mau_cnt) || 0,
                    x: skipCompare ? moment(item.calc_date, 'YYYY-MM-DD').format('MM-DD') : moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
            });
            data.chartData.activeDevices = [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: activeDevices
            }];
            data.chartData.hackingAttempts = [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: hackingAttempts
            }];
            data.chartData.deviceAttempts = [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: deviceAttempts
            }];
            data.chartData.monthlyDevices = [{
                id: "CurrentData",
                color: '#B3CDE3',
                data: monthlyDevices
            }];
            data.analytics.activeDevice = totalActiveCnt;
            data.analytics.deviceAttempts = totalDeviceCnt;
            data.analytics.hackingAttempts = totalHackingCnt;
        }
        if (Array.isArray(response.comparedataList) && skipCompare !== true) {
            let oldActiveCnt = 0,
                oldDeviceAttemptCnt = 0,
                oldHackingAttemptCnt = 0;

            let activeDevices: any = [],
                deviceAttempts: any = [],
                monthlyDevices: any = [],
                hackingAttempts: any = [];
            response.comparedataList.forEach((item: any) => {
                oldActiveCnt += parseInt(item.dau_cnt) || 0;
                oldDeviceAttemptCnt += parseInt(item.dah_cnt) || 0;
                oldHackingAttemptCnt += parseInt(item.dth_cnt) || 0;
                activeDevices.push({
                    ...item,
                    y: parseInt(item.dau_cnt) || 0,
                    x: moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                hackingAttempts.push({
                    ...item,
                    y: parseInt(item.dth_cnt) || 0,
                    x: moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                deviceAttempts.push({
                    ...item,
                    y: parseInt(item.dah_cnt) || 0,
                    x: moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
                monthlyDevices.push({
                    ...item,
                    y: parseInt(item.mau_cnt) || 0,
                    x: moment(item.calc_date, 'YYYY-MM-DD').format('DD'),
                });
            });
            data.chartData.activeDevices = [...data.chartData.activeDevices, {
                id: "HistoryData",
                color: '#FAB4AE',
                data: activeDevices
            }];
            data.chartData.hackingAttempts = [...data.chartData.hackingAttempts, {
                id: "HistoryData",
                color: '#FAB4AE',
                data: hackingAttempts
            }];
            data.chartData.deviceAttempts = [...data.chartData.deviceAttempts, {
                id: "HistoryData",
                color: '#FAB4AE',
                data: deviceAttempts
            }];
            data.chartData.monthlyDevices = [...data.chartData.monthlyDevices, {
                id: "HistoryData",
                color: '#FAB4AE',
                data: monthlyDevices
            }];
            data.analytics.activeDeviceChange = (((((totalActiveCnt / response.dataList.length) - (oldActiveCnt / response.comparedataList.length)) / (oldActiveCnt / response.comparedataList.length)) || 0) * 100);
            data.analytics.deviceAttemptsChange = (((((totalDeviceCnt / response.dataList.length) - (oldDeviceAttemptCnt / response.comparedataList.length)) / (oldDeviceAttemptCnt / response.comparedataList.length)) || 0) * 100);
            data.analytics.hackingAttemptsChange = (((((totalHackingCnt / response.dataList.length) - (oldHackingAttemptCnt / response.comparedataList.length)) / (oldHackingAttemptCnt / response.comparedataList.length)) || 0) * 100);
        }
    }
    return data;
};

export const processPieChartDataResponse = (androidResponse?: any, androidMinorResponse?: any, HackingTypeResponse?: any, rootingResponse?: any, hackingPatternResponse?: any, jailBreakRespone?: any) => {
    const processPieData = (response?: any) => {
        let pieData: any = [];
        if (response?.result?.code === '0000' && Array.isArray(response?.hackingAttemptsList)) {
            let chartData = [],
                totalCnt = 0;
            chartData = response.hackingAttemptsList.map((e: any) => {
                totalCnt += e.h_cnt;
                return {
                    ...e,
                    id: e.h_type,
                    label: i18n.t(e.h_type.toLowerCase().replace(/ /g, "_")),
                    value: e.h_cnt
                };
            });
            pieData = chartData.map((e: any) => {
                return ({ ...e, distribution: (Math.round(e.value / totalCnt * 100)).toFixed(2) })
            });
        }
        return pieData;
    };
    const data: { [key: string]: Array<any> } = {
        androidMajor: processPieData(androidResponse),
        androidMinor: processPieData(androidMinorResponse),
        hackingTypes: processPieData(HackingTypeResponse),
        rooting: processPieData(rootingResponse),
        hackingPattern: processPieData(hackingPatternResponse),
        jailBreak: processPieData(jailBreakRespone),
    };
    return data;
};

export const processHackingDataResponse = (response?: any) => {
    let hackingTypes: Array<any> = [],
        hackingAttemptsSumList: Array<any> = [];
    if (response?.result?.code === '0000') {
        if (Array.isArray(response?.hackingAttemptsList)) {
            response?.hackingAttemptsList.forEach((item: any) => {
                const existingItemIndex = hackingTypes.findIndex((e: any) => e.report_date === item.report_date);
                if (existingItemIndex >= 0) {
                    hackingTypes[existingItemIndex] = {
                        ...hackingTypes[existingItemIndex],
                        [`${item['h_type']}`]: item.dth_cnt,
                        [`${item['h_type']}__dth_cnt`]: item.dth_cnt,
                        [`${item['h_type']}__dah_cnt`]: item.dah_cnt
                    }
                }
                else {
                    hackingTypes.push({
                        report_date: item.report_date,
                        [`${item['h_type']}`]: item.dth_cnt,
                        [`${item['h_type']}__dth_cnt`]: item.dth_cnt,
                        [`${item['h_type']}__dah_cnt`]: item.dah_cnt,
                        date: moment(item.report_date, 'YYYYMMDD').format('MM-DD'),
                    });
                }
            });
        }
        if (Array.isArray(response?.hackingAttemptsSumList)) {
            hackingAttemptsSumList = response.hackingAttemptsSumList;
        }
    }
    return { hackingTypes, hackingAttemptsSumList };
};

const processStreamLegend = (responseByType?: any, responseByDevices?: any) => {
    let data: any = {
        byType: {
            total: 0,
            types: [],
            models: []
        },
        byDevice: {
            total: 0,
            types: [],
            models: []
        }
    };
    if (Array.isArray(responseByType?.gridData)) {
        responseByType.gridData.forEach((item: any) => {
            const existingHackingIndex = data.byType.types.findIndex((e: any) => e.key === item.desc_en);
            const existingModelIndex = data.byType.models.findIndex((e: any) => e.key === item.device_model);

            if (existingHackingIndex > -1)
                data.byType.types[existingHackingIndex] = { key: item.desc_en, cnt: item.cnt + data.byType.types[existingHackingIndex]['cnt'] };
            else
                data.byType.types.push({ key: item.desc_en, cnt: item.cnt });
            if (existingModelIndex > -1)
                data.byType.models[existingModelIndex] = { key: item.device_model, cnt: item.cnt + data.byType.models[existingModelIndex]['cnt'] };
            else
                data.byType.models.push({ key: item.device_model, cnt: item.cnt });
            data.byType.total += item.cnt;
            data.byType.types.sort((a: any, b: any) => b.cnt - a.cnt);
            data.byType.models.sort((a: any, b: any) => b.cnt - a.cnt);
        });
    }
    if (Array.isArray(responseByDevices?.gridData)) {
        responseByDevices.gridData.forEach((item: any) => {
            const existingHackingIndex = data.byDevice.types.findIndex((e: any) => e.key === item.desc_en);
            const existingModelIndex = data.byDevice.types.findIndex((e: any) => e.key === item.device_model);
            if (existingHackingIndex > -1)
                data.byDevice.types[existingHackingIndex] = { key: item.desc_en, cnt: item.cnt + data.byDevice.types[existingHackingIndex]['cnt'] };
            else
                data.byDevice.types.push({ key: item.desc_en, cnt: item.cnt });
            if (existingModelIndex > -1)
                data.byDevice.models[existingModelIndex] = { key: item.device_model, cnt: item.cnt + data.byDevice.models[existingModelIndex]['cnt'] };
            else
                data.byDevice.models.push({ key: item.device_model, cnt: item.cnt });
            data.byDevice.total += item.cnt;
            data.byDevice.types.sort((a: any, b: any) => b.cnt - a.cnt);
            data.byDevice.models.sort((a: any, b: any) => b.cnt - a.cnt);
        });
    }
    return data;
};

let initialData = [{
    id: "Inka Entworks",
    data: getRange(moment().subtract(30, 'minutes').format('YYYYMMDDHHmmss'), moment().format('YYYYMMDDHHmmss'))
}];

const Dashboard = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState<any>(null);
    const [loop, setLoop] = useState<any>(null);
    const [period, setPeriod] = useState<moment.Moment | null>(moment());
    const [selectedApp, setSelectedApp] = useState<string | null | undefined>(null);
    const [platform, setPlatform] = useState<'android' | 'ios'>('android');
    const [sealingMode, setSealingMode] = useState<'R' | 'T'>('R');
    const [streamType, setStreamType] = useState<string>('byDevice');
    const [selectedPie, setSelectedPie] = useState<string>('androidMajor');
    const [hackingTypes, setHackingTypes] = useState<Array<any>>([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [totalData, setTotalData] = useState(processDashboardResponse());
    const [hackingData, setHackingData] = useState(processHackingDataResponse());
    const [pieChartData, setPieChartData] = useState(processPieChartDataResponse());
    const [streamData, setStreamData] = useState<any>({ byType: initialData, byDevice: initialData });
    const [streamLegend, setStreamLegend] = useState<any>(processStreamLegend());
    const [hiddenData, setHiddenData] = useState<Array<string>>([]);
    const [clickedItems, setClickedItems] = React.useState<Array<string>>([]);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const countries = useSelector((state: RootState) => state.commonReducer.countries);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const mapFeatures = useSelector((state: RootState) => state.dataReducer.mapFeatures);
    const iosPackages = useSelector((state: RootState) => state.dataReducer.iosPackages);
    const androidPackages = useSelector((state: RootState) => state.dataReducer.androidPackages);
    const localPreference = useSelector((state: RootState) => state.mainReducer.localPreference);

    useEffect(() => {
        if (localPreference && data === null && Array.isArray(iosPackages) && Array.isArray(androidPackages)) {
            if (localPreference.platform)
                setPlatform(localPreference.platform);
            if (localPreference.sealingMode)
                setSealingMode(localPreference.sealingMode);
            if (localPreference.period && moment(localPreference.period, 'YYYY-MM'))
                setPeriod(moment(localPreference.period, 'YYYY-MM'));
            if (localPreference.platform === 'android' && androidPackages.find(e => e.package_name === localPreference.android_pkg))
                setSelectedApp(localPreference.android_pkg);
            else if (localPreference.platform === 'ios' && iosPackages.find(e => e.bundle_id === localPreference.ios_pkg))
                setSelectedApp(localPreference.ios_pkg);
            setData(localPreference);
        }
    }, [localPreference, data, iosPackages, androidPackages]);

    useEffect(() => {
        if (platform && selectedApp && sealingMode) {
            const newPref = {
                ...data,
                platform,
                sealingMode,
                period: period?.format('YYYY-MM'),
                [platform === 'android' ? 'android_pkg' : 'ios_pkg']: selectedApp
            };
            dispatch(setLocalPreferences(newPref));
            localStorage.setItem('user_preferences', JSON.stringify(newPref));


            const account_id = platform === 'ios' ? iosPackages?.find(e => e.bundle_id === selectedApp)?.account_id : undefined;
            const fetchMonthlyData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').subtract(1, 'year').format('YYYYMM'),
                    calc_date_to: moment(period).startOf('month').format('YYYYMM'),
                    dimension: 'MT',
                    lang_set: 'EN'
                };
                const response = await getPieChartData(params, region);
                let data = [];
                if (response?.result?.code === '0000' && Array.isArray(response?.hackingAttemptsList)) {
                    data = response.hackingAttemptsList.map((e: any) => ({
                        ...e,
                        month: moment(e.h_type).format('YYYY-MM')
                    }));
                }
                setMonthlyData(data);
            };
            fetchMonthlyData();
        }
    }, [platform, selectedApp, period, sealingMode, data, dispatch, authContext.token, iosPackages, region]);

    const fetchHackingDataStream = useCallback(async (package_name: string = "", type: string = "android") => {
        let params = {
            type,
            package_name,
            group_by_device: true,
            token: authContext.token,
            calc_date_to: moment(new Date()).utc().format('YYYYMMDDHHmmss'),
            calc_date_from: moment(new Date()).subtract(11, 'minute').utc().format('YYYYMMDDHHmmss')
        };
        const responseByType = await getHackingDataStream(params, region);
        params['group_by_device'] = false;
        const responseByDevices = await getHackingDataStream(params, region);
        if (responseByType?.result?.code === '0000' && responseByDevices?.result?.code === '0000') {
            const dataByType = processStreamData(responseByType, countries, false, i18n.language);
            const dataByDevice = processStreamData(responseByDevices, countries, false, i18n.language);
            setStreamData({
                byDevice: dataByDevice.streamData,
                byType: dataByType.streamData
            });

            setStreamLegend(processStreamLegend(dataByType, dataByDevice));
        }
        else {
            setStreamData({
                byType: initialData,
                byDevice: initialData
            });
        }

    }, [region, authContext.token, countries, i18n.language]);

    useEffect(() => {
        if (iosPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token
                };
                const response = await getIOSPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataBundleIdList)) {
                    dispatch(setIOSPackages([
                        ...response.dataBundleIdList
                    ]));
                }
            }
            fetchPackages();
        }
    }, [region, iosPackages, selectedApp, authContext.token, dispatch]);

    useEffect(() => {
        if (androidPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token
                };
                const response = await getAndroidPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataPackageList)) {
                    dispatch(setAndroidPackages([
                        ...response.dataPackageList
                    ]));
                }
            }
            fetchPackages();
        }
    }, [region, androidPackages, selectedApp, authContext.token, dispatch]);

    useEffect(() => {
        if (platform === 'android')
            setHackingTypes(hackingTypeKeys.slice(0, 9));
        else
            setHackingTypes(hackingTypeKeys.slice(6, 13));
    }, [platform]);

    useEffect(() => {
        if (platform && selectedApp && sealingMode) {
            const account_id = platform === 'ios' ? iosPackages?.find(e => e.bundle_id === selectedApp)?.account_id : undefined;
            const fetchDashboardData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMMDD'),
                    calc_date_to: moment(period).endOf('month').format('YYYYMMDD'),
                    compare_date_from: moment(period).startOf('month').subtract(1, 'M').format('YYYYMM'),
                    compare_date_to: moment(period).startOf('month').subtract(1, 'M').format('YYYYMM')
                };
                setTotalData(processDashboardResponse());
                const response = await getDashboardData(params, region);
                const data = processDashboardResponse(response, countries, false, i18n.language);
                setTotalData(data);
            };
            const fetchHackingData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMMDD'),
                    calc_date_to: moment(period).endOf('month').format('YYYYMMDD'),
                    dimension: 'HT',
                    lang_set: 'EN'
                };
                setHackingData(processHackingDataResponse());
                const response = await getHackingData(params, region);
                const data = processHackingDataResponse(response);
                setHackingData(data);
            };
            const fetchPieChartData = async () => {
                let params: any = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMMDD'),
                    calc_date_to: moment(period).endOf('month').format('YYYYMMDD'),
                    lang_set: 'EN'
                };

                setPieChartData(processPieChartDataResponse());
                params.dimension = 'OV';
                const androidResponse = await getPieChartData(params, region);
                params.dimension = 'OVD';
                const androidMinorResponse = await getPieChartData(params, region);
                params.dimension = 'HT';
                const hackingTypeResponse = await getPieChartData(params, region);
                let rootingResponse = undefined,
                    jailBreakRespone = undefined,
                    hackingPatternResponse = undefined;
                if (platform === 'android') {
                    params.dimension = 'RT';
                    rootingResponse = await getPieChartData(params, region);
                }
                else {
                    params.dimension = 'HP';
                    hackingPatternResponse = await getPieChartData(params, region);
                    params.dimension = 'JB';
                    jailBreakRespone = await getPieChartData(params, region);
                }
                const data = processPieChartDataResponse(androidResponse, androidMinorResponse, hackingTypeResponse, rootingResponse, hackingPatternResponse, jailBreakRespone);
                setPieChartData(data);
            };
            fetchHackingData();
            fetchDashboardData();
            fetchPieChartData();
            fetchHackingDataStream(selectedApp, platform);
        }
    }, [platform, region, selectedApp, period, iosPackages, sealingMode, countries, authContext.token, fetchHackingDataStream ,i18n.language]);

    useEffect(() => {
        if (loop === null && selectedApp && platform) {
            setLoop(setInterval(() => {
                fetchHackingDataStream(selectedApp, platform);
            }, (600000)));
        }
        if (loop) {
            return () => clearInterval(loop);
        }
    }, [loop, selectedApp, fetchHackingDataStream, platform]);

    
    const filterHackingData = (id: string) => {
        setClickedItems(prev => {
            if (prev.includes(id)) {
                return prev.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
        
        const updateFilteredData = hackingData.hackingTypes.map((item: any) => {
            if (item[id] === 0) return item;
            if (item[id]) {
                item[`backup_${id}`] = item[id];
                delete item[id];
            }else if(item[`backup_${id}`]){
                item[id] = item[`backup_${id}`];
                delete item[`backup_${id}`];
            }
            return item;
        });

        setHackingData({
            ...hackingData,
            hackingTypes: updateFilteredData,
        });
    }
    
    return (
        <DataLayout>
            <DataHeader 
                title={t('dashboard')} 
                subtitle={t('detailed_overview_for_hacking_insignts')}
                platform={platform}
                sealingMode={sealingMode}                
                period={period}
                selectedApp={selectedApp ? (platform === 'android' ? androidPackages?.find(e => e.package_name === selectedApp) : iosPackages?.find(e => e.bundle_id === selectedApp)) : null}
                packages={(platform === 'android' ? androidPackages?.map((item, i)=> ({...item, key: i})) : iosPackages?.map((item, i)=> ({...item, key: i}))) || null}
                setPeriod={setPeriod}
                setPlatform={setPlatform}
                setSelectedApp={setSelectedApp}
                setSealingMode={setSealingMode}
            />
            <div className="w-full grid md:grid-cols-12 grid-cols-1 md:gap-x-4 gap-y-4 mb-4">
                <ThreatAnlysis totalData={totalData}/>
                <HackingTypesChart setStreamType={setStreamType} streamType={streamType} streamLegend={streamLegend} streamData={streamData} />
            </div>
           <MonthTrendBarChart monthlyData={monthlyData} />
            <div className="w-full grid grid-cols-5 mb-4 md:gap-x-4 gap-y-4"> 
                <HackingDetectionByCountry totalData={totalData} mapFeatures={mapFeatures} />
                <HackingDetectionOverview 
                    selectedPie={selectedPie}   
                    platform={platform}
                    hiddenData={hiddenData}
                    pieChartData={pieChartData}
                    setSelectedPie={setSelectedPie}
                    setPieChartData={setPieChartData}
                    setHiddenData={setHiddenData}
                />   
               
            </div>
            <HackingTypeBarChart hackingTypes={hackingTypes} hackingData={hackingData} filterHackingData={filterHackingData} clickedItems={clickedItems} />
            <MonthlyActiveChart totalData={totalData} period={period}/>
            <TopOperatingSystemsCompromised totalData={totalData.osData} />
        </DataLayout >
    );
};

export default Dashboard;