import React from 'react';
import { Drawer, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import JsonView from '@uiw/react-json-view';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';

interface GroupDrawerProps {
    open: boolean;
    onClose: () => void;
    jsonContent: any;
    logId: string | number | null;
}

const GroupDrawer: React.FC<GroupDrawerProps> = ({ open, onClose, jsonContent, logId }) => {
    const { t } = useTranslation();
    const [copyText, setCopyText] = useState('Copy to clipboard');

    const onCancel = () => {
        onClose();
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard
            .writeText(JSON.stringify(jsonContent, null, 2))
            .then(() => {
                setCopyText('Copied!');
                setTimeout(() => setCopyText('Copy to clipboard'), 2000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <Drawer anchor="right" open={open} onClose={onCancel}>
            <div className="p-10 w-9/12 lg:w-[800px]">
                <div className="mb-9">
                    <div>
                        <h2 className="m-0 text-2xl font-medium">
                            {t('user_log')} #ID{logId}
                        </h2>
                        <p className="text-xs text-gray-500">{t('user_log_desc')}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <TabContext value={'1'}>
                            <div className="relative">
                                <span className="absolute right-0 z-10 text-xs cursor-pointer top-6 text-linkblue" onClick={handleCopyToClipboard}>
                                    {copyText}
                                </span>
                                <TabList className="border-b border-[#E9E9E9]">
                                    <Tab label="JSON" value="1" />
                                </TabList>
                            </div>
                            <TabPanel value="1">
                                <JsonView value={jsonContent} />
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default GroupDrawer;
