import { useTranslation } from 'react-i18next';
import { AlwaysOnSecurityData } from './data';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

interface SecurityFeaturesProps {
    viewType: string;
}

const SecurityFeatures = ({ viewType }: SecurityFeaturesProps) => {
    const { t } = useTranslation();
    const [selectedKey, setSelectedKey] = useState<string>('');

    const getDefaultEl = (data: any) => {
        return (
            <div className="flex flex-col gap-4 mb-10">
                <h4 className="font-medium text-sm">{t(data.text)}</h4>
                {data.options.map((item: any, index: any) => {
                    return (
                        <div key={index} className="flex flex-row gap-4">
                            <VerifiedUserOutlinedIcon fontSize="small" className="text-[#02F681]" />
                            <div>
                                <span className="block text-sm">{t(item.text)}</span>
                                <span className="block flex-grow text-xs italic">{t(item.tooltip)}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    const onChange = (key: string | string[]) => {
        setSelectedKey(key as string);
    };

    return (
        <>
            {viewType === 'basic' ? (
                <div className="flex flex-col gap-3">
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'code_encryption'))}
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'anti_debugging_runtime_attack'))}
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'anti_tampering'))}
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'virtual_environments'))}
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'cheating_tools'))}
                    {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'hacking_detection_analytics'))}
                </div>
            ) : (
                <Accordion
                    expanded={selectedKey === '1'}
                    onChange={(event, isExpanded) => onChange(isExpanded ? '1' : '')}
                    sx={{ '&.Mui-expanded': { margin: 0 }, boxShadow: 'none', '&::before': { display: 'none' } }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-0">
                        <Box className="flex items-center w-full">
                            <p className="font-semibold whitespace-nowrap">{t('inherent_features')}</p>
                            <p className="mx-2 w-full h-px bg-[#E0E0E0]" />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="flex flex-col gap-3">
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'code_encryption'))}
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'anti_debugging_runtime_attack'))}
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'anti_tampering'))}
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'virtual_environments'))}
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'cheating_tools'))}
                            {getDefaultEl(AlwaysOnSecurityData.find((e) => e.type === 'hacking_detection_analytics'))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

export default SecurityFeatures;
