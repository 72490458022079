import { Delete, Get, Post, Put } from '../..';
import {
    AccountsInformationInterface,
    ChangePasswordInterface,
    MemberInfoInterface,
    PreferencesInterface,
    ProfileInformationInterface,
    MemberActivationInterface,
    SetPasswordSecurityInterface,
    RemoveMemberInterface,
} from '../../../type/api/manage';
import { AppURL } from '../../../utility/AppURL';

export const saveProfileInfo = async (params: ProfileInformationInterface) => {
    let urlParams = new URLSearchParams();

    const { first_name = '', last_name = '', mobile = '', registered_date, token } = params;
    urlParams.append('division', 'M');
    urlParams.append('first_name', first_name);
    urlParams.append('last_name', last_name);
    urlParams.append('mobile', mobile);
    urlParams.append('registered_date', registered_date);
    urlParams.append('token', token);

    let response = await Post(AppURL.profile, urlParams).then((res) => res.json());

    return response;
};

export const getAccountsInformation = async (params: AccountsInformationInterface) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.information, urlParams).then((res) => res.json());

    return response;
};

export const saveAccountInfo = async (params: AccountsInformationInterface) => {
    let urlParams = new URLSearchParams();

    for (let [key, value] of Object.entries(params)) {
        urlParams.append(key, value || '');
    }

    let response = await Post(AppURL.information, urlParams).then((res) => res.json());

    return response;
};

export const getMemberList = async (params: { token: string }) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.getaccountmemberlist, urlParams)
        .then((res) => res.json())
        .catch((err) => console.log(err));

    return response;
};

export const getInviteHistory = async (params: { token: string }) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.getmemberinvitehistory, urlParams).then((res) => res.json());

    return response;
};

export const sendAccountInvite = async (params: { email: string; token: string; groupId?: string; role?: string; lang_set?: string }) => {
    let { token, email, groupId, role, lang_set } = params,
        urlParams = new URLSearchParams();

    urlParams.append('member_email', email);
    urlParams.append('token', token);
    if (lang_set) {
        urlParams.append('lang_set', lang_set === 'en_US' ? 'EN' : 'KR');
    }
    if (groupId) {
        urlParams.append('group_id', groupId);
    }
    if (role) {
        urlParams.append('owner_type', role);
    }
    let response = await Post(AppURL.inviteaccountmember, urlParams).then((res) => res.json());

    return response;
};
export const activateMember = async (params: MemberActivationInterface) => {
    let { token, user_id, lang } = params;

    const header = {
        'as-token': token,
    };
    let url = `${AppURL.userssvc}/${user_id}/active?langSet=${lang}`;
    let response = await Put(url, null, header).then((res) => res.json());

    return response;
};

export const deactivateMember = async (params: MemberActivationInterface) => {
    let { token, user_id } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('user_id', user_id);

    let response = await Post(AppURL.deactivateaccountmember, urlParams)
        .then(() => ({ result: { code: '0000', message: 'success' } }))
        .catch(() => ({
            result: { code: '9999', message: 'Failed to deactivate member' },
        }));

    return response;
};
export const updateAccountMember = async (params: MemberInfoInterface) => {
    let { account_id, currentNote = '', division = 'M', joined, member_email, member_name, notes, token, you_yn } = params,
        urlParams = new URLSearchParams();

    urlParams.append('account_id', account_id);
    urlParams.append('currentNote', currentNote);
    urlParams.append('division', division);
    urlParams.append('edit', 'true');
    urlParams.append('joined', joined);
    urlParams.append('member_email', member_email);
    urlParams.append('member_name', member_name);
    urlParams.append('notes', notes);
    urlParams.append('token', token);
    urlParams.append('you_yn', you_yn);

    let response = await Post(AppURL.editaccountmembernote, urlParams).then((res) => res.json());

    return response;
};

export const removeMember = async (params: RemoveMemberInterface) => {
    const { token, userId } = params;
    const updatedUrl = `${AppURL.removeaccountmember}/${userId}/inactive`;
    const header = {
        'As-Token': token,
    };

    try {
        const response = await Delete(updatedUrl, null, header);
        const data = await response.json();
        return data;
    } catch (error) {
        return false;
    }
};
export const setPreferences = async (params: PreferencesInterface) => {
    let { lang_set, timezone, timezone_code, token, view_sign_yn } = params,
        urlParams = new URLSearchParams();

    urlParams.append('lang_set', lang_set);
    if (timezone) urlParams.append('timezone', timezone);
    if (timezone_code) urlParams.append('timezone_code', timezone_code);
    urlParams.append('token', token);
    if (view_sign_yn) urlParams.append('view_sign_yn', view_sign_yn);

    let response = await Post(AppURL.preference, urlParams).then((res) => res.json());

    return response;
};

export const changePwd = async (params: ChangePasswordInterface) => {
    let { user_pw, new_user_pw, new_user_pw_confirm, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('new_user_pw', new_user_pw);
    urlParams.append('new_user_pw_confirm', new_user_pw_confirm);
    urlParams.append('token', token);
    urlParams.append('user_pw', user_pw);

    let response = await Post(AppURL.changePwd, urlParams).then((res) => res.json());

    return response;
};

export const getPasswordSettings = async ({ companyId, token }: { companyId: number; token: string }) => {
    const header = {
        'As-Token': token,
    };
    let response = await Get(AppURL.companySecurity + companyId, null, header)
        .then((res) => res.json())
        .catch((e) => 'error');

    return response;
};

export const setPasswordSettings = async (params: SetPasswordSecurityInterface) => {
    const header = {
        'Content-Type': 'application/json',
        'As-Token': params.token,
    };

    await togglePasswordSettings(params);

    let response = await Put(AppURL.companySecurity, JSON.stringify(params), header);

    return response;
};

const togglePasswordSettings = async (params: SetPasswordSecurityInterface) => {
    const header = {
        'Content-Type': 'application/json',
        'As-Token': params.token,
    };

    let duplicateCheckUrl = `${AppURL.companySecurity}${params.companyId}/password-policies/duplicate-check/`;
    duplicateCheckUrl += params.passwordCount === -1 ? 'disable' : 'enable';

    await Put(duplicateCheckUrl, null, header);

    let expirationCheckUrl = `${AppURL.companySecurity}${params.companyId}/password-policies/expiration-check/`;
    expirationCheckUrl += params.passwordExpiration === -1 ? 'disable' : 'enable';

    await Put(expirationCheckUrl, null, header);

    return true;
};

export const updateMemberToMember = async (params: { userId: string; token: string; lang: string }) => {
    const header = {
        'Content-Type': 'application/json',
        'As-Token': params.token,
        lang: params.lang ?? 'KR',
    };
    const url = `${AppURL.userssvc}/${params.userId}/member`;
    const response = await Put(url, null, header).then((res) => res.json());
    return response;
};

export const updateMemberToSubOwner = async (params: { userId: string; token: string; lang: string }) => {
    const header = {
        'Content-Type': 'application/json',
        'As-Token': params.token,
        lang: params.lang ?? 'KR',
    };
    const url = `${AppURL.userssvc}/${params.userId}/sub-owner`;
    const response = await Put(url, null, header).then((res) => res.json());

    return response;
};

export const updateMFAPolicy = async (params: { companyId: number; type: string; token: string }) => {
    const header = {
        'Content-Type': 'application/json',
        'As-Token': params.token,
    };
    const body = params.type;
    const url = `${AppURL.companySecurity}${params.companyId}/mfa-policy`;
    const response = await Put(url, JSON.stringify(body), header).then((res) => res.json());
    return response;
};
