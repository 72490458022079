import { Tooltip } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveChoropleth } from "@nivo/geo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { ChoroplethChartColors } from "../../../utils/common";
import { DummyBarChart } from "../../charts/DummyChart";

interface HackingDetectionByCountryProps {
  totalData: any;
  mapFeatures: any;
}

const HackingDetectionByCountry = ({
  totalData,
  mapFeatures,
}: HackingDetectionByCountryProps) => {
  const { t } = useTranslation();


const maxValue = Math.max(...totalData.countryData.slice(0, 5).map((item: { typeDistribution: any; }) => item.typeDistribution));


const getBarColor = (bar: { data: { typeDistribution: number; }; }) => {
  const opacity = 0.3 + (0.5 * (bar.data.typeDistribution / maxValue)); 
  return `rgba(243, 78, 78, ${opacity})`;
};

  return (
    <div className=" col-span-6 xl:col-span-3 min-h-24 bg-primary py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <div className="h-3/5 flex flex-col">
        <p className="text-base text-primary normal-case font-normal">
          {t("hacking_detections_by_country")}
        </p>
        <div
          className="flex-grow mt-4 mb-2 min-h-400 chart-map-wrap"
          style={{ overflow: "visible" }}
        >
          {totalData.countryData && (
            <ResponsiveChoropleth
              data={totalData.countryData}
              features={mapFeatures}
              colors={ChoroplethChartColors}
              domain={[
                totalData.countryLegend.minDomain,
                totalData.countryLegend.maxDomain,
              ]}
              unknownColor="#e6e6e6"
              label="properties.name"
              valueFormat=".1s"
              borderWidth={0.3}
              borderColor="#152538"
              projectionTranslation={[0.5, 0.7]}
              projectionScale={90} 
              tooltip={(e: any) =>
                e.feature?.data ? (
                  <div className="bg-primary p-2 border border-solid">
                    <p className="font-semibold truncate max-w-sm">
                      {e.feature.data.countryName}
                    </p>
                    <p>
                      {t("no_of_hacking_types")}:{" "}
                      <span className="font-semibold">{`${e.feature.data.h_cnt.toLocaleString()} (${e.feature.data.typeDistribution}%)`}</span>
                    </p>
                    <p>
                      {t("unique_devices_hacking_attempts")}:{" "}
                      <span className="font-semibold">{`${e.feature.data.d_cnt.toLocaleString()} (${e.feature.data.deviceDistribution}%)`}</span>
                    </p>
                    <p>
                      {t("daily_avg_no_of_hacking_types")}:{" "}
                      <span className="font-semibold">
                        {e.feature.data.h_avg.toLocaleString()}
                      </span>
                    </p>
                    <p>
                      {t("daily_avg_unique_devices_hacking_attempts")}:{" "}
                      <span className="font-semibold">
                        {e.feature.data.d_avg.toLocaleString()}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p />
                )
              }
            />
          )}
        </div>
      </div>
      <div className="h-[280px] flex flex-col mt-8">
        <div className="flex-grow">
          {totalData.countryData.length > 0 ?
            <ResponsiveBar
              data={totalData.countryData.slice(0, 5)}
              layout="horizontal"
              indexBy="countryName"
              keys={["typeDistribution"]}
              colorBy="id"
              axisBottom={{
                format: (value) => `${value}%`,
              }}
              axisLeft={{
                renderTick: ({ textAnchor, textX, textY, x, y, value }) => {
                  let newValue =
                    value?.length > 12
                      ? `${value.match(/(.{1,11}.)|(.{1,11})/gm)?.[0]}..`
                      : value;
                  return (
                    <Tooltip title={value}>
                      <g transform={`translate(${x},${y})`}>
                        <text
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "11px",
                            fill: "rgb(51, 51, 51)",
                          }}
                          textAnchor={textAnchor}
                          transform={`translate(${textX},${textY})`}
                        >
                          {newValue}
                        </text>
                      </g>
                    </Tooltip>
                  );
                },
              }}
              label={""}
              padding={0.5}
              minValue={0.0}
              maxValue={100.0}
              labelTextColor={() => "#f8f8f8"}
              colors={getBarColor}
              margin={{ top: 10, right: 30, left: 90, bottom: 30 }}
              tooltip={(e: any) => (
                <div className="bg-white border border-solid p-4">
                  <p className="font-semibold truncate max-w-sm">
                    {e.data.countryName}
                  </p>
                  <p>
                    {t("no_of_hacking_types")}:{" "}
                    <span className="font-semibold">{`${e.data.h_cnt.toLocaleString()} (${e.data.typeDistribution}%)`}</span>
                  </p>
                  <p>
                    {t("unique_devices_hacking_attempts")}:{" "}
                    <span className="font-semibold">{`${e.data.d_cnt.toLocaleString()} (${e.data.deviceDistribution}%)`}</span>
                  </p>
                  <p>
                    {t("daily_avg_no_of_hacking_types")}:{" "}
                    <span className="font-semibold">
                      {e.data.h_avg.toLocaleString()}
                    </span>
                  </p>
                  <p>
                    {t("daily_avg_unique_devices_hacking_attempts")}:{" "}
                    <span className="font-semibold">
                      {e.data.d_avg.toLocaleString()}
                    </span>
                  </p>
                </div>
              )}
            />
            : <DummyBarChart layout="horizontal" count={5} />}
        </div>

        <div className="flex justify-end mt-5">
          <Tooltip title={t("show_hacking_dections_details")}>
            <div>
              <span className="mr-2">{t("hacking_attempts_by_location")}</span>
              <span className="hover:underline text-linkblue">
                <Link className="text-linkblue" to="/threatdetection">
                  {t("details")}
                  <EastIcon className="text-xs ml-1" fontSize="small" />
                </Link>
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default HackingDetectionByCountry;
