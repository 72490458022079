import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './footer.less';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

interface FeedbackButtonProps {
	visible: boolean;
	handleOpenModal: () => void;
}

const FeedbackButton = ({visible, handleOpenModal}: FeedbackButtonProps) => {
		const {t} = useTranslation();
		const [isVisible, setIsVisible] = useState(visible);
		useEffect(() => {
			if(visible !== isVisible){
				setIsVisible(visible)
			}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	const handleClose = () => {
    sessionStorage.setItem('FeedbackButtonClosed', 'true');
    setIsVisible(false);
		setIsVisible(false as boolean);
};
    return (
			<>
        {isVisible && <div className='feedback-button-wrap'>
					<div className='rounded-[50px] bg-accent text-white cursor-pointer btn-pulse' onClick={handleOpenModal}>
						<span className='mr-3 normal-case' onClick={handleOpenModal}>{t('feedback')}</span>
						<IconButton onClick={handleClose} className='text-accent bg-white h-6 w-6'><CloseIcon style={{fontSize: 20}}/></IconButton>
					</div>
				</div>}
			</>
    );
};

export default FeedbackButton;
