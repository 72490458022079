import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '../assets/localization/adc-en_US.json';
import koTranslation from '../assets/localization/adc-kr_KR.json';

const userLanguage = localStorage.getItem('user-lang') || 'en_US';

const resources = {
    en_US: { translation: enTranslation },
    kr_KR: { translation: koTranslation },
};
const options = { order: ['localStorage', 'cookie', 'navigator'], lookupLocalStorage: 'user-lang' };
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: options,
        resources,
        debug: false,
        lng: userLanguage,
        fallbackLng: 'en_US',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
