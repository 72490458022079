import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { Routes, Route } from 'react-router-dom';

import SignIn from '../components/login/signin';
import Demo from '../components/login/signin/demo';
import HelpDesk from '../components/login/signin/helpdesk';

import SignUp from '../components/login/signup';
import ForgotPassword from '../components/login/forgotpassword';
import Announcement from '../components/main/announcement';

import Dashboard from '../components/data/dashboard';
import Analytics from '../components/data/analytics';
import ActiveDevices from '../components/data/activedevices';
import HackingDetection from '../components/data/hackingdetection';

import Android from '../components/sealing/android';
import AppServiceiOS from '../components/sealing/ios';

import User from '../components/manage/user';
import BillingDetails from '../components/manage/billingdetails';
import BillingPlan from '../components/manage/billingdetails/plan';
import BillingInvoice from '../components/manage/billingdetails/invoice';
import BillingTransaction from '../components/manage/billingdetails/transactionhistory';
import Account from '../components/manage/organization';
import { PlanExpired } from '../components/utils/common';
import Confirmation from '../components/login/signin/confirmation';
import Invite from '../components/login/invite';
import ManageNotifications from '../components/manage/settings/managenotifications';
import ManageService from '../components/manage/settings/manageservice';
import ConfigureService from '../components/manage/settings/manageservice/manage';
import NewPassword from '../components/login/ResetPassword/index';
import InviteFail from '../components/login/invite/Fail';

const TRACKING_ID = 'UA-62232685-1';
ReactGA.initialize(TRACKING_ID);

const NEW_TRACKING_ID = 'G-W6Y2779HVQ';
ReactGA4.initialize(NEW_TRACKING_ID);

const PublicRoutes = () => {
    useEffect(() => {
        if (window.location.host === 'console.appsealing.com') {
            ReactGA.pageview(window.location.hash);
            ReactGA4.send({ hitType: 'pageview', page: window.location.hash });
        }
    });

    return (
        <Routes>
            <Route path="/demo" element={<Demo />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/invites/*" element={<Invite />} />
            <Route path="/invites/fail" element={<InviteFail />} />
            <Route path="/awssignup" element={<SignUp />} />
            <Route path="/helpdesk" element={<HelpDesk />} />
            <Route path="/confirmation/*" element={<Confirmation />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<NewPassword />} />
            <Route path="/updatepassword" element={<ForgotPassword />} />
            <Route path="/*" element={<SignIn />} />
        </Routes>
    );
};

const ProtectedRoutes = (props: { androidExpired: boolean; iOSExpired: boolean }) => {
    useEffect(() => {
        if (window.location.host === 'console.appsealing.com') {
            ReactGA.pageview(window.location.hash);
            ReactGA4.send({ hitType: 'pageview', page: window.location.hash });
        }
    });

    return (
        <Routes>
            <Route path="/dashboard" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <Dashboard />} />
            <Route path="/analytics" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <Analytics />} />
            <Route path="/activedevices" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <ActiveDevices />} />
            <Route path="/threatdetection" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <HackingDetection />} />
            <Route path="/threatdetection/:id" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <HackingDetection />} />
            <Route path="/start-securing-your-app/:id" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <Android />} />
            <Route path="/sealingandroid" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <Android />} />
            <Route path="/sealingandroid/*" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <Android />} />
            <Route path="/sealingios" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <AppServiceiOS />} />
            <Route path="/sealingios/*" element={props.androidExpired && props.iOSExpired ? <PlanExpired /> : <AppServiceiOS />} />
            <Route path="/organization" element={<Account />} />
            <Route path="/organization/*" element={<Account />} />
            <Route path="/billingdetails" element={<BillingDetails />} />
            <Route path="/billingdetails/info" element={<BillingDetails />} />
            <Route path="/billingdetails/plan" element={<BillingPlan />} />
            <Route path="/billingdetails/invoice" element={<BillingInvoice />} />
            <Route path="/billingdetails/history" element={<BillingTransaction />} />
            <Route path="/settings/notifications" element={<ManageNotifications />} />
            <Route path="/settings/service" element={<ManageService />} />
            <Route path="/settings/service/configure/:id" element={<ConfigureService />} />
            <Route path="/announcement" element={<Announcement />} />
            <Route path="/announcement/:seq" element={<Announcement />} />
            <Route path="/profile" element={<User />} />
            <Route path="/*" element={<Dashboard />} />
        </Routes>
    );
};

export { PublicRoutes, ProtectedRoutes };
