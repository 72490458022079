import { Button, Tooltip } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { PieChartColors } from "../../../utils/common";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { DummyPieChart } from "../../charts/DummyChart";

interface HackingDetectionOverviewProps {
  selectedPie: string;
  platform: string;
  pieChartData: any;
  setPieChartData: React.Dispatch<React.SetStateAction<any>>;
  hiddenData: any;
  setHiddenData: React.Dispatch<React.SetStateAction<any>>;
  setSelectedPie: React.Dispatch<React.SetStateAction<any>>;
}

const HackingDetectionOverview = ({
  selectedPie,
  platform,
  pieChartData,
  setPieChartData,
  hiddenData,
  setHiddenData,
  setSelectedPie,
}: HackingDetectionOverviewProps) => {
  const { t } = useTranslation();

  const calculatePiePercentage = (data: any) => {
    let hiddenId: Array<string> = hiddenData || [];

    if (!data.hidden) {
      hiddenId.push(data.id);
    } else {
      hiddenId = hiddenId.filter((item: any) => item !== data.id);
    }
    let total = 0;
    pieChartData.hackingTypes.forEach((item: any) => {
      if (!hiddenId.includes(item.id)) {
        total += item.h_cnt;
      }
    });
    const updatedHackingTypes = pieChartData.hackingTypes.map((item: any) => {
      if (!hiddenId.includes(item.id)) {
        return {
          ...item,
          distribution: Math.round((item.h_cnt / total) * 100).toFixed(2),
        };
      }
      return item;
    });

    setPieChartData({
      ...pieChartData,
      hackingTypes: updatedHackingTypes,
    });

    setHiddenData(hiddenId);
  };

  return (
    <div className="col-span-6 lg:col-span-3 xl:col-span-2 min-h-24 bg-primary py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <p className="text-base text-primary normal-case font-normal">{t("hacking_detections_overview")}</p>

      <div className="mt-4 flex gap-x-3 gap-y-5 justify-center lg:mx-10">
        <Button
          className="w-1/2 normal-case text-[12px] leading-none"
          color={selectedPie === "androidMajor" ? "primary" : "secondary"}
          variant={selectedPie === "androidMajor" ? "contained" : "outlined"}
          onClick={() => setSelectedPie("androidMajor")}
        >
          {t("os_version_major")}
        </Button>
        {platform === "android" && (
          <Button
            className="w-1/2 normal-case text-[12px] leading-none"
            color={selectedPie === "rooting" ? "primary" : "secondary"}
            variant={selectedPie === "rooting" ? "contained" : "outlined"}
            onClick={() => setSelectedPie("rooting")}
          >
            {t("rooting")}
          </Button>
        )}
        {platform === "ios" && (
          <Button
            className="w-1/2 normal-case text-[12px] leading-none"
            color={selectedPie === "jailBreak" ? "primary" : "secondary"}
            variant={selectedPie === "jailBreak" ? "contained" : "outlined"}
            onClick={() => setSelectedPie("jailBreak")}
          >
            {t("jailbreak")}
          </Button>
        )}
      </div>
      <div className="mt-4 flex gap-x-3 gap-y-5 justify-center lg:mx-10">
        <Button
          className="w-1/2 normal-case text-[12px] leading-none"
          color={selectedPie === "hackingTypes" ? "primary" : "secondary"}
          variant={selectedPie === "hackingTypes" ? "contained" : "outlined"}
          onClick={() => setSelectedPie("hackingTypes")}
        >
          {t("hacking_types")}
        </Button>
        {platform === "ios" && (
          <Button
            className="w-1/2 normal-case text-[12px] leading-none"
            color={selectedPie === "hackingPattern" ? "primary" : "secondary"}
            variant={
              selectedPie === "hackingPattern" ? "contained" : "outlined"
            }
            onClick={() => setSelectedPie("hackingPattern")}
          >
            {t("hacking_patterns")}
          </Button>
        )}
        <Button
          className="w-1/2 normal-case text-[12px] leading-none"
          color={selectedPie === "androidMinor" ? "primary" : "secondary"}
          variant={selectedPie === "androidMinor" ? "contained" : "outlined"}
          onClick={() => setSelectedPie("androidMinor")}
        >
          {t("os_version_minor")}
        </Button>
      </div>
      <div className="flex-grow mt-4 mb-4 min-h-[300px]">

        {pieChartData[selectedPie].length > 0 ? 
        <ResponsivePie
          data={pieChartData[selectedPie]}
          padAngle={0.7}
          innerRadius={0.5}
          cornerRadius={3}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={8}
          colors={PieChartColors}
          margin={{ top: 40, right: 30, bottom: 10, left: 30 }}
          arcLabelsTextColor={"#36373A"}
          arcLabel={(e: any) => `${e.data.distribution} %`}
          arcLabelsSkipAngle={20}
          tooltip={(e: any) => (
            <div className="bg-white p-2">
              <strong>
                {t(
                  e.datum.data.id.toLowerCase().replace(/ /g, "_"),
                  e.datum.data.id as string
                )}{" "}
                :{" "}
              </strong>
              <span>{`${e.datum.data.value.toLocaleString()} (${e.datum.data.distribution}%)`}</span>
            </div>
          )}
          legends={
            selectedPie === "hackingTypes"
              ? [
                  {
                    anchor: "top-left",
                    direction: "column",
                    justify: false,
                    translateX: -10,
                    translateY: -20,
                    itemWidth: 150,
                    itemHeight: 12,
                    itemsSpacing: 4,
                    symbolSize: 10,
                    toggleSerie: true,
                    itemDirection: "left-to-right",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 0.5,
                        },
                      },
                    ],
                    onClick: (d) => {
                      calculatePiePercentage(d);
                    },
                  },
                ]
              : undefined
          }
        /> : <DummyPieChart/> }
      </div>
      <div className="flex justify-end mt-5">
          <Tooltip title={t("show_hacking_dections_details")}>
            <div>
              <span className="mr-2">{t("hackingDetection")}</span>
              <span className="hover:underline text-linkblue">
                <Link className="text-linkblue" to="/threatdetection">
                  {t("details")}
                  <EastIcon className="text-xs ml-1" fontSize="small" />
                </Link>
              </span>
            </div>
          </Tooltip>
        </div>
    </div>
  );
};

export default HackingDetectionOverview;
