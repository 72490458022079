export const proxy = {
    accountsvc: '/covault/gw/v3/account',
    appsvc: '/covault/gw/v3/application',
    appssvc: '/covault/gw/v3/applications',
    authsvc: '/covault/gw/v3/auth',
    blacklistsvc: '/covault/gw/v3/blacklist',
    commonsvc: '/covault/gw/v3/common',
    datasvc: '/covault/gw/v3/data',
    invoicesvc: '/covault/gw/v3/invoice',
    pkgmangersvc: '/covault/gw/v3/pkgmanger',
    promotionsvc: '/covault/gw/v3/promotion',
    sdksvc: '/covault/gw/v3/sdk',
    sealinghybridsvc: '/covault/gw//',
    sealingsvc: '/covault/gw/v3/sealing',
    usersvc: '/covault/gw/v3/user',
    userssvc: '/covault/gw/v3/users',
    companiessvc: '/covault/gw/v3/companies',
    webhooksvc: '/covault/gw/v3/webhook',
    mfasvc: '/covault/gw/v3/multi-factors',
    asiasvc: '/covault/gw/v3/asia',
    presetsvc: '/covault/gw/v3/hardening-mains',
    sealingPresetsvc: '/covault/gw/v3/sealing-presets',
    assetsvc: 'https://appsealing-adc-site.s3.ap-northeast-1.amazonaws.com',
    userlogssvc: '/covault/gw/v3/apis',
    groupssvc: '/covault/gw/v3/groups',
};
