import Dialog from '@mui/material/Dialog';
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

interface SuccessModalProps {
  downloadSuccess: boolean;
  setDownloadSuccess: React.Dispatch<SetStateAction<boolean>>;
}

const SuccessModal = ({ downloadSuccess, setDownloadSuccess }: SuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className='modal-border-none'
      open={downloadSuccess}
      onClose={() => setDownloadSuccess(false)}
    >
       <IconButton
          aria-label="close"
          onClick={() => setDownloadSuccess(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ><CloseIcon /></IconButton>
      <div className='text-center py-7'>
        <Typography variant='h1' className='text-accent'>
          {t("success_in_downloading_title")}
        </Typography>
        <p className='my-10 whitespace-pre-wrap'>
          {t("success_in_downloading_start")}
        </p>
      </div>
    </Dialog>
  );
};

export default SuccessModal;

