import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { deleteCookie, setCookies } from '../../../utility/Common';
import LanguageSelector from '../languageselector';
import { Typography } from '@mui/material';

interface MfaNotificationModalProps {
    mfaPolicy: string;
    handleEnable: () => void;
    handleLater: () => void;
}

const MfaNotificationModal = ({ mfaPolicy, handleEnable, handleLater }: MfaNotificationModalProps) => {
    const { t } = useTranslation();

    const setDontAskAgainCookie = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked.toString();
        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const expires = new Date(Date.now() + oneWeek).toUTCString();
        if (event.target.checked) {
            setCookies('dontAskAgain', isChecked, expires);
        } else {
            deleteCookie('dontAskAgain');
        }
    };

    return (
        <div
            className="w-full h-screen max-h-screen bg-no-repeat"
            style={{
                backgroundImage: `linear-gradient(to bottom right, #D72544, #362674)`,
            }}
        >
            <div
                className="mfa-modal py-16 px-10 pb-10 z-10 bg-white absolute top-1/2 left-1/2 rounded-md max-w-[450px] w-4/5"
                style={{
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Typography variant="h1" className="text-center">
                    {t('mfa_activation_title')}
                </Typography>
                <p className="text-sm whitespace-pre-line py-7">{t('mfa_activation_desc')}</p>
                <div className="flex justify-between w-full gap-4">
                    {mfaPolicy !== 'ENFORCED' && (
                        <Button onClick={handleLater} variant="outlined" color="secondary" className="w-1/2 h-10">
                            {t('mfa_enable_later')}
                        </Button>
                    )}
                    <Button onClick={handleEnable} variant="contained" color="primary" className={`${mfaPolicy !== 'ENFORCED' ? 'w-1/2' : 'w-full'} h-10`}>
                        {t('mfa_enable_now')}
                    </Button>
                </div>
                <div className={`flex items-center ${mfaPolicy !== 'ENFORCED' ? 'justify-between' : 'justify-end'} flex-none py-3`}>
                    {mfaPolicy !== 'ENFORCED' && <FormControlLabel control={<Checkbox onChange={setDontAskAgainCookie} />} label={t('disable_for_one_week')} />}
                    <LanguageSelector />
                </div>
            </div>
        </div>
    );
};

export default MfaNotificationModal;
