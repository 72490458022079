import { FilterField } from "../../../../type/manage";

export const InvoiceStatusCode: any = {
  S: { text: "Sent", color: "blue" },
  P: { text: "Paid", color: "green" },
  O: { text: "Overdue", color: "red" },
  C: { text: "Cancelled", color: "grey" },
  CR: { text: "Cancel Requested", color: "lightsalmon" },
  PL: { text: "Payment link ready", color: "orange" },
  I: { text: "Not Available", color: undefined },
  "N/A": { text: "Not Available", color: undefined },
};

export const InvoiceFields: FilterField[] = [
	{
			"name": "period_from",
			"label": "from_period",
			"type": "datepicker",
			"required": true,
			"errorMessage": "please_enter_from_period",
	},
	{
			"name": "period_to",
			"label": "to_period",
			"type": "datepicker",
			"required": false,
	},
	{
			"name": "invoice_id",
			"label": "Invoice ID",
			"type": "input",
			"required": false,
			"placeholder": "enter_invoice_id"
	},
	{
			"name": "company_name",
			"label": "company_name",
			"type": "input",
			"required": false,
			"placeholder": "enter_company_name"
	},
	{
			"name": "package_name",
			"label": "package_name",
			"type": "input",
			"required": false,
			"placeholder": "enter_package_name"
	},
	{
			"name": "minMonth",
			"label": "min_amount",
			"type": "number",
			"required": false,
			"placeholder": "enter_min_amount"
	},
	{
			"name": "maxMonth",
			"label": "max_amount",
			"type": "number",
			"required": false,
			"placeholder": "enter_max_amount"
	},
	{
			"name": "status",
			"label": "status",
			"type": "select",
			"options":InvoiceStatusCode,
			"required": false,
			"placeholder": "select_status"
	}
]