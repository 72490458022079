import React from "react";
import { Control, Controller } from "react-hook-form";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormState } from "../../../../type/user/index";
interface UserInfoProps {
  userDetails: {
    user_id: string;
    first_name: string;
    last_name: string;
    mobile: string;
    registered_date: string;
  };
  control: Control<FormState>;
}

const UserInfo = ({ userDetails, control }: UserInfoProps) => {
  const { t } = useTranslation();
  const memberSince = userDetails?.registered_date
    ? `${userDetails.registered_date.slice(0, 4)}/${userDetails.registered_date.slice(4, 6)}/${userDetails.registered_date.slice(6, 8)}`
    : "";

  return (
    <Box className='mt-5 mb-10 pb-10 border-b border-gray-300'>
      <Typography variant='h6' className='font-bold text-base'>
        {t("user_info")}
      </Typography>
      <Box className='bg-f4 px-4 py-3 mt-4 mb-10 flex flex-wrap gap-x-8 gap-y-4 rounded-sm'>
        <div className='w-full lg:w-1/3 lg:max-w-[310px]'>
          <Typography
            variant='subtitle1'
            component='strong'
            className='block text-darkgray font-bold text-sm'
          >
            {t("user_id")}
          </Typography>
          <Typography variant='body2' className='block text-lightgray'>
            {userDetails.user_id}
          </Typography>
        </div>
        <div className='w-1/3 max-w-[310px]'>
          <Typography
            variant='subtitle1'
            component='strong'
            className='block text-darkgray font-bold text-sm'
          >
            {t("member_since")}
          </Typography>
          <Typography variant='body2' className='block text-lightgray'>
            {memberSince}
          </Typography>
        </div>
        <div className='w-1/3 max-w-[310px]' />
      </Box>
      <Box className='flex flex-wrap gap-x-8 gap-y-4 w-full'>
        <Controller
          name='userInfo.first_name'
          control={control}
          render={({ field }) => (
            <TextField
              className='w-full lg:w-1/3 lg:max-w-[325px]'
              label={t("first_name")}
              variant='outlined'
              fullWidth
              inputProps={{ maxLength: 32, }}
              {...field}
              margin='normal'
            />
          )}
        />
        <Controller
          name='userInfo.last_name'
          control={control}
          render={({ field }) => (
            <TextField
              className='w-full lg:w-1/3 lg:max-w-[325px]'
              label={t("last_name")}
              variant='outlined'
              inputProps={{ maxLength: 32, }}
              fullWidth
              {...field}
              margin='normal'
            />
          )}
        />
        <Controller
          name='userInfo.mobile'
          control={control}
          render={({ field }) => (
            <TextField
              sx={{
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              className='w-full lg:w-1/3 lg:max-w-[325px]'
              label={t("telephone")}
              variant='outlined'
              fullWidth
              type="number"
              {...field}
              margin='normal'
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default UserInfo;
