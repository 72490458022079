import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Countries from './country.json';
import MapFeatures from './features.json';

interface DataReducerInterface {
    countries: Array<any>;
    mapFeatures: Array<any>;
    androidPackages: Array<any> | null;
    iosPackages: Array<any> | null;
};

const initialState: DataReducerInterface = {
    countries: Countries,
    mapFeatures: MapFeatures,
    androidPackages: null,
    iosPackages: null
};

const DataReducerSlice = createSlice({
    name: 'dataReducer',
    initialState,
    reducers: {
        setAndroidPackages: (state, action: PayloadAction<any>) => {
            state.androidPackages = action.payload
        },
        setIOSPackages: (state, action: PayloadAction<any>) => {
            state.iosPackages = action.payload
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = initialState;
        }
    }
});

export const { setAndroidPackages, setIOSPackages, userLogout } = DataReducerSlice.actions;

export default DataReducerSlice.reducer;