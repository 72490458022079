import { Tab, Tabs, Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LineChart from "../../charts/LineChart";
import { DummyLineChart } from "../../charts/DummyChart";


interface DashboardThreatAnalysisProps {
  totalData: any;
}

const DashboardThreatAnalysis = ({
  totalData
}: DashboardThreatAnalysisProps) => {
  const { t } = useTranslation();
  const [selectedAnalysis, setSelectedAnalysis] = useState("activeDevices");

  const navItems = [
    {
      id: "activeDevices",
      label: t("active_devices"),
      value: totalData.analytics.activeDevice.toLocaleString(),
      percentChange: totalData.analytics.activeDeviceChange,
    },
    {
      id: "hackingAttempts",
      label: t("hacking_attempts"),
      value: totalData.analytics.hackingAttempts.toLocaleString(),
      percentChange: totalData.analytics.hackingAttemptsChange,
    },
    {
      id: "deviceAttempts",
      label: t("unique_devices_hacking_attempts"),
      value: totalData.analytics.deviceAttempts.toLocaleString(),
      percentChange: totalData.analytics.deviceAttemptsChange,
    },
  ];

  const chartTooltip = (tooltipData: any) => {
    const data: any = tooltipData;
    const label = selectedAnalysis === "activeDevices" ? t("no_of_devices"): selectedAnalysis === "hackingAttempts"? t("no_of_hacking_types"): t("no_of_hacking_types");
    return data ? (
      <div className="bg-primary p-2 border border-solid">
        <p>
          {t("detection_date")} : <b>{data.calc_date}</b>
        </p>
        <p>
          {label} :{" "}
          <b>{data.y ? data.y?.toLocaleString() : data.y}</b>
        </p>
      </div>
    ) : (
      <p />
    );
  };
  return (
    <div className="col-span-12 xl:col-span-8 flex flex-col min-h-24 py-5 px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] min-w-[400px]">
      <p className="text-base cursor-pointer mb-3.5">
        <Tooltip
          title={t("daily_data_will_be_start_processing_after_next_day")}
        >
          <span>{t("threat_analysis")}</span>
        </Tooltip>
      </p>
      <div className="mt-2">
        <div>
        <Tabs
          value={selectedAnalysis}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            '.MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
            {navItems.map((item) => (
              <Tab
                key={item.id}
                value={item.id}
                sx={{padding: 0, marginRight: '4em'}}
                label={
                  <div
                  key={item.id}
                  className="cursor-pointer text-left border-none"
                  onClick={() => setSelectedAnalysis(item.id)}
                >
                  <p className={`pb-1 text-xs normal-case lg:text-sm ${selectedAnalysis === item.id ? "font-semibold text-accent border-b-2 border-[#8E44AD]" : "text-disabled"}`}>
                    {item.label}
                  </p>
                  <p className="text-2xl lg:text-2.5xl py-4 text-[#2C2C2C]">{item.value}</p>
                  <p className={`${item.percentChange < 0 ? "text-warning" : "text-[#127BF5]"}`}>
                    {` ${Math.abs(item.percentChange).toFixed(2)} %`}
                  </p>
                </div>
                }
              />
            ))}

        </Tabs>
    
            
          
        </div>
      </div>
      <div className="flex-grow mt-2 h-[250px] lg:h-[420px]">
        {totalData.chartData[selectedAnalysis].length > 1 ? 
          <LineChart data={totalData.chartData[selectedAnalysis]} chartTooltip={chartTooltip}/>
          : <DummyLineChart/>
        }
      </div>
    </div>
  );
};

export default DashboardThreatAnalysis;
