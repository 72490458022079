// Dummy data for Bar Chart
export const barData = (count:number) => {
  return  Array.from({length: count}, (_, i) => ({
    x: i + 1,
    value: i + 1,
  }));
}



// Dummy data for Line Chart
export const  lineData = [
  {
    id: "line1",
    data: Array.from({length: 30}, (_, i) => ({
      x: i + 1,
      y: 0,
    })),
  },
];

// Dummy data for Pie Chart
export const pieData = [
  { id: "slice1", label: "Slice 1", value: 10 },
  { id: "slice2", label: "Slice 2", value: 20 },
  { id: "slice3", label: "Slice 3", value: 30 },
];
