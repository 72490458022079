import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, FormControlLabel, RadioGroup, Radio, Button, FormControl, Switch, Chip } from '@mui/material';
import DataTable from '../../../../DataDisplay/Table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useGroupManagement } from '../../../../../hooks/useGroupManagement';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useLocation } from 'react-router-dom';

interface ManageAccessProps {
    groupAppId: number | string;
}

const ManageAccess = ({ groupAppId }: ManageAccessProps) => {
    const location = useLocation();
    const { subGroupList, permissionList, handleGrantPermission, handleUpdatePermission, handleRemovePermission } = useGroupManagement();
    const [selectedMember, setSelectedMember] = useState<any>(null);
    const [filteredSubGroupList, setFilteredSubGroupList] = useState<any[]>([]);
    const [platform, setPlatform] = useState<string>('');
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const accountDetails = useSelector((state: RootState) => state.mainReducer.accountDetails);
    const isBetaAccount = authContext?.is_beta_account;
    const isOwner = authContext?.role === 'Owner';
    const isSubOwner = authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;
    const { t } = useTranslation();

    let query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        const updatedSubGroupList = subGroupList.map((member) => ({
            ...member,
            disabled:
                permissionList.some((permission) => permission.memberId === member.memberId && permission.groupAppId === groupAppId) ||
                member.type === 'Manager' ||
                accountDetails.owner === member.userId,
        }));
        setFilteredSubGroupList(updatedSubGroupList);
        setPlatform(query.get('platform') || '');
    }, [subGroupList, permissionList, groupAppId]);

    const filterPermissionList = (data: any) => {
        return data.filter((member: { groupAppId: string | number }) => member.groupAppId === groupAppId);
    };
    const updatePermission = (id: string, permissionType: string, value: boolean) => {
        const isActive = value ? `active-${permissionType}` : `inactive-${permissionType}`;
        handleUpdatePermission(id, isActive);
    };

    const grantPermission = () => {
        handleGrantPermission(groupAppId, platform, selectedMember.memberId);
        setSelectedMember(null);
    };

    const revokePermission = (permissionId: string) => {
        handleRemovePermission(permissionId);
    };

    const updateKeyStorePermission = (id: string, value: boolean) => {
        const isActive = value ? `use-keystore` : `un-use-keystore`;
        handleUpdatePermission(id, isActive);
    };
    const columns: GridColDef[] = [
        {
            field: 'userId',
            headerName: 'ID',
            flex: 1,
        },
        {
            field: 'groupMemberType',
            headerName: t('position'),
            flex: 0.5,
        },
        {
            field: 'analytics',
            headerName: t('analytics'),
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => {
                const { permissionId } = params.row;
                const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedPermission = event.target.checked;
                    updatePermission(permissionId, event.target.name, updatedPermission);
                };
                return (
                    <div>
                        <Switch
                            size="small"
                            name="analytics"
                            disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false}
                            defaultChecked={params.row.analyticsActive}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                );
            },
        },
        {
            field: 'permission',
            headerName: platform === 'ios' ? t('download_sdk') : t('sealing_permission'),
            flex: platform === 'ios' ? 0.5 : 1.5,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div className="flex items-center gap-5 h-[52px]">
                        {platform === 'ios' ? (
                            <div>
                                <Switch
                                    size="small"
                                    name="sealing"
                                    disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false}
                                    defaultChecked={params.row.seaLingActive}
                                    onChange={(e) => updatePermission(params.row.permissionId, 'sealing', e.target.checked)}
                                />
                            </div>
                        ) : (
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={params.row.seaLingActive ? 'sealing' : params.row.presetActive ? 'preset' : 'none'}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        updatePermission(params.row.permissionId, 'preset', newValue === 'preset');
                                        updatePermission(params.row.permissionId, 'sealing', newValue === 'sealing');
                                        params.api.updateRows([
                                            {
                                                ...params.row,
                                                seaLingActive: newValue === 'sealing',
                                                presetActive: newValue === 'preset',
                                            },
                                        ]);
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Radio disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false} size="small" value="none" style={{ padding: '3px 9px' }} />}
                                        label={t('none')}
                                    />
                                    <FormControlLabel
                                        control={<Radio disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false} size="small" value="preset" style={{ padding: '3px 9px' }} />}
                                        label={t('preset')}
                                    />
                                    <FormControlLabel
                                        control={<Radio disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false} size="small" value="sealing" style={{ padding: '3px 9px' }} />}
                                        label={t('option_control')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    </div>
                );
            },
        },
        {
            field: 'keystore',
            headerName: t('keystore'),
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <div className="flex items-center gap-5 h-[52px]">
                    <Switch
                        size="small"
                        name="keystore"
                        disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false}
                        defaultChecked={params.row.keyStoreUsed}
                        onChange={(e) => updateKeyStorePermission(params.row.permissionId, e.target.checked)}
                    />
                </div>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 0.3,
            renderCell: (params: GridRenderCellParams) => {
                const handleRemoveClick = () => {
                    revokePermission(params.row.permissionId);
                };

                return (
                    <Button variant="text" disabled={isBetaAccount ? !(isOwner || isManager || isSubOwner) : false} className="p-0 capitalize cursor-pointer text-error" onClick={handleRemoveClick}>
                        {t('remove')}
                    </Button>
                );
            },
        },
    ];

    const getRowId = (row: any) => row.userId;

    return (
        <div>
            <div className="my-6">
                <div className="flex items-center gap-6">
                    <div className="flex flex-row w-full gap-6">
                        <Autocomplete
                            fullWidth
                            className="w-1/2"
                            options={filteredSubGroupList}
                            getOptionLabel={(option) => option.userId}
                            renderOption={(props, option) => (
                                <li {...props} key={option.userId} style={{ pointerEvents: option.disabled ? 'none' : 'auto', opacity: option.disabled ? 0.5 : 1 }}>
                                    {option.userId} {option.type === 'Manager' && <Chip className="ml-2" color="secondary" label={t('manager')} size="small" />}
                                    {accountDetails.owner === option.userId && <Chip className="ml-2" color="secondary" label={t('owner')} size="small" />}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('find_member')}
                                    placeholder={t('enter_member_name')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            value={selectedMember}
                            onChange={(event, newValue) => {
                                setSelectedMember(newValue);
                            }}
                        />
                    </div>
                    <Button variant="contained" onClick={grantPermission} disabled={!selectedMember || (isBetaAccount ? !(isOwner || isManager || isSubOwner) : false)}>
                        {t('add')}
                    </Button>
                </div>
                <div className="w-full overflow-x-auto ">
                    <div className="min-w-[950px]">
                        <DataTable
                            columns={platform === 'ios' ? columns.filter((col, index) => index !== 4) : columns}
                            rows={filterPermissionList(permissionList)}
                            pageSize={10}
                            pageSizeOptions={[5, 10]}
                            getRowId={getRowId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageAccess;
