import React, { useEffect, useState } from 'react';
import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { showDialog, showToast } from '../../../../utils/notifications';
import LoadMask from '../../../../utils/loadmask';
import DragnDrop from '../../../../utils/dragndrop';
import { deleteKeyStore, getKeyStoreInfo, uploadKeyStore } from '../../../../../services/sealing/android';
import { setKeyStoreInfo } from '../../../../../store/sealing/android';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Accept } from 'react-dropzone';
import CachedIcon from '@mui/icons-material/Cached';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const acceptFiles: Accept = {
    '': ['*'],
};
const ManageKeyStore = (props: { showTitle?: boolean; endBtn?: boolean; packageName?: string }) => {
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const [showMask, setShowMask] = useState<any>(null);
    const [key, setKey] = useState(0);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const keyStoreInfo = useSelector((state: RootState) => state.androidSealingReducer.keyStoreInfo);
    const [showPassword, setShowPassword] = useState(false);
    const [showKeyPassword, setShowKeyPassword] = useState(false);

    useEffect(() => {
        const fetchKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                account_status: authContext.account_status,
                account_type: authContext.account_type,
                auth_key: authContext.auth_key,
                role: authContext.role,
                user_status: authContext.user_status,
                packageName: props.packageName,
            };
            const response = await getKeyStoreInfo(params, region);
            if (response?.result?.code === '0000') dispatch(setKeyStoreInfo(response?.result?.keystore));
        };
        fetchKeyStoreInfo();
    }, [region, authContext, dispatch, props.packageName]);

    const onUpdateClick = async (values: any) => {
        const updateKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                packageName: props.packageName,
                keystoreFile: values.keystoreFile,
                ksPass: values.ksPass,
                keyPass: values.keyPass,
                alias: values.alias,
            };
            setShowMask({ msg: t('updating_key_store_information') });
            const response = await uploadKeyStore(params, region);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                reset();
                dispatch(setKeyStoreInfo(response?.result?.keystore));
                showToast('success', t('keystore_information_updated_successfully'));
            } else if (response?.result?.code) {
                dispatch(setKeyStoreInfo(null));
                if (response?.result?.code === '10002') {
                    showToast('error', t(`RCM-10002`, { msg: response?.result?.message }));
                } else {
                    showToast('error', t(`RCM-${response?.result?.code}`, 'failed_to_update_keystore_information'));
                }
            } else {
                dispatch(setKeyStoreInfo(null));
                showToast('error', t('failed_to_update_keystore_information'));
            }
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_update_keystore_information'),
            onOk: updateKeyStoreInfo,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onDeleteKeyStoreInfo = async () => {
        const deleteKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                alias: keyStoreInfo.alias,
                keystoreFileName: keyStoreInfo.keystoreFileName,
                packageName: props.packageName,
            };
            setShowMask({ msg: t('updating_key_store_information') });
            const response = await deleteKeyStore(params, region);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                reset();
                dispatch(setKeyStoreInfo(null));
                showToast('success', t('keystore_information_deleted_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_delete_keystore_information')));
            else showToast('error', t('failed_to_delete_keystore_information'));
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_delete_keystore_information'),
            onOk: deleteKeyStoreInfo,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onResetClick = () => {
        reset();
        setKey(1);
    };
    const handleKeyStoreFile = (files: any) => {
        setValue('keystoreFile', files[0]);
    };
    return (
        <div className="flex w-full h-full gap-10">
            <div className="flex flex-col w-full">
                {showMask && <LoadMask msg={showMask.msg} />}

                <div>
                    {keyStoreInfo ? (
                        <div className="flex gap-10">
                            <TextField size="small" label={t('alias')} value={keyStoreInfo.alias} fullWidth variant="outlined" inputProps={{ readOnly: true }} />
                            <TextField size="small" label={t('keystore_file_name')} value={keyStoreInfo.keystoreFileName} fullWidth variant="outlined" inputProps={{ readOnly: true }} />
                            <hr />

                            <Button variant="outlined" color="error" onClick={onDeleteKeyStoreInfo}>
                                {t('delete')}
                            </Button>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit(onUpdateClick)}>
                            <div className="flex justify-between gap-20 mb-7">
                                <div className="flex justify-between w-full gap-3">
                                    <Controller
                                        name="keyPass"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('please_enter_key_password') }}
                                        render={({ field }) => (
                                            <TextField
                                                size="small"
                                                label={t('key_password')}
                                                error={!!errors.keyPass}
                                                helperText={errors.keyPass?.message as string}
                                                fullWidth
                                                variant="outlined"
                                                type={showKeyPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowKeyPassword(!showKeyPassword)}
                                                                edge="end"
                                                            >
                                                                {showKeyPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="alias"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('please_enter_alias_for_signing_key') }}
                                        render={({ field }) => (
                                            <TextField
                                                size="small"
                                                label={t('alias')}
                                                className="max-w-[320px]"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.alias}
                                                helperText={errors.alias?.message as string}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="ksPass"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('please_enter_keystore_password') }}
                                        render={({ field }) => (
                                            <TextField
                                                size="small"
                                                label={t('keystore_password')}
                                                error={!!errors.ksPass}
                                                helperText={errors.ksPass?.message as string}
                                                fullWidth
                                                variant="outlined"
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                    
                                </div>
                                <div>
                                    {keyStoreInfo ? (
                                        !props.endBtn && (
                                            <Button variant="outlined" color="error" onClick={onDeleteKeyStoreInfo}>
                                                {t('delete')}
                                            </Button>
                                        )
                                    ) : (
                                        <div className="flex justify-end gap-2">
                                            <Button variant="outlined" className="w-[36px] h-[36px] p-0" color={'secondary'} onClick={onResetClick} disabled={isDirty ? false : true}>
                                                {<CachedIcon />}
                                            </Button>
                                            <Button
                                                disabled={isDirty ? false : true}
                                                className={'min-w-[100px]'}
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={handleSubmit(onUpdateClick)}
                                            >
                                                {t('update')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Controller
                                name="keystoreFile"
                                control={control}
                                defaultValue=""
                                rules={{ required: t('please_upload_keystore_file') }}
                                render={({ field }) => (
                                    <div className="w-full md:w-1/2 lg:w-1/3">
                                        <DragnDrop
                                            key={key}
                                            maxFiles={1}
                                            accept={acceptFiles}
                                            onChange={handleKeyStoreFile}
                                            title={t('click_or_drag_key_store_file')}
                                            subtitle={t('supported_ext_keystore_file')}
                                        />
                                        {errors.keystoreFile && <div className="text-xs italic text-warning">{String(errors.keystoreFile?.message)}</div>}
                                    </div>
                                )}
                            />
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageKeyStore;
