import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getAppList } from '../../../../services/manage/settings';
import { filterBy } from '../../../../utility/filtering';

import { updateAdvanceService, updateService } from '../../../../services/securityservices';
import { showDialog, showToast } from '../../../utils/notifications';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { Chip, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DropdownMenu from '../../../utils/dropDownMenu';
import DataTable from '../../../DataDisplay/Table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getLocalisedDate } from '../../../utils';

const ManageService = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<any>([]);
    const [filterText, setFilterText] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [appList, setAppList] = useState<any[] | null>(null);
    const [platform, setPlatform] = useState<string>('android');
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isBetaAccount = authContext?.is_beta_account;
    useEffect(() => {
        if (appList === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };
                setShowMask({ msg: t('fetching_application') });
                const response = await getAppList(params, region);
                setShowMask(null);
                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus;
                }
                setAppList(data);
            };
            fetchApplications();
        }
    }, [region, appList, authContext, t]);

    const onPlatformChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPlatform((e.target as HTMLInputElement).value);
        setFilterText('');
        setFilters([]);
    };

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'app_name', value: value, operator: 'contains' },
                    { field: 'package_name', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };

    const updateServices = async (type: string, params: any) => {
        let data: any = null;
        if (type === 'service') {
            const response = await updateService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('app_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { service_onoff: params.status };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', `${t('app_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'datasealing') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('data_sealing_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { use_data_sealing: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('data_sealing_service_failed_to_update_for_package')));
            else showToast('error', `${t('data_sealing_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'otaservice') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('ota_service_is_updated_for_the_package', 'OTA Service is updated for the package')} ${params.package_name}`);
                data = { use_ota_service: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('ota_failed_to_update_for_package')));
            else showToast('error', `${t('ota_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        }

        if (data && Array.isArray(appList)) {
            setAppList((oldData: any) => {
                const index = oldData.findIndex((e: any) => e.platform === platform && e.package_name === params.package_name);
                let item = oldData[index];
                oldData[index] = { ...item, ...data };
                return [...oldData];
            });
        }
    };

    const onSwitchToggle = async (type: string, params: any) => {
        let msg = '';
        switch (type) {
            case 'service':
                msg = params.status === 'Y' ? t('app_can_be_sealed_and_will_incur_a_monthly_fee') : t('app_cannot_be_sealed_and_data_will_not_collected_while_the_sealing_option_is_off');
                break;
            case 'datasealing':
                msg =
                    params.request === 'Y'
                        ? isBetaAccount
                            ? t('beta_datasealing_option_will_be_available_while_applying_security_to_your_app')
                            : t('datasealing_option_will_be_available_while_applying_security_to_your_app')
                        : t('datasealing_is_not_supported_when_applying_security_to_your_app_any_longer');
                break;
            case 'otaservice':
                msg =
                    params.request === 'Y'
                        ? t('once_activated_you_can_manage_the_custom_blacklist_by_yourself_and_it_is_updated_over')
                        : t('custom_blacklist_option_is_not_supported_when_applying_security_to_your_app_any_longer_and_mad_is_not_counted_any_longer');
                break;
            default:
                break;
        }
        if (msg) {
            showDialog({
                title: t('confirm_update'),
                content: msg,
                onOk: () => updateServices(type, params),
                okText: t('yes'),
                cancelText: t('no'),
            });
        } else updateServices(type, params);
    };

    const handleMenuAction = (action: string, app: any) => {
        if (action === 'configure') {
            navigate(`/settings/service/configure/${app.id}${app.groupId ? `?groupId=${app.groupId}&` : '?'}platform=${app.platform}`);
        } else if (action === 'service_toggle') {
            const params = {
                api_ver: '4',
                platform: 'android',
                token: authContext.token,
                package_name: app.package_name,
                status: app.service_onoff === 'Y' ? 'N' : 'Y',
            };
            onSwitchToggle('service', params);
        } else if (action === 'delete') {
            onSwitchToggle('delete', app);
        }
    };

    const AppColumns: GridColDef[] = [
        { field: 'app_name', headerName: t('app_name'), flex: 1 },
        { field: 'package_name', headerName: t('package_name'), flex: 1 },
        {
            field: 'status',
            headerName: t('service_state'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <Chip color={params.row.service_onoff === 'Y' ? 'primary' : 'secondary'} label={params.row.service_onoff === 'Y' ? t('on') : t('off')} />,
        },
        { field: 'create_date', headerName: t('app_register_date'), flex: 1 },
        { field: 'groupName', headerName: t('group'), flex: 1 },
        {
            field: 'action',
            headerName: t('action'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const menuOptions = [
                    { label: t('configure'), value: 'configure' },
                    { label: params.row.service_onoff === 'Y' ? t('service_off') : t('service_on'), value: 'service_toggle' },
                    { label: t('delete'), value: 'delete', className: 'text-red-500' },
                ];

                return <DropdownMenu menuOptions={menuOptions} handleMenuChange={(action: string) => handleMenuAction(action, params.row)} />;
            },
        },
    ];

    const filteredRows = useMemo(() => {
        if (!Array.isArray(appList)) return [];

        return (filterBy(appList, filters) || [])
            .filter((e: any) => e.platform === platform)
            .map((item: any, index: number) => ({
                ...item,
                key: index,
                id: item.id || `${item.package_name}-${index}`, // Use existing id or create a unique id
                create_date: item.create_date ? moment(
                    getLocalisedDate(new Date(item.create_date))).format("YYYY/MM/DD HH:mm:ss"): '', // 로칼라이즈된 날짜로 변경
            }));
    }, [appList, filters, platform]);
    return (
        <ContentLayout fullWidth={true} showMask={showMask} btnText={t('save')}>
            <div className="grid content-start h-full gap-8 pr-2">
                <div className="flex flex-col gap-6 mb-8 1xl:flex-row">
                    <div className="w-full lg:w-[316px] text-2xl font-medium whitespace-nowrap">{t('manage_services')}</div>
                    <div className="flex flex-col flex-wrap flex-1 gap-4 text-center xl:flex-nowrap sm:flex-row">
                        <RadioGroup className="min-w-[200px] flex flex-row items-center gap-6 text-sm" value={platform} onChange={onPlatformChange}>
                            <FormControlLabel value="android" control={<Radio />} label={t('android')} />
                            <FormControlLabel value="ios" control={<Radio />} label={t('ios')} />
                        </RadioGroup>
                        <TextField
                            className="w-full max-w-full lg:max-w-[430px] text-sm"
                            variant="outlined"
                            onChange={onSearchClick}
                            defaultValue={filterText}
                            label={t('search')}
                            size="small"
                            placeholder={platform === 'android' ? t('search_app_name_or_package_name') : t('search_app_name_or_bundle_id')}
                        />
                    </div>
                </div>
                <div className="overflow-auto" tabIndex={-1}>
                    <div>
                        <DataTable
                            height={600}
                            columns={AppColumns}
                            rows={filteredRows.slice(0, filteredRows.length)}
                            pageSize={10}
                            sorting={{ field: 'create_date', sort: 'desc' }}
                        />
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
};

export default ManageService;
