import { Button } from '../../../Foundation/Button';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { openHelpCenter } from "../../../../services/main";
import { RootState } from "../../../../store";
import { AppURL } from "../../../../utility/AppURL";
import ConfirmWithdrawal from "./confirmwithdrawal";
import { useTranslation } from "react-i18next";
import { FormGroup, Stack, FormControlLabel, TextField, Checkbox } from "@mui/material";
import ContentLayout from '../../../Layout/Content';
import { showToast } from '../../../utils/notifications';



const options = [
    { value: 'Pricing Policy', label: 'Higher billing rates' },
    { value: 'Tech Supoprt', label: 'Unfriendly Customer Support' },
    { value: 'Hacking after AppSealing', label: 'Hacking occurred after AppSealing' },
    { value: 'Decreased App Performance', label: 'App performance degradation after AppSealing' },
    { value: 'Less Sealing Option Less Dynamic Monitoring&Control on Web', label: 'Lack of sealing features' },
    { value: 'Etc', label: 'Other' },
];

const AccountWithdrawal = () => {
    const { i18n, t } = useTranslation();
    const [selection, setSelection] = useState<Array<any>>([]);
    const [feedbackDetails, setFeedbackDetails] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    
    const onDeleteClick = () => {
        if (selection.length)
            setIsModalVisible(true);
        else
            showToast('error', t('please_specify_reason_for_account_withdrawal', 'Please specify reason for account withdrawal'));
    };

    const handleCancel = () => setIsModalVisible(false);

    const onHelpCenterClick = () => openHelpCenter(authContext.token, AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'));

    return (
        <ContentLayout>
            <ConfirmWithdrawal visible={isModalVisible} onCancel={handleCancel}
                feedbackSelection={selection} feedbackDetails={feedbackDetails} />
            <div className="ant-descriptions-title text-2xl flex-none mb-4 font-medium">
                {t('account_withdrawal')}
            </div>
            <div className="h-full flex flex-col overflow-auto ml-1">
                <div className="flex-grow">
                    <div className="ant-descriptions-title mb-2 text-base font-bold">{t('account_withdrawal_title')}:</div>
                    <div className="p-2 pb-10">
                        <ul className="list-disc p-0 m-0 pl-5 leading-6 text-darkgray">
                            <li>{t('account_withdrawal_point_1')}</li>
                            <li>{t('account_withdrawal_point_2')}</li>
                            <li>{t('account_withdrawal_point_3')}<span className='ml-0 sm:ml-1 text-linkblue underline cursor-pointer' onClick={onHelpCenterClick}>{t('help_center')}</span></li>
                        </ul>
                    </div>
                    <hr />
                    <div className="ant-descriptions-title mt-10 mb-2 text-base font-bold">{t('tell_us_why_you_re_closing_account')}:</div>
                    <div className="p-10 pt-2 pl-2 pb-0">
                        <FormGroup>
                            <Stack direction="column">
                                {options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                    <Checkbox
                                        checked={selection.includes(option.value)}
                                        onChange={(e) => setSelection(e.target.checked ? [...selection, option.value] : selection.filter((item) => item !== option.value))}
                                        value={option.value}
                                        size="small"
                                    />
                                    }
                                    label={t(option.label.toLowerCase().replaceAll(' ', '_'), option.label)}
                                />
                                ))}
                            </Stack>
                        </FormGroup>
                        <TextField
                            multiline
                            label={t('your_feedback_matters')}
                            minRows={2}
                            maxRows={6}
                            value={feedbackDetails}
                            onChange={(e) => setFeedbackDetails(e.target.value)}
                            variant="outlined"
                            fullWidth
                            className='mt-4'
                        />
                        <div className="flex flex-row mt-8 gap-x-4">
                            <Button variant="outlined" color="secondary" className="border-disabled uppercase text-xs font-semibold" onClick={onDeleteClick} ><span className='text-disabled'>{t('delete_account')}</span></Button>
                        </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
        );
}

export default AccountWithdrawal;