import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GeneralFeaturesData, MenuProps, appFrameworks, isHybridFramework } from './data';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Hint } from '../../../utils';

interface BasicConfigurationProps {
    control: any;
    formData: any;
    errors: any;
    fileType: string;
    sealingData: any;
    serviceables: any;
    getEl: (data: any, type: string) => any;
    isReleaseEnabled: (type: string) => boolean;
    onFieldsChange: (fields: any) => void;
    validateInput: () => any;
    isAndroidExpired: (type: string) => boolean;
    noTitle?: boolean;
}

const BasicConfiguration = ({
    control,
    formData,
    errors,
    onFieldsChange,
    validateInput,
    isAndroidExpired,
    fileType,
    sealingData,
    isReleaseEnabled,
    serviceables,
    getEl,
    noTitle = false,
}: BasicConfigurationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    useEffect(() => {
        if (formData.always_latest !== checkboxChecked) {
            setCheckboxChecked(formData.always_latest);
        }
    }, [formData, checkboxChecked]);

    const onUpgradeClick = () => {
        navigate(`/billingdetails/plan`);
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxChecked(event.target.checked);
        onFieldsChange([{ name: ['always_latest'], value: event.target.checked }]);
    };

    const getTheHighestVersion = (sealingData: any) => {
        const compareVersions = (v1: string, v2: string): number => {
            const v1Parts: (string | number)[] = v1.split('.').map((part) => (isNaN(Number(part)) ? part : parseInt(part, 10)));
            const v2Parts: (string | number)[] = v2.split('.').map((part) => (isNaN(Number(part)) ? part : parseInt(part, 10)));

            for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
                if (v1Parts[i] === undefined) return -1;
                if (v2Parts[i] === undefined) return 1;
                if (v1Parts[i] < v2Parts[i]) return -1;
                if (v1Parts[i] > v2Parts[i]) return 1;
            }
            return 0;
        };
        const highestVersion = sealingData.reduce((max: any, obj: any) => {
            return compareVersions(max.service_version, obj.service_version) > 0 ? max : obj;
        });
        
        return highestVersion.service_version;
    };
    return (
        <div className="flex flex-col gap-8">
            {!noTitle && <p className="font-semibold text-base underline">{t('basic_configuration')}</p>}
            {serviceables?.errors && serviceables?.errors?.length > 0 && (
                <div>
                    {(serviceables?.errors.includes('androidpay') || serviceables?.errors.includes('hybridpay')) && (
                        <React.Fragment>
                            <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>
                                {t('update_billing_information')}
                            </Button>
                            <p className="text-red-500">{t('billing_information_missing')}</p>
                        </React.Fragment>
                    )}
                    {serviceables?.errors.includes('android') && !isHybridFramework(formData.app_framework) && (
                        <React.Fragment>
                            <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>
                                {t('upgrade_android_plan')}
                            </Button>
                            <p className="text-red-500">{t('android_trial_plan_period_is_expired')}</p>
                        </React.Fragment>
                    )}
                    {serviceables?.errors.includes('hybrid') && isHybridFramework(formData.app_framework) && (
                        <React.Fragment>
                            <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>
                                {t('upgrade_hybrid_plan')}
                            </Button>
                            <p className="text-red-500">{t('hybrid_trial_plan_period_is_expired')}</p>
                        </React.Fragment>
                    )}
                </div>
            )}
            <div className="flex flex-wrap gap-7 items-center lg:items-start lg:flex-col">
                <div>
                    <h4 className="font-semibold text-sm mb-4">{t('select_your_app_framework')}</h4>
                    <div className="flex font-semibold items-center mt-1 mb-1">
                        <Controller
                            name="app_framework"
                            control={control}
                            defaultValue={formData.app_framework || null}
                            rules={{
                                required: t('please_select_your_app_framework'),
                            }}
                            render={({ field }) => (
                                <FormControl fullWidth size="small" className="w-60 mb-0" error={Boolean(errors.app_framework)}>
                                    <InputLabel id="app_framework_label">{t('select_your_app_framework')}</InputLabel>
                                    <Select
                                        {...field}
                                        size="small"
                                        labelId="app_framework_label"
                                        label={t('select_your_app_framework')}
                                        onChange={(e) => onFieldsChange([{ name: ['app_framework'], value: e.target.value }])}
                                        disabled={!validateInput().basicSetting}
                                        error={Boolean(errors.app_framework)}
                                    >
                                        {appFrameworks.map((item) => (
                                            <MenuItem key={item.detail} value={item.detail}>
                                                <span>{item.name}</span>
                                                {isAndroidExpired(item.type) && <Chip label="Expired" color="error" className="ml-2" />}
                                                {item.type === 'hybrid' && <Chip label="Hybrid" className="ml-2 bg-[#B0A32F]" />}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(errors.app_framework) && <FormHelperText className="text-infored italic">{t('select_your_app_framework')}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Tooltip title={t('application_framework_is_platform_used_to_develop_your_android_application')}>
                            <HelpOutlineOutlinedIcon className="ml-2 align-middle text-subtitle text-sm" />
                        </Tooltip>
                    </div>
                    <Hint text={t('app_framework_hint')} />
                </div>
                {fileType !== 'zip' && (
                    <div>
                        <h4 className="font-semibold text-sm">{t('select_your_app_category')}</h4>
                        <div>
                            <Controller
                                name="app_type"
                                control={control}
                                defaultValue={formData.app_type || null}
                                rules={{
                                    required: t('please_select_app_category'),
                                }}
                                render={({ field }) => (
                                    <RadioGroup {...field} row value={formData.app_type} onChange={(e) => onFieldsChange([{ name: ['app_type'], value: e.target.value }])}>
                                        <FormControlLabel disabled={!!!validateInput().basicSetting} value="NON_GAME" control={<Radio size="small" />} label={t('non_game_app_security')} />
                                        <FormControlLabel disabled={!!!validateInput().basicSetting} value="GAME" control={<Radio size="small" />} label={t('game_app_security')} />
                                    </RadioGroup>
                                )}
                            />
                        </div>
                    </div>
                )}
                <div>
                    <h4 className="font-semibold text-sm mb-4">{t('select_sealing_version')}</h4>
                    <div className="flex font-semibold items-center mt-1 mb-1">
                        <Controller
                            name="service_version"
                            control={control}
                            defaultValue={formData.service_version || ''}
                            rules={{
                                required: t('please_select_sealing_version'),
                            }}
                            render={({ field }) => (
                                <FormControl fullWidth size="small" className="w-60 mb-0" error={Boolean(errors.service_version)}>
                                    <InputLabel id="sealing_version_label">{t('select_sealing_version')}</InputLabel>
                                    <Select
                                        {...field}
                                        size="small"
                                        value={formData.service_version || ''}
                                        disabled={!formData.app_framework || !!!validateInput().basicSetting || checkboxChecked}
                                        labelId="sealing_version_label"
                                        label={t('select_sealing_version')}
                                        onChange={(e) => onFieldsChange([{ name: ['service_version'], value: e.target.value }])}
                                        MenuProps={MenuProps}
                                        error={Boolean(errors.service_version)}
                                    >
                                        {sealingData.map((item: any) => (
                                            <MenuItem key={`${item.type}-${item.service_version}`} value={item.service_version}>
                                                {item.service_version}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(errors.service_version) && <FormHelperText className="text-infored italic">{t('select_sealing_version')}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Tooltip title={t('details_related_to_sealing_versions_to_be_applied_on_application')}>
                            <HelpOutlineOutlinedIcon className="ml-2 align-middle text-subtitle text-sm" />
                        </Tooltip>
                    </div>
                    <Hint text={t('appsealing_version_hint')} />
                    {formData?.service_version && getTheHighestVersion(sealingData) !== formData.service_version && <Hint text={t('older_appsealing_version')} className="text-red-500" />}
                    {noTitle && <FormControlLabel control={<Checkbox disabled={!formData.app_framework || !!!validateInput().basicSetting} size="small" checked={checkboxChecked} onChange={handleCheckboxChange} color="primary" />} label={t('always_use_latest')} />}
                </div>
                {fileType !== 'zip' && (
                    <div>
                        <h4 className="font-semibold text-sm">{t('sealing_mode')}</h4>
                        <div>
                            <Controller
                                name="mode"
                                control={control}
                                defaultValue={formData.mode || null}
                                rules={{
                                    required: t('please_select_app_mode'),
                                }}
                                render={({ field }) => (
                                    <RadioGroup {...field} row value={formData.mode} onChange={(e) => onFieldsChange([{ name: ['mode'], value: e.target.value }])}>
                                        <Tooltip title={t('no_charge_will_be_incurred')}>
                                            <FormControlLabel disabled={!!!validateInput().basicSetting} value={'test'} control={<Radio />} label={t('test')} />
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                isReleaseEnabled(formData.app_framework)
                                                    ? t('charges_will_be_incurred_and_test_mode_message_will_be_removed')
                                                    : t('release_mode_is_restricted_for_trial_plan_user')
                                            }
                                        >
                                            <FormControlLabel disabled={!isReleaseEnabled(formData.app_framework)} value={'release'} control={<Radio />} label={t('release')} />
                                        </Tooltip>
                                    </RadioGroup>
                                )}
                            />
                        </div>
                        <Hint text={t('sealing_mode_hint')} />
                    </div>
                )}
                {fileType !== 'zip' && (
                    <div className="flex flex-col gap-2 w-full max-w-[600px]">
                        <h4 className="font-semibold text-sm">{t('encryptions')}</h4>
                        {getEl(
                            GeneralFeaturesData.filter((e) => e.type === 'encryptions'),
                            'encryptions',
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BasicConfiguration;
