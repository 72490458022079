import React from 'react';
import { useTranslation } from 'react-i18next';
import PromoImage from '../../assets/images/promo_main.png';
import { Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const features = [
    'try_free_for_30_days',
    'secure_mobile_apps_without_any_coding',
    'integrate_security_into_devsecops_strategy',
    'real-time_threat_analytics_dashboard',
    'cli_tool_for_ci/cd_pipeline',
    'app_security_and_data_encryption_in_a_single_workflow',
];

interface AuthLayoutProps {
  children: React.ReactNode;
  BackButton?: boolean;
  hideInfo?: boolean;
}

const AuthLayout = ({ children, BackButton = true, hideInfo=false }: AuthLayoutProps) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month.toString();
  const navigate = useNavigate();
  
  return (
    <div
      className="max-h-screen h-screen bg-no-repeat relative flex flex-none justify-center items-center"
      style={{
        backgroundImage: `linear-gradient(to bottom right, #D72544, #362674)`,
      }}
    >
      <div className="max-w-[1040px] w-full flex flex-none max-h-screen py-20 lg:py-0 max-h-auto min-h-[720px] lg:m-0 lg:max-h-[720px] flex-wrap-reverse lg:flex-nowrap overflow-auto">
        {!hideInfo && <div
          className="w-4/5 lg:w-1/2 mx-auto max-w-[520px] p-5 lg:p-16 lg:pt-5 relative"
          style={{ backgroundColor: "rgba(41, 41, 41, 0.7)" }}
        >
          <div className="flex flex-none justify-end items-center mb-4">
            <span className="mr-2 text-sm font-bold text-white">
              {t("not_sure")}
            </span>{" "}
            <Button
              target="_blank"
              variant="contained"
              className={
                "h-8 text-xs border-white bg-white text-accent hover:bg-accent hover:text-white"
              }
              href={`https://calendly.com/adarshpanda/30min?month=${year}-${formattedMonth}`}
            >
              {t("book_a_demo")}
            </Button>
          </div>
          <h1 className="text-white font-semibold text-base text-center">
            {t("rated_higly_by_leading_security_and_industry_expert")}
          </h1>
          <div className="w-full my-6">
           <img src={PromoImage} alt="promo"/>
          </div>
          <div className="text-white">
            <h2 className="mt-8 mb-0 text-white font-semibold text-[18px]">
              {t("welcome_to_appsealing")}
            </h2>
            <h1 className="font-semibold text-white text-2xl sm:text-[30px] mb-0">
              {t("smart_and_secured_move")}
            </h1>
            <p className="text-xs sm:text-sm">{t("no_credit_card_needed_no_commitment_no_setup_fees")}</p>
            <ul className="p-0 m-0 list-disc pl-4 my-9 text-xs sm:text-sm">
              {features.map((item, index) => (
                <li key={`feature-${index}`}>{t(item)}</li>
              ))}
            </ul>
          </div>
        </div>}
        <div className="min-h-[720px] bg-white w-4/5 max-w-[520px] mx-auto lg:w-1/2 relative overflow-y-auto">
          {BackButton && <div className="absolute left-4 sm:left-[30px] top-[30px]">
            <IconButton onClick={()=> navigate('/login')}>
              <ArrowBackIcon style={{color: '#797A7C'}}/>
            </IconButton>
          </div>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
