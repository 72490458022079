import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../../utils/dropDownMenu';
import DataTable from '../../../../DataDisplay/Table';
import { useGroupManagement } from '../../../../../hooks/useGroupManagement';
import AddApp from './AddApp';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { getAppList } from '../../../../../services/manage/settings';

const AppTable: React.FC = () => {
    const { t } = useTranslation();

    const { group, subGroupList, appList, handleMenuChange, handleAddApp } = useGroupManagement();
    const [appListAll, setAppListAll] = useState<any[] | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [disable, setDisable] = useState<boolean>(true);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';

    useEffect(() => {
        const getManager = subGroupList.find((member) => member.type === 'Manager');
        const managerUserId = getManager ? getManager.userId : null;
        if (isOwner || (managerUserId && userDetails.user_id === getManager.userId)) {
            setDisable(false);
        }
    }, [isOwner, subGroupList, userDetails.user_id]);

    useEffect(() => {
        if (appListAll === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };

                const response = await getAppList(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus;
                }

                setAppListAll(data);
            };
            fetchApplications();
        }
    }, [region, appListAll, authContext, t, appList]);

    const AppColumns: GridColDef[] = [
        {
            headerName: t('app_name'),
            field: 'appName',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('app_id'),
            field: 'packageName',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('os'),
            field: 'os',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => <div>{params.row.os.toLowerCase() === 'ios' ? 'iOS' : 'Android'}</div>,
        },
        {
            headerName: t('action'),
            field: 'action',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const editMenu = [
                    {
                        label: t('configure'),
                        value: 'configuration',
                    },
                    {
                        label: t('delete_from_group'),
                        value: 'delete_from_group',
                        className: 'text-red-500',
                        disabled: disable
                    },
                ];
                return <DropdownMenu menuOptions={editMenu} handleMenuChange={(type: string) => handleMenuChange(type, params.row.applicationId, params.row.os, params.row.groupAppId)} />;
            },
        },
    ];
    const getRowId = (row: any) => row.applicationId;
    const handleAddAppToGroup = (appId: number, platform: string, handleDrawerClose: () => void, setSelectedApp: (app: any) => void) => {
        handleAddApp(appId, platform, handleDrawerClose, setSelectedApp);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const filterData = (data: any) => {
        const filteredList = data
            ? data.filter((group: any) => {
                  const groupNameMatch = group.appName.toLowerCase().includes(searchQuery) || group.packageName.toLowerCase().includes(searchQuery);
                  return groupNameMatch;
              })
            : [];

        return filteredList;
    };
    return (
        <div>
            <div className="flex items-center justify-between gap-4 mb-8">
                <TextField
                    className="max-w-[600px]"
                    size="small"
                    label={t('find_app')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder={t('search_app_name_or_app_id')}
                    InputLabelProps={{ shrink: true }}
                />
                <AddApp group={group} appListAll={appListAll} handleAddAppToGroup={handleAddAppToGroup} disable={disable} />
            </div>
            <DataTable height={'100%'} columns={AppColumns} rows={filterData(appList)} pageSize={12} pageSizeOptions={[10, 12, 20, 30]} getRowId={getRowId} />
        </div>
    );
};

export default AppTable;
