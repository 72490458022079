import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthorizationService } from '../../../hooks/useAuthorizationService';
import { checkMfaStatus, login, reactivateUser } from '../../../services/login';
import { openHelpCenter } from '../../../services/main';
import { LoginDataInterface } from '../../../type/api/login';
import { getCookies, setCookies } from '../../../utility/Common';
import LoadMask from '../../utils/loadmask';
import { showDialog, showToast } from '../../utils/notifications';
import { loginData } from '../AuthData';
import AuthForm from '../AuthForm';
import AuthLayout from '../index';
import MfaActivateModal from '../mfa/activate';
import MfaNotificationModal from '../mfa/enable';
import MfaVerifyModal from '../mfa/verify';
import PasswordExpiry from './PasswordExpiry';

const SignIn = () => {
    const authorizationService = useAuthorizationService();
    const { i18n, t } = useTranslation();
    const [loadMask, showLoadMask] = useState<any>(false);
    const [showMfaNotification, setShowMfaNotification] = useState('login');
    const [userInfo, setUserInfo] = useState<null | LoginDataInterface>(null);
    const [response, setResponse] = useState<any>(null);
    const [expirationDays, setExpirationDays] = useState(0);
    const [isExpired, setIsExpired] = useState(false);
    const [showPasswordExpiry, setShowPasswordExpiry] = useState(false);
    const [mfaPolicy, setMfaPolicy] = useState('');
    const [token, setToken] = useState('');
    const hash = window.location.href;
    const queryString = hash.split('?')[1];

    const query = useMemo(() => {
        return new URLSearchParams(queryString);
    }, [queryString]);

    const footerElements = (
        <div className="flex justify-between mt-2.5">
            <Link to="/forgotpassword" className="text-sm underline text-primary hover:text-accent">
                {t('forgot_your_id_password')}
            </Link>
            <Link to="/signup" className="text-sm underline text-primary hover:text-accent">
                {t('dont_have_an_id_create_one_now')}
            </Link>
        </div>
    );
    useEffect(() => {
        if (query.get('locale_id') && query.get('return_to')) {
            if (!((i18n.language === 'kr_KR' && query.get('locale_id') === '69') || (i18n.language !== 'kr_KR' && query.get('locale_id') === '1'))) {
                localStorage.setItem('user-lang', query.get('locale_id') === '1' ? 'en_US' : 'kr_KR');
                i18n.changeLanguage(query.get('locale_id') === '1' ? 'en_US' : 'kr_KR');
            }
        }
    }, [i18n, i18n.language, query, t]);

    const reactivateAccount = async (values: any) => {
        let response = await reactivateUser({
            user_id: values.user_id,
            lang_set: i18n.language === 'kr_KR' ? 'KR' : 'EN',
        });
        if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') showToast('success', t('please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email'));
        else showToast('error', response?.result?.message);
    };

    const handleEnable = () => {
        if (!userInfo) return;
        handleLoginProcess(userInfo, true);
    };

    const handleMfaLogin = (code = '') => {
        if (code !== '' && userInfo) {
            const updatedData = { ...userInfo, otpCode: code };
            handleLoginProcess(updatedData);
        }
    };

    const handleMfaCancel = () => {
        setShowMfaNotification('login');
    };

    const handleMfaLater = () => {
        if (!userInfo) return;
        handleLoginProcess(userInfo);
    };

    const handleMfaActivate = () => {
        const dontShowUntilExpired = getCookies('dontShowUntilExpired');

        if (response?.company) {
            const isCompanyUsingPasswordExpiry = response.company?.expirationCheck;
            const remainingDays = response.company?.userPasswordExpiration;
            const isLessThan33Percent = remainingDays < response.company?.companyPasswordExpiration * 0.33;
            const disabledPasswordExpiry = response.company?.companyPasswordCount > 0 || response.company?.companyPasswordExpiration > 0;
            const didPasswordExpired = response.company?.userPasswordExpiration < 0;
            setExpirationDays(remainingDays + 1);

            if (didPasswordExpired && isCompanyUsingPasswordExpiry) {
                setIsExpired(true);
                setShowPasswordExpiry(true);
                setShowMfaNotification('login');
                setResponse(response);
                showLoadMask(false);
                return;
            }
            if (disabledPasswordExpiry && isLessThan33Percent && !dontShowUntilExpired && dontShowUntilExpired !== 'true' && isCompanyUsingPasswordExpiry) {
                setIsExpired(false);
                setShowPasswordExpiry(true);
                setShowMfaNotification('login');
                setResponse(response);
                showLoadMask(false);
            } else {
                allowLogin(response);
            }
        } else {
            allowLogin(response);
        }
    };

    async function mfaStatus(data: LoginDataInterface) {
        const dontAskAgain = getCookies('dontAskAgain');
        try {
            const response = await checkMfaStatus(data);
            if (response.result.code === '0000') {
                setMfaPolicy(response.data.mfaPolicy);
                const isActive = String(response.data.isActive);
                if (isActive === '1') {
                    setShowMfaNotification('mfaActive');
                    showLoadMask(false);
                } else if (isActive === '0' || isActive === '-1') {
                    if (dontAskAgain && response.data.mfaPolicy !== 'ENFORCED') {
                        handleLoginProcess(data);
                    } else {
                        setShowMfaNotification('mfaInactive');
                        showLoadMask(false);
                    }
                }
            } else if (response.result.code === '11001') {
                if (dontAskAgain) {
                    handleLoginProcess(data);
                } else {
                    setShowMfaNotification('mfaInactive');
                    showLoadMask(false);
                }
            } else {
                const msg = response?.result?.message || t('failed_to_login_contact_help_desk_for_more_information');
                showToast('error', t(`RCM-${response?.result?.code}`, msg) as string);
            }
        } catch (error) {
            const msg = t('failed_to_login_contact_help_desk_for_more_information', 'error');
            showToast('error', t(`error`, msg));
        }
        showLoadMask(null);
    }

    const allowLogin = (response: any) => {
        setCookies('appsealing', response.auth.token);
        const getAuthToken = response.auth.token;
        if (getAuthToken) {
            authorizationService.authorizeUser(getAuthToken, showLoadMask);
        }

        if (query.get('return_to')) {
            openHelpCenter(response.auth.token, query.get('return_to') || '', '_self');
        }
    };

    const updateLater = () => {
        if (!response) return;
        allowLogin(response);
    };

    const handleLoginProcess = async (userData: LoginDataInterface, isMfa = false) => {
        showLoadMask({ msg: t('checking_account_information') });
        const dontShowUntilExpired = getCookies('dontShowUntilExpired');
        let response = await login(userData);
        if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
            if (isMfa) {
                showLoadMask(false);
                setToken(response.auth.token);
                setShowMfaNotification('mfaActivate');
                setResponse(response);
            } else {
                if (response?.company) {
                    const isCompanyUsingPasswordExpiry = response.company?.expirationCheck;
                    const remainingDays = response.company?.userPasswordExpiration;
                    const isLessThan33Percent = remainingDays < response.company?.companyPasswordExpiration * 0.33;
                    const disabledPasswordExpiry = response.company?.companyPasswordCount > 0 || response.company?.companyPasswordExpiration > 0;
                    const didPasswordExpired = response.company?.userPasswordExpiration < 0;
                    setExpirationDays(remainingDays + 1);

                    if (didPasswordExpired && isCompanyUsingPasswordExpiry) {
                        setIsExpired(true);
                        setShowPasswordExpiry(true);
                        setShowMfaNotification('login');
                        setResponse(response);
                        showLoadMask(false);
                        return;
                    }
                    if (disabledPasswordExpiry && isLessThan33Percent && !dontShowUntilExpired && dontShowUntilExpired !== 'true' && isCompanyUsingPasswordExpiry) {
                        setIsExpired(false);
                        setShowPasswordExpiry(true);
                        setShowMfaNotification('login');
                        setResponse(response);
                        showLoadMask(false);
                    } else {
                        allowLogin(response);
                    }
                } else {
                    allowLogin(response);
                }
            }
        } else {
            showLoadMask(false);
            if (response?.result?.code === '2700') {
                showDialog({
                    title: t('confirm_reactivation'),
                    content: (
                        <div>
                            <p>{t('please_verify_your_credentials_by_clicking_on_the_link_sent_to_you_by_email')}</p>
                            <br />
                            <p>{t('didnt_receive_the_email')}</p>
                        </div>
                    ),
                    onOk: () => reactivateAccount(userData),
                    okText: t('resend'),
                    cancelText: t('close'),
                });
            } else if (response?.result?.code === '12001') {
                setIsExpired(true);
                setShowPasswordExpiry(true);
                setShowMfaNotification('login');
            } else if (response?.result?.code) {
                const msg = response?.result?.message || t('failed_to_login_contact_help_desk_for_more_information');
                showToast('error', t(`RCM-${response?.result?.code}`, msg) as string);
            } else {
                const msg = response?.result?.message || t('failed_to_login_contact_help_desk_for_more_information');
                showToast('error', msg);
            }
        }
    };

    const onSignInClick = async (values: any) => {
        const userData = {
            ...values,
            domain: i18n.language === 'kr_KR' ? 'KR' : 'EN',
        };
        mfaStatus(userData);
        setUserInfo(userData);
    };

    return (
        <React.Fragment>
            {loadMask && <LoadMask msg={loadMask.msg} style={{ zIndex: '10007', background: '#88295C' }} />}
            {showMfaNotification === 'login' && (
                <AuthLayout BackButton={false}>
                    {showPasswordExpiry ? (
                        <PasswordExpiry isExpired={isExpired} expirationDays={expirationDays} updateLater={updateLater} />
                    ) : (
                        <AuthForm title={'login'} data={loginData} btnText="sign_in" onSubmit={onSignInClick} footerElements={footerElements} />
                    )}
                </AuthLayout>
            )}
            {showMfaNotification === 'mfaActivate' && <MfaActivateModal token={token} handleMfaActivate={handleMfaActivate} />}
            {showMfaNotification === 'mfaInactive' && <MfaNotificationModal mfaPolicy={mfaPolicy} handleEnable={handleEnable} handleLater={handleMfaLater} />}
            {showMfaNotification === 'mfaActive' && <MfaVerifyModal handleMfaLogin={handleMfaLogin} handleMfaCancel={handleMfaCancel} />}
        </React.Fragment>
    );
};

export default SignIn;
