import React from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { showDialog, showToast } from '../../../utils/notifications';
import { getPlanDetails, removePaymentInfo, savePaymentInfo } from '../../../../services/manage/billingdetails';
import { setCardDetails } from '../../../../store/manage/billingdetails';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import LockIcon from '@mui/icons-material/Lock';
import { Button } from '../../../Foundation/Button';
import './card.less';

const fieldStyle = {
    base: {
        fontSize: '1rem',
        lineHeight: '56px',
    },
};

const PaymentCard = (props: { data: any }) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const { t } = useTranslation();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const cardDetails = useSelector((state: RootState) => state.billingDetailsReducer.cardDetails);
    const billingDetails = useSelector((state: RootState) => state.billingDetailsReducer.billingDetails);
    const isOwner = authContext.role === 'Owner';
    const { handleSubmit } = useForm();

    const fetchAccountInfo = async (token: string) => {
        const { isAuth, ...params } = authContext;
        const response = await getPlanDetails(params);
        if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
            const cardDetails = response.card ? JSON.parse(response.card) : null;
            dispatch(setCardDetails(cardDetails));
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_load_billing_details')));
        else showToast('error', t('failed_to_load_billing_details'));
    };

    const onRemoveCardClick = () => {
        const removeCard = async () => {
            const params = {
                token: authContext.token,
            };
            const response = await removePaymentInfo(params);
            if (response?.result.code === '0000') {
                fetchAccountInfo(authContext.token);
                showToast('success', t('card_information_removed_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_removing_card_details')));
            else showToast('error', t('error_while_removing_card_details'));
        };
        showDialog({
            title: t('confirm_card_removal'),
            content: t('confirm_card_removal_content'),
            onOk: removeCard,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onSubmit = async (event: any) => {
        if (billingDetails == null) {
            showToast('error', t('please_save_billing_information_to_register_card'));
            return;
        }
        if (!props?.data?.paymentInfo?.client_secret || stripe === null || elements == null) {
            showToast('error', t('error_while_connecting_payment_module'));
            return;
        }

        const { error, setupIntent } = await stripe!.confirmCardSetup(props.data.paymentInfo.client_secret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)!,
                billing_details: {
                    email: billingDetails.email,
                },
            },
        });
        if (!error || (setupIntent as any)?.status === 'succeeded') {
            const { setupIntent, error } = await stripe!.retrieveSetupIntent(props.data.paymentInfo.client_secret);
            if (!error || (setupIntent && (setupIntent as any).payment_method)) {
                const params = {
                    ...billingDetails,
                    token: authContext.token,
                    paymentmethod: setupIntent?.payment_method,
                };
                const response = await savePaymentInfo(params);
                if (response?.result.code === '0000') {
                    fetchAccountInfo(authContext.token);
                    showToast('success', t('card_registered_successfully'));
                } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_saving_card_details')));
                else showToast('error', t('error_while_saving_card_details'));
            } else showToast('error', t('error_while_saving_card_details'));
        } else showToast('error', t('error_while_saving_card_details'));
    };

    return (
        <>
            <div className="flex flex-none pb-8">
                <div className="text-base font-semibold mr-2.5">{t('credit_card_details')}</div>
                <Tooltip title={t('available_credits_tip')}>
                    <HelpOutlineIcon className="text-[#D7D7D7]" />
                </Tooltip>
            </div>
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {cardDetails ? (
                        <TextField
                            label={t('card_number')}
                            fullWidth
                            className="bg-primary"
                            value={`xxxx xxxx xxxx ${cardDetails.last4}`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CreditCardIcon />
                                    </InputAdornment>
                                ),
                            }}
                            disabled
                        />
                    ) : (
                        <Tooltip title={!isOwner ? t('only_owner') : ''}>
                            <div className="custom-input bg-primary pl-2 border rounded-md h-[56px]">
                                <span className="custom-input-label">{t('card_number')}</span>
                                <CardNumberElement
                                    options={{
                                        showIcon: true,
                                        placeholder: t('card_number'),
                                        style: fieldStyle,
                                        disabled: !isOwner,
                                    }}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex-grow">
                        {cardDetails ? (
                            <TextField
                                label={t('expiry_date')}
                                className="bg-primary mt-2"
                                value={`${String(cardDetails.exp_month).padStart(2, '0')} / ${String(cardDetails.exp_year).slice(-2)}`}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EventIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled
                            />
                        ) : (
                            <Tooltip title={!isOwner ? t('only_owner') : ''}>
                                <div className="custom-input bg-primary pl-2 mt-2 border rounded-md h-[56px]">
                                    <span className="custom-input-label">{t('expiry_date')}</span>
                                    <CardExpiryElement
                                        options={{
                                            placeholder: t('mm_yy'),
                                            style: fieldStyle,
                                            disabled: !isOwner,
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <div className="flex-grow">
                        {cardDetails ? (
                            <TextField
                                label={t('cvc')}
                                className="bg-primary mt-2"
                                value={`xxx`}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled
                            />
                        ) : (
                            <Tooltip title={!isOwner ? t('only_owner') : ''}>
                                <div className="custom-input bg-primary pl-2 mt-2 border rounded-md h-[56px]">
                                    <span className="custom-input-label">{t('cvc')}</span>
                                    <CardCvcElement
                                        options={{
                                            placeholder: t('cvc'),
                                            style: fieldStyle,
                                            disabled: !isOwner,
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {!isOwner ? (
                            <></>
                        ) : (
                            <div className="flex flex-none justify-end mt-5">
                                {cardDetails ? (
                                    <Button className="ml-auto h-[40px]" fullWidth={true} color="error" variant="outlined" type="button" onClick={onRemoveCardClick}>
                                        {t('remove_card')}
                                    </Button>
                                ) : (
                                    <Button className="ml-auto h-[40px]" fullWidth={true} onClick={handleSubmit(onSubmit)} color="primary" type="submit" disabled={!stripe || !elements}>
                                        {t('register_card')}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
};

export default PaymentCard;
