import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';

interface ActionMenuProps {
    row: any;
    onCopy: (data: any) => void;
    onView: (data: any) => void;
    onDelete: (id: string, presetName: string) => void;
    onEdit: (data: any) => void;
    disabled?: boolean;
    isBetaAccount?: boolean;
}
const ActionMenu = ({ row, onDelete, onEdit, onCopy, onView, disabled = false, isBetaAccount = false }: ActionMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewClick = () => {
        handleClose();
        onView(row);
    };

    const handleEditClick = () => {
        handleClose();
        onEdit(row);
    };

    const handleCopyClick = () => {
        handleClose();
        onCopy(row);
    };

    const handleDeleteClick = () => {
        handleClose();
        onDelete(row.id, row.presetName);
    };

    return (
        <div key={row.id}>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon fontSize="small" />
            </IconButton>
            <Menu
                id="actionMenu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'actionMenu',
                }}
            >
                <MenuItem onClick={handleViewClick}>{t('view')}</MenuItem>
                {isBetaAccount && (
                    <MenuItem disabled={disabled} onClick={handleCopyClick}>
                        {t('copy')}
                    </MenuItem>
                )}
                <MenuItem disabled={disabled} onClick={handleEditClick}>
                    {t('edit')}
                </MenuItem>
                <MenuItem disabled={disabled} onClick={handleDeleteClick} className="text-red-500">
                    {t('delete')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ActionMenu;
