
import moment from "moment";  
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTransactionHistory } from "../../../../services/manage/billingdetails";
import { RootState } from "../../../../store";
import { filterBy } from "../../../../utility/filtering";

import { useTranslation } from "react-i18next";
import ContentLayout from "../../../Layout/Content";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../../Foundation/Button";
import { TransactionFields, TransactionTableColumns } from "./TransactionFields";
import { TextField } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataTable from "../../../DataDisplay/Table";

const TransactionHistory = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm();
  const [filters, setFilters] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [transactionData, setTransactionData] = useState<Array<any> | null>(
    null
  );
  const [fromDate, setFromDate] = useState<any>(undefined);
  const [toDate, setToDate] = useState<any>(undefined);
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );

  useEffect(() => {
    if (authContext.token && transactionData === null) {
      const loadCreditInfo = async () => {
        const params = {
          token: authContext.token,
          count_per_page: "5000",
          current_page: "1",
          srchEndDate: toDate ? toDate.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          srchStartDate: fromDate ? fromDate.format("YYYY-MM-DD") : moment().subtract(6, "M").format("YYYY-MM-DD"),
        };
        const response = await getTransactionHistory(params);
        if (
          response?.result?.code === "0000" &&
          Array.isArray(response.transaction_history)
        ) {
          const desc = response?.StatusDesc || {};
          const data = response.transaction_history.map(
            (item: any, i: number) => ({
              ...item,
              seq: i,
              statusText: desc[item.Status],
              transactionDate: moment(
                item.Date,
                "YYYY-MM-DD HH:mm:ss.s"
              ).toDate(),
            })
          );
          setTransactionData(data);
        }
      };
      setTransactionData([]);
      loadCreditInfo();
    }
  }, [authContext.token, fromDate, toDate, transactionData]);

  const onSearchClick = (data: any) => {
    let values = {...data, fromDate, toDate};
    let filter: { field: string; value: any; operator: string }[] = [];
    for (let [key, value] of Object.entries(values) as [string, any][]) {
      if (value) {
        if (key === "fromDate" || key === "toDate")
          filter.push({
            field: "transactionDate",
            value: value.toDate(),
            operator: key === "fromDate" ? "gte" : "lte",
          });
        else filter.push({ field: key, value: value, operator: "contains" });
      }
    }

    setFilters(filter);
  };

  const onResetClick = () => {
    reset({});
    setFromDate(undefined);
    setToDate(undefined);
    setFilters([]);
    setKey(key + 1)
  };
  const createRows = (data: any[]) => {
    const rows = data.map((item: any, index: number) => {
      return { ...item, id: index + 1 };
    });

    return rows;
  };
  return (
    <ContentLayout fullWidth={true}>
      <div className="max-h-auto xl:max-h-44 flex flex-row">
        <div className="flex flex-wrap xl:flex-nowrap flex-none w-full justify-between">
          <div className="text-2xl font-medium mr-12 whitespace-nowrap lg:w-[316px] 1xl:w-1/3 w-full">
            {t("transaction_history")}
          </div>
          <form
            onSubmit={handleSubmit(onSearchClick)}
            className="flex flex-auto flex-wrap xl:flex-nowrap xl:mt-0 my-4 1xl:w-2/3 w-full" 
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 w-full">
                {TransactionFields.map((item) => {
                  switch (item.type) {
                    case "input":
                      return (
                        <Controller
                          key={item.name}
                          name={item.name}
                          control={control}
                          defaultValue={item.value}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="max-w-full lg:max-w-[250px]"
                              size="small"
                              label={t(item.label)}
                              placeholder={t(item.placeholder || "")}
                              fullWidth
                            />
                          )}
                        />
                      );
                    case "datepicker":
                      return (
                        <Controller
                          key={item.name + key}
                          name={item.name}
                          control={control}
                          render={({ field }) => (
                            <>
                              <DatePicker
                              {...field}
                              slotProps={{ textField: { size: "small", value:  item.name === "fromDate" ? fromDate : toDate } }}
                              label={t(item.label)}
                              minDate={item.name === "fromDate" ? undefined : fromDate}
                              views={["year", "month", "day"]}
                              openTo="day"
                              className="max-w-full lg:max-w-[250px]"
                              defaultValue={
                                item.name === "fromDate"
                                  ? fromDate
                                    ? fromDate
                                    : undefined
                                  : undefined
                              }
                              format="YYYY-MM-DD"
                              disableFuture
                              onChange={(date) =>
                                item.name === "fromDate"
                                  ? setFromDate(date)
                                  : setToDate(date)
                              }
                              />
                            </>
                          )}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </LocalizationProvider>
            <div className="xl:ml-5 flex flex-none gap-2 mt-4 xl:mt-0">
              <Button
                className="h-[37px]"
                onClick={handleSubmit(onSearchClick)}
                type="submit"
                label={t("search")}
              />
              <Button
                className="h-[37px]"
                onClick={onResetClick}
                variant="outlined"
                color="secondary"
                label={t("reset")}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto">
      <div className="flex-grow mt-10 min-w-[800px]">
         <DataTable
              columns={TransactionTableColumns.map((e: any) => ({
                ...e,
                headerName: t(e.headerName),
              }))
              }
              rows={createRows(filterBy(transactionData, filters) || [])}
              pageSize={10}
              pageSizeOptions={[5, 10]}
            />
      </div>
      </div>
    </ContentLayout>
  );
};

export default TransactionHistory;
