import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { removeMember, updateAccountMember } from '../../../../../../services/manage/accounts';
import { RootState } from '../../../../../../store';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { showDialog, showToast } from '../../../../../utils/notifications';
import DropdownMenu from '../../../../../utils/dropDownMenu';
import { Link } from 'react-router-dom';

interface EditMemberModalInterface {
    visible: boolean;
    memberInfo: any;
    onCancel: (member_email?: string) => void;
    updateMemberDetails: (data: any) => void;
}

const menuOptions = [
    {
        label: 'remove_member',
        value: 'remove_member',
        className: 'text-red-500',
    },
];

const EditMemberModal = (props: EditMemberModalInterface) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    useEffect(() => {
        if (props.visible) {
            reset({ email: props.memberInfo?.member_email, notes: props.memberInfo?.notes, fullName: props.memberInfo?.member_name });
        }
    }, [props.visible, props.memberInfo, reset]);
    const onUpdateClick = async (values: any) => {
        const params = {
            ...props.memberInfo,
            currentNote: props.memberInfo?.notes,
            member_name: values.fullName,
            notes: values.notes || '',
            token: authContext.token,
        };

        const response = await updateAccountMember(params);

        if (response?.result?.code === '0000') {
            reset();
            showToast('success', t('member_information_updated_successfully'));
            props.updateMemberDetails(response?.applicationTeamMemberList);
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_update_member_information')));
        else showToast('error', t('failed_to_update_member_information'));
    };

    const handleMenuChange = (type: string, member: any) => {
        if (type === 'remove_member') {
            showDialog({
                title: t(`${type}_title`),
                content: t(`${type}_desc`),
                okColor: 'error',
                onOk: () => {
                    handleRemoveMember(member);
                },
                okText: t('yes'),
                cancelText: t('no'),
            });
        }
    };
    const handleRemoveMember = async (member: any) => {
        const params = {
            token: authContext.token,
            userId: member.member_email,
        };
        let response = await removeMember(params);
        if (response?.result.code === '0000') {
            showToast('success', t('successfully_removed_member'));
            props.onCancel(member.member_email);
        } else {
            showToast('error', t(`RCM-${response?.result?.code}`, t('failed_while_removing_member')));
        }
    };

    return (
        <Drawer anchor="right" open={props.visible} onClose={() => props.onCancel()}>
            <form onSubmit={handleSubmit(onUpdateClick)}>
                <div className="p-10 pr-16 max-w-[800px] min-w-3/4 lg:min-w-[800px]">
                    <div className="flex items-start justify-between gap-10 mb-10">
                        <div>
                            <h2 className="m-0 text-2xl font-medium">{t('edit_team_member')}</h2>
                        </div>
                        <div className="flex justify-end gap-2">
                            {isOwner && <DropdownMenu menuOptions={menuOptions} handleMenuChange={(type: string) => handleMenuChange(type, props.memberInfo)} />}
                            <Button type="submit" variant="contained" color="primary" className="h-10">
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                    <Box>
                        <div className="flex items-center gap-2 mb-8">
                            <strong className="w-32">{t('user_id')}</strong>
                            <span>{props.memberInfo?.member_email}</span>
                        </div>
                        <div className="flex items-center gap-2 mb-8">
                            <strong className="w-32">{t('user_name')}</strong>
                            <span>{props.memberInfo?.member_name}</span>
                        </div>
                        <div className="flex items-center gap-2 mb-8">
                            <strong className="w-32">{t('user_register_date')}</strong>
                            <span>{props.memberInfo?.joined ? `${props.memberInfo.joined.slice(0, 4)}.${props.memberInfo.joined.slice(4, 6)}.${props.memberInfo.joined.slice(6, 8)}` : ''}</span>
                        </div>

                        <div className="flex items-center gap-2 mb-8">
                            <strong className="w-32">{t('group')}</strong>
                            {props.memberInfo?.GROUPS?.length > 0
                                ? props.memberInfo?.GROUPS.map((group: [string, string]) => (
                                      <>
                                          [
                                          <Link key={group[0]} to={`/organization/group/manage/sub?groupId=${group[0]}`} className="text-linkblue">
                                              {group[1]}
                                          </Link>
                                          ]
                                      </>
                                  ))
                                : '-'}
                        </div>
                        <div className="flex items-center gap-2 mb-8">
                            <strong className="w-32">{t('position')}</strong>
                            <span>{t(props?.memberInfo?.owner_type)}</span>
                        </div>
                        <div className="flex gap-2">
                            <strong className="w-32">{t('notes')}</strong>
                            <div className="w-full">
                                <Controller
                                    name="notes"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField {...field} multiline rows={4} label={t('notes')} placeholder={t('enter_notes')} fullWidth variant="outlined" className="mb-4" />
                                    )}
                                />
                            </div>
                        </div>
                    </Box>
                </div>
            </form>
        </Drawer>
    );
};

export default EditMemberModal;
