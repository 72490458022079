import { Button } from '../../../../Foundation/Button';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addSelectiveDex, getSelectiveDexList, removeSelectiveDex } from '../../../../../services/securityservices';
import { RootState } from '../../../../../store';
import { showDialog, showToast } from '../../../../utils/notifications';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, TextField, Select, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { GridRenderCellParams } from '@mui/x-data-grid';
import DataTable from '../../../../DataDisplay/Table';
import { Hint } from '../../../../utils';
import CachedIcon from '@mui/icons-material/Cached';
interface ManageDexInterface {
    appData?: any;
}

const ManageDex = (props: ManageDexInterface) => {
    const { t } = useTranslation();
    const [dexData, setDexData] = useState<Array<any>>([]);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isBetaAccount = authContext?.is_beta_account;
    const isOwner = authContext?.role === 'Owner';
    const isSubOwner = authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            enc_type: '',
            selective_dex_class_package: '',
        },
    });

    useEffect(() => {
        if (props.appData) {
            const fetchSelectiveDexList = async () => {
                const params = {
                    token: authContext.token,
                    package_name: props.appData.package_name,
                };
                const response = await getSelectiveDexList(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.selectiveDexClassPackageList)) setDexData(response.selectiveDexClassPackageList);
                else if (response?.result?.code) {
                    showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_updating_dex_list')));
                    setDexData([]);
                } else setDexData([]);
            };
            fetchSelectiveDexList();
        }
    }, [region, authContext, props.appData, t]);

    const onAddDexClick = async (values: any) => {
        const { selective_dex_class_package, enc_type } = watch();
        if (props.appData) {
            const params = {
                token: authContext.token,
                enc_type,
                selective_dex_class_package,
                package_name: props.appData.package_name,
            };

            const response = await addSelectiveDex(params, region);
            if (response?.result?.code === '0000') {
                setDexData((oldData: any) => [{ ...values, package_name: props.appData.package_name }, ...oldData]);
                reset();
                showToast('success', t('selective_dex_list_updated_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_updating_dex_list')));
            else showToast('error', t('error_while_updating_dex_list'));
        }
    };

    const onDeleteDexClick = async (record: any) => {
        const deleteDex = async () => {
            const { selective_dex_class_package, enc_type, package_name } = record;
            if (props.appData) {
                const params = {
                    token: authContext.token,
                    enc_type,
                    selective_dex_class_package,
                    package_name,
                };

                const response = await removeSelectiveDex(params, region);
                if (response?.result?.code === '0000') {
                    setDexData((oldData: any) => {
                        const index = oldData.findIndex((e: any) => e.enc_type === enc_type && e.selective_dex_class_package === selective_dex_class_package);
                        oldData.splice(index, 1);
                        return [...oldData];
                    });
                    showToast('success', t('selective_options_list_updated_successfully'));
                } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('error_while_updating_options_list')));
                else showToast('error', t('error_while_updating_options_list'));
            }
        };
        showDialog({
            title: t('confirm_delete'),
            content: t('are_you_sure_to_delete_dex_selection'),
            onOk: deleteDex,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const columns = [
        {
            headerName: t('encryption_type'),
            field: 'selective_dex_class_package',
            sortable: true,
            flex: 1,
        },
        {
            headerName: t('dex_type'),
            field: 'enc_type',
            sortable: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.row.enc_type === 'C' ? t('class') : params.row.enc_type === 'D' ? t('dll_file') : params.row.enc_type === 'S' ? t('so_file') : t('package')}</span>
            ),
        },
        {
            headerName: t('action'),
            field: 'action',
            sortable: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const onClick = () => {
                    onDeleteDexClick(params.row as any);
                };
                return <Button variant="outlined" color="error" className="w-[70px] h-[30px] text-xs" onClick={onClick} label={t('delete')} />;
            },
        },
    ];

    const onReset = () => reset();

    const createRows = (data: any[]) => {
        const rows = data.map((item: any, index: number) => {
            return { ...item, id: index + 1 };
        });

        return rows;
    };

    return (
        <>
            <Hint text={t('manage_dex_note')} />
            <form onSubmit={handleSubmit(onAddDexClick)} className="mt-10">
                <div className="flex items-center justify-between flex-none w-full gap-5">
                    <FormControl error={!!errors.selective_dex_class_package} className="w-1/2">
                        <Controller
                            name="selective_dex_class_package"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="h-[40px]"
                                    size="small"
                                    required
                                    placeholder={t('enter_package_class_name_or_file_name')}
                                    label={t('package_or_class_name')}
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth error={!!errors.enc_type} className="w-1/2" size="small">
                        <InputLabel id="enc_type_label">{`${t('target_type')} *`}</InputLabel>
                        <Controller
                            name="enc_type"
                            control={control}
                            rules={{ required: t('account_owner_is_required') }}
                            render={({ field, fieldState: { error } }) => (
                                <Select {...field} labelId="enc_type_label" id="enc_type" label={`${t('target_type')} *`} fullWidth required>
                                    <MenuItem value="C" className="text-sm max-w-[280px]">
                                        {t('java_kotlin_class')}
                                    </MenuItem>
                                    <MenuItem value="P" className="text-sm max-w-[280px] text-wrap">
                                        {t('java_kotlin_package')}
                                    </MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    <Box className="flex items-center gap-x-2">
                        <Button className="h-[40px]" onClick={onReset} color="secondary" variant="outlined" disabled={isBetaAccount ? !(isOwner || isSubOwner || isManager) : false}>
                            {<CachedIcon />}
                        </Button>
                        <Button className="h-[40px]" type="submit" color="primary" variant="contained" disabled={isBetaAccount ? !(isOwner || isSubOwner || isManager) : false}>
                            {t('add')}
                        </Button>
                    </Box>
                </div>
            </form>
            <div className="mt-10">
                <DataTable columns={columns} rows={createRows(dexData)} pageSize={10} pageSizeOptions={[5, 10]} />
            </div>
        </>
    );
};

export default ManageDex;
