import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Switch, Accordion, AccordionSummary, AccordionDetails, Button, Tooltip } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import ManageDex from './managedex';
import CustomBlacklist from './customblacklist';
import AccountTransfer from './accounttransfer';
import ManageAccess from './manageaccess';
import ManageKeyStore from './managekeystore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { deleteApp, getAppList } from '../../../../services/manage/settings';
import ContentLayout from '../../../Layout/Content';
import { showDialog, showToast } from '../../../utils/notifications';
import { updateAdvanceService, updateService } from '../../../../services/securityservices';

interface AccordionItem {
    id: string;
    title: string;
    component: React.ComponentType<any>;
    note?: string;
    tooltip?: string;
}

const accordionItems: AccordionItem[] = [
    {
        id: 'manage-keystore',
        title: 'manage_keystore',
        component: ManageKeyStore,
        note: 'Selective Options allows you to choose JAVA/Kotlin classes or packages to employ encryption which mitigates performance loss while in cold boot specifically.',
    },
    {
        id: 'manage-selection',
        title: 'manage_selection',
        component: ManageDex,
    },
    {
        id: 'manage-access',
        title: 'manage_access',
        component: ManageAccess,
        tooltip: 'manage_access_tooltip',
    },
    {
        id: 'custom-blacklist',
        title: 'custom_blacklist',
        component: CustomBlacklist,
        tooltip: 'custom_blacklist_tooltip',
    },
];

const ManageApp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activePanels, setActivePanels] = useState<string[]>([]);
    const [currentAppData, setCurrentAppData] = useState<any>(null);
    const [groupId, setGroupId] = useState<number | null>(null);
    const [groupAppId, setGroupAppId] = useState<number | null>(null);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;
    const isBetaAccount = authContext?.is_beta_account;
    const { id } = useParams();

    useEffect(() => {
        if (currentAppData === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };
                const searchParams = new URLSearchParams(location.search);
                let getPlatform = searchParams.get('platform');

                const response = await getAppList(params, region);

                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus.filter((app: any) => app.platform === getPlatform);
                }
                const selectedApp = data.find((app: any) => app.id === parseInt(id || ''));
                if (!selectedApp) {
                    showDialog({
                        title: t('error'),
                        content: t('you_do_not_have_permission_to_access_this_page'),
                        onOk: () => navigate('/organization/group/manage'),
                        okText: t('ok'),
                        cancelText: t('cancel'),
                    });
                }
                setGroupAppId(selectedApp.groupAppId);
                setGroupId(selectedApp.groupId);
                setCurrentAppData(selectedApp);
            };
            fetchApplications();
        }
    }, [region, authContext, t, currentAppData, id, location.search]);

    const handleAccordionChange = (panelId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        if (isExpanded) {
            setActivePanels((prev) => [...prev, panelId]);
        } else {
            setActivePanels((prev) => prev.filter((id) => id !== panelId));
        }
    };

    const updateServices = async (type: string, params: any) => {
        let data: any = { ...currentAppData }; // Create a copy of currentAppData
        if (type === 'service') {
            const response = await updateService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('app_service_is_updated_for_the_package')} ${params.package_name}`);
                data['service_onoff'] = params.status;
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', `${t('app_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'datasealing') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('data_sealing_service_is_updated_for_the_package')} ${params.package_name}`);
                data['use_data_sealing'] = params.request;
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('data_sealing_service_failed_to_update_for_package')));
            else showToast('error', `${t('data_sealing_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'otaservice') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('ota_service_is_updated_for_the_package', 'OTA Service is updated for the package')} ${params.package_name}`);
                data['use_ota_service'] = params.request;
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('ota_failed_to_update_for_package')));
            else showToast('error', `${t('ota_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        }

        setCurrentAppData(data); // Update state with the new data
    };

    const onSwitchToggle = async (type: string, params: any) => {
        let msg = '';
        switch (type) {
            case 'service':
                msg = params.status === 'Y' ? t('app_can_be_sealed_and_will_incur_a_monthly_fee') : t('app_cannot_be_sealed_and_data_will_not_collected_while_the_sealing_option_is_off');
                break;
            case 'datasealing':
                msg =
                    params.request === 'Y'
                        ? isBetaAccount
                            ? t('beta_datasealing_option_will_be_available_while_applying_security_to_your_app')
                            : t('datasealing_option_will_be_available_while_applying_security_to_your_app')
                        : t('datasealing_is_not_supported_when_applying_security_to_your_app_any_longer');
                break;
            case 'otaservice':
                msg =
                    params.request === 'Y'
                        ? t('once_activated_you_can_manage_the_custom_blacklist_by_yourself_and_it_is_updated_over')
                        : t('custom_blacklist_option_is_not_supported_when_applying_security_to_your_app_any_longer_and_mad_is_not_counted_any_longer');
                break;
            default:
                break;
        }
        if (msg) {
            showDialog({
                title: t('confirm_update'),
                content: msg,
                onOk: () => updateServices(type, params),
                okText: t('yes'),
                cancelText: t('no'),
            });
        } else updateServices(type, params);
    };

    const onServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            api_ver: '4',
            platform: currentAppData.platform === 'android' ? 'android' : 'ios',
            token: authContext.token,
            package_name: currentAppData.package_name,
            status: e.target.checked === true ? 'Y' : 'N',
        };

        onSwitchToggle('service', params);
    };

    const onDataSealingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            package_name: currentAppData.package_name,
            request: e.target.checked === true ? 'Y' : 'N',
            service_id: 'DATA_SEALING',
            token: authContext.token,
        };
        onSwitchToggle('datasealing', params);
    };

    const onCustomBlacklistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            package_name: currentAppData.package_name,
            request: e.target.checked === true ? 'Y' : 'N',
            service_id: 'OTA_SERVICE',
            token: authContext.token,
        };
        onSwitchToggle('otaservice', params);
    };

    const onDeleteApp = () => {
        showDialog({
            title: t('confirm_delete_app'),
            content: t('are_you_sure_you_want_to_delete_this_app'),
            okColor: 'error',
            onOk: () => {
                handleAppDelete();
            },
            okText: t('delete'),
            cancelText: t('cancel'),
        });
    };

    const handleAppDelete = async () => {
        const params = {
            token: authContext.token,
            platform: currentAppData.platform === 'ios' ? 'IOS' : 'AOS',
            id: currentAppData.id,
        };
        const response = await deleteApp(params);
        if (response?.result?.code === '0000') {
            showToast('success', t('successfully_deleted_app'));
            navigate(-1);
        } else {
            showToast('error', t('failed_at_deleting_app'));
        }
    };
    return (
        <ContentLayout fullWidth={true}>
            <div className="flex items-center justify-between mb-10">
                <div className="flex items-center">
                    <IconButton onClick={() => navigate(-1)}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <div className="ml-2">
                        <Typography variant="h6" className="text-[20px]">
                            {currentAppData?.app_name}
                        </Typography>
                    </div>
                </div>
                <div>
                    {isBetaAccount && (
                        <Button variant="outlined" color="error" disabled={!isOwner} onClick={onDeleteApp}>
                            {t('delete_app')}
                        </Button>
                    )}
                </div>
            </div>

            {currentAppData && (
                <div className="flex">
                    <div className="w-1/5 border-r border-[#E0E0E0] mr-6 ml-12 pr-10" style={{ minHeight: 'calc(100vh - 340px)' }}>
                        <p className="mb-4">
                            <strong>{t('release_information')}</strong>
                        </p>
                        <ul className="p-0 m-0 list-none">
                            <li className="mb-8">
                                <p className="mb-2">{t('package')}</p>
                                <p className="break-all">{currentAppData?.package_name}</p>
                            </li>
                            <li className="mb-8">
                                <p className="mb-2">{t('service')}</p>
                                <p>
                                    <Switch disabled={!isOwner} checked={currentAppData?.service_onoff === 'Y'} onChange={onServiceChange} />
                                </p>
                            </li>
                            {currentAppData.platform === 'android' && (
                                <li className="mb-8">
                                    <p className="mb-2">{t('data_sealing')}</p>
                                    <p>
                                        <Switch disabled={isBetaAccount ? !(isOwner || isManager) : false} checked={currentAppData?.use_data_sealing === 'Y'} onChange={onDataSealingChange} />
                                    </p>
                                </li>
                            )}
                            {currentAppData.platform === 'android' && (
                                <li className="mb-8">
                                    <p className="mb-2">{t('custom_blacklist')}</p>
                                    <p>
                                        <Switch disabled={isBetaAccount ? !(isOwner || isManager) : false} checked={currentAppData?.use_ota_service === 'Y'} onChange={onCustomBlacklistChange} />
                                    </p>
                                </li>
                            )}
                        </ul>
                        {currentAppData.platform === 'android' && <div className="pt-10 mt-10 border-t border-[#E0E0E0]">{currentAppData && <AccountTransfer appData={currentAppData} />}</div>}
                    </div>
                    <div className="w-4/5 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 340px)' }}>
                        {accordionItems
                            .filter((item) => (currentAppData.platform === 'ios' ? item.id === 'manage-access' : true))
                            .map((item) => {
                                return (
                                    <>
                                        {(isBetaAccount || (!isBetaAccount && item.id !== 'manage-access' && item.id !== 'manage-keystore')) && (
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    groupId === null && item.id === 'manage-access'
                                                        ? t('manage_access_tooltip')
                                                        : item.id === 'custom-blacklist' && currentAppData?.use_ota_service !== 'Y'
                                                          ? t('custom_blacklist_tooltip')
                                                          : ''
                                                }
                                            >
                                                <Accordion
                                                    key={item.id}
                                                    sx={{
                                                        boxShadow: 'none',
                                                        border: 'none',
                                                        '&:before': {
                                                            display: 'none',
                                                        },
                                                    }}
                                                    disabled={(groupId === null && item.id === 'manage-access') || (item.id === 'custom-blacklist' && currentAppData?.use_ota_service !== 'Y')}
                                                    onChange={handleAccordionChange(item.id)}
                                                    expanded={activePanels.includes(item.id)}
                                                >
                                                    <AccordionSummary className="px-0 border-none" expandIcon={<ChevronRightIcon />} aria-controls={`${item.id}-content`} id={`${item.id}-header`}>
                                                        <div className="flex items-center w-full">
                                                            <span className="text-lg text-nowrap font-medium text-[#36373A] mr-2">{t(item.title)}</span>
                                                            <hr className="block w-full" />
                                                        </div>
                                                    </AccordionSummary>

                                                    <AccordionDetails>
                                                        {item.note && (
                                                            <div className="mb-4">
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {t(item.note)}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <item.component appData={currentAppData} groupAppId={groupAppId} packageName={currentAppData?.package_name} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Tooltip>
                                        )}
                                    </>
                                );
                            })}
                    </div>
                </div>
            )}
        </ContentLayout>
    );
};

export default ManageApp;
