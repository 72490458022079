import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDialog, showToast } from "../../../utils/notifications";
import { changeServiceRegion, openHelpCenter } from "../../../../services/main";
import { RootState } from "../../../../store";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { setRegion } from "../../../../store/main";
import { AppURL } from "../../../../utility/AppURL";
import { Box, Radio, RadioGroup, Tooltip,  FormControlLabel } from "@mui/material";
import ContentLayout from "../../../Layout/Content";

const ServiceRegion = () => {
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const accountDetails = useSelector((state: RootState) => state.mainReducer.accountDetails);
	const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
	const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
	const [selectedRegion, setSelectedRegion] = useState<any>('AWS_TOKYO');

	useEffect(() => {
		if (userDetails) {
				dispatch(setRegion(userDetails?.default_service_region || 'AWS_TOKYO'));
				setSelectedRegion(userDetails?.default_service_region || 'AWS_TOKYO');
		}
}, [userDetails, dispatch]);

	const handleRegionMenuClick = async (e: any) => {
		const region = e.target.value;
		const updateServiceRegion = async () => {
				const response = await changeServiceRegion(region, authContext.token);
				if (response?.result?.code === '0000') {
						showToast('success', `${t('service_changed_to')} ${region === 'AWS_TOKYO' ?
								t('global') : t('mumbai')}`);
						setSelectedRegion(region);
						window.location.reload();
				}
				else {
						showToast('error', t('failed_to_change_service_region_try_again_or_contact_help_desk_for_more_information'));
				}
		};

		showDialog({
				title: t('confirm_update'),
				content: t('do_you_really_want_to_update_service_region'),
				onOk: updateServiceRegion,
				okText: t('yes'),
				cancelText: t('no')
		});
	};


	const onHelpCenterClick = () => openHelpCenter(authContext.token, AppURL.helpCenter + (i18n.language === 'en_US' ? '' : 'ko'));
	
	return (
		<ContentLayout>
				<div className="mb-4">
						<div className="ant-descriptions-title text-2xl flex-none mb-4">
							{t('service_region')}
						</div>
						<div className="max-w-[470px] leading-6">
							<p>{t('service_region_desc1')}</p>
							<p>{t('service_region_desc2')}<span className='ml-1 text-linkblue underline cursor-pointer' onClick={onHelpCenterClick}>{t('help_center')}</span></p>
						</div>
						<ul className="p-5 leading-6">
							<li>{t('service_region_toyko')}</li>
							<li>{t('service_region_mumbai')}</li>
						</ul>
				</div>
				<Box className="flex flex-none align-middle gap-12 mb-4">
					<Box className="flex-none flex items-center">
						<span className="text-sm">{t('service_region')}</span>
						<Tooltip title={t('change_service_region')} arrow>
							<HelpOutlineIcon className="align-middle ml-2" sx={{ color: '#757677' }} />
						</Tooltip>
					</Box>
					<RadioGroup value={selectedRegion} onChange={handleRegionMenuClick} className="flex-row">
						{accountDetails?.use_tokyo === 'Y' && <FormControlLabel value={'AWS_TOKYO'} control={<Radio />} label={t('global')} />}
						{accountDetails?.use_mumbai === 'Y' && <FormControlLabel value={'AWS_MUMBAI'} control={<Radio />} label={t('mumbai')} />}
					</RadioGroup>
				
				</Box>
				
			</ContentLayout>
	);
}

export default ServiceRegion;

