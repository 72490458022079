import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

interface MenuOptions {
    label: string;
    value: string;
}
interface DropdownMenuProps {
    menuOptions: MenuOptions[];
    handleMenuChange: (tyoe: string) => void;
    disabled?: boolean;
}

const DropdownMenu = ({ menuOptions, handleMenuChange, disabled = false }: DropdownMenuProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (type: string) => {
        handleMenuChange(type);
        handleClose();
    };
    return (
        <div>
            <IconButton onClick={handleClick} disabled={disabled}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {menuOptions.map((v: any) => (
                    <MenuItem disabled={v.disabled} key={v.value} value={v.value} onClick={() => handleMenuClick(v.value as string)}>
                        <span className={v?.className ? v.className : ''}>{t(v.label)}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DropdownMenu;
