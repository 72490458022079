import { Controller, useForm } from 'react-hook-form';
import { Button, TextField, Checkbox, FormControlLabel, InputAdornment, IconButton, Typography, Select, InputLabel, FormControl, MenuItem, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import LanguageSelector from './languageselector';
import { FieldConfig } from './AuthData';
import { validateUserName, validatePassword, validateConfirmPassword } from './validate';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordRequirement from '../Feedback/PasswordRequirement';

interface AuthFormProps {
    data: FieldConfig[] | null;
    btnText: string;
    title: string;
    footerElements?: React.ReactNode;
    onSubmit: (data: any) => void;
    signup?: boolean;
    formHeader?: React.ReactNode;
    btnMargin?: string;
    passwordValidator?: boolean;
    emailValidationError?: boolean;
    formKey?: number;
}
type ShowPasswordsState = Record<string, boolean>;
const AuthForm = ({ data, btnText, title, footerElements, onSubmit, signup = false, formHeader, btnMargin, passwordValidator = false, emailValidationError, formKey }: AuthFormProps) => {
    const {
        control,
        handleSubmit,
        getValues,
        setError,
        watch,
        formState: { errors },
    } = useForm({});

    const { t } = useTranslation();

    const [checked, setChecked] = React.useState([false, false]);
    const [showPasswords, setShowPasswords] = useState<ShowPasswordsState>({});
    const [newPassword, setNewPassword] = useState<string>('');
    const formValues = watch();

    useEffect(() => {
        if (passwordValidator) {
            setNewPassword(formValues.user_pw ?? '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.user_pw]);

    useEffect(() => {
        if(signup && emailValidationError) {
            setError('user_id', {
                type: 'manual',
                message: t('please_enter_work_email_address'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailValidationError, formKey]);

    const handleParentChange = (event: { target: { checked: any } }) => {
        const isChecked = event.target.checked;
        setChecked([isChecked, isChecked]);
    };

    const togglePasswordVisibility = (name: string) => {
        setShowPasswords((prev) => ({ ...prev, [name]: !prev[name] }));
    };

    const handleChildChange = (index: number) => (event: { target: { checked: boolean } }) => {
        const updatedChecked = [...checked];
        updatedChecked[index] = event.target.checked;
        setChecked(updatedChecked);
    };

    const isIndeterminate = checked[0] !== checked[1];
    const isParentChecked = checked.every((check) => check);

    return (
        <div className={`${signup || passwordValidator ? 'pt-[54px]' : 'pt-[120px]'} px-5 md:px-[80px] pb-8`}>
            {formHeader ? (
                formHeader
            ) : (
                <div className={`text-center ${signup ? 'mb-[25px]' : 'mb-20'}`}>
                    <Typography variant="h1">
                        {t(title)}
                    </Typography>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                {data &&
                    data.map((item) =>
                        item?.fields && item.fields.length > 0 ? (
                            <div className="flex gap-2" key={item.name}>
                                {item.fields.map((subField) => (
                                    <div className="mb-6 w-1/2" key={subField.name}>
                                        <Controller
                                            name={subField.name}
                                            control={control}
                                            defaultValue={''}
                                            rules={{
                                                required: subField.error,
                                                pattern: subField.type === 'email' ? /^\S+@\S+\.\S+$/ : undefined,
                                                validate: subField.name === 'first_name' || subField.name === 'last_name' ? (value) => validateUserName(null, value, t) : undefined,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type={subField.type}
                                                    label={t(subField.label)}
                                                    placeholder={t(subField.placeholder)}
                                                    fullWidth
                                                    variant="outlined"
                                                    error={Boolean(errors[subField.name] ? errors[subField.name] : false)}
                                                    helperText={errors[subField.name] ? t(subField.error ? subField.error : 'error') : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="mb-6" key={item.name}>
                                {item.type === 'checkbox' ? (
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    control={control}
                                                    name={item.name}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            size="small"
                                                            required={item.required}
                                                            checked={isParentChecked}
                                                            indeterminate={isIndeterminate}
                                                            onChange={handleParentChange}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<strong>{t(item.label)}</strong>}
                                        />
                                        <div>
                                            {item?.children &&
                                                item?.children.map((child, i) => (
                                                    <div className="flex justify-between" key={`checkbox-${i}`}>
                                                        <FormControlLabel
                                                            control={
                                                                <Controller
                                                                    name={child.name}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Checkbox
                                                                            {...field}
                                                                            size="small"
                                                                            className="py-1"
                                                                            required={child.required}
                                                                            checked={checked[i]}
                                                                            onChange={handleChildChange(i)}
                                                                        />
                                                                    )}
                                                                />
                                                            }
                                                            label={t(child.label)}
                                                        />
                                                        <a className="text-[#F59E0B] text-xs underline hover:no-underline" rel="noreferrer" target="_blank" href={child.url}>
                                                            {t('learn_more')}
                                                        </a>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ) : item.type === 'readOnly' ? (
                                    <div className='font-bold'>
                                        {t(item.name)} : {item.value}
                                    </div>
                                ) : item.type === 'select' ? (
                                    <FormControl fullWidth variant="outlined" error={Boolean(errors[item.name] ? errors[item.name] : false)}>
                                        <InputLabel>{t('region')}</InputLabel>
                                        <Controller
                                            control={control}
                                            name={item.name}
                                            defaultValue={''}
                                            rules={{ required: item.error }}
                                            render={({ field }) => (
                                                <>
                                                    <Select {...field} label={t(item.label)}>
                                                        {item.options?.map((option) => (
                                                            <MenuItem value={option.value} key={option.value}>
                                                                {t(option.label)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors[item.name] && <FormHelperText>{t(item.error ? item.error : 'error')}</FormHelperText>}
                                                </>
                                            )}
                                        />
                                    </FormControl>
                                ) : (
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{
                                            required: item.error,
                                            pattern: item.type === 'email' ? /^\S+@\S+\.\S+$/ : undefined,
                                            validate:
                                                signup || passwordValidator
                                                    ? item.name === 'user_pw'
                                                        ? (value) => validatePassword(null, value, t)
                                                        : item.name === 'user_pw_confirm'
                                                          ? (value) => validateConfirmPassword(getValues('user_pw'), value, t)
                                                          : undefined
                                                    : undefined,
                                        }}
                                        defaultValue={item.value ? item.value : ''}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type={item.type === 'password' ? (showPasswords[item.name] ? 'text' : 'password') : item.type}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    autoComplete: signup ? 'new-password' : 'current-password',
                                                    endAdornment:
                                                        item.type === 'password' ? (
                                                            <InputAdornment position="end">
                                                                <IconButton aria-label="toggle password visibility" onClick={() => togglePasswordVisibility(item.name)} edge="end">
                                                                    {showPasswords[item.name] ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : undefined,
                                                }}
                                                label={t(item.label)}
                                                placeholder={t(item.placeholder)}
                                                fullWidth
                                                autoComplete={signup ? 'new-password' : 'current-password'}
                                                variant="outlined"
                                                disabled={item.disabled}
                                                error={Boolean(errors[item.name] ? errors[item.name] : false)}
                                                helperText={
                                                    Boolean(errors[item.name]) ? (errors[item.name]?.message ? t(errors[item.name]?.message as string) : t(item.error ? item.error : 'error')) : ''
                                                }
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        ),
                    )}
                {passwordValidator && (
                    <div className="mt-10">
                        <PasswordRequirement newPassword={newPassword} />
                    </div>
                )}
                <Button type="submit" className={`${signup ? 'mt-2' : btnMargin ? btnMargin : 'mt-20'} h-10`} variant="contained" color="primary" fullWidth>
                    {t(btnText)}
                </Button>
            </form>
            {footerElements && <div>{footerElements}</div>}
            <div className={`flex justify-end w-full ${signup || passwordValidator ? 'mt-2' : ' mt-10'}`}>
                <LanguageSelector />
            </div>
            <div className={`uppercase w-full text-[8px] text-center ${signup ? 'mt-5' : 'absolute bottom-[20px] left-0'}`}>{t('copyright_inka_entworks_all_rights_reserved')}</div>
        </div>
    );
};

export default AuthForm;