import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Drawer, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel, Autocomplete, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getMemberList } from '../../../../services/manage/accounts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import LoadMask from '../../../utils/loadmask';

interface GroupDrawerProps {
    open: boolean;
    onSave: (data: any, type: string) => void;
    onClose: () => void;
    type: string;
    subGroupList?: any;
}

const GroupDrawer: React.FC<GroupDrawerProps> = ({ open, onSave, onClose, type, subGroupList }) => {
    const { t } = useTranslation();
    const [showMask, setShowMask] = useState<any>(null);
    const [memberList, setMemberList] = useState<any>([]);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const accountDetails = useSelector((state: RootState) => state.mainReducer.accountDetails);
    const {
        control,
        handleSubmit,
        clearErrors,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const fetchMembers = async () => {
        const params = { token: authContext.token };
        setShowMask({ msg: t('fetching_members_information') });
        const response = await getMemberList(params);
        setShowMask(null);

        if (response?.result?.code === '0000' && Array.isArray(response?.applicationTeamMemberList)) {
            let members = response.applicationTeamMemberList;
            members = members.map((member: { member_email: string, owner_type: string }) => ({
                ...member,
                disabled: subGroupList?.some((subGroup: { userId: string }) => subGroup.userId === member.member_email) || member.member_email === authContext.owner,
            }));
            members = members.filter((member: { member_email: string; total_row_count: undefined | string; owner_type: string }) => 
                member.member_email !== accountDetails.owner && 
                !member?.total_row_count && 
                (member.owner_type === 'Member' || member.owner_type === undefined)
            );

            setMemberList(members);
        }
    };

    useEffect(() => {
        if (open) fetchMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = (data: any) => {
        reset();
        onSave(data, type);
    };
    const onCancel = () => {
        onClose();
        reset();
        clearErrors();
    };
    return (
        <>
            {showMask && <LoadMask msg={showMask.msg} />}
            <Drawer anchor="right" open={open} onClose={onCancel}>
                <form onSubmit={handleSubmit(onSubmit)} className="min-w-[800px] p-10">
                    <div className="flex items-center justify-between">
                        <Typography variant="h6">{t(type)}</Typography>
                        <div className="flex justify-end gap-2">
                            <Button className="h-10" color="secondary" variant="outlined" onClick={onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary" className="h-10">
                                {t('add')}
                            </Button>
                        </div>
                    </div>
                    <Typography variant="subtitle1" className="mb-4">
                        {t(`${type}_subtitle`)}
                    </Typography>
                    {type.includes('add') && (
                        <>
                            <div className="mb-8">
                                <Controller
                                    name="memberEmail"
                                    control={control}
                                    rules={{
                                        required: t('required_field'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('invalid_email_format'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            options={memberList}
                                            getOptionLabel={(option) => option.member_email}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.member_email} style={{ opacity: option.disabled ? 0.5 : 1, pointerEvents: option.disabled ? 'none' : 'auto' }}>
                                                    {option.member_email} {option.disabled && <Chip className="ml-2" color="secondary" label={t('added')} />}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t('enter_team_member_email_address')}
                                                    label={t('team_member_email_address')}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                field.onChange(value);
                                                if (value && value.member_email) {
                                                    setValue('memberEmail', value.member_email);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                if (newInputValue) {
                                                    setValue('memberEmail', newInputValue);
                                                }
                                            }}
                                            filterOptions={(options, state) => {
                                                return options.filter((option) => option.member_email.toLowerCase().includes(state.inputValue.toLowerCase()));
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mb-8">
                                <Controller
                                    name="role"
                                    control={control}
                                    defaultValue="Member"
                                    render={({ field }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="position-label">{t('position')}</InputLabel>
                                            <Select {...field} label={t('position')} labelId="position-label" id="position-select" variant="outlined">
                                                <MenuItem value="Manager">{t('group_manager')}</MenuItem>
                                                <MenuItem value="Member">{t('member')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </div>
                        </>
                    )}
                    {type.includes('create') && (
                        <div>
                            <div className="flex items-center gap-2">
                                <strong className="min-w-[200px]">{t('group_name')}</strong>
                                <Controller
                                    name="group_name"
                                    control={control}
                                    rules={{ required: t('required_field') }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <FormControl error={!!errors['group_name']}>
                                            <TextField
                                                {...field}
                                                placeholder={t('enter_group_name')}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                className="max-w-[264px]"
                                                margin="normal"
                                                error={!!errors['group_name']}
                                                helperText={errors['group_name'] && errors['group_name'].message ? errors['group_name'].message.toString() : ''}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </form>
            </Drawer>
        </>
    );
};

export default GroupDrawer;
