import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Button } from '../../../Foundation/Button';
import { getCreditInfo, redeemPromoCode } from '../../../../services/manage/billingdetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { showToast } from '../../../utils/notifications';
const AvailableCredits = () => {
    const { t } = useTranslation();
    const [promotionCode, setPromotionCode] = useState('');
    const [availableCredits, setAvailableCredits] = useState(0);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const loadCreditInfo = useCallback(async () => {
        const params = {
            token: authContext.token,
        };
        const response = await getCreditInfo(params);
        if (response?.result?.code === '0000') {
            let credits = parseFloat(response.currentCredit?.credit) ? response.currentCredit.credit : 0;
            setAvailableCredits(credits);
        }
    }, [authContext.token]);

    useEffect(() => {
        if (authContext.token) {
            loadCreditInfo();
        }
    }, [authContext.token, loadCreditInfo]);

    const onApplyPromotionClick = async () => {
        const params = {
            token: authContext.token,
            promotionCode: promotionCode,
        };
        const response = await redeemPromoCode(params);
        if (response?.result?.code === '0000') {
            showToast('success', t('promotional_code_applied_successfully'));
            loadCreditInfo();
        } else if (response?.result?.code) {
          setError(true);
          setHelperText(t(`RCM-${response?.result?.code}`, response?.result?.message as string));
          showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_apply_promotional_code')));
        }
        else showToast('error', t('failed_to_apply_promotional_code'));
    };

    return (
        <>
            <div className="flex flex-none justify-between pb-8">
                <div className="flex flex-none">
                    <div className="text-base font-semibold mr-2.5 items-center">{t('available_credits')}</div>
                    <Tooltip title={t('available_credits_tip')}>
                        <HelpOutlineIcon className="text-[#D7D7D7]" />
                    </Tooltip>
                </div>
                <div className="font-bold text-base">${availableCredits}</div>
            </div>
            <div className="">
                <TextField
                    variant="outlined"
                    label={t('promotional_code')}
                    placeholder={t('enter_promotional_code')}
                    value={promotionCode}
                    onChange={(e) => setPromotionCode(e.target.value)}
                    error={error}
                    helperText={helperText}
                    fullWidth
                />
                <Button
                    className="mt-2"
                    fullWidth={true}
                    variant="contained"
                    disabled={!promotionCode}
                    onClick={onApplyPromotionClick}
                >
                    {t('apply')}
                </Button>
            </div>
        </>
    );
};

export default AvailableCredits;
