import { GeneralFeaturesData } from './data';

interface EnvironmentConfigurationProps {
    getEl: (data: any, key: string) => React.ReactNode;
}
const EnvironmentConfiguration = ({ getEl }: EnvironmentConfigurationProps) => {
    return (
        <div className="flex flex-col gap-2">
            {getEl(
                GeneralFeaturesData.filter((e) => e.type === 'untrusted_environments'),
                'untrusted_environments',
            )}
        </div>
    );
};

export default EnvironmentConfiguration;
