export const isProduction = () => {
  const host = window.location.host;
  const isLocal = host.includes('localhost');
  const isDev = host.includes('dev');
  const isTst = host.includes('tst');

  if (isLocal) return false;
  if (isDev) return false;
  if (isTst) return false;
  return true;
};
