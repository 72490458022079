import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import moment from "moment";

interface UploadingModalProps {
  sealingStatus: number;
  isModalVisible: boolean;
  onDownloadClick: () => void;
  fileName: string;
  hasError: boolean | string;
  onHelpCenterClick: (
    article: string,
    section?: boolean,
    helpDeskOnly?: boolean
  ) => void;
  onCancel: () => void;
}

const UploadingModal = ({
  sealingStatus,
  isModalVisible,
  onDownloadClick,
  fileName,
  hasError,
  onHelpCenterClick,
  onCancel,
}: UploadingModalProps) => {
  const [status, setStatus] = useState(-1);
  const [seconds, setSeconds] = useState(0);
  const [durations, setDurations] = useState([0, 0]);
  const intervalRef = useRef<NodeJS.Timeout>();
  const [isCompleted, setIsCompleted] = useState(false);
  const [checkStatus, setCheckStatus] = useState({
    uploading: false,
    sealing: false,
    completed: false,
  });
  const [isError, setIsError] = useState<boolean>(hasError ? true : false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isError !== hasError) setIsError(hasError ? true : false);
    if (sealingStatus !== status) {
      const updatedDurations = [...durations];
      updatedDurations[status] = seconds;
      setDurations(updatedDurations);
      setStatus(sealingStatus);
      setSeconds(0);
    }
    if (status === 1) setCheckStatus({ ...checkStatus, uploading: true });
    if (status === 2) setCheckStatus({ ...checkStatus, sealing: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sealingStatus, status, durations, seconds, isError, hasError]);

  useEffect(() => {
    if (status !== 2) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      setIsCompleted(true);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [status]);

  const formatTimeWithMoment = (totalSeconds: number) => {
    const duration = moment.duration(totalSeconds, "seconds");
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
  };

  const styleByStatus = (isActive: boolean, completed: boolean) => {
    return isActive
      ? "text-accent font-medium text-20px ml-4"
      : completed
        ? "text-darkgray text-16px ml-4"
        : "text-lightgray text-16px ml-4";
  };

  const styleComplete = (isCompleted: boolean) => {
    return isCompleted
      ? "w-2.5 h-2.5 bg-darkgray rounded-full inline-block"
      : "inline-block w-3.5";
  };
  const customHeader = () => {
    if (isCompleted) {
      return (
        <div>
          <Typography variant="h1">{t("sealing_success")}</Typography>
          <p className="text-xs">
            {t("sealing_sucesss_start")} {fileName} {t("sealing_sucesss_end")}
          </p>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex justify-between">
          <div className="text-red-700">
            <p className="font-medium">{t("sealing_error")}</p>
            <p className="text-xs">{t("error_appsealing")}</p>
          </div>
          <CloseIcon className="cursor-pointer" onClick={handleCancel} fontSize={'small'}/>
        </div>
      );
    }
    return false;
  };
  const handleDownload = () => {
    onDownloadClick();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Dialog
      open={isModalVisible}
      onClose={() => (isCompleted || isError) && handleCancel()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{customHeader()}</DialogTitle>
      <DialogContent>
        {!isError && (
          <ul className="list-none m-0 p-9">
            <li className="mb-10 leading-4 flex items-center">
              {status === 0 ? (
                  <CircularProgress color={'primary'} size={"small"} className="w-3 h-3"/>
              ) : (
                <span className={styleComplete(checkStatus.uploading)}></span>
              )}
              <span
                className={styleByStatus(status === 0, checkStatus.uploading)}
              >
                {t("uploading_application_for_sealing")}{" "}
                {status === 0 && `(${formatTimeWithMoment(seconds)})`}
                {checkStatus.uploading && `(${formatTimeWithMoment(durations[0])})`}
              </span>
            </li>
            <li className="mb-10 leading-4">
              {status === 1 ? (
                  <CircularProgress color={'primary'} size={"small"} className="w-3 h-3"/>
              ) : (
                <span className={styleComplete(checkStatus.sealing)}></span>
              )}
              <span
                className={styleByStatus(status === 1, checkStatus.sealing)}
              >
                {t("sealing_application")}{" "}
                {status === 1 && `(${formatTimeWithMoment(seconds)})`}
                {checkStatus.sealing && `(${formatTimeWithMoment(durations[1])})`}
              </span>
            </li>
            <li>
              <span
                className={`${
                  isCompleted
                    ? "w-2.5 h-2.5 bg-accent rounded-full inline-block"
                    : "inline-block w-3.5"
                }`}
              ></span>
              <span className={styleByStatus(status === 2, isCompleted)}>
                {t("sealing_success")}
              </span>
              {isCompleted && (
                <p className="text-xs text-darkgray ml-6 leading-6 mt-4 mb-9">
                  {t("application_is_sealed_successfully")}
                </p>
              )}
            </li>
          </ul>
        )}
        {isError && (
          <div>
            <div className="text-xs mb-9">
              {hasError === ""
                ? t("error_while_sealing_application")
                : hasError}{" "}
              <span
                className="underline text-blue-500 cursor-pointer"
                onClick={() => onHelpCenterClick("", false, true)}
              >
                {" "}
                {t("help_center")}
              </span>
            </div>
          </div>
        )}
      </DialogContent>
      {isCompleted && (
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDownload}
            fullWidth
            className="bg-accent text-white border-none h-9 rounded uppercase font-medium text-xs"
          >
            {t("download_sealed_application")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UploadingModal;
