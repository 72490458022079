
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { htmlDecode } from "../../utility/Common";
import { setBadgeCount, setNotifications } from "../../store/common";
import { useTranslation } from "react-i18next";
import { Button, Chip, FormControlLabel, Switch } from "@mui/material";
import { NotificationsListType } from "../../type";
import { getColorForTag } from "../utils/common";

const Announcement = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [gridSelection, setGridSelection] = useState<any[]>([-1]);
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

  const notifications = useSelector(
    (state: RootState) => state.commonReducer.notifications
  );
  const badgeCount = useSelector(
    (state: RootState) => state.commonReducer.badgeCount
  );

  const navigate = useNavigate();
  const { seq } = useParams();

  useEffect(() => {
    if (notifications && Array.isArray(notifications.tags)) {
      setCategories([
        {
          seq: -1,
          tag_jp: "View All",
          tag_en: t("view_all"),
          tag_kr: t("view_all"),
        },
        ...notifications.tags,
      ]);
    }
    if (seq) {
      const notification = notifications?.list.find(
        (n) => n.seq === parseInt(seq)
      );
      if (notification) {
        setSelectedData(notification);
      }
    }else if(selectedData){
      setSelectedData(null);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, seq, t]);

  const markAsRead = (seq: number) => {
    const isRead = localStorage.getItem("isRead");
    if (!isRead) {
      localStorage.setItem("isRead", `[${seq}]`);
      dispatch(setBadgeCount(badgeCount ? badgeCount - 1 : 0));
    } else {
      const isReadJson = JSON.parse(isRead);
      if (!isReadJson.includes(seq)) {
        onReadClick(isReadJson, seq);
      }
    }
  };
  
  const onReadMoreClick = (e: React.MouseEvent<HTMLDivElement>, item: any): void => {
    e.preventDefault();
    const target = e.target as HTMLDivElement;
    if(target.className.includes("read-badge") || target.className.includes("read-radio") || target.className.includes("read-dot")) return;
    setSelectedData(item);
    markAsRead(item.seq);
    navigate(`/announcement/${item.seq}`);
  };
  
  const onReadClick = (isReadJson: any, seq: any) => {
    let addIsRead = [...isReadJson, seq];
    localStorage.setItem("isRead", JSON.stringify(addIsRead));
    dispatch(setNotifications({...notifications, list: notifications.list.map((n: any) => (n.seq === seq ? {...n, read: true} : n))}));
    dispatch(setBadgeCount(badgeCount ? badgeCount - 1 : 0));
  }
  const onUnreadClick = (isReadJson: any, seq: any) => {
    let removeIsRead = isReadJson.filter((n: any) => n !== seq);
    localStorage.setItem("isRead", JSON.stringify(removeIsRead));
    dispatch(setNotifications({...notifications, list: notifications.list.map((n: any) => (n.seq === seq ? {...n, read: false} : n))}));
    dispatch(setBadgeCount(badgeCount ? badgeCount + 1 : 0));
  }

  const handleUnread = (e:any, item: any) => {
    e.preventDefault();
    const isRead = localStorage.getItem("isRead") || "[]";
    const isReadJson = JSON.parse(isRead);
    if (!isReadJson.includes(item.seq)) {
      onReadClick(isReadJson, item.seq);
    }else{
      onUnreadClick(isReadJson, item.seq);
    }
  }

  const handleGoBack = () => {  
    setSelectedData(null);
    navigate(`/announcement`);
  }
  
  const removeHTMLTag = (str: string) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = str;
    let html = tmp.textContent || tmp.innerText || "";
    html = JSON.stringify(html).replace(/<[^>]*>?/gm, "").replaceAll("\"", "").replaceAll("\\n", " ").replaceAll("\\t", " ");
    return html;
  };
  const getFilteredNotification = (onlyUnread: boolean) => {
    if(onlyUnread){
      return notifications.list.filter((e: any) => !e.read);
    }

    return notifications.list;
  }
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyUnread(e.target.checked)
  }
  const readAllNotifications = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    let seqs: number[] = notifications.list.map((n: NotificationsListType) => n.seq);
    localStorage.setItem("isRead", JSON.stringify(seqs));
    dispatch(setBadgeCount(0));
  }

  return (
    <div className="flex flex-col m-10 mb-2 bg-primary p-8 pr-1 min-w-[800px]" style={{height: 'calc(100% - 120px)'}}>
      <div className="lg:mb-0 mb-4 flex lg:flex-nowrap flex-wrap justify-between items-center max-w-[1020px]">
        <div className="text-2xl font-medium mb-3">{t('manage_notifications')}</div>
        {!selectedData && <div>
          <span className="hover:underline mr-7 text-darkgray cursor-pointer" onClick={readAllNotifications}>{t('check_all_read')}</span>
          <FormControlLabel className="text-darkgray" control={<Switch size="small" onChange={handleSwitchChange} />} label={t('all_unread')} />
        </div>}
      </div>
      <div className="flex flex-col sm:flex-row flex-grow overflow-hidden ">
        <div className="h-auto mr-2 max-w-full lg:w-1/6 min-w-[150px] lg:max-w-[240px] grid gap-1 pr-4 border-r border-solid overflow-auto">
          <ul className="notification-category-list list-none p-0 max-w-[200px] mt-2">
            {categories && categories.map((e: any) => (
              <li
                key={e.seq}
                className={`cursor-pointer leading-10 text-sm mb-3 rounded-r-[100px] ${
                  gridSelection[0] === e.seq
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setGridSelection([e.seq]);
                  setSelectedData(null);
                  navigate(`/announcement`);
                }}
              >
                <span className="pl-4 text-sm">
                  {i18n.language === "kr_KR" ? e.tag_kr : e.tag_en}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className={`flex flex-col flex-grow gap-4 py-4 overflow-auto mr-2 lg:mt-0 mt-4`}>
          {selectedData ? (
            <div className="flex flex-col h-full ml-4 mr-4 p-6 md:ml-10 max-w-[1240px] border self-stretch min-w-[400px]">
              <div className="flex mb-4 border-b pb-1">
                <div className="w-full">
                  <div className="flex justify-between">
                    <p className="text-base font-bold truncate">
                      {
                        selectedData[
                          i18n.language === "kr_KR" ? "kr_title" : "en_title"
                        ]
                      }
                    </p>
                    <Button variant="outlined" color="secondary" className="ml-auto uppercase" onClick={() => handleGoBack()}>
                      {t("back")}
                    </Button>
                  </div>
                  <p className="flex flex-row gap-1 my-1">
                    {selectedData.ntypescode.map((e: any) => {
                      const ntag = notifications.tags.find(
                        (el) => e === `${el.seq}`
                      );
                
                      if (ntag)
                        return (
                          <Chip
                            key={ntag.tag_en}
                            sx={{backgroundColor: getColorForTag(ntag.color)}}
                            className={`text-tiny leading-4 text-white`}
                            label={ntag[i18n.language === "kr_KR" ? "tag_kr" : "tag_en"]}
                          />
                            
                    
                        );
                      else return null;
                    })}
                  </p>
                 <div className="flex justify-between"><p className="text-sm leading-8 text-darkgray italic">Writer. {selectedData.update_by.split('@')[0]}</p>  <p className="text-tiny font-light text-darkgray">{moment(selectedData.modify_date_format,"YYYYMMDD").fromNow()}</p></div> 
                </div>
              </div>
              <div className="flex-grow overflow-auto">           
                <div
                  className="flex-grow max-w-full"
                  dangerouslySetInnerHTML={{
                    __html:
                    htmlDecode(
                        selectedData[
                          i18n.language === "kr_KR"
                            ? "kr_contents"
                            : "en_contents"
                        ]
                      ) || "",
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="lg:pl-4 md:pl-10 max-w-[794px] h-full">
                {getFilteredNotification(onlyUnread)
                  .filter((e: any) => {
                    if (
                      gridSelection[0] === -1 ||
                      e.notify_type.includes(gridSelection[0])
                    )
                      return true;
                    else return false;
                  })
                  .map((item, i) => (
                      <div
                        key={i}
                        onClick={(e) => onReadMoreClick(e, item)}
                        className={`${item.read ? "isRead" : ""} p-4 cursor-pointer notification-item pr-6`}
                      >
                        <div className="flex gap-4 items-center">
                        <p
                          className={`${item.read ? "opacity-50" : ""} text-sm font-bold leading-6 hover:underline whitespace-nowrap truncate`}
                        >
                          {
                            item[
                              i18n.language === "kr_KR"
                                ? "kr_title"
                                : "en_title"
                            ]
                          }
                        </p>
                        <p className="text-tiny font-light whitespace-nowrap text-darkgray">{moment(item.modify_date_format,"YYYYMMDD").fromNow()}</p>
                        </div>
                        <div className="flex justify-between gap-4">
                          <p
                            className={`line-clamp-1 text-sm leading-6 ${item.read ? "opacity-50" : ""}`}>
                              {removeHTMLTag(htmlDecode(item[
                                    i18n.language === "kr_KR"
                                      ? "kr_contents"
                                      : "en_contents"
                                  ]
                                ) || "")}</p>
                  
                          <div className="read-btn gap-4 items-center">
                            <button className="text-10px text-white rounded-sm py-1 px-2 min-w-[92px]">
                              {item.read ? t("unread") : t("read")}
                            </button>
                            <span onClick={(e)=> handleUnread(e, item)} className="read-badge w-4 h-4 min-w-4 read-radio flex justify-center items-center">{!item.read ? <span className="read-dot w-2 h-2 bg-accent rounded"></span>: ""}</span>
                          </div>
                        </div>
                        <p className="flex flex-row gap-1 mt-1">
                          {item.ntypescode.map((e: any) => {
                            const ntag = notifications.tags.find(
                              (el) => e === `${el.seq}`
                            );
                            if (ntag)
                              return (
                                <Chip
                                key={ntag.tag_en}
                                sx={{backgroundColor: getColorForTag(ntag.color)}}
                                className={`text-tiny leading-4 text-white`}
                                label={ntag[i18n.language === "kr_KR" ? "tag_kr" : "tag_en"]}
                              />    
                              );
                            else return null;
                          })}
                        </p>
                      </div>
                  ))}
                  {getFilteredNotification(onlyUnread)
                  .filter((e: any) => {
                    if (
                      gridSelection[0] === -1 ||
                      e.notify_type.includes(gridSelection[0])
                    )
                      return true;
                    else return false;
                  }).length === 0 && (<div className="text-center w-full flex-grow h-full flex justify-center items-center">{t('no_notification')}</div>)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Announcement;
