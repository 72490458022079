import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateAccountMember } from '../../../../../services/manage/accounts';
import { RootState } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { showToast } from '../../../../utils/notifications';

interface EditMemberModalInterface {
    visible: boolean;
    memberInfo: any;
    onCancel: () => void;
    updateMemberDetails: (data: any) => void;
}

const EditMemberModal = (props: EditMemberModalInterface) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    useEffect(() => {
        if (props.visible) {
            reset({ email: props.memberInfo?.member_email, notes: props.memberInfo?.notes, fullName: props.memberInfo?.member_name });
        }
    }, [props.visible, props.memberInfo, reset]);
    const onUpdateClick = async (values: any) => {
        const params = {
            ...props.memberInfo,
            currentNote: props.memberInfo?.notes,
            member_name: values.fullName,
            notes: values.notes || '',
            token: authContext.token,
        };

        const response = await updateAccountMember(params);

        if (response?.result?.code === '0000') {
            reset();
            showToast('success', t('member_information_updated_successfully'));
            props.updateMemberDetails(response?.applicationTeamMemberList);
        } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_update_member_information')));
        else showToast('error', t('failed_to_update_member_information'));
    };

    return (
        <Drawer anchor="right" open={props.visible} onClose={props.onCancel}>
            <form onSubmit={handleSubmit(onUpdateClick)}>
                <div className="p-10 max-w-[800px]">
                    <div className="flex items-start justify-between gap-10 mb-10">
                        <div>
                            <h2 className="m-0 text-2xl font-medium">{t('edit_team_member')}</h2>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button className="h-10" color="secondary" variant="outlined" onClick={props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary" className="h-10">
                                {t('update')}
                            </Button>
                        </div>
                    </div>
                    <Box>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    label={t('email_address')}
                                    type="email"
                                    placeholder={t('enter_email_address')}
                                    fullWidth
                                    disabled
                                    variant="outlined"
                                    className="mb-8"
                                />
                            )}
                        />
                        <Controller
                            name="fullName"
                            control={control}
                            rules={{ required: t('enter_full_name') }}
                            render={({ field }) => (
                                <TextField {...field} size="small" label={t('full_name')} placeholder={t('enter_full_name')} fullWidth variant="outlined" className="mb-8" required />
                            )}
                        />
                        <Controller
                            name="notes"
                            control={control}
                            render={({ field, fieldState }) => (
                                <TextField {...field} multiline rows={4} label={t('notes')} placeholder={t('enter_notes')} fullWidth variant="outlined" className="mb-4" />
                            )}
                        />
                    </Box>
                </div>
            </form>
        </Drawer>
    );
};

export default EditMemberModal;
