import { Get, Post } from "../..";
import { ChangePlanInterface, ContactUSInterface, PlanInterface, TransactionHistoryInterface } from "../../../type/api/manage";
import { AppURL } from "../../../utility/AppURL";

export const getTermsOfService = async () => {
    try {
        const response = await Get(AppURL.termsofservice)
        const textResponse = await response.text()
        return textResponse;
    } catch (error) {
        throw error;
    }
};
export const getPrivacyPolicy = async () => {
    try {
        const response = await Get(AppURL.privacypolicy)
        const textResponse = await response.text()
        return textResponse;
    } catch (error) {
        throw error;
    }
};

export const getPlanDetails = async (params: PlanInterface) => {
    let urlParams = new URLSearchParams();

    for (let [key, value] of Object.entries(params)) {
        urlParams.append(key, value || '');
    }
    
    try {
        const response = await Post(AppURL.plan, urlParams);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const changePlanDetails = async (params: ChangePlanInterface) => {
    const { token, aos_plan_type, ios_plan_type, hybrid_plan_type } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('aos_plan_type', aos_plan_type);
    urlParams.append('ios_plan_type', ios_plan_type);
    urlParams.append('hybrid_plan_type', hybrid_plan_type);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.changeplan, urlParams
    ).then(res => res.json());
    return response;
};

export const savePlanDetails = async (params: object) => {
    let urlParams = new URLSearchParams();

    for (let [key, value] of Object.entries(params)) {
        urlParams.append(key, value || '');
    }

    let response = await Post(
        AppURL.payment, urlParams
    ).then(res => res.json());
    return response;
};

export const getCreditInfo = async (params: { token: string }) => {
    let urlParams = new URLSearchParams();

    urlParams.append('token', params.token);

    let response = await Post(
        AppURL.getcreditinfo, urlParams
    ).then(res => res.json());
    return response;
};

export const getTransactionHistory = async (params: TransactionHistoryInterface) => {
    const { token, srchStartDate, srchEndDate, current_page, count_per_page } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('srchStartDate', srchStartDate);
    urlParams.append('srchEndDate', srchEndDate);
    urlParams.append('current_page', current_page);
    urlParams.append('count_per_page', count_per_page);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.txhistory, urlParams
    ).then(res => res.json());
    return response;
};

export const redeemPromoCode = async (params: { token: string, promotionCode: string }) => {
    const { token, promotionCode } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('promotionCode', promotionCode);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.redeempromocode, urlParams
    ).then(res => res.json());
    return response;
};

export const getPublicKey = async (params: { token: string }) => {
    let urlParams = new URLSearchParams();

    urlParams.append('token', params.token);

    let response = await Post(
        AppURL.getpublickey, urlParams
    ).then(res => res.json());
    return response;
};

export const getSetupIntent = async (params: { token: string }) => {
    let urlParams = new URLSearchParams();

    urlParams.append('token', params.token);

    let response = await Post(
        AppURL.getsetupintent, urlParams
    ).then(res => res.json());
    return response;
};

export const savePaymentInfo = async (params: object) => {
    let urlParams = new URLSearchParams();

    for (let [key, value] of Object.entries(params)) {
        urlParams.append(key, value || '');
    }

    let response = await Post(
        AppURL.savepaymentinfo, urlParams
    ).then(res => res.json());
    return response;
};

export const removePaymentInfo = async (params: { token: string }) => {
    let urlParams = new URLSearchParams();

    urlParams.append('api_ver', '4');
    urlParams.append('pay_auth_info', '');
    urlParams.append('token', params.token);

    let response = await Post(
        AppURL.removepginfo, urlParams
    ).then(res => res.json());
    return response;
};

export const contactUs = async (params: ContactUSInterface) => {
    const { token, name, email, mobile, company = "need not", subject = "need not", content } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('name', name);
    urlParams.append('email', email);
    urlParams.append('mobile', mobile);
    urlParams.append('company', company);
    urlParams.append('subject', subject);
    urlParams.append('content', content);

    let response = await Post(
        AppURL.contactus, urlParams
    ).then(res => res.json());
    return response;
};