import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadInvoicePdf, getInvoiceList, raiseRefundRequest } from '../../../../services/manage/billingdetails/invoice';
import { RootState } from '../../../../store';
import { showDialog, showToast } from '../../../utils/notifications';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { useForm } from 'react-hook-form';

import { InvoiceFields, InvoiceStatusCode } from './InvoiceFields';
import { Button } from '../../../Foundation/Button';
import InvoiceTable from './invoiceTable';
import { filterBy } from '../../../../utility/filtering';
import FilterSearch from '../../../utils/common/FilterSearch';

const currentTime = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`;
const groupInvoices = (data: any[]) => {
    data.pop();
    data = data.map((e) => ({ ...e, key: `${e.account_id} - ${e.period}` }));
    data = data.reduce((result, currentItem) => {
        result[currentItem['key']] = result[currentItem['key']] || { ...currentItem, items: [], amount: 0 };
        result[currentItem['key']].amount += Number.parseFloat(currentItem.amount_us);
        result[currentItem['key']].amount += Number.parseFloat(currentItem.ota_service_fee);
        result[currentItem['key']].amount += Number.parseFloat(currentItem.data_sealing_fee);
        result[currentItem['key']].items.push(currentItem);
        return result;
    }, {});
    let invoices = [];
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) invoices.push(data[key]);
    }
    return invoices;
};

interface InvoiceParams {
    invoiceType: string;
    period_from: string;
    period_from_view: string;
    period_to: string;
    token: string;
}
const Invoice = () => {
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            period_from: moment(currentTime, 'YYYY-MM').subtract(1, 'months'),
            period_to: undefined,
            invoice_id: '',
            company_name: '',
            package_name: '',
            status: '',
            minMonth: '',
            maxMonth: '',
        },
    });
    const { t } = useTranslation();
    const [key, setKey] = useState(0);
    const [filters, setFilters] = useState<any>([]);
    const [showMask, setShowMask] = useState<any>(null);
    const [invoiceList, setInvoiceList] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(moment(currentTime, 'YYYY-MM').subtract(1, 'months'));
    const [toDate, setToDate] = useState<any>(undefined);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const formatDate = (date: string) => {
        if (date.length === 6) {
            return `${date.substring(0, 4)}-${date.substring(4)}`;
        } else if (date.length === 8) {
            return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6)}`;
        }
    };
    const fetchInvoiceList = async (params: InvoiceParams) => {
        setShowMask({ msg: t('fetching_invoices') });
        const response = await getInvoiceList(params);
        setShowMask(null);
        if (response?.result.code === '0000' && Array.isArray(response?.invoiceList)) {
            const invoices = groupInvoices(response.invoiceList);

            const data = invoices.map(
                (
                    {
                        invoice_id,
                        company_name,
                        period,
                        amount_us: amount,
                        package_name,
                        status,
                        due_date,
                        items,
                    }: {
                        invoice_id: string;
                        company_name: string;
                        period: string;
                        amount_us: string;
                        package_name: string;
                        status: string;
                        due_date: string;
                        items: any[];
                    },
                    index
                ) => ({
                    id: index,
                    invoice_id,
                    company_name,
                    package_name,
                    amount_us: amount,
                    period: period ? formatDate(period) : 'N/A',
                    amount: `$${amount ? parseFloat(amount)?.toLocaleString() : amount}`,
                    status: InvoiceStatusCode[status].text || 'N/A',
                    status_code: status,
                    due_date: due_date ? formatDate(due_date) : 'N/A',
                    items: items
                        ? items.map(
                              ({
                                  region,
                                  package_name,
                                  service_type: platform,
                                  mau_cnt: mad_count,
                                  amount_us: mad_fee,
                                  data_sealing_fee: datasealing_fee,
                                  ota_service_fee: ota_update_fee,
                              }: {
                                  region: string;
                                  package_name: string;
                                  service_type: string;
                                  mau_cnt: string;
                                  amount_us: string;
                                  data_sealing_fee: string;
                                  ota_service_fee: string;
                                  total_fee: string;
                              }) => ({
                                  region,
                                  package_name,
                                  platform: platform.includes('IOS') ? 'IOS' : 'Android',
                                  mad_count,
                                  mad_fee: '$' + parseFloat(mad_fee)?.toLocaleString(),
                                  datasealing_fee: '$' + parseFloat(datasealing_fee)?.toLocaleString(),
                                  ota_update_fee: '$' + parseFloat(ota_update_fee)?.toLocaleString(),
                                  total_fee: '$' + parseFloat(mad_fee)?.toLocaleString(),
                              })
                          )
                        : [],
                })
            );
            setInvoiceList(data);
        }
    };
    useEffect(() => {
        if (invoiceList === null) {
            const params = {
                invoiceType: 'merged',
                period_from: fromDate ? fromDate.format('YYYYMM') : '',
                period_from_view: fromDate ? fromDate.format('YYYYMM') : '',
                period_to: toDate ? toDate.format('YYYYMM') : undefined,
                token: authContext.token,
            };
            fetchInvoiceList(params);
            setInvoiceList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceList, authContext.token, t]);

    const filterChange = (key: any, value: string) => {
        setValue(key, value);
    };

    const onSearchClick = (values: any) => {
        let filter = [];

        for (let [key, value] of Object.entries(values)) {
            if (value && key !== 'period_from' && key !== 'period_to') {
                if (key === 'minMonth') filter.push({ field: 'amount_us', value: Number(value), operator: 'gte' });
                else if (key === 'maxMonth') filter.push({ field: 'amount_us', value: Number(value), operator: 'lte' });
                else if (key === 'status') {
                    filter.push({ field: key, value: (InvoiceStatusCode as any)[value as keyof typeof InvoiceStatusCode].text, operator: 'contains' });
                } else filter.push({ field: key, value: value, operator: 'contains' });
            }
       
            setFilters(filter);
        }

        const params: InvoiceParams = {
            invoiceType: 'merged',
            period_from: values.period_from ?  values.period_from.format('YYYYMM') : '',
            period_from_view:  values.period_from  ?  values.period_from.format('YYYYMM') : '',
            period_to:  values.period_to ? values.period_to.format('YYYYMM') : undefined,
            token: authContext.token,
        };
        fetchInvoiceList(params);
    };

    const onMenuClick = (e: string, record: any) => {
        switch (e) {
            case '1':
                onPayNowClick(record);
                break;
            case '2':
                onDownloadInvoiceClick(record);
                break;
            case '3':
                onDownloadReceiptClick(record);
                break;
            case '4':
                onRaiseRefundClick(record);
                break;
            default:
                break;
        }
    };

    const onPayNowClick = (record: any) => {
        if (record?.stripe_invoice_url) window.open(record.stripe_invoice_url, '_blank');
    };

    const onDownloadInvoiceClick = (record: any) => {
        const downloadInvoice = async () => {
            const params = {
                action: 'SEE_PAYED',
                token: authContext.token,
                invoice_id: record.invoice_id,
            };
            const response = await downloadInvoicePdf(params);
            if (response) {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(response);
                link.download = `${record.invoice_id}.pdf`;
                link.click();
            }
        };

        showDialog({
            title: t('confirm_download'),
            content: `${t('do_you_want_to_download_invoice')} ${record.invoice_id}`,
            onOk: downloadInvoice,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onDownloadReceiptClick = (record: any) => {
        const downloadReceipt = async () => {
            const params = {
                action: 'SEE_BANK',
                token: authContext.token,
                invoice_id: record.invoice_id,
            };
            const response = await downloadInvoicePdf(params);
            if (response) {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(response);
                link.download = `${record.invoice_id}.pdf`;
                link.click();
            }
        };

        showDialog({
            title: t('confirm_download'),
            content: `${t('do_you_want_to_download_paid_receipt')} ${record.invoice_id}`,
            onOk: downloadReceipt,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onRaiseRefundClick = (record: any) => {
        const refundRequest = async () => {
            const params = {
                invoiceType: 'merged',
                token: authContext.token,
                invoiceId: record.invoice_id,
                invoice_id: record.invoice_id,
            };
            const response = await raiseRefundRequest(params);
            if (response?.result.code === '0000') showToast('success', t('refund_request_is_generated_for_invoice'));
            else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_generate_refund_request_for_invoice')));
            else showToast('error', t('failed_to_generate_refund_request_for_invoice'));
        };

        showDialog({
            title: t('confirm_refund'),
            content: `${t('do_you_want_to_raise_refund_for_payment_of_invoice')} - ${record.invoice_id}`,
            onOk: refundRequest,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };
    const handleReset = () => {
        reset();
        setFromDate(moment(currentTime, 'YYYY-MM').subtract(1, 'months'));
        setToDate(undefined);
        setFilters([]);
        setKey(key + 1);
    };

    const ActionElement = (
        <div className="flex flex-none gap-2 mt-4 xl:ml-5 xl:mt-0">
            <Button onClick={handleSubmit(onSearchClick)} type="submit" label={t('search')} />
            <Button onClick={handleReset} variant="outlined" color="secondary" label={t('reset')} className='whitespace-nowrap' />
        </div>
    );

    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <div className="max-h-auto lg:max-h-44">
                <div className="flex flex-wrap flex-none w-full xl:flex-nowrap">
                    <div className="flex-auto mr-12 text-2xl font-medium whitespace-nowrap">{t('invoice')}</div>
                    <form onSubmit={handleSubmit(onSearchClick)}>
                        <FilterSearch fields={InvoiceFields} control={control} actionElement={ActionElement} onChange={filterChange} />
                    </form>
                </div>
            </div>
            <div className="flex-grow h-full p-4 pt-1 mt-8 overflow-auto">
                {invoiceList && invoiceList.length > 0 ? (
                    <InvoiceTable onMenuClick={onMenuClick} data={filterBy(invoiceList, filters)} />
                ) : (
                    <div className="w-full m-auto mx-auto text-center whitespace-pre h-fulltext-xl py-52">{t('no_invoices_found')}</div>
                )}
            </div>
        </ContentLayout>
    );
};

export default Invoice;
