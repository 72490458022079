import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialData = {
    mode: 'test',
    app_type: 'NON_GAME',
    dex_encrypt: 'yes',
    use_query_all_packages: 'no',
    dex_string_encrypt: 'no',
    block_dex_dump: 'no',
    rooting: 'forbid',
    vmenv: 'forbid',
    block_work_profile: 'yes',
    block_usb_debugging: 'yes',
    macro: 'yes',
    sniff: 'yes',
    block_keylogger: 'no',
    block_external_launch: 'no',
    block_mirroring: 'no',
    block_remote_access: 'no',
    block_screen_capture: 'no',
    allow_emulator: [],
    allow_work_profiles_owner_app_name: [],
    hide_overlay_windows: 'no',
    data_sealing: 'no',
    use_ota_service: 'no',
    block_developer_options: 'no',
    wifi_security_protocol: 'disable',
};

const getInitialSealingData = () => {
    let data: any = localStorage.getItem('sealing_preferences');
    try {
        data = JSON.parse(data);
    } catch (error) {}

    if (!data) {
        data = { ...initialData };
    } else {
        data = {
            ...initialData,
            ...data,
        };
    }

    return data;
};

interface AndroidSealingReducerInterface {
    sealingPreferences: any;
    keyStoreInfo: any;
    serviceables: { [key: string]: Array<any> } | null;
    presets: Array<any> | null;
}

const initialState: AndroidSealingReducerInterface = {
    sealingPreferences: getInitialSealingData(),
    keyStoreInfo: null,
    serviceables: null,
    presets: null,
};

const AndroidSealingReducerSlice = createSlice({
    name: 'androidReducer',
    initialState,
    reducers: {
        setServiceables: (state, action: PayloadAction<any>) => {
            state.serviceables = action.payload;
        },
        setKeyStoreInfo: (state, action: PayloadAction<any>) => {
            state.keyStoreInfo = action.payload;
        },
        setSealingPreferences: (state, action: PayloadAction<any>) => {
            state.sealingPreferences = action.payload;
        },
        setPresets: (state, action: PayloadAction<any>) => {
            state.presets = action.payload;
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = { ...initialState, sealingPreferences: state.sealingPreferences };
        },
    },
});

export const { setServiceables, setKeyStoreInfo, setSealingPreferences, setPresets, userLogout } = AndroidSealingReducerSlice.actions;

export default AndroidSealingReducerSlice.reducer;
