import { userLogout } from "../../store/main";
import { userLogout as userLogoutCommonStore } from "../../store/common";
import { userLogout as userLogoutDataStore } from "../../store/data";
import { userLogout as userLogoutAccountStore } from "../../store/manage/accounts";
import { userLogout as userLogoutBillingStore } from "../../store/manage/billingdetails";
import { userLogout as userLogoutAOSStore } from "../../store/sealing/android";
import { userLogout as userLogoutIOSStore } from "../../store/sealing/ios";
import { getCookies, setCookies } from "../../utility/Common";
import { logout } from "../../services/login";


export const userLogoutFunction = async() => {
	const token = getCookies('appsealing') || "";
	const response = await logout(token);
	if(response?.result?.code === '0000') {
		handleUserLogout();
	}else{
		handleUserLogout();
	}
};

const handleUserLogout = () => {
		setCookies('appsealing', '');
		userLogout(null)
		userLogoutCommonStore(null);
		userLogoutDataStore(null);
		userLogoutAccountStore(null);
		userLogoutBillingStore(null);
		userLogoutAOSStore(null);
		userLogoutIOSStore(null);
		sessionStorage.removeItem('FeedbackButtonClosed');
	 	window.location.reload();
}