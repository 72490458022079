import { GeneralFeaturesData } from './data';

interface ToolsAndPackagesProps {
    getEl: (data: any, key: string) => React.ReactNode;
}

const ToolsAndPackages = ({ getEl }: ToolsAndPackagesProps) => {
    return (
        <>
            <div className="flex flex-col gap-2">
                {getEl(
                    GeneralFeaturesData.filter((e) => e.type === 'cheat_tools'),
                    'cheat_tools',
                )}
                {getEl(
                    GeneralFeaturesData.filter((e) => e.type === 'app_permission'),
                    'app_permission',
                )}
            </div>
            
        </>
    );
};

export default ToolsAndPackages;
