import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { activateMember, getInviteHistory, getMemberList, updateMemberToMember, updateMemberToSubOwner } from '../../../../../services/manage/accounts';
import { RootState } from '../../../../../store';
import EditMemberModal from './details';
import InviteModal from './invite';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../../Layout/Content';
import { InputAdornment, TextField, Chip, Button, Select, MenuItem, SelectChangeEvent, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../../../../DataDisplay/Table';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import DropdownMenu from '../../../../utils/dropDownMenu';
import { showDialog, showToast } from '../../../../utils/notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const Members = () => {
    const { t, i18n } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [memberList, setMemberList] = useState<any>(null);
    const [memberInfo, setMemberInfo] = useState<any>(null);
    const [showMask, setShowMask] = useState<any>(null);
    const [inviteHistory, setInviteHistory] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOwnerTypes, setSelectedOwnerTypes] = useState<string[]>(['Owner', 'SubOwner', 'Member']);

    const handleMenuChange = async (type: string, member: any) => {
        if (type === 'user_details') {
            if (memberList) {
                setMemberInfo(member);
                setIsEditModalVisible(true);
            }
        } else if (type === 'user_activate') {
            showDialog({
                title: t('reactivate_user_title', { name: member.member_name, id: member.member_email }),
                content: t('reactivate_user_description', { name: member.member_name, id: member.member_email }),
                onOk: () => reactivateUser(member),
                okText: t('confirm'),
                cancelText: t('cancel'),
            });
        }
    };

    const reactivateUser = async (member: any) => {
        const params = {
            user_id: member.member_email,
            token: authContext?.token,
            lang: i18n.language === 'kr_KR' ? 'KR' : 'EN',
        };
        const response = await activateMember(params);

        if (response?.result?.code === '0000') {
            const updatedMemberList = memberList.map((mem: any) => (mem.member_email === member.member_email ? { ...mem, user_status_translate: t(`user_status_A`) } : mem));

            setMemberList(updatedMemberList);
            showToast('success', t('successfully_activated_user'));
        } else {
            showToast('error', t('failed_to_activated_user'));
        }
    };

    const changeRoles = (
        e: React.ChangeEvent<HTMLSelectElement>,
        member: {
            owner_type: any;
            member_name: string;
            member_email: string;
        },
    ) => {
        const newRole = e.target.value;
        showDialog({
            title: t('change_role_title', { name: member.member_name, id: member.member_email, role: t(member.owner_type.toLowerCase()), new_role: t(newRole.toLowerCase()) }),
            content: t('change_role_description', { name: member.member_name, id: member.member_email }),
            onOk: () => updateRoles(member, newRole),
            okText: t('confirm'),
            cancelText: t('cancel'),
        });
    };

    const updateRoles = async (member: { member_name: string; member_email: string }, newRole: string) => {
        const lang = i18n.language === 'kr_KR' ? 'KR' : 'EN';
        const params = { userId: member.member_email, token: authContext?.token, lang };
        setShowMask({ msg: t('changing_role') });
        if (newRole === 'Member') {
            const response = await updateMemberToMember(params);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                showToast('success', t('successfully_changed_role'));
                let updateMembers = memberList.map((mem: { member_email: string }) => (mem.member_email === member.member_email ? { ...mem, owner_type: newRole } : mem));
                setMemberList(updateMembers);
            } else {
                showToast('error', t('failed_to_change_role'));
            }
        } else if (newRole === 'SubOwner') {
            const response = await updateMemberToSubOwner(params);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                showToast('success', t('successfully_changed_role'));
                let updateMembers = memberList.map((mem: { member_email: string }) => (mem.member_email === member.member_email ? { ...mem, owner_type: newRole } : mem));
                setMemberList(updateMembers);
            } else {
                showToast('error', t('failed_to_change_role'));
            }
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOwnerTypeToggle = (ownerType: string) => {
        setSelectedOwnerTypes((prev) => {
            if (prev.includes(ownerType)) {
                return prev.filter((type) => type !== ownerType);
            } else {
                return [...prev, ownerType];
            }
        });
    };

    const columns: GridColDef[] = [
        {
            headerName: t('user_name'),
            field: 'member_name',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('email_address'),
            field: 'member_email',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('position'),
            field: 'owner_type',
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <div className="flex items-center">
                    <strong>{t('position')}</strong>
                    <Box display="flex" alignItems="center">
                        <IconButton size="small" aria-label="options" onClick={handleClick} sx={{ marginLeft: 1 }}>
                            <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                            {['Owner', 'SubOwner', 'Member'].map((ownerType) => (
                                <MenuItem key={ownerType} onClick={() => handleOwnerTypeToggle(ownerType)}>
                                    <Checkbox checked={selectedOwnerTypes.includes(ownerType)} size="small" />
                                    <ListItemText primary={t(ownerType.toLowerCase())} />
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </div>
            ),
            renderCell: (params: GridRenderCellParams) => {
                const roles = ['Member', 'SubOwner'];
                return (
                    <>
                        {params.value === 'Owner' ? (
                            <span>{t(params.value?.toLowerCase())}</span>
                        ) : (
                            <Select
                                value={params.value}
                                disabled={authContext?.role !== 'Owner'}
                                onChange={(e: SelectChangeEvent<string>) => {
                                    changeRoles(e as unknown as React.ChangeEvent<HTMLSelectElement>, params.row);
                                }}
                                fullWidth
                                size="small"
                                variant="standard"
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {t(role?.toLowerCase())}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </>
                );
            },
        },
        {
            headerName: t('notes'),
            field: 'notes',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            headerName: t('status'),
            field: 'user_status_translate',
            flex: 0.7,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <Chip label={params.row.user_status_translate} color={params.row.user_status_translate === 'Active' || params.row.user_status_translate === '정상' ? 'success' : 'warning'} />
            ),
        },
        {
            headerName: t('joining_date'),
            field: 'joined',
            sortable: true,
            flex: 0.5,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => <span>{params.value ? `${params.value.slice(0, 4)}/${params.value.slice(4, 6)}/${params.value.slice(6, 8)}` : ''}</span>,
        },
        {
            headerName: t('action'),
            field: 'action',
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                const menuOptions = [
                    {
                        label: 'user_details',
                        value: 'user_details',
                    },
                    {
                        label: 'user_activate',
                        value: 'user_activate',
                        className: 'text-red-500',
                        disabled: params.row.user_status === 'A',
                    },
                ];

                return <>{params.row.owner_type !== 'Owner' && <DropdownMenu menuOptions={menuOptions} handleMenuChange={(type: string) => handleMenuChange(type, params.row)} />}</>;
            },
        },
    ];

    useEffect(() => {
        const fetchMembers = async () => {
            let members = [];
            const params = { token: authContext.token };
            setShowMask({ msg: t('fetching_members_information') });
            const response = await getMemberList(params);
            setShowMask(null);
            if (response?.result?.code === '0000' && Array.isArray(response?.applicationTeamMemberList)) {
                members = response.applicationTeamMemberList;
                members.splice(members.length - 1, 1);
            }
            members.forEach((mem: { user_status_translate: string; user_status: string }) => {
                mem.user_status_translate = t(`user_status_${mem.user_status}`);
            });
            setMemberList(members);
        };

        fetchMembers();
    }, [authContext, i18n.language, t]);

    useEffect(() => {
        if (inviteHistory === null) {
            const fetchInviteHistory = async () => {
                let invites = [];
                const params = { token: authContext.token };
                const response = await getInviteHistory(params);
                if (response?.result?.code === '0000' && Array.isArray(response?.inviteHistory)) {
                    invites = response.inviteHistory;
                }
                setInviteHistory(invites);
            };
            fetchInviteHistory();
        }
    }, [inviteHistory, authContext]);

    const filterData = (data: any) => {
        if (!data) return [];
        return data.filter(
            (member: any) =>
                (member.member_name.toLowerCase().includes(searchValue.toLowerCase()) || member.member_email.toLowerCase().includes(searchValue.toLowerCase())) &&
                selectedOwnerTypes.includes(member.owner_type),
        );
    };

    const onSearch = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    const handleEditCancel = (member_email?: string) => {
        if (member_email) {
            setMemberList((prev: any[]) => prev.filter((member: any) => member.member_email !== member_email));
        }
        setMemberInfo(null);
        setIsEditModalVisible(false);
    };

    const updateMemberDetails = (data: any) => {
        let members: any = memberList;
        if (Array.isArray(data)) {
            members = data;
            members.splice(members.length - 1, 1);
            members.forEach((mem: { user_status_translate: string; user_status: string }) => {
                mem.user_status_translate = t(`user_status_${mem.user_status}`);
            });
        }
        setMemberInfo(null);
        setMemberList(members);
        setIsEditModalVisible(false);
    };

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);
    const updateHistoryDetails = () => setInviteHistory(null);

    const createRows = (data: any[]) => {
        const rows = data.map((item: any, index: number) => {
            return { ...item, id: index + 1 };
        });

        return rows;
    };

    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <div className="flex flex-wrap justify-between gap-6 mb-8 lg:flex-nowrap">
                <div className="text-2xl font-medium">
                    <span className="text-2xl font-medium whitespace-nowrap">{t('user_management')}</span>
                </div>
                <div className="lg:mt-0 w-full max-w-[656px]">
                    <TextField
                        id="search-input"
                        label="Search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder={t('team_search_placeholder')}
                        onChange={(e) => onSearch(e.target.value)}
                        className="w-full"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div>
                    <Button variant="contained" onClick={showModal} className="text-xs font-medium">
                        {t('add')}
                    </Button>
                </div>
            </div>

            <InviteModal visible={isModalVisible} inviteHistory={inviteHistory} onCancel={handleCancel} updateHistoryDetails={updateHistoryDetails} />
            <EditMemberModal visible={isEditModalVisible} memberInfo={memberInfo} onCancel={handleEditCancel} updateMemberDetails={updateMemberDetails} />

            <div className="overflow-auto">
                <div className="flex-grow pl-1 p-4 pt-1 min-w-[800px]">
                    <DataTable height={'100%'} columns={columns} rows={createRows(filterData(memberList) || [])} pageSize={10} pageSizeOptions={[5, 10, 15, 20, 25, 30]} />
                </div>
            </div>
        </ContentLayout>
    );
};

export default Members;
