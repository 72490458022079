import { useTranslation } from "react-i18next";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import LineChart from "../../charts/LineChart";
import { DummyLineChart } from "../../charts/DummyChart";

interface MonthlyActiveChartProps {
  totalData: any;
  period: moment.Moment | null;
}

const MonthlyActiveChart = ({ totalData, period }: MonthlyActiveChartProps) => {
  const { t } = useTranslation();
  
  const chartTooltip = (tooltipData: any) => {
    return tooltipData ? (
      <div className="bg-primary p-2 border border-solid">
        <p>
          {t("detection_date")} : <b>{tooltipData.calc_date}</b>
        </p>
        <p>
          {t("no_of_devices")} :{" "}
          <b>{tooltipData.y.toLocaleString()}</b>
        </p>
      </div>
    ) : (
      <p />
    )
  };

  const chartLengend = () => {
    return [
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateY: 40,
            itemWidth: 80,
            itemHeight: 10,
            symbolSpacing: 12,
            symbolShape: ({ fill }: { fill: string }) => {
              const yOffset = (12 - 3) / 2; 
              return <rect width={23} height={3} fill={fill} y={yOffset} className="mr-2" />;
            },
            data: ["HistoryData", "CurrentData"].map((e) => ({
              id: e,
              label:
                e === "HistoryData"
                  ? moment(period).subtract(1, "M").format("YYYY-MM")
                  : moment(period).format("YYYY-MM"),
                color: e === "HistoryData" ? "#4ECCF3" : "#F52D2D",
            })),
          },
        ]
  }
  return (
    <div className="w-full min-h-24 bg-primary mb-4 pt-5 pb-10 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <Tooltip title={t("monthly_active_device_tooltip")}>
        <p className="text-base text-primary normal-case font-normal">
          {t("monthly_active_devices")}
        </p>
      </Tooltip>
      <div className="h-[500px]">
        {totalData.chartData.monthlyDevices.length > 1 ? (
          <LineChart data={totalData.chartData.monthlyDevices} chartTooltip={chartTooltip} chartLengend={chartLengend} />
        ) : <DummyLineChart/>}
      </div>
      <div className="absolute right-[20px] bottom-[20px]">
        <Tooltip title={t('monthly_active_device_tooltip')}>
          <div>
            <span className="mr-2">{t("monthly_active_devices")}</span>
            <span className="hover:underline text-linkblue"><Link className="text-linkblue" to="/activedevices">{t("details")}<EastIcon className="text-xs ml-1" fontSize="small"/></Link></span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default MonthlyActiveChart;
