import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { BarChartColors2 } from "../../../utils/common";
import { DummyBarChart } from "../../charts/DummyChart";

interface MonthTrendBarChartProps {
  monthlyData: any[];
}
const formatter = new Intl.NumberFormat('en-GB', { 
  notation: "compact", 
  compactDisplay: "short" 
}) 
const MonthTrendBarChart = ({ monthlyData }: MonthTrendBarChartProps) => {
  const { t } = useTranslation();

  const getColor = (data: any[], colors: string[]): any[] => {
    const values = data.map(d => d.h_cnt);
    const maxVal = Math.max(...values);
    const minVal = Math.min(...values);
    const colorScale = (value: number): string => {
        const index = Math.floor((colors.length - 1) - ((colors.length - 1) * (value - minVal) / (maxVal - minVal)));
        return colors[index];
    };
    return data.map(d => ({ ...d, color: colorScale(d.h_cnt) }));
};

  const coloredData = getColor(monthlyData, BarChartColors2);

  return (
    <div className="w-full min-h-24 bg-primary mb-4 py-5 px-[30px] flex flex-col relative rounded-[5px] border border-[#E0E0E0]">
      <p className="text-base text-primary normal-case font-normal">
        {t("monthly_hacking_information")}
      </p>
      <div className="flex-grow h-[500px]">
       {coloredData.length > 0 ? <ResponsiveBar
          data={coloredData}
          indexBy="month"
          keys={["h_cnt"]}
          enableGridX={false}
          theme={{
            "text": {
              "fontSize": "8px",
            },
            "axis": {
              "ticks": {
                "text": {
                  "fontSize": "11px",
                },
              },
              }
           }}
          labelTextColor={"#36373A"}
          labelSkipWidth={12}
          labelSkipHeight={12}
          label={(e) => Number(e.value) > 1000 ? `${formatter.format(Number(e.value))}` : `${e.value}`}
          colors={({ data }) => data.color as string}
          padding={0.4}
          margin={{ top: 10, right: 10, bottom: 30, left: 60 }}
          tooltip={(e: any) => (
            <div className="bg-white border border-solid p-4">
              <p>
                <strong>{t("hacking_information")}</strong>
              </p>
              <p>
                {t("no_of_hacking_types")}:{" "}
                <span className="font-semibold">
                  {e.data.h_cnt.toLocaleString()}
                </span>
              </p>
              <p>
                {t("period")}:{" "}
                <span className="font-semibold">{e.data.month}</span>
              </p>
            </div>
          )}
        /> : <DummyBarChart count={12} />}
      </div>
    </div>
  );
};

export default MonthTrendBarChart;
