import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { barData, lineData, pieData } from './DummyData';
import i18n from '../../../i18n/config';

const grayColor = '#E9E9E9';

export const DummyBarChart = ({ count = 10, layout = 'vertical' }) => (
    <div className="h-full relative">
        <div className="w-full h-full">
            <ResponsiveBar
                data={barData(count)}
                layout={layout as 'vertical' | 'horizontal'}
                keys={['value']}
                indexBy="x"
                colors={grayColor}
                margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            />
        </div>
        <div className="py-10 px-20 border border-[#797A7C] text-[#797A7C] rounded-md bg-primary absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            {i18n.t('no_data_available')}
        </div>
    </div>
);

// Line Chart Component
export const DummyLineChart = () => (
    <div className="h-full relative">
        <div className="w-full h-full">
            <ResponsiveLine
                data={lineData}
                curve="linear"
                lineWidth={1}
                margin={{ top: 20, right: 5, bottom: 50, left: 5 }}
                useMesh={true}
                pointBorderWidth={1}
                pointLabelYOffset={-12}
                colors={grayColor}
                pointColor="white"
                pointSize={8}
                pointBorderColor={{ from: 'serieColor' }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false,
                }}
                axisLeft={{
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
            />
        </div>
        <div className="py-10 px-20 border border-[#797A7C] text-[#797A7C] rounded-md bg-primary absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            {i18n.t('no_data_available')}
        </div>
    </div>
);

// Pie Chart Component
export const DummyPieChart = () => (
  <div className="h-full relative">
  <div className="w-full h-full">
    <ResponsivePie
        data={pieData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={grayColor}
        enableArcLinkLabels={false}
        borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
    />
    </div>
        <div className="py-10 px-20 border border-[#797A7C] text-[#797A7C] rounded-md bg-primary absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            {i18n.t('no_data_available')}
        </div>
    </div>
);
