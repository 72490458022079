import React, { useState } from "react";
import { login } from "../../../services/login";
import { setCookies } from "../../../utility/Common";
import LoadMask from "../../utils/loadmask";
import { useAuthorizationService } from "../../../hooks/useAuthorizationService";
import { submitFeedback } from "../../../services/main";
import { useTranslation } from "react-i18next";
import AuthLayout from "..";
import AuthForm from "../AuthForm";
import { loginData } from "../AuthData";
import { showToast } from "../../utils/notifications";

const Demo = () => {
    const authorizationService = useAuthorizationService();
    const [loadMask, showLoadMask] = useState<any>(false);
    const { t } = useTranslation();
    const submitDemoFeedback = (user_id: string) => {
        const payload = `
        >>>${user_id}
        about = Login
        Feedback: Demo access by Email - ${user_id} at ${window.location.host}`;
        submitFeedback({ payload });
    };

    const onSignInClick = async (values: any) => {
        showLoadMask({ msg: t('checking_account_information') });
        let response = await login({ user_id: values.user_id, user_pw: values.user_pw });
        if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
            let demoResponse = await login({ user_id: 'demo@appsealing.com', user_pw: 'appsealing' });
            if (demoResponse?.result.code === '0000' && demoResponse?.result.message === 'SUCCESS') {
                if (window.location.host === 'console.appsealing.com')
                    submitDemoFeedback(values.userId);
                setCookies('appsealing', demoResponse.auth.token);
                authorizationService.authorizeUser(demoResponse.auth.token, showLoadMask);
            }
            else {
                showLoadMask(false);
                const msg = demoResponse?.result?.message || t('failed_to_login_contact_help_desk_for_more_information');
                showToast('error', msg);
            }
        }
        else {
            showLoadMask(false);
            const msg = response?.result?.message || t('failed_to_login_contact_help_desk_for_more_information');
            showToast('error', msg);
        }
    };

    return (
        <React.Fragment>
            {loadMask && <LoadMask msg={loadMask.msg} style={{ zIndex: '10007'}} />}
            <AuthLayout BackButton={false}>
            <AuthForm
                title={'login'}
                data={loginData}
                btnText="sign_in"
                onSubmit={onSignInClick}
            />
        </AuthLayout>
        </React.Fragment>
    );
};

export default Demo;