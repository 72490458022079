type Field = {
  name: string;
  label: string;
  type: 'input' | 'select';
  options?: { label: string; value: string }[];
  value: string | undefined;
  required: boolean;
  errorMessage?: string;
  placeholder: string;
};


export const BillingFields: Field[] = [
	{
		"name": "email",
		"label": "email_address",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "email_address_is_required",
		"placeholder" : "enter_email_address"
	},
	{
		"name": "company",
		"label": "company_name",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "company_name_is_required",
		"placeholder" : "enter_company_name"
	},
	{
		"name": "first_name",
		"label": "first_name",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "first_name_is_required",
		"placeholder" : "enter_first_name"
	},
	{
		"name": "last_name",
		"label": "last_name",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "last_name_is_required",
		"placeholder" : "enter_last_name"
	},
	{
		"name": "tel",
		"label": "tel",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "please_enter_valid_telephone_number",
		"placeholder" : "enter_telephone"
	},
	{
		"name": "country",
		"label": "country",
		"type": "select",
		"options": [],
		"value": '',
		"required": true,
		"errorMessage": "country_is_required",
		"placeholder" : "select_country"
	},
	{
		"name": "state",
		"label": "state",
		"type": "select",
		"options": [],
		"value": '',
		"required": true,
		"errorMessage": "state_is_required",
		"placeholder" : "select_state"
	},
	{
		"name": "city",
		"label": "city",
		"type": "select",
		"options": [],
		"value": '',
		"required": false,
		"placeholder" : "select_city"
	},
	{
		"name": "zip_code",
		"label": "zip_code",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "zip_code_is_required",
		"placeholder" : "enter_zip_code"
	},
	{
		"name": "address1",
		"label": "address_1",
		"type": "input",
		"value": "",
		"required": true,
		"errorMessage": "address_1_is_required",
		"placeholder" : "enter_address_1"
	},
	{
		"name": "address2",
		"label": "address_2",
		"type": "input",
		"value": "",
		"required": false,
		"errorMessage": "",
		"placeholder" : "enter_address_2"
	},
	{
		"name": "brn",
		"label": "tax_id",
		"type": "input",
		"value": "",
		"required": false,
		"errorMessage": "",
		"placeholder": "enter_tax_id"
	}
]