import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UpgradeYourPlan = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const onUpgradeClick = () =>
      navigate(`/billingdetails/plan`);

    return (
        <React.Fragment>
            <div className="flex flex-col overflow-auto">
                <div className="flex-grow">
                    <div className="ant-descriptions-title text-2xl opacity-70 mb-2">{t('ready_for_an_upgrade')}</div>
                    <hr />
                    <p className="text-xl mt-2 mb-2">{t('looks_like_you_need_bigger_plan_to_suit_your_requirement')}</p>
                    <p className="pt-2">{t('you_can_navigate_to_plan_settings_based_on_features')}:</p>
                    <div className="p-10 pt-2 pb-2">
                        <ul className="list-disc">
                            <li>
                                <span className="font-medium">{t('framework_for_ios_plan')}:</span> {t('xcode_unity_unreal_flutter')}
                            </li>
                            <li>
                                <span className="font-medium">{t('framework_for_hybrid_plan')}:</span> {t('react_native_ionic_cordova')}
                            </li>
                            <li>
                                {i18n.language === 'kr_KR' && (
                                    <a type="link" className="underline text-accent" href="p-0">
                                        {t('help_center')}
                                    </a>
                                )}
                                <span>{t('you_may_connect_with_us')}</span>
                                {i18n.language !== 'kr_KR' && (
                                    <a type="link" className="underline text-accent" href="p-0">
                                        {t('help_center')}
                                    </a>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="p-5 pb-2 flex flex-row gap-4">
                        <Button variant='contained' color="primary" onClick={() => onUpgradeClick()}>
                            {t('upgrade_plan')}
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UpgradeYourPlan;
