import { Get, Post, Put } from '..';
import { AcceptInvitationInterface, LoginDataInterface, SignUpInterface } from '../../type/api/login';
import { AppURL } from '../../utility/AppURL';

export const login = async (data: LoginDataInterface) => {
    let { user_id, user_pw, domain = 'EN', otpCode } = data,
        searchparams = new URLSearchParams();

    searchparams.append('user_id', user_id);
    searchparams.append('user_pw', user_pw);
    searchparams.append('domain', domain);
    if (otpCode) {
        searchparams.append('multiFactor', 'true');
        searchparams.append('otpCode', otpCode);
    }

    try {
        const response = await Post(AppURL.login, searchparams);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const logout = async (token: string) => {
    const headeer = {
        'As-Token': token,
    };

    try {
        const response = await Post(AppURL.logout, null, headeer);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const resetPwd = async (data: { user_id: string; lang_set: string }) => {
    let { user_id, lang_set = 'EN' } = data;
    const params = JSON.stringify({
        userId: user_id,
        langSet: lang_set,
        domain: lang_set,
    });
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await Post(AppURL.resetPwd, params, headers);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const resetNewPwd = async (data: { token: string; password: string }) => {
    let { password, token } = data;
    const params = JSON.stringify({
        token,
        password,
    });
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await Post(AppURL.resetNewPwd, params, headers);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const signupValidate = async (params: string) => {
    return await Get(AppURL.signupmemberValidate + `?${params}`);
};

export const signup = async (data: SignUpInterface) => {
    const searchParams = new URLSearchParams({
        is_free_mail: data.is_free_mail,
        lang_set: data.locale || 'EN',
        first_name: data.first_name,
        last_name: data.last_name,
        user_id: data.user_id,
        user_pw: data.user_pw,
        user_pw_confirm: data.user_pw_confirm,
        ...(data.registration_token && { registration_token: data.registration_token }),
        ...(data.token && { token: data.token }),
        ...(data.invite_by && { invite_by: data.invite_by }),
        ...(data.utm_source && { utm_source: data.utm_source }),
        ...(data.utm_medium && { utm_medium: data.utm_medium }),
        ...(data.utm_campaign && { utm_campaign: data.utm_campaign }),
        ...(data.group_id && { group_id: data.group_id }),
        ...(data.owner_type && { owner_type: data.owner_type }),
    });

    try {
        const endpoint = data.registration_token ? AppURL.signupaws : data.token || data.invite_by ? AppURL.signupmember : AppURL.signup;
        const response = await Post(endpoint, searchParams.toString());
        const result = await response.json();
        const location = window.location.hostname;
        if (result.result.code === '0000' && location.includes('console.appsealing.com')) {
            sendUserInfoSalesforce(data);
        }
        return result;
    } catch (error) {
        throw error;
    }
};

const freeEmailDomains = [
    '@gmail.com',
    '@yahoo.com',
    '@outlook.com',
    '@hotmail.com',
    '@aol.com',
    '@naver.com',
    '@daum.net',
    '@nate.com',
    '@yahoo.co.in',
    '@rediffmail.com',
    '@indiatimes.com',
    '@xlluck.com',
    '@sablecc.com',
    '@protectt.ai',
    '@zimperium.com',
    '@appdome.com',
    '@proton.com',
    '@protonmail.com',
    '@verimatrix.com',
    '@digital.ai',
    '@digital.com',
    '@protectt',
    '@zimperium',
    '@appdome',
    '@proton',
    '@protonmail',
    '@verimatrix',
    '@digital',
];

const isFreeEmailDomain = (email: string) => {
    return freeEmailDomains.some((domain) => email.toLowerCase().includes(domain));
};
export const validateEmail = async (data: { user_id: string }) => {
    if (isFreeEmailDomain(data.user_id)) {
        return false;
    }

    return new Promise((resolve, reject) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.name = 'hidden_iframe';
        document.body.appendChild(iframe);

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = AppURL.emailValidate;
        form.target = 'hidden_iframe';

        const emailField = document.createElement('input');
        emailField.type = 'hidden';
        emailField.name = 'email';
        emailField.value = data.user_id;
        form.appendChild(emailField);

        document.body.appendChild(form);

        iframe.onload = function () {
            try {
                const iframeDoc = iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document);
                const iframeUrl = iframeDoc?.location.href;

                const urlParams = new URLSearchParams(iframeUrl?.split('?')[1]);
                const errorMessage = urlParams?.get('errorMessage');

                document.body.removeChild(iframe);
                document.body.removeChild(form);

                if (errorMessage !== null) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            } catch (err) {
                document.body.removeChild(iframe);
                document.body.removeChild(form);
                reject(err);
            }
        };

        form.submit();
    });
};

const sendUserInfoSalesforce = async (data: SignUpInterface) => {
    let formData = new URLSearchParams();
    formData.append('fname', data.first_name);
    formData.append('lname', data.last_name);
    formData.append('email', data.user_id);
    formData.append('user_pw', '********');
    formData.append('user_pw_confirm', '********');
    formData.append('agreement', 'true');
    formData.append('region', data.region);

    await fetch(AppURL.salesforce, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
    })
        .then((res) => res.json())
        .catch((err) => {
            console.log(err);
        });
};

export const reactivateUser = async (data: { user_id: string; lang_set?: string }) => {
    let { user_id, lang_set = 'EN' } = data,
        searchparams = new URLSearchParams();

    searchparams.append('user_id', user_id);
    searchparams.append('lang_set', lang_set);

    let response = await Post(AppURL.reactivation, searchparams)
        .then((res) => res.json())
        .catch(() => {});

    return response;
};

export const acceptInvitation = async (data: AcceptInvitationInterface) => {
    let { user_id, user_pw, domain = 'EN', invite_by, token } = data;
    const searchparams = new URLSearchParams({
        user_id,
        user_pw,
        domain,
        invite_by,
        token,
    });

    try {
        const response = await Post(AppURL.agreeinvitemember, searchparams.toString());
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const checkMfaStatus = async (data: LoginDataInterface) => {
    const { user_id, user_pw } = data;
    const searchParams = new URLSearchParams({
        userId: user_id,
        password: user_pw,
    });

    const queryParams = searchParams.toString();
    const updateUrl = `${AppURL.mfa}?${queryParams}`;

    try {
        const response = await Get(updateUrl);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const getMfaInfo = async (token: string) => {
    let url = AppURL.mfa + `/active/${token}`;
    try {
        const response = await Put(url);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const editMfaInfo = async (status: string, token: string) => {
    let url = AppURL.mfa + `/${status}/${token}`;
    try {
        const response = await Put(url);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const validateMfa = async (token = '', otp: string) => {
    let searchparams = new URLSearchParams();
    searchparams.append('token', token);
    searchparams.append('otpCode', otp);

    const queryParams = new URLSearchParams(searchparams).toString();
    let updateUrl = `${AppURL.mfaValidate}?${queryParams}`;
    let response = await Get(updateUrl, false, {}).then((res) => res.json());
    return response;
};
