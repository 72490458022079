import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box, IconButton, TablePagination } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

import { ActionMenuList } from './InvoiceAction';

interface Detail {
    region: string;
    package_name: string;
    platform: string;
    mad_count: number;
    mad_fee: number;
    data_sealing_fee: number;
    otaUpdateFee: number;
    totalFee: number;
}
export interface RowData {
    id: number;
    invoice_id: string;
    company_name: string;
    period: string;
    amount_us: string;
    status: string;
    due_date: string;
    items: Detail[];
    [key: string]: any; // Add index signature
}

interface ExpandableTableProps {
    data: RowData[];
    onMenuClick: (key: string, row: RowData) => void;
}

const filterItems = ['key', 'id', 'amount_us', 'package_name', 'items', 'status_code'];

const ExpandableTable: React.FC<ExpandableTableProps> = ({ data, onMenuClick }) => {
    const [open, setOpen] = useState<Record<string, boolean>>({});
    const { t } = useTranslation();
    const [sortConfig, setSortConfig] = useState<{
        field: string;
        direction: 'asc' | 'desc';
    } | null>(null);
    const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(12);

    const handleToggle = (invoice_id: string) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [invoice_id]: !prevOpen[invoice_id],
        }));
    };
    const sortData = (data: RowData[]) => {
        if (!sortConfig) return data;

        return [...data].sort((a, b) => {
            let aValue = a[sortConfig.field];
            let bValue = b[sortConfig.field];

            // Attempt to convert string values to numbers if they represent monetary amounts
            if (typeof aValue === 'string' && aValue.includes('$')) {
                aValue = parseFloat(aValue.replace(/[^0-9.-]+/g, ''));
            }
            if (typeof bValue === 'string' && bValue.includes('$')) {
                bValue = parseFloat(bValue.replace(/[^0-9.-]+/g, ''));
            }

            const bothAreNumbers = typeof aValue === 'number' && typeof bValue === 'number';

            if (bothAreNumbers) {
                return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                // Fallback to string comparison
                aValue = String(aValue);
                bValue = String(bValue);
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        });
    };
    const handleSort = (field: string) => {
        const isAsc = sortConfig && sortConfig.field === field && sortConfig.direction === 'asc';
        setSortConfig({ field, direction: isAsc ? 'desc' : 'asc' });
    };

    const tableHeaders = Object.keys(data[0] || {}).filter((key) => !filterItems.includes(key));

    return (
        <>
            {tableHeaders.length === 0 ? (
                <div className="m-auto w-full h-fulltext-xl text-center py-52 mx-auto whitespace-pre">{t('no_results_found')}</div>
            ) : (
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ padding: '6px 14px' }} />
                                {tableHeaders.map((header) => (
                                    <TableCell
                                        key={header}
                                        className="font-bold cursor-pointer"
                                        sortDirection={sortConfig?.field === header ? sortConfig.direction : false}
                                        onClick={() => handleSort(header)}
                                        onMouseEnter={() => setHoveredColumn(header)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                        sx={{ padding: '6px 14px' }}
                                    >
                                        <span>{t(header)}</span>
                                        <span className="inline-block min-w-[4] ml-2">
                                            {sortConfig && sortConfig.field === header ? (
                                                sortConfig.direction === 'asc' ? (
                                                    <ArrowUpwardIcon fontSize="small" />
                                                ) : (
                                                    <ArrowDownwardIcon fontSize="small" />
                                                )
                                            ) : hoveredColumn === header ? (
                                                <ArrowDownwardIcon fontSize="small" />
                                            ) : null}
                                        </span>
                                    </TableCell>
                                ))}
                                <TableCell sx={{ padding: '6px 14px' }} className="font-bold">
                                    {t('action')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(data)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell sx={{ padding: '6px 14px' }}>
                                                <IconButton aria-label="expand row" size="small" onClick={() => handleToggle(row.invoice_id)}>
                                                    {open[row.invoice_id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            {tableHeaders.map((header) => (
                                                <TableCell key={header}>{(row as any)[header]}</TableCell>
                                            ))}
                                            <TableCell>{row.status !== 'I' && <ActionMenuList onMenuClick={onMenuClick} row={row} />}</TableCell>
                                        </TableRow>
                                        <TableRow className="w-full">
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={8}>
                                                <Collapse in={open[row.invoice_id]} timeout="auto" unmountOnExit className="border-none">
                                                    <Box>
                                                        <Table size="small" aria-label="details" sx={{ border: 'none' }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    {Object.keys(data[0].items[0]).map((detailHeader) => (
                                                                        <TableCell key={detailHeader} className="font-bold">
                                                                            {t(detailHeader)}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.items.map((detail, index) => (
                                                                    <TableRow key={index}>
                                                                        {Object.entries(detail).map(([key, value], valueIndex) => (
                                                                            <TableCell key={valueIndex} className="truncate max-w-[250px]">
                                                                                {key === 'platform' ? value === 'IOS' ? <AppleIcon /> : value === 'Android' ? <AndroidIcon /> : value : value}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                        rowsPerPageOptions={[5, 10, 12, 20, 30]}
                    />
                </TableContainer>
            )}
        </>
    );
};

export default ExpandableTable;
