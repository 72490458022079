import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { editMfaInfo, getMfaInfo, validateMfa } from '../../../services/login';
import { showToast } from '../../utils/notifications';
import LanguageSelector from '../languageselector';

interface MfaVerifyModalProps {
    token: string;
    handleMfaActivate: () => void;
}

const MfaVerify = ({ token = '', handleMfaActivate }: MfaVerifyModalProps) => {
    const { t } = useTranslation();
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [optCode, setOptCode] = useState<string>('');

    useEffect(() => {
        createMFA();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event: { returnValue: string }) => {
            inactivateMfa();
            const confirmationMessage = t('mfa_unsaved');
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createMFA = async () => {
        try {
            const response = await getMfaInfo(token.toLocaleUpperCase());
            const { result, data } = response;
            if (result.code === '0000' || result.msg === 'SUCCESS') {
                setQrCodeUrl(data);
            } else if (result.code === '11002' || result.msg === 'MULTI_FACTOR_IS_ALREADY_EXIST') {
                reactivateMfa();
            } else {
                showToast('error', t(`RCM-${result.code}`));
            }
        } catch (error) {
            showToast('error', t('failed_to_validate_please_try_again_later'));
        }
    };
    const inactivateMfa = async (isToast = false) => {
        try {
            const response = await editMfaInfo('inactive', token.toLocaleUpperCase());
            const { result } = response;
            if (result.code === '0000' || result.msg === 'SUCCESS') {
                if (isToast) {
                    showToast('success', t('disable_mfa'));
                }
            } else {
                showToast('error', t(`RCM-${result.code}`));
            }
        } catch (error) {
            showToast('error', t('failed_to_validate_please_try_again_later'));
        }
    };
    const reactivateMfa = async () => {
        try {
            const response = await editMfaInfo('active', token.toLocaleUpperCase());
            const { result, data } = response;
            if (result.code === '0000' || result.msg === 'SUCCESS') {
                setQrCodeUrl(data);
            } else {
                showToast('error', t(`RCM-${result.code}`));
            }
        } catch (error) {
            showToast('error', t('failed_to_validate_please_try_again_later'));
        }
    };

    const handleValidateMfa = async () => {
        try {
            const response = await validateMfa(token.toLocaleUpperCase(), optCode);
            if (response) {
                showToast('success', t('activate_mfa'));
                handleMfaActivate();
            } else {
                showToast('error', t('failed_to_validate_please_try_again_later'));
            }
        } catch (error) {
            showToast('error', t('failed_to_validate_please_try_again_later'));
        }
    };

    const handleOk = () => {
        handleValidateMfa();
    };

    const handleOnKeyPress = (e: { key: string }) => {
        if (e.key === 'Enter') {
            handleValidateMfa();
        }
    };

    return (
        <Box
            className="h-screen max-h-screen bg-no-repeat"
            sx={{
                backgroundImage: 'linear-gradient(to bottom right, #D72544, #362674)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                className="z-10 px-10 py-16 bg-white rounded-md"
                sx={{
                    maxWidth: 500,
                    width: '80%',
                    boxShadow: 3,
                }}
            >
                <div className="mb-10">
                    <div className="flex justify-between">
                        <Typography variant="h1" className="mb-0">
                            {t('google_opt')}
                        </Typography>
                    </div>
                </div>

                <Box className="flex flex-col">
                    <ul className="p-0 pt-2 pl-4 m-0 whitespace-pre-line list-decimal lg:text-sm sm:text-lg">
                        <li>{t('google_opt_1')}</li>
                        <li>{t('google_opt_2')}</li>
                        <li>{t('google_opt_3')}</li>
                    </ul>
                    <div className="flex justify-center flex-none py-6">
                        <div className="flex justify-center flex-none w-3/6">{qrCodeUrl !== '' && <QRCode value={qrCodeUrl} width={200} />}</div>
                    </div>
                    <p className="lg:text-sm">4. {t('google_opt_4')}</p>
                    <div className="w-full my-4">
                        <TextField
                            label={t('google_opt_code')}
                            variant="outlined"
                            placeholder={t('google_opt_code')}
                            inputProps={{ maxLength: 16 }}
                            size={'small'}
                            onChange={(e: { target: { value: SetStateAction<string> } }) => setOptCode(e.target.value)}
                            onKeyDown={handleOnKeyPress}
                            focused
                            fullWidth
                        />
                    </div>
                </Box>
                <Box className="w-full">
                    <Button variant="contained" onClick={handleOk} color="primary" className="h-10 px-10" fullWidth>
                        {t('enable')}
                    </Button>
                </Box>

                <div className="flex items-center justify-end w-full py-3">
                    <LanguageSelector />
                </div>
            </Box>
        </Box>
    );
};

export default MfaVerify;
