import { Post } from "../../..";
import { InvoiceInterface, InvoiceListInterface, RefundInterface } from "../../../../type/api/manage";
import { AppURL } from "../../../../utility/AppURL";

export const getInvoiceList = async (params: InvoiceListInterface) => {
    let { invoiceType, period_from, period_from_view, period_to, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('invoiceType', invoiceType);
    urlParams.append('period_from', period_from);
    urlParams.append('period_from_view', period_from_view);
    if (period_to)
        urlParams.append('period_to', period_to);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.invoicelist, urlParams
    ).then(res => res.json());

    return response;
};

export const downloadInvoicePdf = async (params: InvoiceInterface) => {
    let { invoice_id, action, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('invoice_id', invoice_id);
    if (action)
        urlParams.append('action', action);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.downloadinvoice, urlParams, {}, true
    ).then(res => res.blob()
    ).catch(() => {
        //onerror
    });
    return response;
};

export const raiseRefundRequest = async (params: RefundInterface) => {
    let { invoiceId, invoice_id, invoiceType, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('invoiceId', invoiceId);
    urlParams.append('invoice_id', invoice_id);
    urlParams.append('invoiceType', invoiceType);
    urlParams.append('token', token);

    let response = await Post(
        AppURL.refundrequest, urlParams
    ).then(res => res.json());
    return response;
};