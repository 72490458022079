import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Tabs, Tab } from '@mui/material';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine, Serie } from '@nivo/line';
import { RootState } from '../../../store';
import DataHeader from '../DataHeader';
import { getDashboardData } from '../../../services/data/dashboard';
import { getAndroidPackages, getIOSPackages } from '../../../services/data';
import { setAndroidPackages, setIOSPackages } from '../../../store/data';
import { getDeviceHackingDetails, getHackingData, getPieChartData } from '../../../services/data/hackingdetection';
import { processPieChartDataResponse, processDashboardResponse, processHackingDataResponse, hackingTypeKeys } from '../dashboard';
import { ResponsiveChoropleth } from '@nivo/geo';
import HackingDetails from './details';
import { setLocalPreferences } from '../../../store/main';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ThreatDetectedData } from './data';
import { BarChartColor3, ChoroplethChartColors, PieChartColors } from '../../utils/common';
import DataCard from '../DataCard';
import CountryTable from '../analytics/CountryTable';
import HackingAttemptsTable from '../analytics/HackingAttemptsTable';
import DataLayout from '../DataLayout';
import { showToast } from '../../utils/notifications';
import { DummyPieChart } from '../charts/DummyChart';

const hackingColors: any = {
    'Speed modification': '#4EF3E9',
    'Cheat tool': '#DC4EF3',
    Debugging: '#F3804E',
    'Rooted device': '#79F34E',
    'Repackaged application': '#E6F34E',
    'Detour security module': '#514EF3',
    'Bypass security module': 'hsl(53, 83%, 75%)',
    'Code modification': '#F34EC5',
    'Dynamic memory cracking': 'hsl(332, 94%, 57%)',
    ETC: '#F3CF4E',
    'Android emulator': '#F3E34E',
};

const convertToLineData = (data: Array<any>, platform?: string) => {
    let hackingData: Serie[] = [];
    const hackingTypes = platform === 'android' ? hackingTypeKeys.slice(0, 9) : hackingTypeKeys.slice(6, 13);

    hackingTypes.forEach((e: string) => {
        hackingData.push({
            id: e,
            color: hackingColors[e],
            data: [
                { x: moment().subtract(1, 'M').format('YYYY-MM-DD'), y: 0 },
                { x: moment().subtract(1, 'M').format('YYYY-MM-DD'), y: 0 },
            ],
        });
    });

    hackingData.forEach((e) => {
        data.forEach((item: any) => {
            if (Object.keys(item).includes(`${e.id}__dth_cnt`)) {
                const value = parseInt(item[`${e.id}__dth_cnt`] || 0);
                const mutableData = [...e.data];
                mutableData.push({
                    ...item,
                    x: item.date,
                    y: value,
                });
                e.data = mutableData;
            }
        });
    });

    return hackingData;
};

const processHackingLegend = (data: Array<any>) => {
    let legendInfo: any = {
        byType: {},
        byDevice: {},
    };
    let byTypeTotal = 0,
        byDeviceTotal = 0;

    data.forEach((item) => {
        byTypeTotal += parseInt(item.try_total_cnt);
        byDeviceTotal += parseInt(item.hacking_cnt);
        legendInfo.byType[item.h_type] = item.try_total_cnt;
        legendInfo.byDevice[item.h_type] = item.hacking_cnt;
    });

    legendInfo.byType.total = byTypeTotal;
    legendInfo.byDevice.total = byDeviceTotal;
    return legendInfo;
};

const HackingDetection = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [data, setData] = useState<any>(null);
    const [period, setPeriod] = useState<moment.Moment | null>(moment());
    const [, setMonthlyData] = useState([]);
    const [selectedApp, setSelectedApp] = useState<string | null | undefined>(null);
    const [isAlertClose, setAlertClose] = useState<boolean>(false);
    const [sealingMode, setSealingMode] = useState<'R' | 'T'>('R');
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [gridSelection, setGridSelection] = useState<string[]>([]);
    const [deviceHackingDetails, setDeviceHackingDetails] = useState([]);
    const [platform, setPlatform] = useState<'android' | 'ios'>('android');
    const [totalData, setTotalData] = useState(processDashboardResponse());
    const [legendType, setLegendType] = useState<'byType' | 'byDevice'>('byType');
    const [pieChartData, setPieChartData] = useState(processPieChartDataResponse());
    const [hackingLegend, setHackingLegend] = useState<any>(processHackingLegend([]));
    const [hackingData, setHackingData] = useState<any>(convertToLineData(processHackingDataResponse().hackingTypes, 'android'));
    const [hiddenData, setHiddenData] = useState<Array<string>>([]);
    const [clickedItems, setClickedItems] = React.useState<Array<string>>([]);

    const region = useSelector((state: RootState) => state.mainReducer.region);
    const countries = useSelector((state: RootState) => state.commonReducer.countries);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const iosPackages = useSelector((state: RootState) => state.dataReducer.iosPackages);
    const mapFeatures = useSelector((state: RootState) => state.dataReducer.mapFeatures);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const androidPackages = useSelector((state: RootState) => state.dataReducer.androidPackages);
    const localPreference = useSelector((state: RootState) => state.mainReducer.localPreference);

    const { id } = useParams();

    useEffect(() => {
        if (localPreference && data === null && Array.isArray(iosPackages) && Array.isArray(androidPackages)) {
            if (localPreference.platform) setPlatform(localPreference.platform);
            if (localPreference.sealingMode) setSealingMode(localPreference.sealingMode);
            if (localPreference.period && moment(localPreference.period, 'YYYY-MM')) setPeriod(moment(localPreference.period, 'YYYY-MM'));
            if (localPreference.platform === 'android' && androidPackages.find((e) => e.package_name === localPreference.android_pkg)) setSelectedApp(localPreference.android_pkg);
            else if (localPreference.platform === 'ios' && iosPackages.find((e) => e.bundle_id === localPreference.ios_pkg)) setSelectedApp(localPreference.ios_pkg);
            setData(localPreference);
        }
    }, [localPreference, data, iosPackages, androidPackages]);

    useEffect(() => {
        if (platform && selectedApp && period) {
            const newPref = {
                ...data,
                platform,
                sealingMode,
                period: period.format('YYYY-MM'),
                [platform === 'android' ? 'android_pkg' : 'ios_pkg']: selectedApp,
            };
            dispatch(setLocalPreferences(newPref));
            localStorage.setItem('user_preferences', JSON.stringify(newPref));
        }
    }, [platform, selectedApp, sealingMode, data, period, dispatch]);

    useEffect(() => {
        if (id) {
            const hackingDetails = deviceHackingDetails?.find((e: any) => e.key === id);
            if (hackingDetails) setGridSelection([hackingDetails['key']]);
        } else {
            setShowDetails(false);
            setGridSelection([]);
        }
    }, [id, deviceHackingDetails]);

    useEffect(() => {
        if (region) {
            setIOSPackages(null);
            setAndroidPackages(null);
        }
    }, [region]);

    useEffect(() => {
        if (iosPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getIOSPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataBundleIdList)) {
                    dispatch(setIOSPackages([...response.dataBundleIdList]));
                }
            };
            fetchPackages();
        }
    }, [region, iosPackages, authContext.token, dispatch]);

    useEffect(() => {
        if (androidPackages === null) {
            const fetchPackages = async () => {
                const params = {
                    token: authContext.token,
                };
                const response = await getAndroidPackages(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.dataPackageList)) {
                    dispatch(setAndroidPackages([...response.dataPackageList]));
                }
            };
            fetchPackages();
        }
    }, [region, androidPackages, authContext.token, dispatch]);

    useEffect(() => {
        if (platform && selectedApp && sealingMode) {
            const account_id = platform === 'ios' ? iosPackages?.find((e) => e.bundle_id === selectedApp)?.account_id : undefined;
            const fetchDashboardData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMM'),
                    calc_date_to: moment(period).format('YYYYMM'),
                    compare_date_from: moment(period).startOf('month').subtract(1, 'M').format('YYYYMM'),
                    compare_date_to: moment(period).startOf('month').subtract(1, 'M').format('YYYYMM'),
                };
                const response = await getDashboardData(params, region);
                const data = processDashboardResponse(response, countries, true, i18n.language);
                setTotalData(data);
            };
            const fetchMonthlyData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').subtract(1, 'year').format('YYYYMM'),
                    calc_date_to: moment(period).startOf('month').format('YYYYMM'),
                    dimension: 'MT',
                    lang_set: 'EN',
                };
                const response = await getPieChartData(params, region);
                let data = [];
                if (response?.result?.code === '0000' && Array.isArray(response?.hackingAttemptsList)) {
                    data = response.hackingAttemptsList.map((e: any) => ({
                        ...e,
                        month: moment(e.h_type).format('YYYY-MM'),
                    }));
                }
                setMonthlyData(data);
            };
            const fetchHackingData = async () => {
                const params = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMM'),
                    calc_date_to: moment(period).format('YYYYMM'),
                    dimension: 'HT',
                    lang_set: 'EN',
                };
                const response = await getHackingData(params, region);
                let data = processHackingDataResponse(response);
                setHackingData(convertToLineData(data.hackingTypes, platform));
                setHackingLegend(processHackingLegend(data.hackingAttemptsSumList));
            };
            const fetchDeviceHackingDetails = async () => {
                const params = {
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMM'),
                    calc_date_to: moment(period).format('YYYYMM'),
                };
                const response = await getDeviceHackingDetails(params, region);
                let data = [];
                if (response?.result?.code === '0000' && Array.isArray(response.hackingAttemptsList)) {
                    data = response.hackingAttemptsList.map((e: any, index: any) => ({
                        ...e,
                        key: e.calc_date + e.android_raw_id + e.os_version + e.device_model + index,
                        country_name_kr: countries.find((item) => item.country_name === e.country)?.country_name_kr || e.country,
                    }));
                }
                setDeviceHackingDetails(data);
            };
            const fetchPieChartData = async () => {
                let params: any = {
                    type: platform,
                    account_id: account_id,
                    token: authContext.token,
                    package_name: selectedApp,
                    sealing_mode: sealingMode,
                    calc_date_from: moment(period).startOf('month').format('YYYYMMDD'),
                    calc_date_to: moment(period).endOf('month').format('YYYYMMDD'),
                    lang_set: 'EN',
                };

                params.dimension = 'OV';
                const androidResponse = await getPieChartData(params, region);
                params.dimension = 'OVD';
                const androidMinorResponse = await getPieChartData(params, region);
                params.dimension = 'HT';
                const hackingTypeResponse = await getPieChartData(params, region);
                let rootingResponse = undefined,
                    jailBreakRespone = undefined,
                    hackingPatternResponse = undefined;
                if (platform === 'android') {
                    params.dimension = 'RT';
                    rootingResponse = await getPieChartData(params, region);
                } else {
                    params.dimension = 'HP';
                    hackingPatternResponse = await getPieChartData(params, region);
                    params.dimension = 'JB';
                    jailBreakRespone = await getPieChartData(params, region);
                }
                const data = processPieChartDataResponse(androidResponse, androidMinorResponse, hackingTypeResponse, rootingResponse, hackingPatternResponse, jailBreakRespone);
                setPieChartData(data);
            };
            fetchDashboardData();
            fetchMonthlyData();
            fetchHackingData();
            fetchPieChartData();
            if (platform === 'android') {
                setGridSelection([]);
                fetchDeviceHackingDetails();
            }
        }
    }, [region, platform, selectedApp, sealingMode, iosPackages, countries, period, authContext.token, i18n.language]);

    useEffect(() => {
        if (gridSelection?.length) {
            setShowDetails(true);
        }
    }, [gridSelection]);

    const onShowDetailsClick = (record: any) => {
        if (planDetails.plan?.android?.type === 'E' || planDetails.plan?.android?.type === 'P' || planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') {
            setGridSelection([record.key]);
            navigate(`/threatdetection/${record.key}`);
        } else showToast('error', t('device_information_feature_is_available_only_with_professional_or_enterprise_plan'));
    };

    const calculatePiePercentage = (data: any) => {
        let hiddenId: Array<string> = hiddenData || [];

        if (!data.hidden) {
            hiddenId.push(data.id);
        } else {
            hiddenId = hiddenId.filter((item: any) => item !== data.id);
        }
        let total = 0;
        pieChartData.hackingTypes.forEach((item: any) => {
            if (!hiddenId.includes(item.id)) {
                total += item.h_cnt;
            }
        });
        const updatedHackingTypes = pieChartData.hackingTypes.map((item: any) => {
            if (!hiddenId.includes(item.id)) {
                return {
                    ...item,
                    distribution: Math.round((item.h_cnt / total) * 100).toFixed(2),
                };
            }
            return item;
        });

        setPieChartData({
            ...pieChartData,
            hackingTypes: updatedHackingTypes,
        });

        setHiddenData(hiddenId);
    };
    const filterHackingData = (id: string) => {
        if (!clickedItems.includes(id) && clickedItems.length + 1 === hackingData.length) return;
        setClickedItems((prev) => {
            if (prev.includes(id)) {
                return prev.filter((item) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };
    const chartTooltip = (tooltipData: any) => {
        const keys = platform === 'android' ? hackingTypeKeys.slice(0, 9) : hackingTypeKeys.slice(6, 13);
        const data = keys.map((el) => ({
            label: t(el.toLowerCase().replaceAll(' ', '_'), el),
            cnt: tooltipData.point.data[el + '__dth_cnt'] || 0,
        }));

        return (
            <div className="bg-white border border-solid p-4">
                <p>
                    <strong>
                        {t('detection_date')} : {tooltipData.point.data.date}
                    </strong>
                </p>
                {data
                    .sort((a: any, b: any) => b.cnt - a.cnt)
                    .map((el) => (
                        <p key={el.label}>
                            <span>{el.label}: </span>
                            <strong className="text-[#DC4EF3]">{el.cnt.toLocaleString()}</strong>
                        </p>
                    ))}
            </div>
        );
    };
    return (
        <React.Fragment>
            {showDetails && platform === 'android' ? (
                <HackingDetails sealingMode={sealingMode} data={deviceHackingDetails.find((e: any) => e.key === gridSelection[0])} onBackClick={() => navigate(-1)} />
            ) : (
                <DataLayout>
                    <DataHeader
                        title={t('hackingDetection')}
                        subtitle={t('hacking_analytics_and_devices_information')}
                        selectedApp={
                            selectedApp ? (platform === 'android' ? androidPackages?.find((e) => e.package_name === selectedApp) : iosPackages?.find((e) => e.bundle_id === selectedApp)) : null
                        }
                        setSelectedApp={setSelectedApp}
                        packages={(platform === 'android' ? androidPackages?.map((item: any, i: any) => ({ ...item, key: i })) : iosPackages?.map((item, i) => ({ ...item, key: i }))) || null}
                        platform={platform}
                        setPlatform={setPlatform}
                        sealingMode={sealingMode}
                        setSealingMode={setSealingMode}
                        period={period}
                        setPeriod={setPeriod}
                    />
                    <div className="w-full grid grid-cols-2 lg:grid-cols-5 md:gap-x-2.5 gap-y-2.5">
                        <div className="col-span-2 lg:col-span-3 flex flex-col py-5 false px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] mb-2.5 ">
                            <div>
                                <div className="flex justify-between flex-wrap xl:flex-nowrap flex-none items-center">
                                    <div className="mb-3.5">
                                        <p className="text-base cursor-pointer">{t('threats_detected')}</p>
                                        <p className="text-3xl font-medium text-infored">{hackingLegend[legendType].total?.toLocaleString() || 0}</p>
                                    </div>
                                </div>
                                <Tabs value={legendType} onChange={(e, newLegendType) => setLegendType(newLegendType)} variant="fullWidth" className='mb-8'>
                                    <Tab label={t('hacking_attempts')} value="byType" />
                                    <Tab label={t('unique_devices_hacking_attempts')} value="byDevice" />
                                </Tabs>
                                {ThreatDetectedData.map((section) => {
                                    if (section.platform === platform) {
                                        return section.items.map((item) => (
                                            <p className={`text-sm xl:text-lg flex justify-between mt-1`}>
                                                <span>
                                                    <GppGoodOutlinedIcon className={`mr-2 ${item.iconColor}`} />
                                                    {t(item.type)}
                                                </span>
                                                <span className={`font-medium ${item.iconColor}`}>{hackingLegend[legendType][item.value]?.toLocaleString() || 0}</span>
                                            </p>
                                        ));
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        <DataCard col={2} title={t('threats_types')}>
                            <div className="flex flex-col min-h-24 ">
                                {pieChartData.hackingTypes.length > 0 ? (
                                    <ResponsivePie
                                        data={pieChartData.hackingTypes}
                                        margin={{ top: 70, right: 10, bottom: 10, left: 30 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        theme={{
                                            text: {
                                                fontSize: '10px',
                                            }, 
                                        }}
                                        colors={PieChartColors}
                                        cornerRadius={3}
                                        enableArcLinkLabels={false}
                                        activeOuterRadiusOffset={8}
                                        arcLabelsSkipAngle={20}
                                        arcLabel={(e) => `${e.data.distribution} %`}
                                        tooltip={(e: any) => (
                                            <div className="bg-white p-2">
                                                <strong>{t(e.datum.data.id.toLowerCase().replaceAll(' ', '_'), e.datum.data.id as string)} : </strong>
                                                <span>{`${e.datum.data.value?.toLocaleString()} (${e.datum.data.distribution} %)`}</span>
                                            </div>
                                        )}
                                        legends={[
                                            {
                                                anchor: 'top-left',
                                                direction: 'column',
                                                justify: false,
                                                translateX: -20,
                                                translateY: -70,
                                                itemWidth: 150,
                                                itemHeight: 12,
                                                itemsSpacing: 4,
                                                symbolSize: 10,
                                                toggleSerie: true,
                                                itemDirection: 'left-to-right',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 0.5,
                                                        },
                                                    },
                                                ],
                                                onClick: (d) => {
                                                    calculatePiePercentage(d);
                                                },
                                            },
                                        ]}
                                    />
                                ) : (
                                    <DummyPieChart />
                                )}
                            </div>
                        </DataCard>
                    </div>
                    {platform === 'android' && (
                        <DataCard>
                            <div>
                                <p className="text-base mb-3.5">{t('hacking_attempts_by_device_information')} </p>
                                {!isAlertClose && (
                                    <Alert severity="warning" onClose={() => setAlertClose(true)}>
                                        <p>{t('hacking_attempts_by_device_information_tooltip')}</p>
                                        <a target="_blank" className="text-linkblue" rel="noopener noreferrer" href="https://helpcenter.appsealing.com/hc/en-us/articles/25267077311513">
                                            {'>> ' + t('check_android_category_details')}
                                        </a>
                                    </Alert>
                                )}
                            </div>
                            <div className="flex-grow overflow-hidden overflow-x-auto">
                                <div className="min-w-[600px]">
                                    <HackingAttemptsTable
                                        rows={deviceHackingDetails
                                            .sort((a: any, b: any) => b.hacking_cnt - a.hacking_cnt)
                                            .map((item: any) => ({
                                                ...item,
                                                register_datetime: item.calc_date,
                                                cnt: item.hacking_cnt,
                                            }))}
                                        hackingDetection={true}
                                        platform={platform}
                                        onShowDetailsClick={onShowDetailsClick}
                                    />
                                </div>
                            </div>
                        </DataCard>
                    )}

                    <DataCard title={t('hacking_type')}>
                        <div className="flex justify-end">
                            <div className="flex flex-wrap justify-end max-w-[600px] p-4 mr-4">
                                {hackingData.map((e: { id: string }) => {
                                    return (
                                        <div className="w-full md:w-1/3 text-right" key={e.id}>
                                            <div className="flex items-center text-xs text-darkgray cursor-pointer justify-end" onClick={() => filterHackingData(e.id)}>
                                                <p className="'h-4">{t(e.id.toLowerCase().replace(/ /g, '_'))}</p>
                                                <p
                                                    className="w-3 h-3 ml-1"
                                                    style={{
                                                        backgroundColor: clickedItems.includes(e.id) ? '#9ca3af' : hackingColors[e.id],
                                                    }}
                                                ></p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex-grow h-[500px]">
                            <ResponsiveLine
                                theme={{ grid: { line: { strokeWidth: 0.5 } } }}
                                data={hackingData.filter((e: any) => !clickedItems.includes(e.id))}
                                margin={{ top: 10, right: 50, bottom: 50, left: 50 }}
                                xScale={{ type: 'point' }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    tickValues: 10,
                                }}
                                colors={BarChartColor3}
                                pointSize={8}
                                pointColor="white"
                                pointBorderWidth={1}
                                pointBorderColor={{ from: 'serieColor' }}
                                useMesh={true}
                                tooltip={(e: any) => chartTooltip(e)}
                            />
                        </div>
                    </DataCard>
                    <div className={`w-full grid grid-cols-1 lg:gap-x-2.5 lg:grid-cols-3`}>
                        <DataCard col={1} title={t('os_version_major')}>
                            <div className="flex-grow min-h-[300px] 2xl:h-24">
                                {pieChartData.androidMajor.length > 0 ? (
                                    <ResponsivePie
                                        data={pieChartData.androidMajor}
                                        innerRadius={0.5}
                                        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        colors={PieChartColors}
                                        enableArcLinkLabels={false}
                                        activeOuterRadiusOffset={8}
                                        arcLabelsSkipAngle={20}
                                        arcLabel={(e) => `${e.data.distribution} %`}
                                        theme={{
                                            text: {
                                                fontSize: '8px',
                                            }, 
                                        }}
                                        tooltip={(e: any) => (
                                            <div className="bg-white p-2 text-[12px]">
                                                <strong>{e.datum.data.id} : </strong>
                                                <span>{`${e.datum.data.value?.toLocaleString()} (${e.datum.data.distribution} %)`}</span>
                                            </div>
                                        )}
                                    />
                                ) : (
                                    <DummyPieChart />
                                )}
                            </div>
                        </DataCard>
                        <DataCard col={1} title={t('os_version_minor')}>
                            <div className="flex-grow min-h-[300px] 2xl:h-24">
                                {pieChartData.androidMinor.length > 0 ? (
                                    <ResponsivePie
                                        data={pieChartData.androidMinor}
                                        innerRadius={0.5}
                                        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        enableArcLinkLabels={false}
                                        activeOuterRadiusOffset={8}
                                        colors={PieChartColors}
                                        arcLabelsSkipAngle={20}
                                        theme={{
                                            text: {
                                                fontSize: '8px',
                                            }, 
                                        }}
                                        arcLabel={(e) => `${e.data.distribution} %`}
                                        tooltip={(e: any) => (
                                            <div className="bg-white p-2">
                                                <strong>{e.datum.data.id} : </strong>
                                                <span>{`${e.datum.data.value?.toLocaleString()} (${e.datum.data.distribution} %)`}</span>
                                            </div>
                                        )}
                                    />
                                ) : (
                                    <DummyPieChart />
                                )}
                            </div>
                        </DataCard>
                        {platform === 'android' && (
                            <DataCard col={1} title={t('rooting')}>
                                <div className="flex-grow min-h-[300px] 2xl:h-24">
                                    {pieChartData.rooting.length > 0 ? (
                                        <ResponsivePie
                                            innerRadius={0.5}
                                            data={pieChartData.rooting}
                                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                            padAngle={0.7}
                                            cornerRadius={3}
                                            enableArcLinkLabels={false}
                                            activeOuterRadiusOffset={8}
                                            colors={PieChartColors}
                                            arcLabelsSkipAngle={20}
                                            theme={{
                                                text: {
                                                    fontSize: '8px',
                                                }, 
                                            }}
                                            arcLabel={(e) => `${e.data.distribution} %`}
                                            tooltip={(e: any) => (
                                                <div className="bg-white p-2">
                                                    <strong>{e.datum.data.id?.toLowerCase() === 'n' ? t('no') : t('yes')} : </strong>
                                                    <span>{`${e.datum.data.value?.toLocaleString()} (${e.datum.data.distribution} %)`}</span>
                                                </div>
                                            )}
                                        />
                                    ) : (
                                        <DummyPieChart />
                                    )}
                                </div>
                            </DataCard>
                        )}
                        {platform === 'ios' && (
                            <DataCard col={1} title={t('jailbreak')}>
                                <div className="flex-grow h-[300px] 2xl:h-24">
                                    {pieChartData.jailBreak.length > 0 ? (
                                        <ResponsivePie
                                            data={pieChartData.jailBreak}
                                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                                            padAngle={0.7}
                                            cornerRadius={3}
                                            enableArcLinkLabels={false}
                                            activeOuterRadiusOffset={8}
                                            colors={{ scheme: 'pastel1' }}
                                            arcLabelsSkipAngle={20}
                                            theme={{
                                                text: {
                                                    fontSize: '8px',
                                                }, 
                                            }}
                                            arcLabel={(e) => `${e.data.distribution} %`}
                                            tooltip={(e: any) => (
                                                <div className="bg-white p-2">
                                                    <strong>{e.datum.data.id?.toLowerCase() === 'n' ? t('no') : t('yes')} : </strong>
                                                    <span>{`${e.datum.data.value?.toLocaleString()} (${e.datum.data.distribution} %)`}</span>
                                                </div>
                                            )}
                                        />
                                    ) : (
                                        <DummyPieChart />
                                    )}
                                </div>
                            </DataCard>
                        )}
                    </div>

                    <div className="w-full grid grid-cols-1 1xl:grid-cols-2 mb-4 md:gap-x-4 gap-y-4">
                        <DataCard col={4} title={t('hacking_attempts_by_map_overlay')}>
                            <div className="flex flex-col relative">
                                <div className="flex-grow min-h-[600px]">
                                    <ResponsiveChoropleth
                                        data={totalData.countryData}
                                        features={mapFeatures}
                                        colors={ChoroplethChartColors}
                                        domain={[totalData.countryLegend.minDomain, totalData.countryLegend.maxDomain]}
                                        unknownColor="#f2f2f2"
                                        label="properties.name"
                                        fillColor="#000"
                                        valueFormat=".1s"
                                        borderWidth={0.3}
                                        projectionTranslation={[0.5, 0.6]}
                                        borderColor="#152538"
                                        tooltip={(e: any) =>
                                            e.feature?.data ? (
                                                <div className="bg-primary p-2 border border-solid">
                                                    <p className="font-semibold truncate max-w-sm">{e.feature.data.countryName}</p>
                                                    <p>
                                                        {t('no_of_hacking_types')}:{' '}
                                                        <span className="font-semibold">{`${e.feature.data.h_cnt?.toLocaleString()} (${e.feature.data.typeDistribution}%)`}</span>
                                                    </p>
                                                    <p>
                                                        {t('unique_devices_hacking_attempts')}:{' '}
                                                        <span className="font-semibold">{`${e.feature.data.d_cnt?.toLocaleString()} (${e.feature.data.deviceDistribution}%)`}</span>
                                                    </p>
                                                    <p>
                                                        {t('daily_avg_no_of_hacking_types')}: <span className="font-semibold">{e.feature.data.h_avg?.toLocaleString()}</span>
                                                    </p>
                                                    <p>
                                                        {t('daily_avg_unique_devices_hacking_attempts')}: <span className="font-semibold">{e.feature.data.d_avg?.toLocaleString()}</span>
                                                    </p>
                                                </div>
                                            ) : (
                                                <p />
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </DataCard>

                        <DataCard col={4} title={t('hacking_attempts_by_country_list')}>
                            <div className="flex-grow overflow-auto">
                                <CountryTable pageSize={9} countryDataFull={totalData.countryData.sort((a: any, b: any) => b.h_cnt - a.h_cnt)} />
                            </div>
                        </DataCard>
                    </div>
                </DataLayout>
            )}
        </React.Fragment>
    );
};

export default HackingDetection;
