import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

const drawerWidth = 230;
const iconOnlyWidth = 56;

const HoverDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded?: boolean }>(({ isExpanded }) => ({
    '.MuiDrawer-paper': {
        width: isExpanded ? drawerWidth : iconOnlyWidth,
        overflowX: 'hidden',
        transition: 'width 0.3s ease',
        whiteSpace: 'nowrap',
        marginTop: '58px',
    },
    '&:hover .MuiDrawer-paper': {
        width: drawerWidth,
    },
}));
interface MenuItemChild {
    title: string;
    path: string;
    disabled?: boolean;
    hide?: boolean;
}
interface MenuItem {
    title: string;
    icon: JSX.Element;
    path: string;
    children?: MenuItemChild[];
    hide?: boolean;
}

const HoverableDrawer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(false); // State to control the expansion of the drawer
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const handleNavigation = (path: string) => {
        setIsExpanded(false);
        navigate(path);
    };
    const isActive = (menuItem: MenuItem) => {
        const currentPath = location.pathname;
        if (currentPath.includes(menuItem.path)) return true;
        return menuItem.children?.some((child) => currentPath.startsWith(child.path));
    };

    const isChildActive = (menuItemChildren: MenuItemChild) => {
        const currentPath = location.pathname;
        if (currentPath.includes(menuItemChildren.path)) return true;
        return false;
    };
    let isExpired = planDetails.android.billing_type === 'T' && planDetails.android.trial_expired === 'Y';
    let isOwner = authContext?.role === 'Owner';
    let isSubOwner = authContext?.role === 'SubOwner';
    let isBetaAccount = authContext?.is_beta_account;
    let isManager = authContext?.is_group_manager;

    const menuList: MenuItem[] = [
        {
            title: 'analytics',
            icon: <SpaceDashboardIcon />,
            path: '/dashboard',
            children: [
                {
                    title: 'dashboard',
                    path: '/dashboard',
                },
                {
                    title: 'realTimeThreatAnalytics',
                    path: '/analytics',
                },
                {
                    title: 'hackingDetection',
                    path: '/threatdetection',
                },
                {
                    title: 'active_devices',
                    path: '/activedevices',
                },
            ],
        },
        {
            title: 'android',
            icon: <AndroidIcon />,
            path: isBetaAccount ? '/sealingandroid/configurations' : '/sealingandroid/sealing',
            children: [
                {
                    title: 'app_configurations',
                    path: '/sealingandroid/configurations',
                    hide: !isBetaAccount,
                },
                {
                    title: 'new_appsealing',
                    path: '/sealingandroid/sealing',
                },
                {
                    title: 'appsealing_preset',
                    path: '/sealingandroid/preset',
                },
                {
                    title: 'historical_information',
                    path: '/sealingandroid/history',
                },
                {
                    title: 'app_configurations',
                    path: '/sealingandroid/configurations',
                    hide: isBetaAccount,
                },
                {
                    title: 'manage_keystore',
                    path: '/sealingandroid/keystore',
                    hide: isBetaAccount,
                },
            ],
        },
        {
            title: 'ios',
            icon: <AppleIcon />,
            path: '/sealingios/configurations',
            children: [
                {
                    title: 'app_configurations',
                    path: '/sealingios/configurations',
                },
                {
                    title: 'manage_projects',
                    path: '/sealingios/projects',
                },
                {
                    title: 'aism',
                    path: '/sealingios/aism',
                },
            ],
        },
        {
            title: 'settings',
            icon: <SettingsIcon />,
            path: '/settings/notifications',
            children: [
                {
                    title: 'manage_notifications',
                    path: '/settings/notifications',
                },
                {
                    title: 'manage_services',
                    path: '/settings/service',
                },
            ],
        },
        {
            title: 'billingdetails',
            icon: <PaymentIcon />,
            path: '/billingdetails/info',
            hide: !isOwner,
            children: [
                {
                    title: 'billing_information',
                    path: '/billingdetails/info',
                },
                {
                    title: 'plan',
                    path: '/billingdetails/plan',
                },
                {
                    title: 'invoice',
                    path: '/billingdetails/invoice',
                },
                {
                    title: 'transaction_history',
                    path: '/billingdetails/history',
                },
            ],
        },
        {
            title: 'organization',
            icon: <AccountTreeIcon />,
            path: isOwner ? '/organization/accounts' : isBetaAccount && isManager ? '/organization/group/manage' : '/organization/serviceregion',
            children: [
                {
                    title: 'accounts',
                    path: '/organization/accounts',
                    disabled: !isOwner,
                },
                {
                    title: 'team_management',
                    path: '/organization/members',
                    hide: isBetaAccount,
                },
                {
                    title: 'user_management',
                    path: '/organization/group/members',
                    disabled: !(isOwner || isManager || isSubOwner),
                    hide: !isBetaAccount,
                },
                {
                    title: 'group_management',
                    path: '/organization/group/manage',
                    hide: !isBetaAccount,
                },
                {
                    title: 'user_log',
                    path: '/organization/user/log',
                    hide: !isBetaAccount,
                },
                {
                    title: 'security',
                    path: '/organization/security',
                    disabled: !isOwner,
                },
                {
                    title: 'service_region',
                    path: '/organization/serviceregion',
                    disabled: isExpired,
                },
                {
                    title: '3rd_party_integration',
                    path: '/organization/3rdpartyintegration',
                    disabled: isExpired,
                },
                {
                    title: 'account_withdrawal',
                    path: '/organization/withdrawal',
                    disabled: !isOwner,
                },
            ],
        },
        {
            title: 'notifications',
            icon: <SettingsIcon />,
            hide: true,
            path: '/announcement',
            children: [
                {
                    title: 'notifications',
                    path: '/announcement',
                },
            ],
        },
        {
            title: 'profile',
            icon: <SettingsIcon />,
            hide: true,
            path: '/profile',
            children: [
                {
                    title: 'profile',
                    path: '/profile',
                },
            ],
        },
    ];

    return (
        <div className="relative" style={{ height: 'calc(100vh - 58px)' }}>
            <HoverDrawer variant="permanent" open={true} anchor="left" onMouseEnter={() => setIsExpanded(true)} onMouseLeave={() => setIsExpanded(false)} isExpanded={isExpanded}>
                <Box sx={{ overflowX: 'hidden' }}>
                    <List>
                        {menuList.map(
                            (menuItem) =>
                                !menuItem.hide && (
                                    <ListItemButton
                                        key={menuItem.title}
                                        sx={{
                                            color: isActive(menuItem) ? 'white' : '#797A7C',
                                            backgroundImage: isActive(menuItem)
                                                ? `linear-gradient(45deg, rgba(60, 35, 124, 1), rgba(135, 52, 140, 1), rgba(148, 48, 130, 1), rgba(192, 38, 98, 1), rgba(219, 31, 78, 1), rgba(230, 29, 71, 1))`
                                                : 'inherit',
                                        }}
                                        onClick={() => handleNavigation(menuItem.path)}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                color: isActive(menuItem) ? 'white' : '#797A7C',
                                                minWidth: '40px',
                                            }}
                                        >
                                            {menuItem.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(menuItem.title)} />
                                    </ListItemButton>
                                ),
                        )}
                    </List>
                </Box>
            </HoverDrawer>
            <div className={`relative ml-[56px] ${isSidebarOpen ? 'isOpen' : 'isClose'} transition-all ease-in-out duration-300 bg-[#EFF2F5] p-0 list-none pt-1.5 sider-submenu text-sm`}>
                {menuList.map(
                    (menuItem) =>
                        isActive(menuItem) && (
                            <div className="min-w-[200px]" key={menuItem.title}>
                                <div className="h-10 pl-6 py-2 pr-4 rounded-tr-[25px] rounded-br-[25px] text-primary">{t(menuItem.title)}</div>

                                <span className="block h-px m-2" style={{ backgroundColor: 'rgba(224, 224, 224, 1)' }} />
                                <ul className="p-0">
                                    {menuItem.children?.map((child) =>
                                        child.hide ? null : child.disabled ? (
                                            <li key={child.title} className={`text-nowrap cursor-not-allowed`}>
                                                <div className="h-10 pl-6 py-2 pr-4 rounded-tr-[25px] rounded-br-[25px] text-[#999]">{t(child.title)}</div>
                                            </li>
                                        ) : (
                                            <li key={child.title} className={`text-nowrap ${isChildActive(child) ? 'is-menu-active' : ''}`}>
                                                <Link to={child.path}>
                                                    <div className="h-10 pl-6 py-2 pr-4 rounded-tr-[25px] rounded-br-[25px] text-primary">{t(child.title)}</div>
                                                </Link>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        ),
                )}
            </div>
            <div className={`sider-toggle ${isSidebarOpen ? 'toggle-open' : 'toggle-close'}`} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </div>
        </div>
    );
};

export default HoverableDrawer;
