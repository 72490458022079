import { errorDialog } from '../components/utils/notifications';
import i18next from 'i18next';
import { userLogoutFunction } from '../components/main/logout';

export const handleError = (code: string | number | null) => {
    if (code === '4000') {
        userLogoutFunction();
    } else window.location.reload();
    
};

export const handleErrorResponse = (code: string | number | null): void => {
    const errorConfig = {
        title: i18next.t('error'),
        content: '',
        onOk: () => handleError(code),
        okText: i18next.t('ok'),
        className: 'api-error-dialog',
        zIndex: 10008,
    };
    if (code === 401 || code === 403) {
        errorConfig.content = i18next.t(code === 401 ? 'session_timeout' : 'unauthorized_access');
    } else if (code === 504) {
        errorConfig.content = i18next.t('system_error');
    } else if (code === '4000') {
        errorConfig.content = i18next.t('session_timeout');
    } else if (code === null) {
        errorConfig.content = i18next.t('service_error');
    } else {
        return;
    }

    errorDialog(errorConfig);
};
