import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";


export const PlanExpired = () => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const onUpgradeClick = () => navigate(`/billingdetails/plan`);

    return (
        <div className="h-full p-2">
            <div className="flex flex-col h-full p-4 overflow-auto bg-white">
                <div className="flex-grow">
                    <div className="mb-2 text-2xl ant-descriptions-title opacity-70">{t('free_trial_period_is_expired')}</div>
                    <hr />
                    <p className="mt-2 mb-2 text-xl">{t('upgrade_now_to_protect_your_applications')}</p>
                    <p className="pt-2">{t('you_can_navigate_to_plan_settings_based_on_features')}:</p>
                    <div className="p-10 pt-2 pb-2">
                        <ul className="list-disc">
                            <li><span className="font-medium">{t('framework_for_android_plan')}:</span> {t('pure_android_unity_unreal_flutter')}</li>
                            <li><span className="font-medium">{t('framework_for_ios_plan')}:</span> {t('xcode_unity_unreal_flutter')}</li>
                            <li><span className="font-medium">{t('framework_for_hybrid_plan')}:</span> {t('react_native_ionic_cordova')}</li>
                            <li>
                                {i18n.language === 'kr_KR' && <a type="link" className="underline text-accent" href="p-0">{t('help_center')}</a>}
                                <span>{t('you_may_connect_with_us')}</span>
                                {i18n.language !== 'kr_KR' && <a type="link" className="underline text-accent" href="p-0">{t('help_center')}</a>}
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-row gap-4 p-5 pb-2">
                        <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>{t('upgrade_android_plan')}</Button>
                        <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>{t('upgrade_ios_plan')}</Button>
                        <Button variant="contained" color="primary" onClick={() => onUpgradeClick()}>{t('upgrade_hybrid_plan')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LineChartColors = ['#F52D2D', '#4ECCF3'];
export const BarChartColor3 = ["#F3E34E", "#F34EC5", "#514EF3", "#E6F34E", "#79F34E", "#F3CF4E", "#4EF3E9", "#F3804E", "#DC4EF3"];
export const BarChartColors2 =["#F34E4E", "#F45F5F", "#F46F6F", "#F48181", "#F49191", "#F4A1A1", "#F4C2C2", "#F5D4D4", "#F4E4E4"];
export const BarChartColors = ["rgba(78, 204, 243, 0.4)", "rgba(243, 236, 78, 0.4)", "rgba(181, 243, 78, 0.4)", "rgba(78, 243, 174, 0.4)", "rgba(171, 78, 243, 0.4)", "rgba(243, 227, 78, 0.4)", "rgba(243, 78, 197, 0.4)", "rgba(81, 78, 243, 0.4)", "rgba(230, 243, 78, 0.4)", "rgba(121, 243, 78, 0.4)", "rgba(243, 207, 78, 0.4)", "rgba(78, 243, 233, 0.4)", "rgba(243, 128, 78, 0.4)", "rgba(220, 78, 243, 0.4)"];
export const PieChartColors = ["#6FD4F3", "#B2E5F4", "#F3E76F", "#F4EEB2", "#726FF3", "#B3B2F4", "#5CC2BC", "#99D7D3", "#CAC05D", "#DBD59A", "#5A57BE", "#9796D4", "#3F93AD", "#88BACA", "#A8AB2A", "#C7C97B", "#2F55A0", "#7E95C2", "#7CA02F", "#ACC27E"];
export const ChoroplethChartColors = ["rgba(243, 78, 78, 0.1)", "rgba(243, 78, 78, 0.2)", "rgba(243, 78, 78, 0.3)", "rgba(243, 78, 78, 0.4)", "rgba(243, 78, 78, 0.5)", "rgba(243, 78, 78, 0.6)", "rgba(243, 78, 78, 0.7)", "rgba(243, 78, 78, 0.8)", "rgba(243, 78, 78, 0.9)", "rgba(243, 78, 78, 1)"];

type ColorMapType = {
    [key: string]: string;
  };
  
  const colorMap: ColorMapType = {
    green: "#96C217",
    blue: "#0288D1",
    purple: "#8548A8",
    "blue-steel": "#C4BA63",
    "grey-silver": "#517FF7",
    "yellow-lemon": "#1D8734",
    yellow: "#FF5C00",
    "green-jungle": "#8548A8",
    dark: "#F52D2D",
    "blue-dark": "#F5812D",
  };
  
 export const getColorForTag = (colorCode: keyof ColorMapType) => {
    return colorMap[colorCode] || "gray";
  };
  