import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SDKInfo {
    nativeSdkInfo: Array<any>,
    hybridSdkInfo: Array<any>
};

const initialData = {};

const getInitialSealingData = () => {
    let data: any = localStorage.getItem('ios_sealing_preferences');
    try {
        data = JSON.parse(data);
    } catch (error) { }

    if (!data) {
        data = { ...initialData };
    } else {
        data = {
            ...initialData,
            ...data
        };
    }

    return data;
};

interface IOSSealingReducerInterface {
    sealingPreferences: any,
    sdkInfo: SDKInfo | null,
};

const initialState: IOSSealingReducerInterface = {
    sealingPreferences: getInitialSealingData(),
    sdkInfo: null
};

const IOSSealingReducerSlice = createSlice({
    name: 'iosReducer',
    initialState,
    reducers: {
        setSealingPreferences: (state, action: PayloadAction<any>) => {
            state.sealingPreferences = action.payload
        },
        setIOSSdkInfo: (state, action: PayloadAction<any>) => {
            state.sdkInfo = action.payload
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = { ...initialState, sealingPreferences: state.sealingPreferences };
        }
    }
});

export const { setIOSSdkInfo, setSealingPreferences, userLogout } = IOSSealingReducerSlice.actions;

export default IOSSealingReducerSlice.reducer;