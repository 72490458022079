import { appFrameworks } from "./iosData";

export function getSealData(sdkInfo: any, isHybrid: boolean, isXamarin: boolean) {
  if (isHybrid) return sdkInfo?.hybridSdkInfo;
  return sdkInfo?.nativeSdkInfo; 
}


function processReactVersions(react_versions: any, data: any) {
  let frameworkData = [];
  let frameworkMinorData: any[] = [];

  for (const [key, value] of Object.entries(react_versions)) {
    let children = (value as any[]).map((element: any) => ({ title: element, value: `${key}.${element}`, selectable: true }));
    if (key === data.framework_major_version) frameworkMinorData = children;
    frameworkData.push({ title: key, value: key, children, selectable: false });
  }

  // Handle framework version checks
  if (!frameworkData.find(e => e.value === data.framework_major_version)) {
    data.framework_major_version = undefined;
    frameworkMinorData = [];
  }

  if (!frameworkMinorData.find(e => e.value === data.framework_version)) {
    data.framework_version = undefined;
  }

  return { frameworkData, frameworkMinorData };
}

export function refactorCodeBlock(sdkInfo: any, serviceVersions: any[], isHybrid: boolean, isXamarin: boolean, data: any) {
  if (!serviceVersions?.length) return;

  let sealData = getSealData(sdkInfo, isHybrid, isXamarin);

  let frmworkData:any[] = [];
  let frmworkMinorData = [];

  if (data.app_framework === 'ReactNative') {
    let react_versions = sdkInfo?.hybridSdkInfo.find((e: { service_version: any; }) => e.service_version === data.sealing_version)?.support_react_version_v2;
    react_versions = react_versions ? JSON.parse(react_versions) : {};

    ({ frameworkData: frmworkData, frameworkMinorData: frmworkMinorData } = processReactVersions(react_versions, data));
  }
  return {sealData, frmworkData, frmworkMinorData};
}


export const isHybridFramework = (framework: string) => appFrameworks.filter(e => e.type === 'hybrid').map(e => e.detail).includes(framework);
