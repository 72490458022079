import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8E44AD',
    },
    secondary: {
      main: '#505050',
    },
    error: {
      main: '#D32F2F',
    },
    success: {
      main: '#66BB6A',
    },
    warning: {
      main: '#FFA726',
    },
    info: {
      main: '#29B6F6',
    },
    text: {
      primary: "#505050",
      accent: "#8E44AD",
      disabled: "#9F9F9F"
    },
    background: {
      accent: "#8E44AD",
    },
    border: {
      primary: "#8E44AD",
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: '500'
        },
        h2: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: '400'
        },
        h4: {
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: '500'
        }
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: '4px',
          left: '4px',
          fontSize: '10px',
          backgroundColor: '#F52D2D',
          color: "#fff",
          minWidth: '18px',
          width: '18px',
          height: '18px',
          padding: '0 0 !important',
          border: '2px solid white',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '56px',
          borderBottom: '1px solid #E0E0E0',
          backgroundColor: 'white',
          boxShadow: 'none',
          justifyContent: 'center',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '32px !important',
          height: '32px',
          padding: '0 0 !important',
          paddingRight: '39px !important',
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        iconFilled: {
          color: '#8E44AD',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
        shrink: {
          transform: "translate(14px, -10px)",
          fontSize: "11px",
          letterSpacing: 0.6,
          lineHeight: "20px",
          "&.MuiInputLabel-sizeSmall": {
            transform: "translate(14px, -10px)" 
          }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          height: 34,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          color: 'white',
          backgroundColor: '#D7D7D7',
          '&:hover': {
            backgroundColor: '#BEBEBE',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        label: {
          fontSize: 14,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        fontSize: 14,
      },
      styleOverrides: {
        label: {
          fontSize: 14,
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
        },
        label: {
          fontSize: 14,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select.MuiSelect-select.MuiSelect-sizeSmall': {
            minHeight: '37px',
          },
          '&.Mui-disabled': {
            color: '#9F9F9F',
            '&::placeholder': {
              color: '#9F9F9F',
            },
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: 12,
          height: 20
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #D6D6D6',
          width: 316,
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '0 18px'
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '17px 0',
          borderBottom: '1px solid #D6D6D6',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '18px 0',
          borderTop: '1px solid #D6D6D6',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '23px 0',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        }
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 40,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogAction: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {

          '&:hover': {
            backgroundColor: 'rgba(133, 72, 168, 0.1)', // changed hover color
          },
        },
      },
    },
  },
});

export default theme;