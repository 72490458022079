import React, { useEffect } from "react";
import { useLocation } from "react-router";

export const Hint = (props: { text: string | JSX.Element, className?: string }) => {
    return (<div className={`mt-2 italic font-light text-xs ml-2 ${props.className || ''}`}>{props.text}</div>);
};

export const getLocalisedDate = (utcDate: Date) => {
    const localDate = new Date();
    localDate.setUTCFullYear(utcDate.getFullYear());
    localDate.setUTCMonth(utcDate.getMonth());
    localDate.setUTCDate(utcDate.getDate());
    localDate.setUTCHours(utcDate.getHours());
    localDate.setUTCMinutes(utcDate.getMinutes());
    localDate.setUTCSeconds(utcDate.getSeconds());
    localDate.setUTCMilliseconds(utcDate.getMilliseconds());
    return localDate;
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.querySelector("main")?.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export const getPageConfig = (defaultSize?: number) => {
    defaultSize = defaultSize || 10;
    let pageSize = defaultSize;

    if (window.matchMedia('(min-height: 1280px)').matches)
        pageSize = parseInt((defaultSize + (1.8 * defaultSize)) + '');
    else if (window.matchMedia('(min-height: 1024px)').matches)
        pageSize = parseInt(`${defaultSize + (1.2 * defaultSize)}`);
    else if (window.matchMedia('(min-height: 768px)').matches)
        pageSize = parseInt(`${defaultSize + (0.5 * defaultSize)}`);
    else if (window.matchMedia('(min-height: 640px)').matches)
        pageSize = defaultSize;
    else
        pageSize = 5;

    return {
        defaultPageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '40'].includes(`${pageSize}`) ? ['5', '10', '20', '40'] : [`${pageSize}`, '5', '10', '20', '40']
    }
};

export const exportData = (data: [any], fileName: string) => {
    let csvData: string = '';
    csvData = [
        Object.keys(data[0]).join(','),
        ...data.map((e: any) => Object.values(e).join(','))
    ].join('\n');

    window.URL = window.webkitURL || window.URL;


    let a = document.createElement('a');
    let csvFile: any = new Blob([csvData], { type: 'text/csv' });
    a.download = fileName;
    a.href = window.URL.createObjectURL(csvFile);
    a.textContent = 'Download CSV';
    a.click();
    window.URL.revokeObjectURL(a.href);
    csvFile = null;
    csvData = "";
};



export const validateTelephone = (value: any) => {
    const regularExpression = /^[0-9-+]+.{8,}$/;
    if (value && !regularExpression.test(value)) return "";
    return value;
};
  