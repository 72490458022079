import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from '@mui/material';
import AnnouncementItem from '../announcementItem';
import { NotificationsListType } from '../../../type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Link } from 'react-router-dom';
import { setBadgeCount } from '../../../store/common';
import { useTranslation } from 'react-i18next';

interface NotificationPopperProps {
  notifications: any;

}

const NotificationPopper = ({notifications} : NotificationPopperProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const badgeCount = useSelector((state: RootState) => state.commonReducer.badgeCount);
  const { t } = useTranslation();
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const readAllNotifications = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    let seqs: number[] = notifications.list.slice(0, 5).map((n: NotificationsListType) => n.seq);
    localStorage.setItem("isRead", JSON.stringify(seqs));
    dispatch(setBadgeCount(0));
}

  return (    
      <div onMouseLeave={handleClose}>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="p-1"
        >
          <Badge badgeContent={badgeCount} max={30}>
            <NotificationsIcon sx={{color: "#797A7C", fontSize: '24px'}}/>
          </Badge>
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className='z-[100]'
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:'right top',
              }}
            >
              <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    className='max-w-[367px] custom-menu'
                  >
                     <MenuItem key={'notification-head'} className='hover:bg-transparent'>
                      <div className='border-b border-[#E0E0E0] flex justify-between pb-4 items-center w-full'>
                        <strong
                            onClick={readAllNotifications}
                            className="text-[12px] cursor-pointer block z-10"
                        >
                            {t("all_read")}
                        </strong>
                        <Link
                            className="ml-auto appsealing-purple hover:underline hover:text-accent"
                            to="/announcement"
                        >
                            <span className='text-sm'>{t("view_all_notification")}</span>
                        </Link>
                        </div>
                    </MenuItem>
                     {notifications.list.slice(0, 5).map((item: NotificationsListType) => {
                      return (
                          <MenuItem key={item.seq} className={`custom-menu-item mx-3 py-3`}>
                              <AnnouncementItem
                              item={item}
                              locale={"en"}
                              tags={notifications.tags}
                              key={item.seq}
                              />
                          </MenuItem>
                          );
                      })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
  );
}

export default NotificationPopper;
