import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentIcon from "@mui/icons-material/Payment";
import DownloadIcon from "@mui/icons-material/Download";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";
import { RowData } from "./invoiceTable";

interface ActionMenuListProps {
  row: RowData;
  onMenuClick: (key: string, row: RowData) => void;
}

export const ActionMenuList = ({ row, onMenuClick }: ActionMenuListProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key?: string) => {
    setAnchorEl(null);
    if(key) onMenuClick(key, row)
  };
 
  return (
    <div>
      <Button
        sx={{ color: "black" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: 'bottom',  
          horizontal: 'right', 
        }}
        transformOrigin={{
          vertical: 'top',  
          horizontal: 'right',
        }}
      >
        {row.status_code === "PL" && (
          <MenuItem
            key ="1"
            onClick={() => handleClose('1')}
            className="flex flex-none items-center gap-2 text-sm px-5"
          >
            <PaymentIcon fontSize="small" />
            {t("pay_now")}
          </MenuItem>
        )}
        <MenuItem
          key ="2"
          onClick={() => handleClose('2')}
          className="flex flex-none items-center gap-2 text-sm px-5"
        >
          <DownloadIcon fontSize="small" />
          {t("download_invoice")}
        </MenuItem>
       {(row.status_code === 'P' || row.status_code === 'C' || row.status_code === 'CR') && <MenuItem
          key ="3"
          onClick={() => handleClose('3')}
          className="flex flex-none items-center gap-2 text-sm px-5"
        >
          <DownloadIcon fontSize="small" />
          {t("download_paid_receipt")}
        </MenuItem>}
        {row.status_code === "P" && (
          <MenuItem key="4"   onClick={() => handleClose('4')} className="flex flex-none items-center gap-2 text-sm px-5">
            <AttachMoneyIcon fontSize="small" />
            {t("raise_refund")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
