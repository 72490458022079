import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isHybridFramework, securityMeasuresInfo } from '.';
import React from 'react';

export const useSecurityMeansure = (formData: any, sealingData: any, fileType: string, selectedServiceDetails: any) => {
    const { t } = useTranslation();
    const [securityTip, setSecurityTip] = useState<any>('');
    const [securityMeasure, setSecurityMeasure] = useState<number>(35);

    useEffect(() => {
        if (formData) {
            let num = 45,
                text: Array<any> = [<p className="text-success underline">++ {t('appsealing_applied')}</p>];

            if (sealingData.findIndex((e: any) => e.service_version === formData.service_version) === 0) {
                num += 10;
                text.push(<p className="text-infoblue underline">+ {t('latest_appsealing_version')}</p>);
            } else text.push(<p className="text-warning underline">- {t('older_appsealing_version')}</p>);

            if (fileType === 'zip') {
                num += 45;
                text.push(<p className="text-infoblue underline">+ {t('app_sealing_will_be_applied_on_zip_file')}</p>);
            } else {
                const isHybrid = isHybridFramework(formData.app_framework);
                Object.keys(securityMeasuresInfo).forEach((key) => {
                    let value = formData[key];
                    let item = securityMeasuresInfo[key];
                    if (value) {
                        let sum = item[value].num ?? 0;
                        let color = item[value].color;
                        let label = item[value].text as string;
                        let symbol = item[value].symbol;

                        if (item?.compare && item.compare?.service_version) {
                            if ((key === 'block_mirroring' || key === 'block_remote_access') && formData.service_version < '2.27.0.0' && !isHybrid) {
                                num += sum;
                                text.push(
                                    <p className={`text-${color} underline`}>
                                        {symbol} {t(label)}
                                    </p>,
                                );
                            } else {
                                return;
                            }
                            if (formData.service_version >= item.compare?.service_version) {
                                num += sum;
                                text.push(
                                    <p className={`text-${color} underline`}>
                                        {symbol} {t(label)}
                                    </p>,
                                );
                            }
                        } else if (item?.compare && item.compare?.aos_service_version && selectedServiceDetails && selectedServiceDetails.aos_service_version) {
                            if (key === 'block_screen_capture' && selectedServiceDetails.aos_service_version >= item.compare?.aos_service_version) {
                                num += sum;
                                text.push(
                                    <p className={`text-${color} underline`}>
                                        {symbol} {t(label)}
                                    </p>,
                                );
                            } else if (formData.service_version < item.compare?.aos_service_version) {
                                num += sum;
                                text.push(
                                    <p className={`text-${color} underline`}>
                                        {symbol} {t(label)}
                                    </p>,
                                );
                            }
                        } else {
                            num += sum;
                            text.push(
                                <p className={`text-${color} underline`}>
                                    {symbol} {t(label)}
                                </p>,
                            );
                        }
                    }
                });
            }
            setSecurityMeasure(num);
            setSecurityTip(
                <React.Fragment>
                    {text.map((e, index) => (
                        <React.Fragment key={index}>{e}</React.Fragment>
                    ))}
                </React.Fragment>,
            );
        } else {
            setSecurityMeasure(35);
        }
    }, [formData, sealingData, fileType, t, selectedServiceDetails]);

    return { securityTip, securityMeasure };
};
