import { Tooltip } from "@mui/material";

interface DataCardProps {
  col?: number;
  title?: string;
  subtitle?: string | null;
  tooltip?: string;
  children: React.ReactNode;
  minHeight?: boolean;
  className?: string;
}

const DataCard = ({
  children,
  tooltip = "",
  title,
  col = 12,
  minHeight = false,
  subtitle,
  className = ""
}: DataCardProps) => {
  return (
    <div
      className={`col-span-${col} flex flex-col py-5 ${minHeight && "min-h-24"} px-[30px] bg-primary rounded-[5px] border border-[#E0E0E0] mb-2.5 ${className}`}
    >
      {title && <div className=' mb-3.5'>
        <p className='text-base cursor-pointer'>
          <Tooltip title={tooltip}>
            <span>{title}</span>
          </Tooltip>
        </p>
        <p> {subtitle && <span>{subtitle}</span>}</p>
      </div>}
      {children}
    </div>
  );
};

export default DataCard;
