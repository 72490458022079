import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getInviteHistory, getMemberList } from '../../../../services/manage/accounts';
import { RootState } from '../../../../store';
import EditMemberModal from './edit';
import InviteModal from './invite';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { InputAdornment, TextField, Chip, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../../../DataDisplay/Table';
import { GridRenderCellParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

const Members = () => {
    const { t, i18n } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [memberList, setMemberList] = useState(null);
    const [memberInfo, setMemberInfo] = useState(null);
    const [showMask, setShowMask] = useState<any>(null);
    const [inviteHistory, setInviteHistory] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [gridColumns, setGridColumns] = useState<any>([]);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const columns = [
        {
            headerName: t('user_name'),
            field: 'member_name',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('email_address'),
            field: 'member_email',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('notes'),
            field: 'notes',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            headerName: t('status'),
            field: 'user_status_translate',
            flex: 0.7,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <Chip label={params.row.user_status_translate} color={params.row.user_status_translate === 'Active' || params.row.user_status_translate === '정상' ? 'success' : 'warning'} />
            ),
        },
        {
            headerName: t('joining_date'),
            field: 'joined',
            sortable: true,
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => {
                if (!params.value) return '-';
                const formattedDate = moment(params.value, 'YYYYMMDDHHmmss').format('YYYY/MM/DD');
                return formattedDate;
            },
        },
    ];
    useEffect(() => {
        if (authContext?.role === 'Owner') {
            setGridColumns([
                ...columns,
                {
                    headerName: t('edit'),
                    flex: 0.3,
                    renderCell: (params: GridRenderCellParams) => {
                        const onEditClick = () => {
                            setMemberInfo(params.row);
                            setIsEditModalVisible(true);
                        };
                        return (
                            <IconButton disabled={params.row.member_email === userDetails.user_id} onClick={onEditClick}>
                                <EditIcon />
                            </IconButton>
                        );
                    },
                },
            ]);
        } else setGridColumns([...columns]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext, t, memberList]);

    useEffect(() => {
        const fetchMembers = async () => {
            let members = [];
            const params = { token: authContext.token };
            setShowMask({ msg: t('fetching_members_information') });
            const response = await getMemberList(params);
            setShowMask(null);
            if (response?.result?.code === '0000' && Array.isArray(response?.applicationTeamMemberList)) {
                members = response.applicationTeamMemberList;
                members.splice(members.length - 1, 1);
            }
            members.forEach((mem: { user_status_translate: string; user_status: string }) => {
                mem.user_status_translate = t(`user_status_${mem.user_status}`);
            });
            setMemberList(members);
        };

        fetchMembers();
    }, [authContext, i18n.language, t]);

    useEffect(() => {
        if (inviteHistory === null) {
            const fetchInviteHistory = async () => {
                let invites = [];
                const params = { token: authContext.token };
                const response = await getInviteHistory(params);
                if (response?.result?.code === '0000' && Array.isArray(response?.inviteHistory)) {
                    invites = response.inviteHistory;
                }
                setInviteHistory(invites);
            };
            fetchInviteHistory();
        }
    }, [inviteHistory, authContext]);

    const filterData = (data: any) => {
        const filteredList = data ? data.filter((member: any) => member.member_name.toLowerCase().includes(searchValue) || member.member_email.toLowerCase().includes(searchValue)) : data;

        return filteredList;
    };

    const onSearch = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    const handleEditCancel = () => {
        setMemberInfo(null);
        setIsEditModalVisible(false);
    };

    const updateMemberDetails = (data: any) => {
        let members: any = memberList;
        if (Array.isArray(data)) {
            members = data;
            members.splice(members.length - 1, 1);
            members.forEach((mem: { user_status_translate: string; user_status: string }) => {
                mem.user_status_translate = t(`user_status_${mem.user_status}`);
            });
        }
        setMemberInfo(null);
        setMemberList(members);
        setIsEditModalVisible(false);
    };

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);
    const updateHistoryDetails = () => setInviteHistory(null);

    const createRows = (data: any[]) => {
        const rows = data.map((item: any, index: number) => {
            return { ...item, id: index + 1 };
        });

        return rows;
    };

    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <div className="flex flex-wrap justify-between gap-6 mb-8 lg:flex-nowrap">
                <div className="text-2xl font-medium">
                    <span className="text-2xl font-medium whitespace-nowrap">{t('team_management')}</span>
                </div>
                <div className="lg:mt-0 w-full max-w-[656px]">
                    <TextField
                        id="search-input"
                        label="Search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder={t('team_search_placeholder')}
                        onChange={(e) => onSearch(e.target.value)}
                        className="w-full"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div>
                    <Button variant="contained" onClick={showModal} className="text-xs font-medium">
                        {t('add')}
                    </Button>
                </div>
            </div>

            <InviteModal visible={isModalVisible} inviteHistory={inviteHistory} onCancel={handleCancel} updateHistoryDetails={updateHistoryDetails} />
            <EditMemberModal visible={isEditModalVisible} memberInfo={memberInfo} onCancel={handleEditCancel} updateMemberDetails={updateMemberDetails} />

            <div className="overflow-auto">
                <div className="flex-grow pl-1 p-4 pt-1 min-w-[800px]">
                    <DataTable height={'100%'} columns={gridColumns} rows={createRows(filterData(memberList) || [])} pageSize={10} pageSizeOptions={[10, 15, 20, 30]} />
                </div>
            </div>
        </ContentLayout>
    );
};

export default Members;
