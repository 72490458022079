import React, { useEffect, useState } from "react";
import { resetPwd } from "../../services/login";
import { useTranslation } from "react-i18next";
import { showToast } from "../utils/notifications";
import LoadMask from '../utils/loadmask';
import AuthLayout from "./.";
import AuthForm from "./AuthForm";
import { forgotPasswordData } from "./AuthData";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageSelector from "./languageselector";

const ForgotPassword = () => {
  const { i18n, t } = useTranslation();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loadMask, showLoadMask] = useState(false);
  const [title, setTitle] = useState(t("forgot_password"));

  useEffect(() => {
    if(location.pathname === '/forgotpassword') {
      setTitle(t('forgot_password'));
    } else if(location.pathname === '/updatepassword') {
      setTitle(t('update_password'));
    }
  }, [location.pathname, t, title])

  const onResetPwdClick = async (values: any) => {
    if (values.user_id) {
      showLoadMask(true);
      let response = await resetPwd({
        user_id: values.user_id,
        lang_set: i18n.language === "kr_KR" ? "KR" : "EN",
      });
      showLoadMask(false);
      if (
        response?.result.code === "0000" &&
        response?.result.message === "SUCCESS"
      )
        setIsPasswordReset(true);
      else {
        const msg =
          t(`RCM-${response.result.code}`) !== ""
            ? t(`RCM-${response.result.code}`)
            : response?.result?.message ||
              t("user_id_does_not_exists_in_system");
        showToast("error", msg);
      }
    } else showToast("error", t("enter_valid_user_id_to_proceed"));
  };

  return (
    <div>
      {loadMask && <LoadMask msg={t('validating_user_information')} />}
      <AuthLayout>
        {isPasswordReset ? (
          <div className={`pt-[120px] px-[80px] pb-8`}>
            <div className={`text-xl font-medium text-center mb-20`}>
              {t("find_password")}
            </div>
            <div className="text-center">
              {t("please_check_your_inbox_for_password")}
            </div>
            <Button
              className="w-full h-[42px] mt-20"
              variant="contained"
              onClick={() => navigate("/login")}
            >
              {t("back_to_login")}
            </Button>
            <div className={`flex justify-end w-full mt-2`}>
              <LanguageSelector />
            </div>
          </div>
        ) : (
          <AuthForm
            title={title}
            data={forgotPasswordData}
            btnText={title}
            onSubmit={onResetPwdClick}
          />
        )}
      </AuthLayout>
    </div>
  );
};

export default ForgotPassword;
