
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authoriseUser,
  requestWithdrawal,
} from "../../../../../services/manage/settings";
import { RootState } from "../../../../../store";
import { setAuthContext } from "../../../../../store/main";
import { setCookies } from "../../../../../utility/Common";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { PasswordInput } from "../../../../Foundation/Input";
import { Controller, useForm } from "react-hook-form";
import { showToast } from "../../../../utils/notifications";

interface ConfirmWithdrawalInterface {
  visible: boolean;
  onCancel: () => void;
  feedbackDetails?: string;
  feedbackSelection: Array<any>;
}

const ConfirmWithdrawal = (props: ConfirmWithdrawalInterface) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );
  const planDetails = useSelector(
    (state: RootState) => state.mainReducer.planDetails
  );
  const userDetails = useSelector(
    (state: RootState) => state.mainReducer.userDetails
  );

  const onConfirmClick = async (values: any) => {
    if (isAuthorised) {
      const params = {
        account_id: planDetails.account_id,
        content: `- ${props.feedbackSelection.join("<br/>- ")} ${props.feedbackDetails ? "<br/>- " + props.feedbackDetails : ""}`,
        token: authContext.token,
        user_id: userDetails.user_id,
        user_pw: values.user_pw,
      };
      const response = await requestWithdrawal(params);
      if (response?.result?.code === "0000") {
        showToast("success", t("account_deactivation_success_msg"));
        setTimeout(() => {
          setCookies("appsealing", "");
          dispatch(setAuthContext(null));
        }, 5000);
      } else if (response?.result?.code) {
        reset();
        setIsAuthorised(false);
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            response?.result?.message
          ) as string
        );
      } else {
        reset();
        setIsAuthorised(false);
        showToast(
          "error",
          t(
            "account_deactivation_failure_msg",
            "Error while deleting account, Please try again or connect with help desk"
          )
        );
      }
    } else {
      const params = {
        user_id: userDetails.user_id,
        user_pw: values.user_pw,
        token: authContext.token,
      };
      const response = await authoriseUser(params);
      if (response?.result?.code === "0000") {
        setIsAuthorised(true);
      } else {
        reset();
        setIsAuthorised(false);
        showToast(
          "error",
          response?.result?.code || response?.result?.message
            ? (t(
                `RCM-${response?.result?.code}`,
                response?.result?.message
              ) as string)
            : t("failed_to_validate_please_try_again_later")
        );
      }
    }
  };

  return (
    <Dialog
      open={props.visible}
      onClose={props.onCancel}
    >
      <form onSubmit={handleSubmit(onConfirmClick)}>
        <DialogTitle id='confirm-withdrawal-title'>
          <Typography variant="h1">{t("confirm_account_withdrawal")}</Typography>  
        </DialogTitle>
        <DialogContent>
          {isAuthorised ? (
            <div>
              <p className='mt-2 mb-1'>
                {t(
                  "withdrawal_confirmation_msg",
                  `After withdrawal confirmation account will be suspended and data will be removed permanently after 30 days from the request.`
                )}
              </p>
              <p className='mt-2 mb-1'>
                {t("are_you_sure_to_request_to_withdraw_your_account")}
              </p>
            </div>
          ) : (
            <div>
              <p className='mt-2 mb-1'>
                {t("to_validate_authority_please_confirm_your_password")}
              </p>
              <Controller
                name='user_pw'
                control={control}
                rules={{ required: t("password_is_required") }}
                render={({ field: { ref, ...field } }) => (
                  <PasswordInput
                    label={t("password")}
                    className='max-w-[325px]'
                    fullWidth={true}
                    ref={ref}
                    {...field}
                  />
                )}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions className="p-0 mt-10">
          <Button variant="outlined" color="secondary" onClick={props.onCancel}>{t("cancel")}</Button>
          <Button type='submit' color='primary' variant="contained">
            {isAuthorised ? t("agree_delete") : t("confirm_identity")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmWithdrawal;
