import { AdvancedFeaturesData, GeneralFeaturesData } from "./data";

interface AdvancedDetectionProps {
  getEl: (data: any, key: string) => React.ReactNode;
}

const AdvancedDetection = ({getEl}: AdvancedDetectionProps) => {
    return (
        <>
            <div className="mb-5 flex flex-col gap-2">
                {getEl(
                    GeneralFeaturesData.filter((e) => e.type === 'malicious_behaviour'),
                    'malicious_behaviour',
                )}
                {getEl(
                    AdvancedFeaturesData.filter((e) => e.type === 'others'),
                    'others',
                )}
            </div>
        </>
    );
};

export default AdvancedDetection;
