import { useState } from "react";
import { useTranslation } from "react-i18next";
import Arm64WarningImageEn from "../../../../assets/images/arm64_warning_en.png";
import Arm64WarningImageKr from "../../../../assets/images/arm64_warning_kr.png";
import XattrImage from "../../../../assets/images/Xattr.png";
import Arm64DragImage from "../../../../assets/images/arm64_drag.png";
import Arm64LinkImage from "../../../../assets/images/arm64_link.png";
import Arm64FinalImage from "../../../../assets/images/arm64_final.png";
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface Arm64PopupProps {
  btnText: string;
  onClick: (value: string) => void;
  disabled?: boolean;
}

const Arm64Popup = ({ btnText, onClick, disabled }: Arm64PopupProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { i18n, t } = useTranslation();
  const onClose = () => {
    setVisible(false);
  };
  const handleDownload = () => {
    onClick("latest");
  }
  return (
    <>
      <Button
        disabled={disabled}
        variant='contained'
        onClick={() => setVisible(true)}
        sx={{
          width: "100%",
          maxWidth: 200,
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {btnText}
      </Button>
      <Dialog
        open={visible}
        onClose={onClose}
        maxWidth='md'
        fullWidth
        sx={{ "& .MuiDialog-paper": { maxWidth: "720px" } }} 
      >
        <DialogTitle className="flex justify-between items-center gap-5 mb-10">
          <Typography variant="h2">{t("aism_arm64_guide_title")}</Typography>
          <CloseIcon onClick={()=> setVisible(false)} className="opacity-50"/>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "65vh"}}>
            <p style={{ color: "#F52D2D", fontWeight: "bold" }}>
              {t("aism_arm64_guide_warning")}
            </p>
            <p style={{ justifyContent: "center", display: "flex" }}>
              <img
                src={
                  i18n.language === "kr_KR"
                    ? Arm64WarningImageKr
                    : Arm64WarningImageEn
                }
                alt='Arm64WarningImage'
              />
            </p>
            <p>{t("aism_arm64_instruction")}</p>
            <p>{t("aism_arm64_instruction_1")}</p>
            <p>{t("aism_arm64_instruction_2")}</p>
            <p>
              {t("aism_arm64_instruction_3_1")}
              <strong className='mx-1'>“xattr -c “</strong>
              {t("aism_arm64_instruction_3_2")}
            </p>
            <p className='flex justify-center'>
              <img src={XattrImage} alt={"xattr"} />
            </p>
            <p>{t("aism_arm64_instruction_4")}</p>
            <p className='mt-4'>
              <img src={Arm64DragImage} alt='arm64guideimage' />
            </p>
            <p className='flex justify-center'>
              <img src={Arm64FinalImage} alt='arm64guideimage' />
            </p>
            <p>{t("aism_arm64_instruction_5")}</p>
            <p className='flex justify-center'>
              <img src={Arm64LinkImage} alt='arm64guideimage' />
            </p>
            <p>{t("aism_arm64_instruction_6")}</p>
          </Box>
        </DialogContent>
        <DialogActions className="p-0 mt-10">
          <Button
            onClick={handleDownload}
            color='primary'
            variant='contained'
            sx={{ textTransform: "uppercase", fontWeight: "semibold" }}
          >
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Arm64Popup;
