// src/hooks/useGroupManagement.ts

import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    getSubGroupList,
    updateMemberPosition,
    deleteApp,
    deleteMemberFromGroup,
    addAppToGroup,
    grantPermission,
    updatePermission,
    removePermission,
    addGroupMember,
} from '../services/manage/groupmanagement';
import { RootState } from '../store';
import { showDialog, showToast } from '../components/utils/notifications';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useGroupManagement = (initialTabIndex: number = 0) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const [tabIndex, setTabIndex] = useState(initialTabIndex);
    const [showMask, setShowMask] = useState<{ msg: string } | null>(null);
    const [subGroupList, setSubGroupList] = useState<any[]>([]);
    const [appList, setAppList] = useState<any[]>([]);
    const [permissionList, setPermissionList] = useState<any[]>([]);
    const [group, setGroup] = useState<any>(null);
    const [groupId, setGroupId] = useState<string | null>(null);
    let query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        const groupIdParam = query.get('groupId');
        if (groupIdParam) {
            setGroupId(groupIdParam);
            fetchSubGroupList(groupIdParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSubGroupList = useCallback(
        async (groupId: string | null) => {
            setShowMask({ msg: 'Fetching group list...' });
            try {
                const response = await getSubGroupList({ token: authContext.token, groupId: groupId ?? undefined });
                if (response?.result?.code === '0000') {
                    let data = response?.data.groups[0];
                    setGroup(data);
                    setSubGroupList(data.members);
                    setAppList(data.apps);
                    setPermissionList(data.permissions);
                } else {
                    showToast('error', t('error_while_fetching_group_list'));
                }
            } catch (error) {
                setShowMask(null);
            } finally {
                setShowMask(null);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authContext.token],
    );

    const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    }, []);
    const handleAddMember = useCallback(async (params: any, handleCloseDrawer: any) => {
        const response = await addGroupMember(params);

        if (response?.result?.code === '0000') {
            showToast('success', t('group_member_added_successfully'));
            setSubGroupList(response.data.groups[0].members);
            handleCloseDrawer();
        } else {
            showToast('error', t(`RCM-${response?.result?.code}`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleUpdateMemberPosition = useCallback(
        async (id: number, newPosition: string) => {
            setShowMask({ msg: 'Updating position...' });
            const groupIdParam = query.get('groupId');
            const lang = i18n.language === 'kr_KR' ? 'KR' : 'EN';
            try {
                const response = await updateMemberPosition({
                    token: authContext.token,
                    memberId: id,
                    type: newPosition,
                    groupId: groupIdParam,
                    lang,
                });

                if (response.result.code === '0000') {
                    setSubGroupList(response.data.groups[0].members);
                    showToast('success', t('role_updated_successfully'));
                } else {
                    showToast('error', t(`RCM-${response.result.code}`));
                }
            } catch (error) {
                showToast('error', t('error_while_updating_role'));
            } finally {
                setShowMask(null);
            }
        },
        [authContext.token, query, t],
    );
    const handleDeleteMember = useCallback(
        async (id: string) => {
            const groupIdParam = query.get('groupId');
            showDialog({
                title: t('delete_member'),
                content: t('are_you_sure_you_want_to_delete_member'),
                okColor: 'error',
                onOk: async () => {
                    try {
                        const response = await deleteMemberFromGroup({
                            token: authContext.token,
                            groupId: groupIdParam,
                            memberId: id,
                        });

                        if (response.result.code === '0000') {
                            setSubGroupList((prevRows) => prevRows.filter((row) => row.memberId !== id));
                            showToast('success', t('member_deleted_successfully'));
                        } else {
                            showToast('error', t('error_while_deleting_member'));
                        }
                    } catch (error) {
                        console.error('Failed to delete member', error);
                        showToast('error', t('error_while_deleting_member'));
                    }
                },
                okText: t('delete'),
                cancelText: t('cancel'),
            });
        },
        [authContext.token, query, t],
    );

    const handleAddApp = useCallback(
        async (appId: number, platform: string, handleDrawerClose: () => void, setSelectedApp: (app: any) => void) => {
            const groupIdParam = query.get('groupId');
            try {
                const response = await addAppToGroup({
                    token: authContext.token,
                    groupId: groupIdParam ?? '',
                    appId: appId,
                    platform: platform === 'android' ? 'AOS' : 'IOS',
                });

                if (response.result.code === '0000') {
                    const newApp = response.data.groups[0].apps;
                    setAppList(newApp);
                    showToast('success', t('successfully_added_app_to_group'));
                    handleDrawerClose();
                } else {
                    showToast('error', t(`RCM-${response.result.code}`));
                }
            } catch (error) {
                showToast('error', t('failed_to_add_app_to_group'));
            } finally {
                setSelectedApp(null);
            }
        },
        [authContext.token, query, t],
    );

    const handleDeleteApp = useCallback(
        async (id: number) => {
            const groupIdParam = query.get('groupId');
            showDialog({
                title: t('delete_app'),
                content: t('are_you_sure_you_want_to_delete_this_app'),
                okColor: 'error',
                onOk: async () => {
                    setShowMask({ msg: t('deleting_app') });
                    try {
                        const response = await deleteApp({
                            token: authContext.token,
                            groupAppId: id,
                            groupId: groupIdParam ?? '',
                        });

                        if (response?.result?.code === '0000') {
                            const updatedAppList = appList.filter((row) => row.groupAppId !== id);
                            setAppList(updatedAppList);
                            showToast('success', t('app_deleted_successfully'));
                        } else {
                            showToast('error', t('error_while_deleting_app'));
                        }
                    } catch (error) {
                        showToast('error', 'Error while deleting app');
                    } finally {
                        setShowMask(null);
                    }
                },
                okText: 'Yes',
                cancelText: 'No',
            });
        },
        [appList, authContext.token, query, t],
    );

    const handleMenuChange = useCallback(
        (type: string, id: number, platform: string, groupAppId: number) => {
            if (type === 'delete_from_group') {
                handleDeleteApp(groupAppId);
            } else {
                navigate(`/settings/service/configure/${id}?groupId=${groupId}&platform=${platform === 'IOS' ? 'ios' : 'android'}`);
            }
        },
        [groupId, handleDeleteApp, navigate],
    );

    const handleGrantPermission = useCallback(
        async (groupAppId: string | number, platform: string, memberId: string) => {
            try {
                const response = await grantPermission(
                    {
                        token: authContext.token,
                        groupId: groupId ?? '',
                        groupAppId: Number(groupAppId) ?? '',
                        memberId: memberId ?? '',
                    },
                    platform,
                );

                if (response?.result?.code === '0000') {
                    setPermissionList(response.data.groups[0].permissions);
                } else {
                    showToast('error', t('error_while_granting_permission'));
                }
            } catch (error) {
                showToast('error', t('error_while_granting_permission'));
            }
        },
        [authContext.token, groupId, t],
    );

    const handleUpdatePermission = useCallback(
        async (permissionId: string, permissionType: string) => {
            try {
                const response = await updatePermission({
                    token: authContext.token,
                    groupId: groupId ?? '',
                    permissionId,
                    permissionType,
                });

                if (response?.result?.code === '0000') {
                    showToast('success', t('permission_updated_successfully'));
                } else {
                    showToast('error', t('error_while_updating_permission'));
                }
            } catch (error) {
                console.error('Error updating permission:', error);
                showToast('error', t('error_while_updating_permission'));
            }
        },
        [authContext.token, groupId, t],
    );

    const handleRemovePermission = useCallback(
        async (permissionId: string) => {
            try {
                const response = await removePermission({
                    token: authContext.token,
                    groupId: groupId ?? '',
                    permissionId: permissionId,
                });

                if (response?.result?.code === '0000') {
                    setPermissionList(response.data.groups[0].permissions);
                    showToast('success', t('removed_permission_successfully'));
                } else {
                    showToast('error', t('error_while_removing_permission'));
                }
            } catch (error) {
                showToast('error', t('error_while_removing_permission'));
            }
        },
        [authContext.token, groupId, t],
    );

    return {
        showMask,
        tabIndex,
        group,
        subGroupList,
        appList,
        permissionList,
        handleAddApp,
        handleTabChange,
        handleAddMember,
        handleUpdateMemberPosition,
        handleDeleteMember,
        handleDeleteApp,
        handleMenuChange,
        handleGrantPermission,
        handleUpdatePermission,
        handleRemovePermission,
    };
};
