import { Control, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Box, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormState } from '../../../../type/user/index';
interface PreferencesProps {
    control: Control<FormState>;
}
const Preferences = ({ control }: PreferencesProps) => {
    const { t } = useTranslation();
    const languages = useSelector((state: RootState) => state.commonReducer.languages);
    const timezones = useSelector((state: RootState) => state.commonReducer.timezones);

    return (
        <Box className="flex flex-col mt-4">
            <Typography variant="h6" className="font-bold text-base mb-4">
                {t('site_preferences')}
            </Typography>
            <Box className="flex flex-col md:flex-row gap-8">
                <FormControl className="lg:max-w-[325px]" fullWidth variant="outlined">
                    <InputLabel>{t('preferred_language')}</InputLabel>
                    <Controller
                        name="preferences.lang_set"
                        control={control}
                        render={({ field }) => (
                            <Select {...field} label={t('preferred_language')}>
                                {languages &&
                                    languages.map((lang) => (
                                        <MenuItem key={lang.value} value={lang.value}>
                                            {lang.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl className="lg:max-w-[325px]" fullWidth variant="outlined">
                    <InputLabel>{t('preferred_timezone')}</InputLabel>
                    <Controller
                        name="preferences.timezone_code"
                        control={control}
                        render={({ field }) => (
                            <Select {...field} label={t('preferred_timezone')}>
                                {timezones.map((zone) => (
                                    <MenuItem key={zone.timezone_code} value={zone.timezone_code}>
                                        {zone.timezone}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Box>
            <Controller
                name="preferences.view_sign_yn"
                control={control}
                render={({ field: { onChange, onBlur, name, value, ref } }) => (
                    <FormControlLabel
                        control={<Checkbox checked={value === 'Y'} onChange={(e) => onChange(e.target.checked ? 'Y' : 'N')} onBlur={onBlur} name={name} inputRef={ref} size="small" />}
                        label={<Typography className="text-xs lg:text-sm">{t('dont_remind_me_to_sign_my_sealed_apk_when_i_want_to_download_it')}</Typography>}
                        className="my-4"
                    />
                )}
            />
        </Box>
    );
};

export default Preferences;
