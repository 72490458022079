import React, { useState } from 'react';
import { TextField as MUIInput, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface CustomInputProps {
  label?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
  required?: boolean;
  className?: string;
  error?: boolean;
  helperText?: string; 
  ref?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<CustomInputProps> = ({
  value,
  required,
  placeholder = '',
  error = false,
  helperText,
  disabled = false,
  fullWidth = false,
  readOnly = false,
  onChange,
}) => {
  return (
    <MUIInput
      error={error} 
      helperText={helperText}
      label="Outlined"
      variant="outlined"
      required={required}
      InputProps={{
        readOnly: readOnly,
      }}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      fullWidth={fullWidth}
    />
  );
};

export const PasswordInput = React.forwardRef<HTMLInputElement, CustomInputProps>(({
  value,
  required,
  placeholder = '',
  error = false,
  helperText,
  disabled = false,
  fullWidth = false,
  readOnly = false,
  className,
  label,
  onChange,
}, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <MUIInput
      error={error}
      placeholder={placeholder}
      helperText={helperText}
      label={label}
      disabled={disabled}
      required={required}
      type={showPassword ? 'text' : 'password'}
      className={className}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      variant="outlined"
      margin="normal"
      autoComplete={"new-password"}
      inputRef={ref} 
      InputProps={{
        readOnly: readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

export default Input;