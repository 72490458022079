export const ThreatDetectedData = [
    {
        platform: 'ios',
        items: [
            {
                type: 'jailbreak',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Jailbreak',
            },
            {
                type: 'dynamic_memory_cracking',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Dynamic memory cracking',
            },
            {
                type: 'repackaged_app',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Repackaged app',
            },
            {
                type: 'debugging',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Debugging',
            },
            {
                type: 'bypass_security_module',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'Bypass security module',
            },
            {
                type: 'cheat_tool',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'Cheat tool',
            },
            {
                type: 'speed_modification',
                hoverColor: 'bg-blue-200',
                iconColor: 'text-[#127BF5]',
                value: 'Speed modification',
            },
        ],
    },
    {
        platform: 'android',
        items: [
            {
                type: 'code_hooking',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Code hooking',
            },
            {
                type: 'disabling_security',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Tampering system & disabling security',
            },
            {
                type: 'code_modification',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Code modification',
            },
            {
                type: 'repackaged_application',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Repackaged application',
            },
            {
                type: 'debugging',
                hoverColor: 'bg-red-200',
                iconColor: 'text-[#F52D2D]',
                value: 'Debugging',
            },
            {
                type: 'rooted_device',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'Rooted device',
            },
            {
                type: 'app_cloning_environment',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'App Cloning environment',
            },
            {
                type: 'cheat_tool',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'Cheat tool',
            },
            {
                type: 'emulator',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'Android emulator',
            },
            {
                type: 'usb_debugging',
                hoverColor: 'bg-yellow-200',
                iconColor: 'text-[#F5812D]',
                value: 'USB Debugging',
            },
            {
                type: 'speed_modification',
                hoverColor: 'bg-blue-200',
                iconColor: 'text-[#127BF5]',
                value: 'Speed modification',
            },
            {
                type: 'etc',
                hoverColor: 'bg-blue-200',
                iconColor: 'text-[#127BF5]',
                value: 'ETC',
            },
        ],
    },
];
