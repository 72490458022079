import { Post } from "../..";
import { ActiveDeviceDataInterface, ActiveDeviceLogInterface } from "../../../type/api/data";
import { getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const getHackingDataStream = async (params: ActiveDeviceDataInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, calc_date_from, calc_date_to, package_name, group_by_device, type = 'android', limit = '10000' } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('calc_date_from', calc_date_from);
    urlParams.append('calc_date_to', calc_date_to);
    urlParams.append('group_by_device', `${group_by_device}`);
    urlParams.append('limit', limit);
    if (type === 'android')
        urlParams.append('package_name', package_name);
    else
        urlParams.append('bundle_id', package_name);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(type === 'android' ? RegionalURL.hackingauditstream : RegionalURL.ioshackingauditstream, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getHackingLogStream = async (params: ActiveDeviceLogInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, reportEndDT, reportStDT, package_name, core_version, sdk_version, type = 'android',
        country, device_model, sealing_mode = 'R', reqDocSize = '10000' } = params;
    let urlParams = new URLSearchParams();

    if (type === 'android' && core_version)
        urlParams.append('core_version', core_version);
    if (type === 'ios' && sdk_version)
        urlParams.append('sdk_version', sdk_version);
    urlParams.append('country', country);
    urlParams.append('device_model', device_model);
    if (type === 'ios')
        urlParams.append('bundle_id', package_name);
    else
        urlParams.append('package_name', package_name);
    urlParams.append('reportStDT', reportStDT);
    urlParams.append('reportEndDT', reportEndDT);
    urlParams.append('reqDocSize', reqDocSize);
    urlParams.append('sealing_mode', sealing_mode);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(type === 'android' ? RegionalURL.hackingauditlogstream : RegionalURL.ioshackingauditlogstream, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};