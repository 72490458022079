// src/components/GroupManagement/MemberTable.tsx

import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, IconButton, Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import DataTable from '../../../../DataDisplay/Table';
import { useGroupManagement } from '../../../../../hooks/useGroupManagement';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import GroupDrawer from '../GroupDrawer';
import { showToast } from '../../../../utils/notifications';
import LoadMask from '../../../../utils/loadmask';

const MemberTable: React.FC = () => {
    const { t } = useTranslation();
    const [showMask, setShowMask] = useState<any>(null);
    const { group, subGroupList, handleDeleteMember, handleUpdateMemberPosition, handleAddMember } = useGroupManagement();
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const [open, setOpen] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(true);

    useEffect(() => {
        const getManager = subGroupList.find((member) => member.type === 'Manager');
        const managerUserId = getManager ? getManager.userId : null;
        if (isOwner || (managerUserId && userDetails.user_id === getManager.userId)) {
            setDisable(false);
        }
    }, [isOwner, subGroupList, userDetails.user_id]);

    const MemberColumns: GridColDef[] = [
        { field: 'memberName', headerName: t('member_name'), flex: 1 },
        { field: 'userId', headerName: t('member_id'), flex: 1 },
        {
            field: 'type',
            headerName: t('position'),
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <Select
                    value={params.value}
                    onChange={(event) => handleUpdateMemberPosition(params.row.memberId, event.target.value)}
                    fullWidth
                    disabled={disable}
                    variant="standard"
                    sx={{
                        border: 'none',
                        '&::before': {
                            display: 'none',
                        },
                        '&::after': {
                            display: 'none',
                        },
                    }}
                >
                    <MenuItem value="Manager">{t('group_manager')}</MenuItem>
                    <MenuItem value="Member">{t('member')}</MenuItem>
                </Select>
            ),
        },
        {
            field: 'action',
            headerName: t('action'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <IconButton disabled={disable || userDetails.user_id === params.row.userId} onClick={() => handleDeleteMember(params.row.memberId)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];
    const getRowId = (row: any) => row.userId;
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const filterData = (data: any) => {
        const filteredList = data
            ? data.filter((group: any) => {
                  const groupNameMatch = group.userId.includes(searchQuery) || group.memberName.includes(searchQuery);
                  return groupNameMatch;
              })
            : [];

        return filteredList;
    };

    const handleSaveDrawer = async (data: any, type: string) => {
        if (subGroupList.some((member) => member.userId === data.memberEmail)) {
            showToast('error', t('already_in_group'));
            return;
        }

        setShowMask({ msg: t('adding_group_member') });

        const params = { token: authContext.token, groupId: group.groupId, ...data };
        handleAddMember(params, handleCloseDrawer);
        setShowMask(null);
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };
    const handleOpenDrawer = () => {
        setOpen(true);
    };

    return (
        <div>
            {showMask && <LoadMask msg={showMask.msg} />}
            <div className="flex items-center justify-between">
                <TextField
                    className="max-w-[600px] mb-8"
                    size="small"
                    label={t('find_member')}
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearch}
                    margin="normal"
                    placeholder={t('team_search_placeholder')}
                    InputLabelProps={{ shrink: true }}
                />
                <Button variant="contained" onClick={handleOpenDrawer} disabled={disable}>
                    {t('add_group_member')}
                </Button>
            </div>
            <GroupDrawer open={open} onSave={handleSaveDrawer} onClose={handleCloseDrawer} type={'add_group_member'} subGroupList={subGroupList} />
            <DataTable height={'100%'} columns={MemberColumns} rows={filterData(subGroupList)} pageSize={12} pageSizeOptions={[10, 12, 20, 30]} getRowId={getRowId} />
        </div>
    );
};

export default MemberTable;
