import { Alert, AlertColor, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, SnackbarOrigin, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import theme from '../../../assets/styles/theme';

interface ToastOptions {
    autoHideDuration?: number;
    position?: SnackbarOrigin;
    onClose?: () => void;
}

interface ToastProps {
    type: AlertColor;
    message: string;
    options?: ToastOptions;
}

interface DialogProps {
    title?: string;
    content?: string | JSX.Element;
    className?: string;
    okText?: string;
    cancelText?: string;
    okColor?: 'primary' | 'secondary' | 'error' | 'success';
    onOk?: () => void;
    onCancel?: () => void;
    cancelButtonProps?: {
        hidden: boolean;
    };
    okButtonProps?: {
        hidden: boolean;
    };
}
// Toast component
const ToastComponent = ({ type, message, options }: ToastProps) => {
    const { onClose } = options || {};
    const [open, setOpen] = React.useState(true);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} style={{ zIndex: 10000 }}>
            <Alert onClose={handleClose} variant="filled" severity={type} sx={{ minWidth: '320px', width: '100%', color: '#ffffff' }}>
                <AlertTitle>{t(type)}</AlertTitle>
                <span className="whitespace-pre-wrap">{message}</span>
            </Alert>
        </Snackbar>
    );
};

// showToast function
export const showToast = (type: AlertColor, msg: string, options?: ToastOptions) => {
    const toastContainer = document.createElement('div');
    document.body.appendChild(toastContainer);

    const root = createRoot(toastContainer);
    root.render(
        <ThemeProvider theme={theme}>
            <ToastComponent type={type} message={msg} options={options} />
        </ThemeProvider>,
    );
};

export const showDialog = ({ title, content, okText = 'Yes', cancelText = 'No', onOk, onCancel, cancelButtonProps, okButtonProps, className = '', okColor = 'primary' }: DialogProps) => {
    const DialogComponent = () => {
        const [open, setOpen] = useState(true);

        const handleClose = (isConfirmed: boolean) => {
            setOpen(false);
            if (isConfirmed && onOk) onOk();
            else if (!isConfirmed && onCancel) onCancel();
        };

        return (
            <>
                {className ? (
                    <Dialog open={open} onClose={() => handleClose(false)} className={className}>
                        <DialogTitle variant="h1" className="mb-3">
                            {title}
                        </DialogTitle>
                        <DialogContent className="my-10 min-w-[444px] text-sm whitespace-pre-line">{content}</DialogContent>
                        <DialogActions>
                            {cancelButtonProps && cancelButtonProps.hidden ? (
                                <></>
                            ) : (
                                <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
                                    {cancelText}
                                </Button>
                            )}
                            {okButtonProps && okButtonProps.hidden ? (
                                <></>
                            ) : (
                                <Button onClick={() => handleClose(true)} color={okColor} variant="contained">
                                    {okText}
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                ) : (
                    <Dialog open={open} onClose={() => handleClose(false)} sx={{ '& .MuiPaper-root': { padding: '24px !important' } }}>
                        <DialogTitle className="mb-5 text-[20px]">
                            <Typography variant="h1" color={okColor ? okColor : undefined}>
                                {title}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className="min-w-[444px] text-sm whitespace-pre-line">{content}</DialogContent>
                        <DialogActions className="p-0 mt-5">
                            {cancelButtonProps && cancelButtonProps.hidden ? (
                                <></>
                            ) : (
                                <Button onClick={() => handleClose(false)} className="text-xs font-medium" variant="outlined" size="small" color="secondary">
                                    {cancelText}
                                </Button>
                            )}
                            {okButtonProps && okButtonProps.hidden ? (
                                <></>
                            ) : (
                                <Button onClick={() => handleClose(true)} className="text-xs font-medium" color={okColor} size="small" variant="contained">
                                    {okText}
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )}
            </>
        );
    };

    const dialogContainer = document.createElement('div');
    document.body.appendChild(dialogContainer);
    const root = createRoot(dialogContainer);
    root.render(
        <ThemeProvider theme={theme}>
            <DialogComponent />
        </ThemeProvider>,
    );
};

// errorDialog function
export const errorDialog = ({ title = 'Error', content, okText = 'OK', onOk }: DialogProps) => {
    const DialogComponent = () => {
        const [open, setOpen] = useState(true);

        const handleClose = () => {
            setOpen(false);
            if (onOk) onOk();
        };

        return (
            <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { padding: '24px !important' } }}>
                <DialogTitle className="mb-5">
                    <Typography variant="h1">{title}</Typography>
                </DialogTitle>
                <DialogContent className="min-w-[444px] text-sm">{content}</DialogContent>
                <DialogActions className="p-0 mt-5">
                    <Button variant="contained" onClick={handleClose} color="primary">
                        {okText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const dialogContainer = document.createElement('div');
    document.body.appendChild(dialogContainer);
    const root = createRoot(dialogContainer);
    root.render(
        <ThemeProvider theme={theme}>
            <DialogComponent />
        </ThemeProvider>,
    );
};
